import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'

// __ __ Components/Helpers/Models __ __ //
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import ActionIcon from '../../../../../../_metronic/assets/icons/ActionIcon'
import DepartmentsModel from './DepartmentsModel'
import {Department} from '../core/_models'
import {getDepartment, deleteDepartment} from '../core/_requests'
import {useAuth} from '../../../../auth'
import {KTSVG} from '../../../../../../_metronic/helpers'
import { useDeleteDepartmentMutation, useGetAllDepartmentsQuery } from '../../../../services/departments'
import { useNavigate } from 'react-router-dom';

const DepartmentsListing = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  // const [department, setDepartment] = useState<Department[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [check, setCheck] = useState<string[]>([])
  const [selected, setSelected] = useState<string[]>([])
  const [deleteDataArr, setdeleteDataArr] = useState<string[]>([])
  const [data, setData] = useState({})
  const [pagination, setPagination] = useState({page: '', limit: '', pages: ''})
  const { data : userDeparmentList, error } = useGetAllDepartmentsQuery(currentUser?.id)
  const [deleteDepartment] = useDeleteDepartmentMutation()
  const [isSitesExist, setIsSitesExist] = useState<any>(false)

  const [deleteCascade, setDeleteCascade] = useState(false);
  // __ __ Getting all Departments __ __ //
  useEffect(() => {
    if(userDeparmentList){
      checkVistingPage(userDeparmentList?.department)
    }
  }, [userDeparmentList])

  // const getAllDepartments = () => {
  //   if (currentUser) {
  //     console.log(currentUser, "currentUsercurrentUser")
  //     getDepartment(currentUser?.id)
  //       .then((response: any) => {
  //         setDepartment(response?.data?.department)
  //       })
  //       .catch((error) => {
  //         console.error('Error:', error)
  //       })
  //   }
  // }

  const checkVistingPage=(dataList:any)=>{
    if(isSitesExist===false)
    {if(dataList && dataList?.length>0){
      setIsSitesExist(dataList?.length)
    }
    else{
      setIsSitesExist(0)
    }
  }
  }
  // __ __ Handling listing checkboxes __ __ //
  const handleCheckboxChange = (id: string) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
    setdeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = userDeparmentList?.department?.map((item:any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setdeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setdeleteDataArr([])
    }
  }

  // __ __ Handling Modals __ __ //
  const handleShow = (item: Department) => {
    setData(item)
    setShow(true)
  }

  const cancle = () => {
    setDeleteModal(false)
    setDeleteCascade(false)
  }

  const confirm = () => {
    deleteSelectedDepartments(check)
  }

  const handleClose = () => setShow(false)

  // __ __ Deleting Departments __ __ //
  const deleteSelectedDepartments = async (ids: any[]) => {
    const res = await deleteDepartment({userId:currentUser?.id, cascade:deleteCascade ? 1 : 0, id:ids}).unwrap()
    // getAllDepartments()
    if(res.status === 409){
      // setDeleteCascade(true)
      toast.info(res.message)
    }else{
      toast.success(res?.message) 
    }
      setSelected([])
      setdeleteDataArr([])
      setCheck([])
      setDeleteModal(false)
  }

  return (
    <>
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        <div id='kt_content_container' className='container'>
          <div className='card'>
            <div className='card-header border-0 pt-6 justify-content-between'>
              <h3 className="card-title">
                <span className='me-2 align-middle'><i className="la la-list fs-1" aria-hidden="true"></i></span>
                <span className="card-label fw-bold fs-3 mb-1">List of Departments</span>
              </h3>
              <div className='card-toolbar'>
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                  <button
                    type='button'
                    className='btn btn-primary me-3'
                    onClick={() => {
                      setShow(true)
                      setData({})
                    }}
                  >
                    <span className='svg-icon svg-icon-2'>
                      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    </span>
                    Add Department
                  </button>
                  <ConfirmationPopup deleteModal={deleteModal} cancle={cancle} confirm={confirm} deleteCascade={deleteCascade}/>
                  <DepartmentsModel
                    handleClose={handleClose}
                    show={show}
                    // getAllDepartments={getAllDepartments}
                    data={data}
                  />
                  {/* <button type='button' className='btn btn-light-primary me-3 custom_ml_4'>
                    <span className='svg-icon svg-icon-2'>
                      <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                    </span>
                    Import
                  </button> */}
                  {deleteDataArr.length > 0 && (
                    <button
                      type='button'
                      className='btn btn-danger'
                      onClick={() => {
                        setDeleteModal(true)
                        setCheck(deleteDataArr)
                        // deleteSelectedCategories(deleteDataArr)
                      }}
                    >
                      Delete Selected ( {deleteDataArr.length} )
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className='card-body py-4'>
            <p className='text-dark'>Add <strong>departments</strong> that own or house the particular assets. Make them as broad or as specific as you want. <strong>Departments</strong> can be <em>'Accounting'</em>, <em>'Marketing'</em>, or <em>'Executive'</em>. Customize to your particular need.</p>
              <div className='custom_table_responsive table-responsive'>
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                  role='table'
                >
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      <th colSpan={1} role='columnheader' className='w-10px pe-2'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check='false'
                            data-kt-check-target='#kt_table_users .form-check-input'
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </div>
                      </th>
                      <th colSpan={1} role='columnheader' className='min-w-125px'>
                        Name
                      </th>
                      <th colSpan={1} role='columnheader' className='text-end min-w-100px'>
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                    {userDeparmentList?.department?.length > 0 ? (
                      userDeparmentList?.department?.map((item: Department, idx: number) => {
                        return (
                          <tr key={item?.id} role='row'>
                            <td role='cell' className=''>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  data-kt-check='false'
                                  data-kt-check-target='#kt_table_users .form-check-input'
                                  checked={selected.includes(item?.id)}
                                  onChange={() => handleCheckboxChange(item?.id)}
                                />
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex flex-column'>
                                  <p className='text-gray-800 text-hover-primary mb-1'>
                                    {item?.department}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <div className='dropdown text-end p_25'>
                              <button
                                className='btn btn-secondary dropdown-toggle custom_action'
                                type='button'
                                id='dropdownMenuButton1'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                Action
                                <ActionIcon />
                              </button>
                              <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                <li>
                                  <span className='dropdown-item' onClick={() => handleShow(item)}>
                                    Edit
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className='dropdown-item'
                                    onClick={() => {
                                      setDeleteModal(true)
                                      setCheck([item.id])
                                      // deleteSelectedDepartments(item.id)
                                    }}
                                  >
                                    Delete
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* <Pagination
                  className='m-3'
                  defaultCurrent={1}
                  pageSize // items per page
                  current={pagination.page} // current active page
                  total={pagination.pages} // total pages
                  onChange={PageHandle}
                  locale={localeInfo}
                /> */}
              </div>
            </div>
            {isSitesExist===0?
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button onClick={()=>{navigate(`/apps/setup/database/asset`)}} className='btn btn-primary'>
              {/* {!loading && isCompanyAdded? 'Submit':!loading && !isCompanyAdded?'Continue':''} */}
              Continue
              {/* {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )} */}
            </button>
          </div>
          :""}
          </div>
        </div>
      </div>
    </>
  )
}

export default DepartmentsListing
