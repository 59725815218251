export interface LeaseReturnInterface {
    leaseReturnNotes:string, 
    returnDate:string|Date,
    sendEmail:string,
    userId:number, 
    assetId:number
}
export const defaultLeaseReturnDetail: LeaseReturnInterface = {
    leaseReturnNotes:"", 
    returnDate:new Date().toISOString().split('T')[0],
    sendEmail:"",
    userId:0, 
    assetId:0
}
