import React from 'react';
import SitesListing from './components/SitesListing';

const Sites = () => {
  return (
    <div>
      <SitesListing />
    </div>
  );
};

export default Sites;