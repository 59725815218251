export type Location = {
  location?: string
  siteId?: number | null
}

export const initialLocation: Location = {
  location: '',
  siteId: null,
}


export type Site = {
  id: number,
  name: "",
  description?: "t",
  address?: "",
  aptSuite?: "",
  city?: "",
  state?: "",
  postalCode?: "",
  country?: 0,
  userId?: 1,
  createdAt?: "",
  updatedAt?: ""
}


export type LocationResponse = {
  id: number,
  location: "",
  siteId: "",
  userId: "",
  createdAt?: "",
  updatedAt?: ""
}



