import React from 'react'
import { CustomEmail } from './components/CustomEmail'
// import ReminderEmails from './components/ReminderEmails'
const CustomEmails = () => {
  return (
    <>
      <CustomEmail/>
      
    </>
  )
}

export default CustomEmails
