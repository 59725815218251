import React, {useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table'
import {toast} from 'react-toastify'
import {ID} from '../../../../../../_metronic/helpers'
import {useAuth} from '../../../../auth'
import {CustomerResponse} from '../core/_models'
import {deleteCustomers, getAllCustomers} from '../core/_requests'
import {CustomersHeader} from './CustomersHeader'
import CustomersModal from './CustomersModal'
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import ActionIcon from '../../../../../../_metronic/assets/icons/ActionIcon'
import { useDeleteCustomersMutation, useGetAllCustomersQuery } from '../../../../services/customers'
import { useNavigate } from 'react-router-dom';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { useIntl } from 'react-intl'
import { PageTitle } from '_metronic/layout/core'

import {
  Grid,
  TableHeaderRow,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
  DragDropProvider,
  TableColumnReordering,
  VirtualTable,
  TableSelection,

} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

function ToggleButton(props) {
  return (
    <button onClick={props.onToggle} ref={props.buttonRef} className="btn btn-light-primary me-2 d-flex align-items-center  main-btn-style">
      <i className="la la-cog fs-xl-3 fs-4 me-1"></i> Setup Column
    </button>
  );
}

const CustomersListing = (props: any) => {
  const intl = useIntl()

  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const userId = currentUser?.id
  const [check, setCheck] = useState<number[]>([])
  const [skip, setSkip] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false)
  const [searchAssets, setSearchAssets] = useState<any>('');
  const [data, setData] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const [selected, setSelected] = useState<number[]>([])
  // const [userCustomersList, setUserCustomersList] = useState<CustomerResponse[]>([])
  const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  // const { data : userCustomersList, error } = useGetAllCustomersQuery(currentUser?.id)
  const { data: userCustomersList, isSuccess } = useGetAllCustomersQuery({id:userId,values:searchAssets}, { skip })

  const [deleteCustomers] = useDeleteCustomersMutation();
  const [isCustomersExist, setIsCustomersExist] = useState<any>(false)
  const [searchFields, setSearchFields] = useState<any[]>([]);
  const [deleteCascade, setDeleteCascade] = useState(false);
  const [selectAllData, setSelectAllData] = useState<boolean>(false)
  const [selectedData, setSelectedData] = useState<boolean>(false)



  const initialColumns = [
    { name: 'fullName', title: 'Name' },
    { name: 'company', title: 'Company' },
    { name: 'address1', title: 'Address1' },
    { name: 'address2', title: 'Address2' },
    { name: 'city', title: 'City' },
    { name: 'state', title: 'State' },
    { name: 'zip', title: 'Zip' },
    { name: 'country', title: 'Country' },
    { name: 'phone', title: 'Phone' },
    { name: 'cell', title: 'Cell' },
    { name: 'email', title: 'Email' },
    { name: 'notes', title: 'Notes' },
    {
      name: 'selections',
      title: <input
        type='checkbox'
        className='form-check-input custom-form-check-input'
        onChange={(e) => handleSelectAll(e)}
        name={'multicheckSelect'}
      />
      ,
      getCellValue: (row) => (
        <div>
          <input
            type='checkbox'
            className='form-check-input custom-form-check-input'
            // checked={selected.includes(row.id)}
            onChange={() => { handleCheckboxChange(row.id) }}
            name={'multicheckSelect'}
          />
        </div>
      ),
    },
    {
      name: 'actions',
      title: 'Action',
      getCellValue: (row) => (
        <div>
          {/* <div className='dropdown'>
            <button
              className='btn btn-secondary dropdown-toggle custom_action'
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              Action
              <ActionIcon />
            </button> */}
            <ul className='list-unstyled d-flex'>
              <li onClick={() => handleShow(row)}>
                <span className='btn btn-secondary main-btn-style py-2 px-3 me-2'>Edit</span>
              </li>
              <li
                onClick={() => {
                  setDeleteModal(true)
                  setCheck([row.id])
                  console.log("itemmmmmm", row.id)
                }}
              >
                <span className='btn btn-secondary main-btn-style py-2 px-3'>Delete</span>
              </li>
            </ul>
          {/* </div> */}
        </div>
      ),
    },
  ];
  const [columns, setColumns] = useState<any>(initialColumns);
  const [selection, setSelection] = useState<any>([]);
  const [tableColumnExtensions] = useState([
    { columnName: 'gender', width: 100 },
  ]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['city', 'zip', "state"]);
  const [columnOrder, setColumnOrder] = useState([
    "selections",
    "fullName",
    'company',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'country',
    'phone',
    'cell',
    'email',
    'notes',
    'actions',
  ]);

  const handleClose = () => {
    // getSites()
    setShow(false)
  }
  useEffect(() => {
    setSkip(false) //set skip false becasue skip param in query hook does not load on mount
  }, [])
  const handleSearch=()=>{
    setSkip(false)
  }

  const handleShow = (item: CustomerResponse) => {
    setData(item)
    setShow(true)
  }

  useEffect(() => {
  checkVistingPage(userCustomersList?.customers)    
  }, [userCustomersList])

  // const getSites = () => {
  //   if (currentUser) {
  //     getAllSites(currentUser?.id)
  //       .then((response: any) => {
  //         setUserCustomersList(response)
  //         console.log(response, 'ressssssss')
  //       })
  //       .catch((error) => {
  //         console.error('Error:', error)
  //       })
  //   }
  // }

  // const handleCheckboxChange = (id: number) => {
  //   setSelected((prevSelected) => {
  //     if (prevSelected.includes(id)) {
  //       return prevSelected.filter((itemId) => itemId !== id)
  //     } else {
  //       return [...prevSelected, id]
  //     }
  //   })
  //   setdeleteDataArr((prevSelected) => {
  //     if (prevSelected.includes(id)) {
  //       return prevSelected.filter((itemId) => itemId !== id)
  //     } else {
  //       return [...prevSelected, id]
  //     }
  //   })
  // }
  const checkVistingPage=(dataList:any)=>{
    if(isCustomersExist===false)
    {if(dataList && dataList?.length>0){
      setIsCustomersExist(dataList?.length)

    }
    else{
      setIsCustomersExist(0)
    }
  }
  }
  // const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     const newSelecteds = userCustomersList?.customers?.map((item:any) => item.id)
  //     setSelected(newSelecteds)
  //     setSelectAll(true)
  //     setdeleteDataArr(newSelecteds)
  //   } else {
  //     setSelected([])
  //     setSelectAll(false)
  //     setdeleteDataArr([])
  //   }
  // }

  const deleteSelectedCustomers = async (id: number[]) => {
    const res = await deleteCustomers({id,userId}).unwrap()
    if(res.status === 409){
      // setDeleteCascade(true)
      toast.info(res.message)
    }else{
      if(res?.status){
        // setDeleteCascade(true)
        toast.success(res.message)
        if(res?.failedIds?.length>0){
          setTimeout(() => {
            toast.info('Some Customers have related records in another table')  
          }, 5000);
        }
      }else{
        //  
        toast.info(res.message)
      }
    }
      setCheck([])
      setdeleteDataArr([])
      setSelectAll(false)
      setDeleteModal(false)
      setDeleteCascade(false)
  }


    //cancle the delete modal
    const cancle = () => {
      setDeleteModal(false)
      setDeleteCascade(false)
    }

    //confirm the delete modal
    const confirm = () => deleteSelectedCustomers(check)
    const searchFieldsOptions = [
      { label: "All", value: "*" },
      { value: 'assetTagId', label: 'Asset Tag ID' },
      { value: 'brand', label: 'Brand' },
      { value: 'description', label: 'Description' },
      { value: 'model', label: 'Model' },
      { value: 'purchasedfrom', label: 'Purchased from' },
      { value: 'serialNo', label: 'Serial No' }
  ];
  function onChangeSearchFields(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
        setSearchFields(searchFieldsOptions);
        value = searchFieldsOptions.slice(1)
    } else if (
        event.action === "deselect-option" &&
        event.option.value === "*"
    ) {
        setSearchFields([]);
        value = []
    } else if (event.action === "deselect-option") {
        setSearchFields(value.filter((o) => o.value !== "*"));
    } else if (value.length === searchFieldsOptions.length - 1) {
        setSearchFields(searchFieldsOptions);
    } else {
        setSearchFields(value);
    }
    props.handleSearchAssets({ name: event.name, value: value });
}

const handleCheckboxChange = (id: any) => {
  setSelected((prevSelected) => {
    if (prevSelected.includes(id)) {
      return prevSelected.filter((itemId) => itemId !== id)

    } else {
      return [...prevSelected, id]
    }
  })
  setdeleteDataArr((prevSelected) => {
    if (prevSelected.includes(id)) {
      setSelectAll(false)
      return prevSelected.filter((itemId) => itemId !== id)
    } else {
      return [...prevSelected, id]
    }
  })
}
const handleSelectAll = (data) => {
  if (data.target.checked) {
    setSelectAllData(true);
    setSelectAll(data.target.checked);
    setSelectedData(true);
  }
  else {
    setSelectAllData(false);
    setSelectAll(data.target.checked);
    setSelectedData(false);
    setSelected([]);
    setdeleteDataArr([]);
  }
};





useEffect(() => {
  if (selectAllData) {
    const newSelecteds = userCustomersList?.customers?.map((item: any) => item.id) || [];
    setSelected(newSelecteds);
    setdeleteDataArr(newSelecteds);
  }
  setSelectAllData(false)
}, [selectedData])
useEffect(() => {
  if (deleteDataArr && deleteDataArr.length === 0) {
    // Find all checkboxes with the name attribute set to "multicheckSelect"
    const multicheckSelectCheckboxes = document.querySelectorAll('[name="multicheckSelect"]');

    // Iterate through the selected checkboxes and uncheck them
    multicheckSelectCheckboxes.forEach((checkbox) => {
      if (checkbox instanceof HTMLInputElement) {
        checkbox.checked = false;
      }
    });
  }
  else if (selectAll == true) {
    const multicheckSelectCheckboxes = document.querySelectorAll('[name="multicheckSelect"]');

    // Iterate through the selected checkboxes and uncheck them
    multicheckSelectCheckboxes.forEach((checkbox) => {
      if (checkbox instanceof HTMLInputElement) {
        checkbox.checked = true;
      }
    });
  }
  else if (selectAll == false) {
    const multicheckSelectCheckbox = document.querySelector('[name="multicheckSelect"]') as HTMLInputElement;

    if (multicheckSelectCheckbox) {
      // If the checkbox exists and selected is empty, uncheck the checkbox
      multicheckSelectCheckbox.checked = false;
    }
  }

}, [deleteDataArr])
useEffect(() => {
  const savedColumnVisibility = localStorage.getItem('customerColumnVisibility');
  const savedColumnOrder = localStorage.getItem('customerGridColumnOrder');
  if (savedColumnVisibility) {
    const visibilityMap = JSON.parse(savedColumnVisibility);
    // Apply the saved visibility state to your columns
    const updatedColumns = initialColumns.map((column) => ({
      ...column,
      hidden: !visibilityMap[column.name],
    }));
    setColumns(updatedColumns);
    const falseKeys = Object.keys(visibilityMap).filter(key => visibilityMap[key] === false);
    
    setHiddenColumnNames(falseKeys)
  }
  if (savedColumnOrder) {
    setColumnOrder(JSON.parse(savedColumnOrder));
  }
}, []);

// Handle column visibility changes
const handleColumnVisibilityChange = (hiddenColumns) => {
  // Update the columns state
  setHiddenColumnNames(hiddenColumns);
  const updatedColumns = columns.map((column) => ({
    ...column,
    hidden: hiddenColumns.includes(column.name),
  }));
  setColumns(updatedColumns);

  // Save the column visibility state in localStorage
  const visibilityMap = {};
  updatedColumns.forEach((column) => {
    visibilityMap[column.name] = !column.hidden;
  });
  localStorage.setItem('customerColumnVisibility', JSON.stringify(visibilityMap));
};
const handleColumnOrderChange = (newOrder) => {
  // Update state with new column order
  setColumnOrder(newOrder);
  // Save column order to local storage
  localStorage.setItem('customerGridColumnOrder', JSON.stringify(newOrder));
};


  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Customers' })}</PageTitle>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className='container'>
            <div className='card'>
            <ConfirmationPopup deleteModal={deleteModal} cancle={cancle} confirm={confirm} deleteCascade={deleteCascade} />
              <CustomersHeader deleteDataArr={deleteDataArr} setDeleteModal={setDeleteModal} setCheck={setCheck} handleShow={handleShow}/>
              <CustomersModal show={show} handleClose={handleClose} CustomerData={data}/>
            
              <div className='card-body py-4'>
              <p className={'text-dark'}>Manage your customers. After you add customers, you can lease assets to them.</p>
              <div className="row">
                <div className='col-md-4 col-lg-3'>
                  <div className="form-group me-sm-4 w-100 w-sm-auto mb-md-3">
                    <label className="mb-1" htmlFor="sSearch_1"> Search Customer / Employee</label>
                    <div className="input-group">
                        <input type="search" className="form-control" onChange={(e)=>{setSearchAssets(e.target.value)}} value={searchAssets} placeholder="NAME, EMAIL or PHONE" id="sSearch_1" name="sSearch_1" />
                        {/* <button type="button" onClick={(e)=>{handleSearch}} className="btn btn-secondary bg-light" aria-label="search" ><i className="fa fa-search" aria-hidden="true"></i></button> */}
                    </div>
                  </div>
                </div>
                {/* <div className='col-md-4 col-lg-3'>
                  <div className="form-group mb-3 assetSearch_field">
                      <label className="col-form-label pt-0 pb-1">Search fields</label>
                      <div className='ReactMultiSelectCheckboxes'>
                          <ReactMultiSelectCheckboxes
                              options={searchFieldsOptions}
                              // value={searchFields}
                              // onChange={setSearchFields}
                              value={searchFields}
                              onChange={onChangeSearchFields}
                              // setState={setSelectedOptions}
                              name="searchField"
                              labelledBy="Select"
                              className="form-control"
                              width = "100%"
                              placeholderButtonLabel="Asset Tag ID, Brand, Description, Model"
                          />
                      </div>
                      <span className="field-validation-valid" data-valmsg-for="chk_searchFields" data-valmsg-replace="true"></span>
                  </div>
                </div> */}
              </div>
              
              
              <div className="card react-grid-card">
                  <Grid
                    rows={userCustomersList?.customers || []}
                    columns={columns}
                  >

<VirtualTable />
        <DragDropProvider />
        <TableHeaderRow />
        {/* <TableColumnVisibility
          defaultHiddenColumnNames={[]}
          onHiddenColumnNamesChange={handleColumnVisibilityChange}
        /> */}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={handleColumnVisibilityChange}
        />
        <Toolbar />
        <ColumnChooser toggleButtonComponent={ToggleButton} />

                    {/* <TableColumnReordering
                      defaultOrder={[
                        "selections",
                        "fullName",
                        'company',
                        'address1',
                        'address2',
                        'city',
                        'state',
                        'zip',
                        'country',
                        'phone',
                        'cell',
                        'email',
                        'notes',
                        'actions',
                      ]}
                    /> */}
                    <TableColumnReordering
        order={columnOrder}
        onOrderChange={handleColumnOrderChange}
      />
                  </Grid>
                </div>
              </div>
              {/* {isCustomersExist===0 ?
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button onClick={()=>{navigate(`/apps/`)}} className='btn btn-primary'>
                
                Continue
              </button>
            </div>:''} */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomersListing
