import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Overview} from '../accounts/components/Overview'
import { AccountDetailHeader } from './AccountDetailHeader'
import { AccountListWrapper } from './Account-details-list/AccountList'


const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/crafted/account/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AccountDetailsPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <AccountDetailHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Account Details</PageTitle>
              <AccountListWrapper />
            </>
          }
        />
      
        <Route index element={<Navigate to='/crafted/pages/account-details/overview' />} />
      </Route>
    </Routes>
  )
}

export default AccountDetailsPage
