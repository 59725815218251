import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { usersColumns } from './columns/_columns'
import { User } from '../core/_models'
import { UsersListLoading } from '../components/loading/UsersListLoading'
import { KTCardBody, KTSVG } from '../../../../../_metronic/helpers'
import { number } from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'

const AccountTable = () => {
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <p>You earn <b>1 Point</b> for every <b>$1.50 </b> spent on tag purchase. Please <a href="" data-bs-toggle="modal"
              data-bs-target="#kt_modal_1">Link Your Tag Purchases</a>.</p>

        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* <!-- Modal --> */}
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Link Order</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group row mb-6">
                  <label className="col-sm-3 col-form-label text-sm-end" htmlFor="OrderNo">Order No.</label>
                  <div className="col-sm-8">
                    <input className="form-control" data-val="true" data-val-length="Maximum length is 50." data-val-length-max="50" data-val-required="Order No. is required." id="OrderNo" name="OrderNo" placeholder="" type="text" value="" />
                    <span className="field-validation-valid material-validate" data-valmsg-for="OrderNo" data-valmsg-replace="true"></span>
                  </div>
                </div>
                <div className="form-group row mb-6">
                  <label className="col-sm-3 col-form-label text-sm-end" htmlFor="Email">Confirm Email</label>
                  <div className="col-sm-8">
                    <div className="input-group">
                      <span className="input-group-text"><i className="fa fa-envelope fs-3" aria-hidden="true"></i></span>
                      <input className="form-control" data-val="true" data-val-length="Maximum length is 50." data-val-length-max="50" data-val-regex="Please enter a valid email address." data-val-regex-pattern="^[a-zA-Z0-9.!#$%&amp;'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$" data-val-required="Email is required." id="Email" name="Email" placeholder="" type="email" value="" />
                    </div>
                    <span className="field-validation-valid material-validate" data-valmsg-for="Email" data-valmsg-replace="true"></span>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-3 col-form-label text-sm-end"></div>
                  <div className="col-sm-8"><ReCAPTCHA
                    sitekey='6Ld1tNkkAAAAAJzflN3c87MmQzDpsV5kTq581D5y'

                  /></div>
                </div>
                {/* end::Form group ReCAPTCHA*/}
              </form>
            </div>
            <div className="modal-footer">
            <button type="button" className="btn btn-primary">
                Search Order
              </button>
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
             
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">

          <div className="mb-3">
            <button type='button' data-bs-toggle="modal"
              data-bs-target="#kt_modal_1" className="btn btn-secondary mb-2 mb-sm-0 tag_purchase_btn">Link Your Tag Purchases</button>
            <a className="btn btn-secondary downlaod_statement_btn" href="/assettiger/account/downloadstatement" download=""><i className="la la-download fs-2" aria-hidden="true"></i> Download Statement</a></div>
        </div>


        {/* <!-- Modal --> */}
        <div className="modal fade modal-lg" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Plan & Billing</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className='plan_billing_section'>
                  <div className='row'>
                    <div className='col-xl-4'>
                      <div className='plan_heading'>
                        <p><b>Select a Plan</b></p>
                      </div>
                      <div className='plan_content'>
                        <input type="radio" name="custom_radio" />
                        <div className='ps-3'>
                          <span> <b>Plan ATS-500: $120.00 / year</b></span>
                          <p className='planning_assets'><b>(up to 500 assets)</b></p>
                        </div>

                      </div>
                      <div className='plan_content'>
                        <input type="radio" name="custom_radio" />
                        <div className='ps-3'>
                          <span> <b> Plan ATS-2500: $230.00 / year</b></span>
                          <p className='planning_assets'><b>(up to 2,500 assets)</b></p>
                        </div>

                      </div>
                      <div className='plan_content'>
                        <input type="radio" name="custom_radio" />
                        <div className='ps-3'>
                          <span> <b>Plan ATS-10K: $400.00 / year</b></span>
                          <p className='planning_assets'><b>(up to 10,000 assets)</b></p>
                        </div>

                      </div>
                      <div className='plan_content'>
                        <input type="radio" name="custom_radio" />
                        <div className='ps-3'>
                          <span> <b> Plan ATS-50K: $800.00 / year</b></span>
                          <p className='planning_assets'><b>(up to 50,000 assets)</b></p>
                        </div>

                      </div>
                      <div className='plan_content'>
                        <input type="radio" name="custom_radio" />
                        <div className='ps-3'>
                          <span> <b>Plan ATS-250K: $1,600.00 / year</b></span>
                          <p className='planning_assets'><b>(up to 250,000 assets)</b></p>
                        </div>

                      </div>
                      <div className='plan_content'>
                        <input type="radio" name="custom_radio" />
                        <div className='ps-3'>
                          <span> <b>Plan ATS-500K: $3,200.00 / year</b></span>
                          <p className='planning_assets'><b>(up to 500,000 assets)</b></p>
                        </div>

                      </div>
                      <div className='plan_content border_none'>
                        <input type="radio" name="custom_radio" />
                        <div className='ps-3'>
                          <span> <b> Buy Points $</b></span>
                          <input className="customAmountBox buy_points" data-val="true" data-val-number="The field Amount must be a number." data-val-range="Amount must be within 0 to 9999999999." data-val-range-max="9999999999" data-val-range-min="0" data-val-required="The Amount field is required." id="Amount" name="Amount" type="number" value="0" />
                        </div>

                      </div>
                      <div className="Subscription_plan" >
                        <div className="main_col">
                          <div className="plan-tilte text-center fw-bolder subscription_plan">Subscription Plan</div>
                          <div className="plan_text text-center fs-6">
                            <span className="plan_name" id="label_plan_Name">ATS-500</span> Up to <span className="value_plan" id="label_assets_up_to">500</span> Assets
                            <br /> <span className="plan_price_val" id="label_cost_per_year">$120.00</span> / Year
                          </div>
                        </div>
                        <div className="main_col">
                          <div className="plan_label text-center"><span className="fw-bolder">Amount charged today:</span> <span className="plan_price_val" id="label_amount_charge_today">$120.00</span></div>
                        </div>
                      </div>

                    </div>
                    <div className='col-xl-8'>

                      <div className='plan_heading'>
                        <p><b> Credit card information</b></p>

                      </div>
                      <div className="form-body">
                        <div className="row credit_card_spacing">
                          <div className="col-md-6">
                            <div className="form-floating custom_style mb-4">
                              <input className="form-control" data-val="true" data-val-number="The field Card Number must be a number." data-val-required="Card Number is required." id="CardNumber" name="CardNumber" placeholder="Card Number *" tabIndex={1} type="text" value="" />
                              <label className="required">Card Number</label>
                              <span className="field-validation-valid material-validate" data-valmsg-for="CardNumber" data-valmsg-replace="true"></span>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-6" >
                                  <div className="form-floating custom_style mb-4">
                                    <select className="form-select" data-val="true" data-val-number="The field Expiry Month must be a number." data-val-required="Month is required." id="ExpiryMonth" name="ExpiryMonth" placeholder="Expiry *" tabIndex={2}><option value="">Month</option>
                                      <option value="1">January</option>
                                      <option value="2">February</option>
                                      <option value="3">March</option>
                                      <option value="4">April</option>
                                      <option value="5">May</option>
                                      <option value="6">June</option>
                                      <option value="7">July</option>
                                      <option value="8">August</option>
                                      <option value="9">September</option>
                                      <option value="10">October</option>
                                      <option value="11">November</option>
                                      <option value="12">December</option>
                                    </select>
                                    <label className="required">Expiry</label>
                                    <span className="field-validation-valid material-validate" data-valmsg-for="ExpiryMonth" data-valmsg-replace="true"></span>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-floating custom_style mb-4">
                                    <select className="form-select" data-val="true" data-val-number="The field Expiry Year must be a number." data-val-required="Year is required." id="ExpiryYear" name="ExpiryYear" placeholder="Expiry *" tabIndex={3}><option value="">Year</option>
                                      <option value="2023">2023</option>
                                      <option value="2024">2024</option>
                                      <option value="2025">2025</option>
                                      <option value="2026">2026</option>
                                      <option value="2027">2027</option>
                                      <option value="2028">2028</option>
                                      <option value="2029">2029</option>
                                      <option value="2030">2030</option>
                                      <option value="2031">2031</option>
                                      <option value="2032">2032</option>
                                      <option value="2033">2033</option>
                                      <option value="2034">2034</option>
                                      <option value="2035">2035</option>
                                      <option value="2036">2036</option>
                                      <option value="2037">2037</option>
                                      <option value="2038">2038</option>
                                      <option value="2039">2039</option>
                                      <option value="2040">2040</option>
                                      <option value="2041">2041</option>
                                      <option value="2042">2042</option>
                                      <option value="2043">2043</option>
                                      <option value="2044">2044</option>
                                      <option value="2045">2045</option>
                                      <option value="2046">2046</option>
                                      <option value="2047">2047</option>
                                      <option value="2048">2048</option>
                                      <option value="2049">2049</option>
                                      <option value="2050">2050</option>
                                      <option value="2051">2051</option>
                                      <option value="2052">2052</option>
                                      <option value="2053">2053</option>
                                    </select>
                                    <label className="required">Expiry</label>
                                    <span className="field-validation-valid material-validate" data-valmsg-for="ExpiryYear" data-valmsg-replace="true"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-floating custom_style mb-4">
                              <input className="form-control" data-val="true" data-val-length="Maximum length is 50." data-val-length-max="50" data-val-required="Card holder name is required." id="CardHolderName" name="CardHolderName" placeholder="Name on Card *" tabIndex={5} type="text" value="" />
                              <label className="required">Name on Card</label>
                              <span className="field-validation-valid material-validate" data-valmsg-for="CardHolderName" data-valmsg-replace="true"></span>
                            </div>
                            <div className="form-floating custom_style mb-4">
                              <input className="form-control" data-val="true" data-val-length="Maximum length is 250." data-val-length-max="250" data-val-required="Address is required." id="Address1" name="Address1" placeholder="Billing Address Line 1 *" tabIndex={6} type="text" value="" />
                              <label className="required">Billing Address Line 1</label>
                              <span className="field-validation-valid material-validate" data-valmsg-for="Address1" data-valmsg-replace="true"></span>
                            </div>
                            <div className="form-floating custom_style mb-4">
                              <input className="form-control" data-val="true" data-val-length="Maximum length is 50." data-val-length-max="50" data-val-required="City is required." id="City" name="City" placeholder="City *" tabIndex={8} type="text" value="" />
                              <label className="required">City</label>
                              <span className="field-validation-valid material-validate" data-valmsg-for="City" data-valmsg-replace="true"></span>
                            </div>
                            <div className="form-floating custom_style mb-4">
                              <select className="form-select" data-val="true" data-val-number="The field Country must be a number." data-val-required="Country is required." id="CountryId" name="CountryId" placeholder="Country *" tabIndex={10}><option value="">Choose Country</option>
                                <option value="1">Afghanistan</option>
                                <option value="2">Albania</option>
                                <option value="3">Algeria</option>
                                <option value="4">American Samoa</option>
                                <option value="5">Andorra</option>
                                <option value="6">Angola</option>
                                <option value="7">Anguilla</option>
                                <option value="8">Antarctica</option>
                                <option value="9">Antigua And Barbuda</option>
                                <option value="10">Argentina</option>
                                <option value="11">Armenia</option>
                                <option value="12">Aruba</option>
                                <option value="13">Australia</option>
                                <option value="14">Austria</option>
                                <option value="15">Azerbaijan</option>
                                <option value="16">Bahamas</option>
                                <option value="17">Bahrain</option>
                                <option value="18">Bangladesh</option>
                                <option value="19">Barbados</option>
                                <option value="20">Belarus</option>
                                <option value="21">Belgium</option>
                                <option value="22">Belize</option>
                                <option value="23">Benin</option>
                                <option value="24">Bermuda</option>
                                <option value="25">Bhutan</option>
                                <option value="26">Bolivia</option>
                                <option value="27">Bosnia And Herzegovina</option>
                                <option value="28">Botswana</option>
                                <option value="29">Bouvet Island</option>
                                <option value="30">Brazil</option>
                                <option value="31">British Indian Ocean Territory</option>
                                <option value="32">Brunei Darussalam</option>
                                <option value="33">Bulgaria</option>
                                <option value="34">Burkina Faso</option>
                                <option value="35">Burundi</option>
                                <option value="36">Cambodia</option>
                                <option value="37">Cameroon</option>
                                <option value="38">Canada</option>
                                <option value="39">Cape Verde</option>
                                <option value="40">Cayman Islands</option>
                                <option value="41">Central African Republic</option>
                                <option value="42">Chad</option>
                                <option value="43">Chile</option>
                                <option value="44">China</option>
                                <option value="45">Christmas Island</option>
                                <option value="46">Cocos (Keeling) Islands</option>
                                <option value="47">Colombia</option>
                                <option value="48">Comoros</option>
                                <option value="49">Congo</option>
                                <option value="236">Congo (DRC)</option>
                                <option value="50">Cook Islands</option>
                                <option value="51">Costa Rica</option>
                                <option value="52">Cote D'Ivorie (Ivory Coast)</option>
                                <option value="53">Croatia (Hrvatska)</option>
                                <option value="54">Cuba</option>
                                <option value="55">Cyprus</option>
                                <option value="56">Czech Republic</option>
                                <option value="57">Denmark</option>
                                <option value="58">Djibouti</option>
                                <option value="59">Dominica</option>
                                <option value="60">Dominican Republic</option>
                                <option value="62">Ecuador</option>
                                <option value="63">Egypt</option>
                                <option value="64">El Salvador</option>
                                <option value="65">Equatorial Guinea</option>
                                <option value="66">Eritrea</option>
                                <option value="67">Estonia</option>
                                <option value="68">Ethiopia</option>
                                <option value="69">Falkland Islands (Malvinas)</option>
                                <option value="70">Faroe Islands</option>
                                <option value="71">Fiji</option>
                                <option value="72">Finland</option>
                                <option value="73">France</option>
                                <option value="74">France, Metropolitan</option>
                                <option value="75">French Guiana</option>
                                <option value="76">French Polynesia</option>
                                <option value="77">French Southern Territories</option>
                                <option value="78">Gabon</option>
                                <option value="79">Gambia</option>
                                <option value="80">Georgia</option>
                                <option value="81">Germany</option>
                                <option value="82">Ghana</option>
                                <option value="83">Gibraltar</option>
                                <option value="84">Greece</option>
                                <option value="85">Greenland</option>
                                <option value="86">Grenada</option>
                                <option value="87">Guadeloupe</option>
                                <option value="88">Guam</option>
                                <option value="89">Guatemala</option>
                                <option value="90">Guinea</option>
                                <option value="91">Guinea-Bissau</option>
                                <option value="92">Guyana</option>
                                <option value="93">Haiti</option>
                                <option value="94">Heard And McDonald Islands</option>
                                <option value="95">Honduras</option>
                                <option value="96">Hong Kong</option>
                                <option value="97">Hungary</option>
                                <option value="98">Iceland</option>
                                <option value="99">India</option>
                                <option value="100">Indonesia</option>
                                <option value="101">Iran</option>
                                <option value="102">Iraq</option>
                                <option value="103">Ireland</option>
                                <option value="104">Israel</option>
                                <option value="105">Italy</option>
                                <option value="106">Jamaica</option>
                                <option value="107">Japan</option>
                                <option value="108">Jordan</option>
                                <option value="109">Kazakhstan</option>
                                <option value="110">Kenya</option>
                                <option value="111">Kiribati</option>
                                <option value="194">Korea Republic</option>
                                <option value="112">Kuwait</option>
                                <option value="113">Kyrgyzstan</option>
                                <option value="114">Laos</option>
                                <option value="115">Latvia</option>
                                <option value="116">Lebanon</option>
                                <option value="117">Lesotho</option>
                                <option value="118">Liberia</option>
                                <option value="119">Libya</option>
                                <option value="120">Liechtenstein</option>
                                <option value="121">Lithuania</option>
                                <option value="122">Luxembourg</option>
                                <option value="123">Macau</option>
                                <option value="124">Macedonia</option>
                                <option value="125">Madagascar</option>
                                <option value="126">Malawi</option>
                                <option value="127">Malaysia</option>
                                <option value="128">Maldives</option>
                                <option value="129">Mali</option>
                                <option value="130">Malta</option>
                                <option value="131">Marshall Islands</option>
                                <option value="132">Martinique</option>
                                <option value="133">Mauritania</option>
                                <option value="134">Mauritius</option>
                                <option value="135">Mayotte</option>
                                <option value="136">Mexico</option>
                                <option value="137">Micronesia (Federated States of)</option>
                                <option value="138">Moldova</option>
                                <option value="139">Monaco</option>
                                <option value="140">Mongolia</option>
                                <option value="141">Montserrat</option>
                                <option value="142">Morocco</option>
                                <option value="143">Mozambique</option>
                                <option value="144">Myanmar (Burma)</option>
                                <option value="145">Namibia</option>
                                <option value="146">Nauru</option>
                                <option value="147">Nepal</option>
                                <option value="148">Netherlands</option>
                                <option value="149">Netherlands Antilles and Aruba</option>
                                <option value="150">New Caledonia</option>
                                <option value="151">New Zealand</option>
                                <option value="152">Nicaragua</option>
                                <option value="153">Niger</option>
                                <option value="154">Nigeria</option>
                                <option value="155">Niue</option>
                                <option value="156">Norfolk Island</option>
                                <option value="157">North Korea</option>
                                <option value="158">Northern Mariana Islands</option>
                                <option value="159">Norway</option>
                                <option value="160">Oman</option>
                                <option value="161">Pakistan</option>
                                <option value="162">Palau</option>
                                <option value="163">Panama</option>
                                <option value="164">Papua New Guinea</option>
                                <option value="165">Paraguay</option>
                                <option value="166">Peru</option>
                                <option value="167">Philippines</option>
                                <option value="168">Pitcairn</option>
                                <option value="169">Poland</option>
                                <option value="170">Portugal</option>
                                <option value="171">Puerto Rico</option>
                                <option value="172">Qatar</option>
                                <option value="173">Reunion</option>
                                <option value="174">Romania</option>
                                <option value="175">Russia</option>
                                <option value="176">Rwanda</option>
                                <option value="177">Saint Helena</option>
                                <option value="178">Saint Kitts And Nevis</option>
                                <option value="179">Saint Lucia</option>
                                <option value="180">Saint Pierre And Miquelon</option>
                                <option value="181">San Marino</option>
                                <option value="182">Sao Tome And Principe</option>
                                <option value="183">Saudi Arabia</option>
                                <option value="184">Senegal</option>
                                <option value="185">Serbia</option>
                                <option value="186">Seychelles</option>
                                <option value="187">Sierra Leone</option>
                                <option value="188">Singapore</option>
                                <option value="189">Slovakia</option>
                                <option value="190">Slovenia</option>
                                <option value="191">Solomon Islands</option>
                                <option value="192">Somalia</option>
                                <option value="193">South Africa</option>
                                <option value="195">Spain</option>
                                <option value="196">Sri Lanka</option>
                                <option value="237">St. Vincent and the Grenadines</option>
                                <option value="197">Sudan</option>
                                <option value="198">Suriname</option>
                                <option value="199">Svalbard And Jan Mayen</option>
                                <option value="200">Swaziland</option>
                                <option value="201">Sweden</option>
                                <option value="202">Switzerland</option>
                                <option value="203">Syria</option>
                                <option value="204">Taiwan</option>
                                <option value="205">Tajikistan</option>
                                <option value="206">Tanzania</option>
                                <option value="207">Thailand</option>
                                <option value="61">Timor-Leste</option>
                                <option value="208">Togo</option>
                                <option value="209">Tokelau</option>
                                <option value="210">Tonga</option>
                                <option value="211">Trinidad And Tobago</option>
                                <option value="212">Tunisia</option>
                                <option value="213">Turkey</option>
                                <option value="214">Turkmenistan</option>
                                <option value="215">Turks And Caicos Islands</option>
                                <option value="216">Tuvalu</option>
                                <option value="217">Uganda</option>
                                <option value="218">Ukraine</option>
                                <option value="219">United Arab Emirates</option>
                                <option value="220">United Kingdom</option>
                                <option value="221">United States</option>
                                <option value="222">Uruguay</option>
                                <option value="223">Uzbekistan</option>
                                <option value="224">Vanuatu</option>
                                <option value="225">Vatican City (Holy See)</option>
                                <option value="226">Venezuela</option>
                                <option value="227">Vietnam</option>
                                <option value="228">Virgin Islands (British)</option>
                                <option value="229">Virgin Islands (US)</option>
                                <option value="230">Wallis And Futuna Islands</option>
                                <option value="231">Western Sahara</option>
                                <option value="232">Western Samoa</option>
                                <option value="233">Yemen</option>
                                <option value="234">Zambia</option>
                                <option value="235">Zimbabwe</option>
                              </select>
                              <label className="required">Country</label>
                              <span className="field-validation-valid material-validate" data-valmsg-for="CountryId" data-valmsg-replace="true"></span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating custom_style mb-4">
                              <select className="form-select" data-val="true" data-val-required="Card Type is required." id="CardType" placeholder="Select Card Type" name="CardType" tabIndex={0} >
                                <option value="" >Select Card Type</option>
                                <option value="VisaCard">Visa Card</option>
                                <option value="MasterCard">Master Card</option>
                                <option value="AmExCard">American Express Card</option>
                                <option value="Discover">Discover Card</option>
                              </select>
                              <label className="required">Card Type</label>
                              <span className="field-validation-valid material-validate" data-valmsg-for="CardType" data-valmsg-replace="true"></span>
                              <div className="row form-group mb-0">
                                <div className="col-md-5 col-sm-8 pr-sm-0">
                                  <div className="form-floating custom_style mt-4">
                                    <input className="form-control" data-val="true" placeholder="CVV *" data-val-number="Must be a number." data-val-range="000 to 9999." data-val-range-max="9999" data-val-range-min="0" data-val-required="Required." id="CVV" maxLength={4} minLength={3} name="CVV" type="text" value="" tabIndex={3} />
                                    <label htmlFor="cvv" className="required">CVV</label>
                                    <span className="field-validation-valid material-validate" data-valmsg-for="CVV" data-valmsg-replace="true"></span>
                                  </div>
                                </div>
                                <div className="col-md-7 col-sm-4 mt-4">
                                  <span className="cvv_img mb-4 mb-mt-4">
                                    <img src="/assettiger/content/img/CVV.png" alt="Image" />
                                    <span className="cvv_badge">535</span>
                                    <span className="cvv_text">(Last 3-4 digits)</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="form-floating custom_style mb-4">
                              <input className="form-control" data-val="true" data-val-length="Maximum length is 250." data-val-length-max="250" id="Address2" name="Address2" placeholder="Address Line 2" tabIndex={7} type="text" value="" />
                              <label>Address Line 2</label>
                              <span className="field-validation-valid material-validate" data-valmsg-for="Address2" data-valmsg-replace="true"></span>
                            </div>
                            <div className="form-floating custom_style mb-4">
                              <input className="form-control" data-val="true" data-val-length="Maximum length is 50." data-val-length-max="50" id="Zip" name="Zip" placeholder="Zip" tabIndex={9} type="text" value="" />
                              <label>Zip</label>
                              <span className="field-validation-valid material-validate" data-valmsg-for="Zip" data-valmsg-replace="true"></span>
                            </div>
                            <div className="form-floating custom_style mb-4">
                              <input className="form-control" data-val="true" data-val-length="Maximum length is 50." data-val-length-max="50" data-val-required="State is required." id="State" name="State" placeholder="State" tabIndex={11} type="text" value="" />
                              <label className="required">State</label>
                              <span className="field-validation-valid material-validate" data-valmsg-for="State" data-valmsg-replace="true"></span>
                            </div>
                            <input data-val="true" data-val-number="The field Amount must be a number." data-val-range="Amount must be within 0 to 9999999999." data-val-range-max="9999999999" data-val-range-min="0" data-val-required="The Amount field is required." id="Amount" name="Amount" style={{ height: 0, width: 0, border: 'none', opacity: 0 }} type="text" value="0" />
                            <span className="field-validation-valid" data-valmsg-for="Amount" data-valmsg-replace="true"></span>
                            <input data-val="true" data-val-required="Subscription Plan is required." id="PlanType" name="PlanType" type="hidden" value="ATS50K" />
                          </div>
                        </div>
                      </div>
                      <p className="tag_line">By proceeding with payment, you agree to our <a href="#termsOfServicesModel" data-bs-toggle="modal">T&amp;C</a> and <a href="#privacyPolicyModel" data-bs-toggle="modal">Privacy policy</a></p>

                    </div>

                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary">Pay Now</button>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="text-danger text-sm-end mb-3" >

            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-primary" >Buy Points</button>

          </div>

          <div className="text-danger text-sm-end mb-3">
            <a href="https://www.myassettag.com" target="_blank" rel="noopener" type="button" className="btn btn-success">Buy Asset Tags</a>
          </div>
        </div>
      </div>


      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export { AccountTable }
