import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface Company {
  userId: string
  name: string
}

type CompaniesResponse = Company[]
const API_URL = process.env.REACT_APP_API_URL

export const optionApi = createApi({
    reducerPath: 'optionApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/` }),
  tagTypes: ['Option'],
  endpoints: (build) => ({
    getOption: build.query<any, any>({
      query: (token) => ({
        url: `option`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      providesTags: ['Option'],
    }),
    // addOption: build.mutation<Company, Partial<Company>>({
    //   query: (body) => ({
    //     url: `posts`,
    //     method: 'POST',
    //     body,
    //   }),
    //   invalidatesTags: [{ type: 'Company', id: 'LIST' }],
    // }),
    // getPost: build.query<Company, string>({
    //   query: (id) => `posts/${id}`,
    //   providesTags: (result, error, id) => [{ type: 'Company', id }],
    // }),
    addOption: build.mutation<any,{data:any,token:any}>({
      query: ({data, token}) => ({
        url: `option`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
       invalidatesTags: () => [{ type: 'Option'}],
    }),
    // deleteCompany: build.mutation<{ success: boolean; }, number>({
    //   query(userId) {
    //     return {
    //       url: `delete/${userId}`,
    //       method: 'DELETE',
    //     }
    //   },
    //   invalidatesOptions: (result, error, userId) => [{ type: 'Company', userId }],
    // }),
  }),
})

export const {
  // useGetPostQuery,
  useGetOptionQuery,
  // useUpdateCompanyMutation,
  useAddOptionMutation,
  // useDeleteCompanyMutation,
} = optionApi
