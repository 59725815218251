
export type Customer = {
  fullName: string
  company?: string,
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?:string
  phone?:string
  cell?:string
  email?: string
  notes?: string
  userId : number
}

export const initialCustomers  : Customer =  {
  fullName:"",
  company: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  country:"",
  cell: "",
  email: "",
  notes: "",
  userId:  0,
}

export type CustomerResponse = {
  id: number,
  fullName: string
  company?: string,
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?:string
  phone?:string
  cell?:string
  email?: string
  notes?: string
  // userId : number
}

