import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import { useAuth } from '../../../modules/auth'
import axios from 'axios'
import { useGetAllAssetsQuery } from 'app/modules/services/assets'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'

const LeaseModal = (props: any) => {
    const { currentUser } = useAuth()
    const [selectAll, setSelectAll] = useState(false)
    const [selected, setSelected] = useState<any>([])
    const [assets, setAssets] = useState<any>([]);
    const [skip, setSkip] = useState<boolean>(false);
    const [searchAssets, setSearchAssets] = useState<any>('')
    const [page, setPage] = useState<number>(1)
    const [limit, setLimit] = useState<number>(10)
    const [pagination, setPagination] = useState<any>({})
    const { data: assetsData, isLoading, error, isSuccess } = useGetAllAssetsQuery({ userId: currentUser?.id, body: new URLSearchParams({keyword:searchAssets,searchField: "assetTagId,description"}).toString(), page: page, limit: limit  },{skip})
    // const [pendingAssets,setPendingAssets]= useState<any>([]);
    useEffect(() => {
        setSkip(false) //set skip false becasue skip param in query hook does not load on mount
      }, [searchAssets])
    const handleClose = () => {
        props.handleClose()
    }
    const getAssetListing = async () => {
        setAssets(assetsData?.userAssets)
    }
    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = assets?.map((item: any) => item.id)
            setSelected(newSelecteds)
            setSelectAll(true)
            props.setPendingAssets(newSelecteds)
        } else {
            setSelected([])
            setSelectAll(false)
            props.setPendingAssets([])
        }
    }
    const handleCheckboxChange = (id: number) => {
        setSelected((prevSelected: any) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((itemId: any) => itemId !== id)
            } else {
                return [...prevSelected, id]
            }
        })
        props.setPendingAssets((prevSelected: any) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((itemId: any) => itemId !== id)
            } else {
                return [...prevSelected, id]
            }
        })
    }
    useEffect(() => {
        if (assetsData) {
            getAssetListing();
        }
    }, [assetsData])
    useEffect(() => {
        if (props.selectedAssets.length == 0) {
            setSelectAll(false)
            setSelected([])
            props.setPendingAssets([])
        }
        else {
            let selectedData = props.selectedAssets.map((item) => item?.id);
            setSelectAll(false)
            setSelected(selectedData)
            props.setPendingAssets(selectedData)
        }
    }, [props.selectedAssets])

    const handlePageChange = (currentPage: any) => {
        setPage(currentPage)
        setSkip(false);
    }
    const currentPage = pagination?.page || 0;
    const totalRecords = pagination?.total || 0;
    const pageSize = limit || 10;
    const startRecord = (currentPage - 1) * pageSize + 1;
    const endRecord = Math.min(currentPage * pageSize, totalRecords);
    useEffect(() => {
        if (assetsData?.userAssets.length > 0) {
            setPagination(assetsData?.pagination)
            setSkip(true)
        }
    }, [assetsData])

    return (
        <>
            <Modal className='custom-modal-lg px-2' show={props.show} onHide={handleClose} backdrop='static' size='lg' keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Assets</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group mb-4">
                                <input className="form-control" onChange={(e)=>{setSearchAssets(e.target.value)}} data-val="true" data-val-length="Maximum length is 50." data-val-length-max="50" id="Search" name="" placeholder="Search with Asset Tag Id or Description" type="search" value={searchAssets}/>
                                    <span className="field-validation-valid" data-valmsg-for="Search" data-valmsg-replace="true"></span>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex limit-options align-items-center mb-4'>
                            <span className="text-muted">Show</span>
                            <select name="iTakeList" id="iTakeList" className="form-select form-select-sm fw-bold mx-3 border-1"
                                value={limit}
                                onChange={(e) => { setPage(1); setLimit(parseInt(e.target.value)); setSkip(false); }}>
                                <option value="10" >10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <span className="text-muted">entries</span>
                        </div>
                        <div className='card-title'>
                            {/* begin::Search */}
                            {/* <div className='d-flex align-items-center position-relative my-1 mb-3'>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen021.svg'
                                    className='svg-icon-1 position-absolute ms-6'
                                />
                                <input
                                    type='text'
                                    data-kt-user-table-filter='search'
                                    className='form-control form-control-solid w-250px ps-14'
                                    placeholder='Search...'
                                />
                            </div> */}
                            {/* end::Search */}

                            <div className="checkout-listing-table mb-3 table-responsive">
                                <table
                                    id='kt_table_users'
                                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable modal-dataTable no-footer'
                                    role='table'
                                >
                                    <thead>
                                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                            <th colSpan={1} role='columnheader' className='w-10px pe-2'>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input'
                                                        checked={selectAll}
                                                        onChange={handleSelectAll}
                                                    />
                                                </div>
                                            </th>
                                            <th colSpan={1} role='columnheader' className=''>
                                                {' '}
                                                Asset Tag ID{' '}
                                            </th>
                                            <th colSpan={1} role='columnheader' className=''>
                                                {' '}
                                                Description{' '}
                                            </th>
                                            <th colSpan={1} role='columnheader' className=''>
                                                {' '}
                                                Status{' '}
                                            </th>
                                            <th colSpan={1} role='columnheader' className=''>
                                                {' '}
                                                Assigned to{' '}
                                            </th>
                                            <th colSpan={1} role='columnheader' className=''>
                                                {' '}
                                                Site{' '}
                                            </th>
                                            <th colSpan={1} role='columnheader' className=''>
                                                {' '}
                                                Location{' '}
                                            </th>
                                            <th colSpan={1} role='columnheader' className=''>
                                                {' '}
                                                Lease to{' '}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                                        {assets?.length > 0 ? (
                                            assets?.map((item: any) => {
                                                return (
                                                    <tr role='row'>
                                                        <td role='cell' className=''>
                                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                                <input
                                                                    type='checkbox'
                                                                    className='form-check-input'
                                                                    checked={selected.includes(item.id)}
                                                                    onChange={() => handleCheckboxChange(item.id)}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td role='cell' className=''>
                                                            <div className=' overflow-hidden me-3'>
                                                                <a href='#' className='d-block table-elipses'>{item?.assetTagId}</a>
                                                            </div>
                                                        </td>
                                                        <td role='cell' className=''>
                                                            <div className=' overflow-hidden me-3'>
                                                                <a href='#' className='d-block table-elipses'>{item?.description}</a>
                                                            </div>
                                                        </td>
                                                        <td role='cell' className=''>
                                                            <div className='d-block table-elipses'>{item.statusType === 'check_out' ? 'Checked Out' : item.statusType === "lease" ? "Leased" : item.statusType === "dispose" ? "Disposed" : item.statusType === "available" ? "Available" : item.statusType}</div>
                                                        </td>
                                                        <td role='cell' className=''>
                                                            <div className=' overflow-hidden me-3'>
                                                                <a href='#' className='d-block table-elipses'>
                                                                {/* {item?.assetstatus && item?.assetstatus?.checkedTo=='site'?item?.location? item?.site?.name+"/"+item?.location?.location:item?.site?.name :item?.assetstatus?.checkedTo=='person'? item?.assetstatus?.person?.fullName:''} */}
                                                                {/* {item?.assetstatus?.checkedTo === 'person'
                                                                    ? item?.assetstatus?.person?.fullName
                                                                : ''}    */}
                                                                {item?.assetstatus && item?.assetstatus?.statusType !== 'lease' ? item?.location ? item?.site?.name + "/" + item?.location?.location : item?.site?.name : item?.assetstatus?.statusType == 'lease' ? "" : ''}
                                                            </a>
                                                            </div>
                                                        </td>
                                                        <td role='cell' className=''>
                                                            <div className=' overflow-hidden me-3'>
                                                                <a href='#' className='d-block table-elipses'>{item?.site?.name}</a>
                                                            </div>
                                                        </td>
                                                        <td role='cell' className=''>
                                                            <div className='d-block table-elipses'>{item?.location?.location}</div>
                                                        </td>
                                                        <td role='cell' className=''>
                                                            <div className='d-block table-elipses'>{item?.statusType=='lease' && item?.assetslease? 
                                                                item?.assetslease?.customer?.fullName:''}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                        No records found
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className=' d-flex align-items-center justify-content-end'>
                            <Button variant='secondary' className='me-3 main-btn-style' onClick={handleClose}>
                                Close
                            </Button>
                            <button
                                className='btn btn-light-primary main-btn-style'
                                disabled={selected?.length > 0 ? false : true}
                                onClick={handleClose}
                            >
                                Add to List
                            </button>
                        </div>
                        {assetsData?.userAssets?.length ?
                            <div className='d-sm-flex align-items-center justify-content-between mt-3'>
                                <div className='mt-3'>
                                    Showing {startRecord} to {endRecord} of {totalRecords} records
                                </div>
                                {pagination && (
                                    <Pagination
                                        className='mt-3 custom-pagination'
                                        defaultCurrent={1}
                                        pageSize={limit}
                                        // pageSize={pagination?.limit} // items per page
                                        current={pagination?.page} // current active page
                                        total={pagination?.total} // total pages
                                        onChange={handlePageChange}
                                        locale={localeInfo}
                                    />
                                )}
                            </div>
                            :
                            ""
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LeaseModal
