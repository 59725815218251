import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const API_URL = process.env.REACT_APP_API_URL

export const importsApi = createApi({
  reducerPath: 'importsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/import/` }),
  tagTypes: ['Imports'],
  endpoints: (build) => ({
    importCategories: build.mutation<any, any>({
      query: ({ data, userId }) => ({
        url: `categories/${userId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Imports' }],
    }),
    importSites: build.mutation<any, any>({
      query: ({ data, userId }) => ({
        url: `sites/${userId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Imports' }],
    }),
    importCustomers: build.mutation<any, any>({
      query: ({ data, userId }) => ({
        url: `customers/${userId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Imports' }],
    }),
    importLocations: build.mutation<any, any>({
      query: ({ data, userId }) => ({
        url: `locations/${userId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Imports' }],
    }),
    importAssets: build.mutation<any, any>({
      query: ({ data, userId }) => ({
        url: `assets/${userId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Imports' }],
    }),
    importMaintenances: build.mutation<any, any>({
      query: ({ data, userId }) => ({
        url: `maintenances/${userId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Imports' }],
    }),
    importPersons: build.mutation<any, any>({
      query: ({ data, userId }) => ({
        url: `persons/${userId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Imports' }],
    }),
    importDepartments: build.mutation<any, any>({
      query: ({ data, userId }) => ({
        url: `departments/${userId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Imports' }],
    }),
    uploads: build.mutation<any, any>({
      query: ({ data, type }) => ({
        url: `uploads/${type}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Imports' }],
    }),
    previewData: build.mutation<any, any>({
      query: ({ data, type }) => ({
        url: `preview/${type}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'Imports' }],
    }),
  }),
});

export const {
  useImportCategoriesMutation,
  useImportAssetsMutation,
  useImportCustomersMutation,
  useImportLocationsMutation,
  useImportMaintenancesMutation,
  useImportPersonsMutation,
  useImportSitesMutation,
  useImportDepartmentsMutation,
  useUploadsMutation,
  usePreviewDataMutation
} = importsApi
