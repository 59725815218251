/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'

import {KTSVG, toAbsoluteUrl, toAbsoluteUrlImage} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Dropdown1} from '../../../_metronic/partials'
import {useLocation} from 'react-router-dom'
import { getAuth, useAuth } from '../auth'
import Avatar from "../../../_metronic/assets/images/avatar.jpg"
import { StatisticsWidget2 } from '../../../_metronic/partials/widgets'
import { getAccountDetail } from './Account-details-list/core/_requests'
import { AccountDetail } from './Account-details-list/core/_models'
import { string } from 'yup'

const AccountDetailHeader: React.FC = () => {
  const location = useLocation()
  const { currentUser } = useAuth()
  const auth = getAuth()
const [account, setAccount] = useState<AccountDetail>()

  useEffect(()=>{
if(currentUser){
  getAccountDetail(auth?.token).then((res)=>{
    if(res?.data?.success){
      setAccount(res?.data?.accountDetails)
    }
  })
}
},[])


  return (
    
      <>
              {/* begin::Row */}
    <div className='row g-5 g-xl-8 mb-5'>
      <div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
       <div className='account_details_card d-flex card-body' >
        <div className='account_details_icon'>
        <i className="la la-user fs-1" aria-hidden="true"></i>
        </div>
        <div className='account_details_desc'>
          <span>Account No</span>

          <span><b>{account?.accountNo ? account?.accountNo : "N/A"}</b></span>


        </div>

       </div>
      </div>

      <div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
      
       <div className='account_details_card d-flex card-body' >
        <div className='account_details_icon'>
        <i className="la la-dollar fs-1" aria-hidden="true"></i>
        </div>
        <div className='account_details_desc'>
          <span>Balance:</span>

          <span><b>${account?.balance}</b></span>


        </div>

       </div>
      </div>

      <div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
      
       <div className='account_details_card d-flex card-body' >
        <div className='account_details_icon'>
        <i className="la la-user-clock fs-1" aria-hidden="true"></i>
        </div>
        <div className='account_details_desc'>
          <span>Account Status:</span>

          <span><b>{account?.accountStatus ? account?.accountStatus : "N/A"}</b></span>


        </div>

       </div>
      </div>

      <div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
      
       <div className='account_details_card d-flex card-body' >
        <div className='account_details_icon'>
        <i className="la la-puzzle-piece fs-1" aria-hidden="true"></i>
        </div>
        <div className='account_details_desc'>
          <span>No. of Assets:</span>

          <span><b>{account?.assetCount}</b></span>


        </div>

       </div>
      </div>

      <div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
      
       <div className='account_details_card d-flex card-body' >
        <div className='account_details_icon'>
        <i className="la la-calendar fs-1" aria-hidden="true"></i>
        </div>
        <div className='account_details_desc'>
          <span>Account Since:</span>

          <span><b>{account?.createdAt}</b></span>


        </div>

       </div>
      </div>
      <div className='col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6' >
      
       <div className='account_details_card d-flex card-body' >
        <div className='account_details_icon'>
        <i className="la la-calendar-check fs-1" aria-hidden="true"></i>
        </div>
        <div className='account_details_desc'>
          <span>Last Active:</span>
          <span><b>{account?.lastActive}</b></span>


        </div>

       </div>
      </div>
       
    </div>
    
    {/* end::Row */}
              </>
         
  )
}

export {AccountDetailHeader}
