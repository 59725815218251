export interface CheckoutInterface {
    checkoutDate:string, 
    checkedTo:number|string | undefined,
    userId:number,
    dueDate:string, 
    checkoutNotes:string,
    locationId:number|undefined, 
    assignTo:number| undefined,
    siteId?:number|undefined,
    departmentId:number|undefined,
    assetId:number|undefined,
    overrideAssets:string|false,
    email:string|undefined
}
export const defaultCHeckoutDetail: CheckoutInterface = {
    checkoutDate: new Date().toISOString().split('T')[0], 
    checkedTo: 0,
    userId: 0,
    dueDate: new Date().toISOString().split('T')[0], 
    checkoutNotes: "",
    locationId: undefined, 
    assignTo: undefined,
    siteId: undefined,
    departmentId: undefined,
    assetId: undefined,
    overrideAssets: false,
    email:undefined
}
