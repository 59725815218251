import React from 'react'
import MaintenanceList from './component/MaintenanceList'

const MaintenanceTable = () => {
  return (
    <MaintenanceList/>
  )
}

export default MaintenanceTable
