import React from 'react';
import { Modal } from 'react-bootstrap';

const ConfirmationPopup = (props: any) => {

    return (
        <div >

            <Modal className='confirmation-modal' size="lg" show={props.deleteModal} onHide={props.cancle} backdrop='static' keyboard={false}>
                <Modal.Body >
                    <div className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10 relative">
                        <button className='close-btn absolute ' onClick={() => props.cancle()}>
                            <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 14L34 34" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" /><path d="M14 34L34 14" stroke="red" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" /></svg>

                        </button>
                        <div className="text-center">
                            <h5 className="fw-bolder fs-1 mb-5">Are you sure you want to delete?</h5>

                            <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                            <div className="mb-9">
                                This action is irreversible
                            </div>
                            {props.deleteCascade &&
                                <div className="mb-9 text-danger">
                                    Warning: This record has related records in another table do you want to delete all related records?
                                </div>
                            }
                            <div className="d-flex flex-center flex-wrap">
                                <button onClick={() => props.cancle()} className="btn btn-outline btn-outline-danger btn-active-danger m-2">Cancel</button>
                                <button onClick={() => { props.confirm(); }} className="btn btn-danger m-2">Delete</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );
};

export default ConfirmationPopup;