import React from 'react'
import DepartmentsListing from './components/DepartmentsListing'

const Departments = () => {
  return (
    <>
      <DepartmentsListing />
    </>
  )
}

export default Departments
