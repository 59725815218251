import { Dispatch, SetStateAction, useState } from "react"
import Modal from "../Modal/modal"
import "../import.css"

type ModalsState = {
    preview: boolean;
    mapAgain: boolean;
    uploads: boolean;
    uploadAgain: boolean;
}

interface setp2Props {
    title: String[],
    option: Object,
    titleIndexMap: object,
    preview?: () => void,
    setModalState: Dispatch<SetStateAction<ModalsState>>,
    setPreviewModal: Dispatch<SetStateAction<boolean>>,
    modalState: ModalsState
}
const FormStep = ({ title, option, preview, titleIndexMap, modalState, setModalState, setPreviewModal }: setp2Props) => {
    const [show, setShow] = useState<boolean>(false)
    return (
        <div className="card mb-5 mb-xl-10">
            <div className="form-body card-body">
                <div className="step2">
                    <div > <h3 className="fw-bolder m-0 mb-4">Step 2. Column Mapping</h3> </div>
                    {modalState.preview ?
                        <>
                            <div id="uploadedFile" className="step2Up">
                                <div className="form-group row">
                                    <div className="col-md-7 ">
                                        <div className="checkbox-list">
                                            <label className="checkbox">
                                                <input data-val="true" data-val-required="Value is required." id="ColumnHeading" name="ColumnHeading" type="checkbox" />
                                                <span></span>  First row contains column heading
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-bordered vertical_middle table-sm" aria-label="Column Mapping">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "right" }} className="text-end">Required Field</th>
                                            <th>Uploaded Field and Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {title && title.map((val, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td className="text-end fw-bold"><label>{val}</label></td>
                                                    <td className="warning">
                                                        <div className="dropdown bootstrap-select show-tick bs-select form-control pull-left">
                                                            <select
                                                                className="bs-select form-control show-tick pull-left"
                                                                data-show-subtext="true"
                                                                data-width="auto"
                                                                style={{ display: "none;" }}
                                                                onChange={(e) => {
                                                                    titleIndexMap[val.toString()] =
                                                                        !option.hasOwnProperty(val.toString()) ?
                                                                            e.target.selectedIndex - 1 : e.target.selectedIndex;
                                                                }}
                                                            >
                                                                {
                                                                    !option.hasOwnProperty(val.toString()) ?
                                                                        <option value="default">No Column</option> :
                                                                        null
                                                                }

                                                                {option && Object.keys(option).map((key, index) => {
                                                                    return (
                                                                        <option
                                                                            value=
                                                                            {
                                                                                key + " " + option[key]
                                                                            }
                                                                            selected={val === key}
                                                                            key={index}>
                                                                            {
                                                                                key + " " + option[key]
                                                                            }

                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <div className="dropdown-menu " style={{ minWidth: "0px;" }}>
                                                                <div className="inner show" role="listbox" id="bs-select-34" tabIndex={-1}>
                                                                    <ul className="dropdown-menu inner show" role="presentation"></ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span className="field-validation-valid" data-valmsg-for="IndexName" data-valmsg-replace="true"></span>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            <div className="">
                                <button id="previewButton" className="btn btn-primary" onClick={preview} > Preview </button>
                                <button type="submit" name="preview" id="preview" value="preview" style={{ display: "none" }}></button>
                            </div>
                        </> : ''}
                    {
                        modalState.mapAgain ?
                            <div className="">
                                <button className="btn btn-primary" onClick={() => setShow(true)} > Map Again </button>
                                <button name="Map Again" id="Map Again" value="Map Again" style={{ display: "none" }}></button>
                            </div>
                            : ''
                    }
                </div>
            </div >
            {
                show ?
                    <Modal
                        setShow={setShow} 
                        show={show}
                        click={(e) => {
                            setPreviewModal(false)
                            setShow(false)
                            setModalState((prevState) => ({
                                ...prevState,
                                uploads: false,
                                uploadAgain: true,
                                preview: true,
                                mapAgain: false
                            }))
                        }}
                    />
                    :

                    ''}
        </div>
    )
}

export default FormStep