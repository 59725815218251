/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { toast } from 'react-toastify'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
  rememberme: false,
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const handleGoogleLogin = async () => {
    window.location.replace(`https://assettiger.arhamsoft.org/auth/google`)
  }

  const handleFacebookLogin = async () => {
    console.log('some')
    window.location.replace(`https://assettiger.arhamsoft.org/auth/facebook`)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: user } = await login(values.email, values.password, values.rememberme)
        setCurrentUser(user.data)
        saveAuth({ token: user.token })
      } catch (error: any) {
        toast.dismiss()
        setLoading(false)
        setCurrentUser(undefined)
        console.error(error)
        saveAuth(undefined)
        if (error.response.status === 403) {
          toast.error(error.response.data.message)
          navigate(`/auth/verify-email?email=${values.email}`)
        } else {
          toast.error(error.response.data.message)
        }
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='form w-100'>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-7'>
          <h1 className='text-dark mb-2'>Sign In to AssetsRack</h1>
          <div className='text-gray-400 fw-bold fs-5'>
            New Here?{' '}
            <Link to='/auth/registration' className='link-primary fw-bolder'>
              Create an Account
            </Link>
          </div>
        </div>
        {/* begin::Heading */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-4'>
          <label className='form-label fs-6 fw-bolder text-dark required'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-4'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 required mb-0'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{ marginLeft: '5px' }}
              >
                Forgot Password ?
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <div className='d-flex position-relative'>
            <input
              type={showPassword ? 'text' : 'password'}
              autoComplete='off'
              placeholder='Password'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <i
              onClick={() => setShowPassword((prevState) => !prevState)}
              className={
                showPassword ? 'bi bi-eye-slash' + ' login-eye' : 'bi bi-eye' + ' login-eye'
              }
            />
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
          <div className='form-check form-check-custom form-check-solid mt-5'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agrees'
              {...formik.getFieldProps('rememberme')}
            />
            <label
              className='form-check-label fw-bold text-gray-700 fs-6 ms-5'
              htmlFor='kt_login_toc_agrees'
            >
              Remember Me?
            </label>
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        {/* <div className='text-center'> */}
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-0 text-center'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </form>
      {/* begin::Separator */}
      {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
      {/* end::Separator */}

      {/* begin::Google link */}
      {/* <button
        onClick={handleGoogleLogin}
        className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 text-center'
      >
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Continue with Google
      </button> */}
      {/* end::Google link */}

      {/* begin::Google link */}
      {/* <button
        onClick={handleFacebookLogin}
        className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 text-center'
      >
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
          className='h-20px me-3'
        />
        Continue with Facebook
      </button> */}
      {/* end::Google link */}

      {/* begin::Google link */}
      {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
      {/* end::Google link */}
      {/* </div> */}
      {/* end::Action */}
    </div>
  )
}
