import React, { useEffect } from 'react';

function AssetsTagList({ criteria, clearSearch }) {
  return (
    <div className="inputtags">

{Object.keys(criteria).map((key, index) => {
  const value = criteria[key];
  if (value) {
    return (
      <span className="tag" key={index}>
        <span className="lbl">{
          key=="keyword"? "Keyword":
          key=="searchField"?"Search Field":
          key=="siteId"? "Site":
          key=="locationId"? "Location":
          key=="categoryId"? "Category":
          key=="departmentId"? "Department":
          key=="person"? "Person":
          key=="customer"? "Customer":
          key=="status"? "Status":
          key=="groupBy"? "Group By":
          key=="recordCount"? "Records Count":
          key=="dateRangeBy"? "Date Range By":
          key=="quickDateRange"? "Quick Date Range":
          key=="startDate"? "Start Date":
          key=="endDate"? "End Date":''
        }</span>
        <span>{" "+value}</span>
        <a
          href="javascript:void(0);"
          title="Removing tag"
          onClick={() => clearSearch(key)}
          className='ms-5'
        >
          <i className="la la-close fs-5 text-danger" style={{ verticalAlign: '-1px' }}></i>
        </a>
      </span>
    );
  }
  return null; // Don't render anything for keys with empty values
})}

    </div>
  );
}

export default AssetsTagList;
