import React from 'react';
import { LocationHeader } from './components/LocationHeader';
import LocationListing from './components/LocationListing';

const Location = () => {
  return (
    <div>
      <LocationListing />
    </div>
  );
};

export default Location;