export interface ReserveInterface {
    startDate:Date|string,
    endDate:Date|string,
    reserveFor:string|undefined,
    personId:number|undefined,
    siteId:number|undefined,
    locationId:number|undefined,
    customerId:number|undefined,
    sendEmail:string|undefined,
    reminderEmail1:number|undefined,
    reminderEmail2:number|undefined,
    assetId:number|undefined,
    userId:number|undefined
}
export const defaultReserveDetail: ReserveInterface = {
    startDate:new Date(),
    endDate:new Date(),
    reserveFor:"person",
    personId:undefined,
    siteId:undefined,
    locationId:undefined,
    customerId:undefined,
    sendEmail:'',
    reminderEmail1:undefined,
    reminderEmail2:undefined,
    assetId:undefined,
    userId:undefined
}
