import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface Company {
  userId: string
  name: string
}

type CompaniesResponse = Company[]
const API_URL = process.env.REACT_APP_API_URL

export const companyApi = createApi({
    reducerPath: 'companyApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/company/` }),
  tagTypes: ['Company'],
  endpoints: (build) => ({
    getCompany: build.query<any, any>({
      query: (userId) => `list/${userId}`,
      providesTags: ['Company'],
    }),
    // addPost: build.mutation<Company, Partial<Company>>({
    //   query: (body) => ({
    //     url: `posts`,
    //     method: 'POST',
    //     body,
    //   }),
    //   invalidatesTags: [{ type: 'Company', id: 'LIST' }],
    // }),
    // getPost: build.query<Company, string>({
    //   query: (id) => `posts/${id}`,
    //   providesTags: (result, error, id) => [{ type: 'Company', id }],
    // }),
    updateCompany: build.mutation<any, { userId: any; formData?: FormData }>({
      query: ({ userId, formData }) => ({
        url: `update/${userId}`,
        method: 'PUT',
        body: formData,
      }),
    //   async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
    //     const patchResult = dispatch(
    //     companyApi.util.updateQueryData('getPost', id, (draft) => {
    //         Object.assign(draft, patch)
    //       })
    //     )
    //     try {
    //       await queryFulfilled
    //     } catch {
    //       patchResult.undo()
    //     }
    //   },
      invalidatesTags: (result, error, { userId }) => [{ type: 'Company', userId }],
    }),
    deleteCompany: build.mutation<{ success: boolean; }, number>({
      query(userId) {
        return {
          url: `delete/${userId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Company', userId }],
    }),
  }),
})

export const {
  // useGetPostQuery,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
//   useUpdatePostMutation,
  useDeleteCompanyMutation,
} = companyApi
