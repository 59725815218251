import React, { useEffect, useState } from 'react'
import { getAuth } from '../../../../../auth'
import { AssetsCustomFieldData, Field, allAssetsFields } from '../../core/_models'
import { addAssetsFields, getAssetsFields } from '../../core/_requests'
import ConfirmationPopup from '../../../../../../../_metronic/helpers/components/ConfirmationPopup'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';


const DataAssets = () => {
  const auth = getAuth()
  const navigate = useNavigate();
  const [standardFields, setStandardFields] = useState<allAssetsFields>({
    hasAssetTagId: true,
    hasAssetDescription: true,
    hasPurchaseDate: true,
    isPurchaseDateRequired: true,
    hasEstimatedCost: true,
    isEstimatedCostRequired: true,
    hasPurchaseFrom: true,
    isPurchaseFromRequired: true,
    hasBrand: true,
    isBrandRequired: true,
    hasModel: true,
    isModelRequired: true,
    hasSerialNo: true,
    isSerialNoRequired: true,
    customFields: '',
    categoryList: "",
  })
  const [editStandardFields, setEditStandardFields] = useState<allAssetsFields>({
    hasAssetTagId: true,
    hasAssetDescription: true,
    hasPurchaseDate: true,
    isPurchaseDateRequired: true,
    hasEstimatedCost: true,
    isEstimatedCostRequired: true,
    hasPurchaseFrom: true,
    isPurchaseFromRequired: true,
    hasBrand: true,
    isBrandRequired: true,
    hasModel: true,
    isModelRequired: true,
    hasSerialNo: true,
    isSerialNoRequired: true,
    customFields: '',
    categoryList: "",
  })
  const [customFieldData, setCustomFieldData] = useState<AssetsCustomFieldData>({
    customFieldName: '',
    customFieldDataType: '',
    isRequired: false,
    listOptions: [],
    categoryList: {
      isBuildingImprovements: true,
      isBuildings: true,
      isComputerEquipment: true,
      isEquipment: true,
      isFurnitureAndfixtures: true,
      isIntangibleAssets: true,
      isLeaseholdImprovements: true,
      isSoftware: true,
      isVehicles: true,
      isfloorA: true
    }
  })
  const [customFieldDataArray, setCustomFieldDataArray] = useState<AssetsCustomFieldData[] | null>([])
  const [loading, setLoading] = useState(false)
  const [editIdx, setEditIdx] = useState<Number | null>()
  const [deleteIdx, setDeleteIdx] = useState<Number | null>()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [flag, setFlag] = useState<Boolean>(false)
  const [categoriesOption, setCategoriesOption] = useState<{ all: boolean, limited: boolean }>({ all: true, limited: false })
  const [fields, setFields] = useState<{ id: number; value: string }[]>([])
  const [fieldValues, setFieldValues] = useState<string[]>([])
  const [isSitesExist, setIsSitesExist] = useState<any>(false)
  const [error, setError] = useState<{fieldName: string; dataType: string; listOption: string}>({
    fieldName: '',
    dataType: '',
    listOption: ''
  })

  useEffect(() => {
    getAssetsFields(auth?.token).then((res) => {
      setStandardFields(res)
      setEditStandardFields(res)
      checkVistingPage(res.customFields)
      Array.isArray(res.customFields) && setCustomFieldDataArray(res?.customFields)
    }).catch((error) => {
      toast.error('Error in responce')
    })
  }, [flag])
  const checkVistingPage=(dataList:any)=>{
    if(isSitesExist===false)
    {if(dataList && dataList?.length>0){
      setIsSitesExist(dataList?.length)
    }
    else{
      setIsSitesExist(0)
    }
  }
  } 
  const selectAllCheckboxes = () => {
    (standardFields?.hasAssetTagId && standardFields.hasAssetDescription && standardFields.hasPurchaseDate && standardFields.hasEstimatedCost && standardFields.hasBrand && standardFields.hasModel && standardFields.hasSerialNo && standardFields.hasPurchaseFrom) ?
      setStandardFields({ ...standardFields, hasAssetTagId: false, hasAssetDescription: false, hasPurchaseDate: false, hasEstimatedCost: false, hasBrand: false, hasModel: false, hasSerialNo: false, hasPurchaseFrom: false })
      : setStandardFields({ ...standardFields, hasAssetTagId: true, hasAssetDescription: true, hasPurchaseDate: true, hasEstimatedCost: true, hasBrand: true, hasModel: true, hasSerialNo: true, hasPurchaseFrom: true })
  }

  const selectAllCategoriesCheckboxes = () => {
    (customFieldData.categoryList.isBuildingImprovements && customFieldData.categoryList.isBuildings && customFieldData.categoryList.isComputerEquipment && customFieldData.categoryList.isEquipment && customFieldData.categoryList.isFurnitureAndfixtures && customFieldData.categoryList.isIntangibleAssets && customFieldData.categoryList.isLeaseholdImprovements && customFieldData.categoryList.isSoftware && customFieldData.categoryList.isVehicles && customFieldData.categoryList.isfloorA) ?
      setCustomFieldData({ ...customFieldData, categoryList: { isBuildingImprovements: false, isBuildings: false, isComputerEquipment: false, isEquipment: false, isFurnitureAndfixtures: false, isIntangibleAssets: false, isLeaseholdImprovements: false, isSoftware: false, isVehicles: false, isfloorA: false } })
      : setCustomFieldData({ ...customFieldData, categoryList: { isBuildingImprovements: true, isBuildings: true, isComputerEquipment: true, isEquipment: true, isFurnitureAndfixtures: true, isIntangibleAssets: true, isLeaseholdImprovements: true, isSoftware: true, isVehicles: true, isfloorA: true } })
  }

  const handleCustomFieldData = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    if (name === 'optional') {
      customFieldData.isRequired = false
      setCustomFieldData({ ...customFieldData })
    } else if (name === 'yes') {
      customFieldData.isRequired = true
      setCustomFieldData({ ...customFieldData })
    } else {
      setCustomFieldData({ ...customFieldData, [name]: value })
    }
  }

  const submitCustomField = () => {
    if (customFieldData?.customFieldName && customFieldData?.customFieldDataType && ((customFieldData?.customFieldDataType === 'Checkbox list' || customFieldData?.customFieldDataType === 'Radio button list' || customFieldData?.customFieldDataType === 'Dropdown list') ? fields[0]?.value : true)) {
      if (customFieldDataArray && typeof editIdx === 'number') {
        customFieldDataArray[editIdx] = customFieldData
      } else {
        customFieldDataArray?.push(customFieldData)
      }
      setCustomFieldDataArray([...(customFieldDataArray ?? [])])
      setEditStandardFields({
        ...editStandardFields,
        customFields: JSON.stringify(customFieldDataArray),
      })
      const updatedFields = {
        ...editStandardFields,
        customFields: JSON.stringify(customFieldDataArray),
      }
      addAssetsFields(updatedFields, auth?.token).then(() => {
        setFlag(!flag)
        toast.success("Custom field updated successfully.");
      if(isSitesExist===0){
          navigate(`/apps/setup/events`)
      }
      })
      setCustomFieldData({
        customFieldName: '',
        customFieldDataType: '',
        isRequired: false,
        listOptions: [],
        categoryList: {
          isBuildingImprovements: true,
          isBuildings: true,
          isComputerEquipment: true,
          isEquipment: true,
          isFurnitureAndfixtures: true,
          isIntangibleAssets: true,
          isLeaseholdImprovements: true,
          isSoftware: true,
          isVehicles: true,
          isfloorA: true
        }
      })
      setError({
        fieldName: '',
        dataType: '',
        listOption: ''
      })
    } else {
      setError({
        ...error,
        fieldName: !customFieldData?.customFieldName ? 'Label is required.' : '',
        dataType: !customFieldData?.customFieldDataType ? 'Data Type is required.' : '',
        listOption: !fields[0].value ? 'Option is required.' : '',
      })
    }
  }

  const confirm = () => {
    if (typeof deleteIdx === 'number') {
      customFieldDataArray?.splice(deleteIdx, 1)
    }
    setEditStandardFields({
      ...editStandardFields,
      customFields: JSON.stringify(customFieldDataArray),
    })
    const updatedFields = {
      ...editStandardFields,
      customFields: JSON.stringify(customFieldDataArray),
    }
    addAssetsFields(updatedFields, auth?.token).then(() => {
      setFlag(!flag)
      toast.success("Custom Field deleted successfully");
    })
    setDeleteModal(false);
  }

  const submitCustomersFields = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const updatedFields = {
      ...standardFields,
      customFields: JSON.stringify(standardFields.customFields),
    }
    setLoading(true)
    addAssetsFields(updatedFields, auth?.token).then(() => {
      setFlag(!flag)
      toast.success("Database updated successfully");
      setLoading(false)
    })
      .catch((error) => {
        setLoading(false)
      });
  }

  const addCustomField = () => {
    setCustomFieldData({
      customFieldName: '',
      customFieldDataType: '',
      isRequired: false,
      listOptions: [],
      categoryList: {
        isBuildingImprovements: true,
        isBuildings: true,
        isComputerEquipment: true,
        isEquipment: true,
        isFurnitureAndfixtures: true,
        isIntangibleAssets: true,
        isLeaseholdImprovements: true,
        isSoftware: true,
        isVehicles: true,
        isfloorA: true
      }
    })
    setError({
      fieldName: '',
      dataType: '',
      listOption: ''
    })
    setEditIdx(null)
    setFields([{ id: Date.now(), value: '' }])
  }

  const addNewField = () => {
    const newField: Field = { id: Date.now(), value: '' }
    setFields([...fields, newField])
  }

  const removeField = (index: number) => {
    const newFields = [...fields]
    newFields.splice(index, 1)
    setFields(newFields)
  }

  const handleChange = (index: number, value: string) => {
    const newFields = [...fields]
    newFields[index] = { ...newFields[index], value }
    setFields(newFields)
    const newFieldValues = [...fieldValues]
    newFieldValues[index] = value
    setFieldValues(newFieldValues)
    setCustomFieldData({ ...customFieldData, listOptions: newFields })
  }

  const cancle = () => {
    setDeleteModal(false);
  };

  return (
    <div className="card">
      <ConfirmationPopup
        deleteModal={deleteModal}
        cancle={cancle}
        confirm={confirm}
      />
      <div id="database_ajax_wrapper">
        <form action="/assettiger/database" className="form-horizontal" data-ajax="true" data-ajax-method="Post" data-ajax-mode="before" data-ajax-update="#database_ajax_wrapper" id="assetDatabaseForm" method="post" role="form" ><input name="__RequestVerificationToken" type="hidden" value="bi5mAQGArgtf83vcFjyPEGK8rKsZ-9D3G-eRhsWQ-rTaCYjXK6gswp10gSiUZu3dXYXV2Zm9_cHp6yxRZbnLhFj5ZuJQOiJIAWnGosU6DIm2Zzc3Qcl40A_U_bv_VIr8fFJjrA2" />            <div className="card-body">
          <div className="form_section">
            <div className="form-title d-flex">
              <span className='me-2'>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                  <path fill="#9b9b9b" d="M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z"></path>
                  <path fill="#9b9b9b" d="M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z"></path>
                  <path fill="#9b9b9b" d="M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z"></path>
                  <path fill="#9b9b9b" d="M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z"></path>
                </svg>
              </span>
              <h4 className='mt-1'>Asset Database Fields</h4>
            </div>
            <div className="text_indent">
              <p>Fill in the appropriate fields for your assets. <strong>Asset Tag ID</strong> and <strong>Asset Description</strong> are the only required fields. Check the boxes next to the field names you want to include.</p>
              <div className="table-responsive">
                <table className="table table-bordered mb-0" aria-label="Asset Database Fields">
                  <thead className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    <tr className="checkbox-list warning">
                      <th style={{ width: "2%" }}><label className="checkbox has_nolabel m-auto"><input checked={standardFields?.hasPurchaseDate && standardFields?.hasEstimatedCost && standardFields?.hasBrand && standardFields?.hasModel && standardFields?.hasSerialNo && standardFields?.hasPurchaseFrom} onChange={selectAllCheckboxes} type="checkbox" id="field_all" value="" /><span></span><span className="sr-only">Field All</span></label></th>
                      <th style={{ width: "20%" }}>Field name </th>
                      <th style={{ width: "20%" }}>Data Required </th>
                      <th>Description </th>
                      <th style={{ width: "20%" }}>Example </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><label className="checkbox has_nolabel m-auto"><input checked={true} disabled={true} className="fieldDisabledCheck" data-val="true" data-val-required="Asset Tag ID is required." id="hasAssetTagId" name="hasAssetTagId" type="checkbox" value="true" /><input name="hasAssetTagId" type="hidden" value="false" /><span></span><span className="sr-only">Field</span></label></td>
                      <td className="fw-bolder text-nowrap">Asset Tag ID <span className="required"></span> <i className="la la-key text-danger fs-4" aria-hidden="true" title="Key Field"></i></td>
                      <td><div className="radio-inline"><label className="radio mb-0"><input checked={true} type="radio" className='m_5' /><span></span> Yes</label></div></td>
                      <td> This field holds the unique asset id number that your company assigns to identify each asset. These are generally sequentially numbered labels with barcodes. </td>
                      <td> A-1001 </td>
                    </tr>
                    <tr>
                      <td><label className="checkbox has_nolabel m-auto"><input checked={true} disabled={true} className="fieldDisabledCheck" data-val="true" data-val-required="Asset Description is required." id="hasAssetDescription" name="hasAssetDescription" type="checkbox" value="true" /><input name="hasAssetDescription" type="hidden" value="false" /><span></span><span className="sr-only">Field</span></label></td>
                      <td className="fw-bolder text-nowrap">Asset Description <span className="required"></span></td>
                      <td><div className="radio-inline"><label className="radio mb-0"><input checked={true} type="radio" className='m_5' /><span></span> Yes</label></div></td>
                      <td> Description of the asset </td>
                      <td> HP - Envy Desktop - 12GB Memory - 2TB Hard Drive </td>
                    </tr>
                    <tr>
                      <td><label className="checkbox has_nolabel m-auto"><input checked={standardFields.hasPurchaseDate} onChange={() => setStandardFields({ ...standardFields, hasPurchaseDate: !standardFields.hasPurchaseDate })} className="fieldCheck valid" data-val="true" data-val-required="Purchase Date is required." id="hasPurchaseDate" name="hasPurchaseDate" type="checkbox" value="true" /><input name="hasPurchaseDate" type="hidden" value="false" /><span></span><span className="sr-only">Field</span></label></td>
                      <td className="fw-bolder text-nowrap">Purchase Date</td>
                      <td>
                        {standardFields.hasPurchaseDate && <div className="radio-inline standard_radio_can_hide hasPurchaseDate_radio" style={{ display: 'flex' }} >
                          <label className="radio mb-0 p_16"><input id="isPurchaseDateRequired_True" checked={standardFields?.isPurchaseDateRequired} onChange={() => setStandardFields({ ...standardFields, isPurchaseDateRequired: true })} name="isPurchaseDateRequired" className='m_5' type="radio" value="True" /><span></span> Yes</label>
                          <label className="radio mb-0"><input id="isPurchaseDateRequired_False" checked={!standardFields?.isPurchaseDateRequired} onChange={() => setStandardFields({ ...standardFields, isPurchaseDateRequired: false })} className='m_5' name="isPurchaseDateRequired" type="radio" value="False" /><span></span> Optional</label>
                        </div>}
                      </td>
                      <td> Date asset was purchased </td>
                      <td> 08/22/2014 </td>
                    </tr>
                    <tr>
                      <td><label className="checkbox has_nolabel m-auto"><input checked={standardFields.hasEstimatedCost} onChange={() => setStandardFields({ ...standardFields, hasEstimatedCost: !standardFields.hasEstimatedCost })} className="fieldCheck valid" data-val="true" data-val-required="Cost is required." id="hasEstimatedCost" name="hasEstimatedCost" type="checkbox" value="true" /><input name="hasEstimatedCost" type="hidden" value="false" /><span></span><span className="sr-only">Field</span></label></td>
                      <td className="fw-bolder text-nowrap">Cost</td>
                      <td>
                        {standardFields.hasEstimatedCost && <div className="radio-inline standard_radio_can_hide hasEstimatedCost_radio" >
                          <label className="radio mb-0 p_16"><input id="isEstimatedCostRequired_True" checked={standardFields?.isEstimatedCostRequired} onChange={() => setStandardFields({ ...standardFields, isEstimatedCostRequired: true })} className='m_5' name="isEstimatedCostRequired" type="radio" value="True" /><span></span> Yes</label>
                          <label className="radio mb-0"><input id="isEstimatedCostRequired_False" checked={!standardFields?.isEstimatedCostRequired} onChange={() => setStandardFields({ ...standardFields, isEstimatedCostRequired: false })} className='m_5' name="isEstimatedCostRequired" type="radio" value="False" /><span></span> Optional</label>
                        </div>}
                      </td>
                      <td> Cost of the asset </td>
                      <td>$225.75</td>
                    </tr>
                    <tr>
                      <td><label className="checkbox has_nolabel m-auto"><input checked={standardFields.hasPurchaseFrom} onChange={() => setStandardFields({ ...standardFields, hasPurchaseFrom: !standardFields.hasPurchaseFrom })} className="fieldCheck valid" data-val="true" data-val-required="Purchased from is required." id="hasPurchaseFrom" name="hasPurchaseFrom" type="checkbox" value="true" /><input name="hasPurchaseFrom" type="hidden" value="false" /><span></span><span className="sr-only">Field</span></label></td>
                      <td className="fw-bolder text-nowrap">Purchased from</td>
                      <td>
                        {standardFields.hasPurchaseFrom && <div className="radio-inline standard_radio_can_hide hasPurchaseFrom_radio" >
                          <label className="radio mb-0 p_16"><input id="isPurchaseFromRequired_True" checked={standardFields?.isPurchaseFromRequired} onChange={() => setStandardFields({ ...standardFields, isPurchaseFromRequired: true })} className='m_5' name="isPurchaseFromRequired" type="radio" value="True" /><span></span> Yes</label>
                          <label className="radio mb-0"><input id="isPurchaseFromRequired_False" checked={!standardFields?.isPurchaseFromRequired} onChange={() => setStandardFields({ ...standardFields, isPurchaseFromRequired: false })} className='m_5' name="isPurchaseFromRequired" type="radio" value="False" /><span></span> Optional</label>
                        </div>}
                      </td>
                      <td> Vendor/Supplier name </td>
                      <td> Amazon </td>
                    </tr>
                    <tr>
                      <td><label className="checkbox has_nolabel m-auto"><input checked={standardFields.hasBrand} onChange={() => setStandardFields({ ...standardFields, hasBrand: !standardFields.hasBrand })} className="fieldCheck valid" data-val="true" data-val-required="Brand is required." id="hasBrand" name="hasBrand" type="checkbox" value="true" /><input name="hasBrand" type="hidden" value="false" /><span></span><span className="sr-only">Field</span></label></td>
                      <td className="fw-bolder text-nowrap">Brand</td>
                      <td>
                        {standardFields.hasBrand && <div className="radio-inline standard_radio_can_hide hasBrand_radio" >
                          <label className="radio mb-0 p_16"><input id="isBrandRequired_True" checked={standardFields?.isBrandRequired} onChange={() => setStandardFields({ ...standardFields, isBrandRequired: true })} className='m_5' name="isBrandRequired" type="radio" value="True" /><span></span> Yes</label>
                          <label className="radio mb-0"><input id="isBrandRequired_False" checked={!standardFields?.isBrandRequired} onChange={() => setStandardFields({ ...standardFields, isBrandRequired: false })} className='m_5' name="isBrandRequired" type="radio" value="False" /><span></span> Optional</label>
                        </div>}
                      </td>
                      <td> Manufacturer of the asset </td>
                      <td> HP </td>
                    </tr>
                    <tr>
                      <td><label className="checkbox has_nolabel m-auto"><input checked={standardFields.hasModel} onChange={() => setStandardFields({ ...standardFields, hasModel: !standardFields.hasModel })} className="fieldCheck valid" data-val="true" data-val-required="Model is required." id="hasModel" name="hasModel" type="checkbox" value="true" /><input name="hasModel" type="hidden" value="false" /><span></span><span className="sr-only">Field</span></label></td>
                      <td className="fw-bolder text-nowrap">Model</td>
                      <td>
                        {standardFields.hasModel && <div className="radio-inline standard_radio_can_hide hasModel_radio" >
                          <label className="radio mb-0 p_16"><input id="isModelRequired_True" checked={standardFields?.isModelRequired} onChange={() => setStandardFields({ ...standardFields, isModelRequired: true })} className='m_5' name="isModelRequired" type="radio" value="True" /><span></span> Yes</label>
                          <label className="radio mb-0"><input id="isModelRequired_False" checked={!standardFields?.isModelRequired} onChange={() => setStandardFields({ ...standardFields, isModelRequired: false })} className='m_5' name="isModelRequired" type="radio" value="False" /><span></span> Optional</label>
                        </div>}
                      </td>
                      <td> Model name of the asset </td>
                      <td> Envy </td>
                    </tr>
                    <tr>
                      <td><label className="checkbox has_nolabel m-auto"><input checked={standardFields.hasSerialNo} onChange={() => setStandardFields({ ...standardFields, hasSerialNo: !standardFields.hasSerialNo })} className="fieldCheck valid" data-val="true" data-val-required="Serial No is required." id="hasSerialNo" name="hasSerialNo" type="checkbox" value="true" /><input name="hasSerialNo" type="hidden" value="false" /><span></span><span className="sr-only">Field</span></label></td>
                      <td className="fw-bolder text-nowrap">Serial No</td>
                      <td>
                        {standardFields.hasSerialNo && <div className="radio-inline standard_radio_can_hide hasSerialNo_radio" >
                          <label className="radio mb-0 p_16"><input id="isSerialNoRequired_True" checked={standardFields?.isSerialNoRequired} onChange={() => setStandardFields({ ...standardFields, isSerialNoRequired: true })} className='m_5' name="isSerialNoRequired" type="radio" value="True" /><span></span> Yes</label>
                          <label className="radio mb-0"><input id="isSerialNoRequired_False" checked={!standardFields?.isSerialNoRequired} onChange={() => setStandardFields({ ...standardFields, isSerialNoRequired: false })} className='m_5' name="isSerialNoRequired" type="radio" value="False" /><span></span> Optional</label>
                        </div>}
                      </td>
                      <td> Manufacturer's serial number </td>
                      <td> HG9C3X </td>
                    </tr>
                  </tbody>
                </table>  </div>
            </div>
          </div>
          <div className="form_section" id="custom_ajax_wrapper">
            <div className="separator separator-solid mb-6"></div>
            <div className="form-title d-flex">
              <span className='me-2'>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                  <path fill="#9b9b9b" d="M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z"></path>
                  <path fill="#9b9b9b" d="M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z"></path>
                  <path fill="#9b9b9b" d="M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z"></path>
                  <path fill="#9b9b9b" d="M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z"></path>
                </svg>
              </span>
              <h4 className='mt-1'>Asset Custom Fields</h4>
            </div>
            <div className="text_indent">
              <p>Add custom fields to join the standard fields that we provided. Feel free to get creative.</p>
              <div className="form_section">

                <div className="mb-4" onClick={addCustomField}> <a data-bs-toggle="modal" href="#addEditFieldBox" className="btn btn-success"> <i className="fa fa-plus" aria-hidden="true"></i> Add Custom Field </a> </div>

                <table className="table table-bordered table-sm vertical_middle mb-0" id="custom_fields_table" aria-label="Asset Custom Fields">
                  <thead className="thead-light">
                    <tr>
                      <th> Field Name </th>
                      <th> Data Type </th>
                      <th> Categories </th>
                      <th> Required </th>
                      <th className="text-center" style={{ width: '80px' }}> Edit </th>
                      <th className="text-center" style={{ width: '95px' }}> Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(standardFields.customFields) &&
                      standardFields.customFields.map((item, idx) => (
                        <tr key={idx}>
                          <td className='fw-bolder'>{item?.customFieldName}</td>
                          <td>{item?.customFieldDataType}</td>
                          <td>
                            {Object.entries(item?.categoryList || {})
                              .filter(([key, value]) => value === true)
                              .map(([key, value]) => key)
                              .join(", ")}
                          </td>
                          <td>{item?.isRequired == true ? 'Yes' : 'Optional'}</td>
                          <td className='text-nowrap'>
                            <a
                              data-bs-toggle='modal'
                              onClick={() => {
                                setEditIdx(idx)
                                setCustomFieldData(
                                  customFieldDataArray?.length && customFieldDataArray[idx]
                                    ? customFieldDataArray[idx]
                                    : {
                                      customFieldName: '',
                                      customFieldDataType: '',
                                      isRequired: false,
                                      listOptions: [],
                                      categoryList: {
                                        isBuildingImprovements: true,
                                        isBuildings: true,
                                        isComputerEquipment: true,
                                        isEquipment: true,
                                        isFurnitureAndfixtures: true,
                                        isIntangibleAssets: true,
                                        isLeaseholdImprovements: true,
                                        isSoftware: true,
                                        isVehicles: true,
                                        isfloorA: true
                                      }
                                    }
                                )
                                setFields(
                                  customFieldDataArray?.length && customFieldDataArray[idx]
                                    ? customFieldDataArray[idx].listOptions
                                    : []
                                )
                              }}
                              href='#addEditFieldBox'
                              className='btn btn-outline btn-outline-success btn-active-success btn-xs'
                            >
                              <i className='fa fa-pencil-alt fs-7' aria-hidden='true'></i>Edit
                            </a>
                          </td>
                          <td className='text-nowrap'>
                            <span
                              className="confirmDeleteCustomField btn btn-outline btn-outline-danger btn-active-danger btn-xs"
                              onClick={() => { setDeleteModal(true); setDeleteIdx(idx) }}
                            >
                              <i className='la la-trash fs-4' aria-hidden='true'></i>Delete
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
          <div className="card-footer right notForWizard">
            <div className="row">
              <div className="col-md-12 text-end">
                <button type="submit" onClick={(e) => submitCustomersFields(e)} className="btn btn-primary">{loading ? (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : "Submit"}</button>
                <a href="/dashboard" className="btn btn-secondary">Cancel</a>
              </div>
            </div>
          </div>
          <input id="hasAssetDescription" name="hasAssetDescription" type="hidden" value="True" /></form><form action="/assettiger/database/AddField?Length=8" className="form-horizontal" data-ajax="true" data-ajax-begin="javascript: funCustomFormSubmitBegin();" data-ajax-method="Post" data-ajax-mode="replace" data-ajax-success="javascript: funCustomFormSubmited();" data-ajax-update="#custom_ajax_wrapper" id="formCustomField" method="post" role="form" ><input name="__RequestVerificationToken" type="hidden" value="P6Drb77htOawFFsvekNgHfWBWhq60oDpCoubbGMBsqUDjl2iAaD8QBR9--EiX9PwaCFSBKs_1KJeo_cU8xCFJxl-CxmIsOWHGs6UMfZP8TvVLl9DSBSzMsLIvq24QW2RmsX0tQ2" />
          <div className="modal fade" id="addEditFieldBox" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable mw-md-600px">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Add Custom field</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" aria-label="close"></button>
                </div>
                <div className="modal-body form">
                  <div className="custom-field-details">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label text-sm-end required" htmlFor="AddEditCustomFieldModel_Label">Custom Field Label</label>
                      <div className="col-sm-7">
                        <input className="form-control input-medium" onChange={handleCustomFieldData} value={customFieldData?.customFieldName} data-val="true" data-val-length="Maximum length is 100." data-val-length-max="100" data-val-required="Label is required." id="AddEditCustomFieldModel_Label" maxLength={100} name='customFieldName' type="text" />
                        <span className="field-validation-valid" data-valmsg-for="AddEditCustomFieldModel.Label" data-valmsg-replace="true"></span>
                        <div className='text-danger mt-1'>{error.fieldName}</div>
                      </div>
                    </div>
                    <div className="form-group row mt-5">
                      <label className="col-sm-4 col-form-label text-sm-end" htmlFor="AddEditCustomFieldModel_FieldTypeId">Data Type<span className="required"></span></label>
                      <div className="col-sm-7">
                        <select className="form-select input-medium valid" onChange={handleCustomFieldData} value={customFieldData.customFieldDataType} data-val="true" data-val-number="The field Data Type must be a number." data-val-required="Data Type is required." id="AddEditCustomFieldModel_FieldTypeId" name='customFieldDataType' >
                          <option value="">Select Data Type</option>
                          <option value="Checkbox list" >Checkbox list</option>
                          <option value="Currency">Currency</option>
                          <option value="Date">Date</option>
                          <option value="Date and Time">Date and Time</option>
                          <option value="Dropdown list" >Dropdown list</option>
                          <option value="Email">Email</option>
                          <option value="GPS Coordinates">GPS Coordinates</option>
                          <option value="Memo">Memo</option>
                          <option value="Numeric">Numeric</option>
                          <option value="Numeric Auto Increment">Numeric Auto Increment</option>
                          <option value="Radio button list" >Radio button list</option>
                          <option value="Scanner">Scanner</option>
                          <option value="Text">Text</option>
                          <option value="URL">URL</option>
                        </select>
                        <span className="field-validation-valid" data-valmsg-for="AddEditCustomFieldModel.FieldTypeId" data-valmsg-replace="true"></span>
                        <div className='text-danger mt-1'>{error.dataType}</div>
                      </div>
                    </div>
                    {customFieldData?.customFieldDataType !== "Radio button list" && <div className="form-group row align-items-center " id="ValidateType">
                      <label className="col-sm-4 col-form-label text-sm-end">Data Required</label>
                      <div className="col-sm-7">
                        <div className="radio-inline">
                          <label className="radio mb-0 p_16"><input checked={customFieldData?.isRequired} onChange={handleCustomFieldData} id="isValidate_True" type="radio" className='m_5' name="yes" /><span></span> Yes</label>
                          <label className="radio mb-0"><input checked={!customFieldData?.isRequired} onChange={handleCustomFieldData} id="isValidate_False" className='m_5' type="radio" name="optional" /><span></span> Optional</label>
                        </div>
                      </div>
                    </div>}
                    <div className="form-group row DataInput mb-5" id="StartingValue" style={{ display: 'none' }} >
                      <label className="col-sm-4 col-form-label text-sm-end" htmlFor="AddEditCustomFieldModel_Counter">Starting Value</label>
                      <div className="col-sm-7">
                        <input className="form-control i" data-val="true" data-val-number="The field Starting value must be a number." id="AddEditCustomFieldModel_Counter" maxLength={10} min="0" name="AddEditCustomFieldModel.Counter" type="text" value="" />
                      </div>
                    </div>
                    {(customFieldData?.customFieldDataType === "Checkbox list" || customFieldData?.customFieldDataType === "Radio button list" || customFieldData?.customFieldDataType === "Dropdown list") && <div className="form-group row DataInput customFieldOption">
                      <label className="col-sm-4 col-form-label text-sm-end" htmlFor="AddEditCustomFieldModel_Option">List Options</label>
                      <div className='col-sm-8 input_fields_wrap'>
                        <div className='form-inline flex-nowrap'>
                          <div className='col'>
                            {fields.map((field, index) => (
                              <div key={field.id} className='form-inline flex-nowrap mt-3 align-items-center'>
                                <div className='col'>
                                  <input
                                    className='form-control input-medium border border-black'
                                    type='text'
                                    value={field.value}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                  />
                                  {index == 0 && <div className='text-danger mt-1'>{error.listOption}</div>}
                                </div>
                                {index !== 0 && <div className="col text-nowrap ps-3">
                                  <a className='btn btn-outline btn-outline-danger btn-active-danger btn-xs remove_field ' style={{ padding: "5px 10px" }} onClick={() => removeField(index)}>
                                    <i className="fa fa-times fs-5"></i>  Remove
                                  </a>
                                </div>}
                                {index == 0 && <div className='col text-nowrap ps-3'>
                                  <a
                                    onClick={addNewField}
                                    href='javascript:void(0)'
                                    className='btn btn-secondary btn-sm add_field_button'
                                  >
                                    <i className='fa fa-plus fs-6' aria-hidden='true'></i>Add More
                                    Fields
                                  </a>
                                </div>}
                              </div>
                            ))}
                          </div>
                        </div>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='AddEditCustomFieldModel.Options'
                          data-valmsg-replace='true'
                        ></span>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='AddEditCustomFieldModel.Option'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>}
                    <div className="form-group row align-items-baseline mt-6">
                      <label className="col-sm-4 col-form-label text-sm-end">Selected Categories</label>
                      <div className="col-sm-7">
                        <span className="help-block">Is this field visible to assets of selective 'Categories'?</span>
                        <div className="radio-inline mt-3">
                          <label className="radio mb-0 p_16"><input id="hasCategory_False" type="radio" name="all" checked={categoriesOption?.all} onChange={() => setCategoriesOption({ all: true, limited: false })} className='m_5' /><span></span> All Categories</label>
                          <label className="radio mb-0 p_16"><input id="hasCategory_True" type="radio" name="limited" checked={categoriesOption?.limited} onChange={() => setCategoriesOption({ all: false, limited: true })} className='m_5' /><span></span> Limited Categories</label>
                        </div>
                      </div>
                    </div>
                    {categoriesOption?.limited && <div className="selectiveCatWrapper" style={{ display: 'block' }}>
                      <div className="form-group row align-items-baseline">
                        <label className="col-sm-4 col-form-label text-sm-end checked_categories">Limit to checked Categories</label>
                        <div className="col-sm-7">
                          <div className="checkbox-list">
                            <label className="checkbox mb-3">
                              <input type="checkbox" checked={customFieldData.categoryList.isBuildingImprovements && customFieldData.categoryList.isBuildings && customFieldData.categoryList.isComputerEquipment && customFieldData.categoryList.isEquipment && customFieldData.categoryList.isFurnitureAndfixtures && customFieldData.categoryList.isIntangibleAssets && customFieldData.categoryList.isLeaseholdImprovements && customFieldData.categoryList.isSoftware && customFieldData.categoryList.isVehicles && customFieldData.categoryList.isfloorA} onChange={selectAllCategoriesCheckboxes} className='m_5' id="category_all" value="" /><span></span>
                              All Categories
                            </label>
                            <div className="border p-3" id="divCategory">
                              <div className="checkbox-list custom_flex_dir">
                                <label className="checkbox mb-3"><input checked={customFieldData.categoryList.isBuildingImprovements} onChange={() => setCustomFieldData({ ...customFieldData, categoryList: { ...customFieldData.categoryList, isBuildingImprovements: !customFieldData.categoryList.isBuildingImprovements } })} data-val="true" className='m_5' data-val-required="The isChecked field is required." id="CategoryList_0__isChecked" name="CategoryList[0].isChecked" type="checkbox" value="Building improvements" /><input name="CategoryList[0].isChecked" type="hidden" value="false" /><span></span> Building improvements <input data-val="true" data-val-number="The field CategoryId must be a number." data-val-required="The CategoryId field is required." id="CategoryList_0__CategoryId" name="CategoryList[0].CategoryId" type="hidden" value="49564088" /></label>
                                <label className="checkbox mb-3"><input checked={customFieldData.categoryList.isBuildings} onChange={() => setCustomFieldData({ ...customFieldData, categoryList: { ...customFieldData.categoryList, isBuildings: !customFieldData.categoryList.isBuildings } })} data-val="true" className='m_5' data-val-required="The isChecked field is required." id="CategoryList_1__isChecked" name="CategoryList[1].isChecked" type="checkbox" value="Buildings" /><input name="CategoryList[1].isChecked" type="hidden" value="false" /><span></span> Buildings <input data-val="true" data-val-number="The field CategoryId must be a number." data-val-required="The CategoryId field is required." id="CategoryList_1__CategoryId" name="CategoryList[1].CategoryId" type="hidden" value="49564089" /></label>
                                <label className="checkbox mb-3"><input checked={customFieldData.categoryList.isComputerEquipment} onChange={() => setCustomFieldData({ ...customFieldData, categoryList: { ...customFieldData.categoryList, isComputerEquipment: !customFieldData.categoryList.isComputerEquipment } })} data-val="true" className='m_5' data-val-required="The isChecked field is required." id="CategoryList_2__isChecked" name="CategoryList[2].isChecked" type="checkbox" value="Computer equipment" /><input name="CategoryList[2].isChecked" type="hidden" value="false" /><span></span> Computer equipment <input data-val="true" data-val-number="The field CategoryId must be a number." data-val-required="The CategoryId field is required." id="CategoryList_2__CategoryId" name="CategoryList[2].CategoryId" type="hidden" value="49564090" /></label>
                                <label className="checkbox mb-3"><input checked={customFieldData.categoryList.isEquipment} onChange={() => setCustomFieldData({ ...customFieldData, categoryList: { ...customFieldData.categoryList, isEquipment: !customFieldData.categoryList.isEquipment } })} data-val="true" className='m_5' data-val-required="The isChecked field is required." id="CategoryList_3__isChecked" name="CategoryList[3].isChecked" type="checkbox" value="Equipment" /><input name="CategoryList[3].isChecked" type="hidden" value="false" /><span></span> Equipment <input data-val="true" data-val-number="The field CategoryId must be a number." data-val-required="The CategoryId field is required." id="CategoryList_3__CategoryId" name="CategoryList[3].CategoryId" type="hidden" value="49564091" /></label>
                                <label className="checkbox mb-3"><input checked={customFieldData.categoryList.isFurnitureAndfixtures} onChange={() => setCustomFieldData({ ...customFieldData, categoryList: { ...customFieldData.categoryList, isFurnitureAndfixtures: !customFieldData.categoryList.isFurnitureAndfixtures } })} data-val="true" className='m_5' data-val-required="The isChecked field is required." id="CategoryList_4__isChecked" name="CategoryList[4].isChecked" type="checkbox" value="Furniture and fixtures" /><input name="CategoryList[4].isChecked" type="hidden" value="false" /><span></span> Furniture and fixtures <input data-val="true" data-val-number="The field CategoryId must be a number." data-val-required="The CategoryId field is required." id="CategoryList_4__CategoryId" name="CategoryList[4].CategoryId" type="hidden" value="49564092" /></label>
                                <label className="checkbox mb-3"><input checked={customFieldData.categoryList.isIntangibleAssets} onChange={() => setCustomFieldData({ ...customFieldData, categoryList: { ...customFieldData.categoryList, isIntangibleAssets: !customFieldData.categoryList.isIntangibleAssets } })} data-val="true" className='m_5' data-val-required="The isChecked field is required." id="CategoryList_5__isChecked" name="CategoryList[5].isChecked" type="checkbox" value="Intangible assets" /><input name="CategoryList[5].isChecked" type="hidden" value="false" /><span></span> Intangible assets <input data-val="true" data-val-number="The field CategoryId must be a number." data-val-required="The CategoryId field is required." id="CategoryList_5__CategoryId" name="CategoryList[5].CategoryId" type="hidden" value="49564093" /></label>
                                <label className="checkbox mb-3"><input checked={customFieldData.categoryList.isLeaseholdImprovements} onChange={() => setCustomFieldData({ ...customFieldData, categoryList: { ...customFieldData.categoryList, isLeaseholdImprovements: !customFieldData.categoryList.isLeaseholdImprovements } })} data-val="true" className='m_5' data-val-required="The isChecked field is required." id="CategoryList_6__isChecked" name="CategoryList[6].isChecked" type="checkbox" value="Leasehold improvements" /><input name="CategoryList[6].isChecked" type="hidden" value="false" /><span></span> Leasehold improvements <input data-val="true" data-val-number="The field CategoryId must be a number." data-val-required="The CategoryId field is required." id="CategoryList_6__CategoryId" name="CategoryList[6].CategoryId" type="hidden" value="49564094" /></label>
                                <label className="checkbox mb-3"><input checked={customFieldData.categoryList.isVehicles} onChange={() => setCustomFieldData({ ...customFieldData, categoryList: { ...customFieldData.categoryList, isVehicles: !customFieldData.categoryList.isVehicles } })} data-val="true" className='m_5' data-val-required="The isChecked field is required." id="CategoryList_7__isChecked" name="CategoryList[7].isChecked" type="checkbox" value="Software" /><input name="CategoryList[7].isChecked" type="hidden" value="false" /><span></span> Software <input data-val="true" data-val-number="The field CategoryId must be a number." data-val-required="The CategoryId field is required." id="CategoryList_7__CategoryId" name="CategoryList[7].CategoryId" type="hidden" value="49564095" /></label>
                                <label className="checkbox mb-3"><input checked={customFieldData.categoryList.isfloorA} onChange={() => setCustomFieldData({ ...customFieldData, categoryList: { ...customFieldData.categoryList, isfloorA: !customFieldData.categoryList.isfloorA } })} data-val="true" className='m_5' data-val-required="The isChecked field is required." id="CategoryList_8__isChecked" name="CategoryList[8].isChecked" type="checkbox" value="Vehicles" /><input name="CategoryList[8].isChecked" type="hidden" value="false" /><span></span> Vehicles <input data-val="true" data-val-number="The field CategoryId must be a number." data-val-required="The CategoryId field is required." id="CategoryList_8__CategoryId" name="CategoryList[8].CategoryId" type="hidden" value="49564096" /></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" value="Save" {...(customFieldData?.customFieldName && customFieldData?.customFieldDataType && ((customFieldData?.customFieldDataType === 'Checkbox list' || customFieldData?.customFieldDataType === 'Radio button list' || customFieldData?.customFieldDataType === 'Dropdown list') ? fields[0]?.value : true) ? {'data-bs-dismiss': 'modal'} : {})} onClick={submitCustomField}>
                    {isSitesExist===0?"Continue":"Save"}</button>
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
              </div>
            </div>
          </div>
          <input data-val="true" data-val-length="Maximum length is 4000." data-val-length-max="4000" id="AddEditCustomFieldModel_Options" name="AddEditCustomFieldModel.Options" type="hidden" value="" /><input data-val="true" data-val-required="Is this field visible to assets of selective 'Categories'?" id="AddEditCustomFieldModel_hasCategory" name="AddEditCustomFieldModel.hasCategory" type="hidden" value="False" /><input data-val="true" data-val-required="Is this field need to be required?" id="AddEditCustomFieldModel_isValidate" name="AddEditCustomFieldModel.isValidate" type="hidden" value="True" /><input data-val="true" data-val-number="The field CustomFieldId must be a number." data-val-required="The CustomFieldId field is required." id="AddEditCustomFieldModel_CustomFieldId" name="AddEditCustomFieldModel.CustomFieldId" type="hidden" value="0" /></form>        <div className="modal fade" id="confirmDeleteCustomField" tabIndex={-1} role="basic" aria-hidden="true">
        </div>
      </div>
    </div>
  )
}

export default DataAssets
