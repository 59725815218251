import React, { useEffect, useState } from 'react'
import { PageTitle } from "../../../_metronic/layout/core"
import { useIntl } from 'react-intl'
import { useAuth } from '../auth'
import { toast } from 'react-toastify'
import {
    useUploadsMutation, usePreviewDataMutation, useImportCategoriesMutation,
    useImportDepartmentsMutation, useImportLocationsMutation,
    useImportSitesMutation, useImportPersonsMutation, useImportAssetsMutation,
    useImportCustomersMutation, useImportMaintenancesMutation
} from '../services/imports'
import FormStep from './Form/step2'
import ImportForm from './verfiyAndImport'
import Modal from './Modal/modal'


type FormStateType = {
    isUpdateSite: boolean;
    updatePerson: boolean;
    deleteAllMaintenance: boolean;
    deleteExistingRecords: boolean;
    updateAssests: boolean;
    keepExistingValueAssets: boolean;
    LinkedFieldsAssets: boolean;
    updateCustomers: boolean;
    updateMaintenanceRecords: boolean;
    deleteExistingMaintenance: boolean;
    updateAssetsMaintenance: boolean
};

function Import() {
    const intl = useIntl()
    const { currentUser } = useAuth();

    const [file, setFile] = useState<any>()
    const [fileError, setFileError] = useState('');
    const [execlData, setExeclData] = useState([])
    const [titleIndexMap, setTitleIndexMap] = useState<any>({})

    const [selectedValue, setSelectedValue] = useState<string>('assets')
    const [title, setTitle] = useState([])
    const [option, setOption] = useState({})

    const [setp2Form, setStep2Form] = useState(false)
    const [previewModal, setPreviewModal] = useState(false)
    const [show, setShow] = useState<boolean>(false)
    const [formState, setFormState] = useState<FormStateType>({
        isUpdateSite: true,
        updatePerson: true,
        deleteAllMaintenance: false,
        deleteExistingRecords: false,
        updateAssests: false,
        keepExistingValueAssets: false,
        LinkedFieldsAssets: false,
        updateCustomers: false,
        updateMaintenanceRecords: true,
        deleteExistingMaintenance: false,
        updateAssetsMaintenance: false,
    });
    const [modalState, setModalState] = useState({
        uploads: true,
        uploadAgain: false,
        preview: false,
        mapAgain: false,

    })


    const [uploads] = useUploadsMutation()
    const [previewData] = usePreviewDataMutation()
    const [importCategories] = useImportCategoriesMutation()
    const [importDepartments] = useImportDepartmentsMutation()
    const [importLocations] = useImportLocationsMutation()
    const [importSites] = useImportSitesMutation()
    const [importPersons] = useImportPersonsMutation()
    const [importAssets] = useImportAssetsMutation()
    const [importCustomers] = useImportCustomersMutation()
    const [importMaintenances] = useImportMaintenancesMutation()

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(e.target.value)
    }

    const downloadTemplateExcel = (fileName: any) => {
        const fileNameTemp = process.env.REACT_APP_PUBLIC_URL + fileName;
        const link = document.createElement('a');
        link.href = fileNameTemp;
        link.download = fileNameTemp;
        // Simulate a click event on the link
        link.click();
    }
    const handleTemplateSubmit = () => {
        if (selectedValue === 'assets') {
            downloadTemplateExcel('templates/ImportAssetTemplate.xlsx')

        } else if (selectedValue === 'categories') {
            downloadTemplateExcel('templates/ImportCategoriesTemplate.xlsx')
        } else if (selectedValue === 'customers') {
            downloadTemplateExcel('templates/ImportCustomersTemplate.xlsx')

        } else if (selectedValue === 'departments') {
            downloadTemplateExcel('templates/ImportDepartmentsTemplate.xlsx')

        } else if (selectedValue === 'locations') {
            downloadTemplateExcel('templates/ImportLocationsTemplate.xlsx')

        } else if (selectedValue === 'maintenances') {
            downloadTemplateExcel('templates/ImportMaintenancesTemplate.xlsx')

        } else if (selectedValue === 'person') {
            downloadTemplateExcel('templates/ImportPersonsTemplate.xlsx')

        } else if (selectedValue === 'sites') {
            downloadTemplateExcel('templates/ImportSitesTemplate.xlsx')

        }
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setFileError('');
    };

    const handleUpload = async () => {
        if (!file) {
            setFileError('Please select a file.');
            return;
        }
        setModalState(prevState => ({
            ...prevState,
            uploads: false,
            uploadAgain: true,
            preview: true
        }))
        setFileError('');
        setTitleIndexMap({})
        const formData = new FormData();
        formData.append('file', file);

        setStep2Form(true)
        const type = returnSelectedType()
        try {
            const { data, message } = await uploads({ data: formData, type: type }).unwrap()

            if (data) {
                const { titles, options } = data
                setTitle(titles)
                setOption(options)
                toast.dismiss()
                toast.success(message ? message : data?.message)
            }
        } catch (error: any) {
            toast.dismiss()
            toast.error(error.message)
            window.location.reload()
        }
    }

    const previewFunction = async () => {
        setPreviewModal(true)
        setModalState(prevState => ({
            ...prevState,
            preview: false,
            mapAgain: true
        }))
        const value: any = [JSON.stringify(titleIndexMap)]

        const formData = new FormData();
        formData.append('file', file);
        formData.append('indexs', value)

        const type = returnSelectedType()

        try {
            const { data, message } = await previewData({ data: formData, type: type }).unwrap()

            if (data) {
                const { options } = data
                setExeclData(options)
                toast.dismiss()
                toast.success(message ? message : data?.message)

            }
        } catch (error: any) {
            const { message } = error
            toast.dismiss()
            toast.error(message ? message : error?.message)
        }

    }

    const handleImports = async (e: any) => {
        e.preventDefault()
        try {
            const value: any = [JSON.stringify(titleIndexMap)]

            const formData: any = new FormData();
            formData.append('file', file);
            formData.append('indexs', value)

            if (selectedValue === 'assets') {
                formData.append('updateAssests', formState.updateAssests)
                formData.append('LinkedFieldsAssets', formState.LinkedFieldsAssets)
                formData.append('keepExistingValueAssets', formState.keepExistingValueAssets)

                const { data, message, success } = await importAssets({ data: formData, userId: currentUser?.id }).unwrap()
                toast.dismiss()
                if (success) {
                    toast.success(message ? message : data?.message)

                } else {
                    toast.error(message ? message : data?.message)
                }
            }
            else if (selectedValue === 'categories') {
                const { data, message, success } = await importCategories({ data: formData, userId: currentUser?.id }).unwrap()
                toast.dismiss()
                if (success) {
                    toast.success(message ? message : data?.message)

                } else {
                    toast.error(message ? message : data?.message)
                }
            }
            else if (selectedValue === 'customers') {
                formData.append('updateCustomers', formState.updateCustomers)

                const { data, message, success } = await importCustomers({ data: formData, userId: currentUser?.id }).unwrap()
                toast.dismiss()
                if (success) {
                    toast.success(message ? message : data?.message)

                } else {
                    toast.error(message ? message : data?.message)
                }
            }
            else if (selectedValue === 'departments') {
                const { data, message, success } = await importDepartments({ data: formData, userId: currentUser?.id }).unwrap()
                toast.dismiss()
                if (success) {
                    toast.success(message ? message : data?.message)

                } else {
                    toast.error(message ? message : data?.message)
                }
            }
            else if (selectedValue === 'locations') {
                const { data, message, success } = await importLocations({ data: formData, userId: currentUser?.id }).unwrap()
                toast.dismiss()
                if (success) {
                    toast.success(message ? message : data?.message)

                } else {
                    toast.error(message ? message : data?.message)
                }
            }
            else if (selectedValue === 'maintenances') {
                formData.append('deleteAllMaintenance', formState.deleteAllMaintenance)
                formData.append('deleteExistingMaintenance', formState.deleteExistingMaintenance)
                formData.append('updateAssetsMaintenance', formState.updateAssetsMaintenance)

                const { data, message, success } = await importMaintenances({ data: formData, userId: currentUser?.id }).unwrap()
                toast.dismiss()
                if (success) {
                    toast.success(message ? message : data?.message)

                } else {
                    toast.error(message ? message : data?.message)
                }
            }
            else if (selectedValue === 'person') {
                formData.append('updatePerson', formState.updatePerson)
                const { data, message, success } = await importPersons({ data: formData, userId: currentUser?.id }).unwrap()
                toast.dismiss()
                if (success) {
                    toast.success(message ? message : data?.message)

                } else {
                    toast.error(message ? message : data?.message)
                }
            }
            else {
                formData.append('isUpdateSite', formState.isUpdateSite)
                const { data, message, success } = await importSites({ data: formData, userId: currentUser?.id }).unwrap()
                toast.dismiss()
                if (success) {
                    toast.success(message ? message : data?.message)

                } else {
                    toast.error(message ? message : data?.message)
                }
            }
        } catch (error: any) {
            const { message } = error
            toast.dismiss()
            toast.error(message ? message : error?.message)

        }

    }

    const cancelImports = (e: any) => {
        e.preventDefault()
        setExeclData([])
        setTitle([])
        setOption([])
        setStep2Form(false)
        setPreviewModal(false)
        setModalState({
            uploads: true,
            uploadAgain: false,
            preview: false,
            mapAgain: false,

        })
    }


    const returnSelectedType = (): number => {
        const type = selectedValue === 'assets' ? 1 :
            selectedValue === 'categories' ? 2 :
                selectedValue === 'customers' ? 3 :
                    selectedValue === 'departments' ? 4 :
                        selectedValue === 'locations' ? 5 :
                            selectedValue === 'maintenances' ? 6 :
                                selectedValue === 'person' ? 7 : 8

        return type
    }

    useEffect(() => {
        // Create an object to store the initial selected option indices
        const initialSelectedOptionIndices = {};

        // Initialize titleIndexMap and initialSelectedOptionIndices
        if (title && option) {

            title.forEach((val: string, index: number) => {
                if (option[val.toString()]) {
                    initialSelectedOptionIndices[val.toString()] = index; // Initialize to the first option
                }
            });

            setTitleIndexMap(initialSelectedOptionIndices);
        }
    }, [title, option]);

    return (
        <div>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Import' })}</PageTitle>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0 '>
                        <h3 className='fw-bolder m-0'><span className='me-2 align-middle'><i className='la la-cloud-upload fs-1'></i></span> Import Wizard</h3>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                        <div className='form'>
                            <div className='card-body border-top p-9'>
                                <div className='pb-5 mb-5'>
                                    <h5>Step 1: Upload File</h5>
                                    <p className="fw-bolder m-0 mb-4">Import assets using an Excel spreadsheet. Download our template, fill it in, and upload. Also download 'Field Limits Info' to make sure your data is within character limits for all fields. There is no limit on the number of you can have. But you can import up to <b>5,000 records</b> in one spreadsheet.</p>
                                    <p>If you need assistance in uploading your assets, please feel free to email your spreadsheet to <span >info@assettiger.com</span>. We’ll take care of the rest.</p>
                                </div>
                               { modalState.uploadAgain ?
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button type='button' className='btn btn-primary d-flex justify-content-center' onClick={() => setShow(true)}>
                                    <i className='la la-cloud-upload fs-2 me-1'></i>
                                    Upload Again
                                </button>
                            </div>
                            :
                            <>
                                <div className="row mb-5">
                                    <div className="col-xl-6">
                                        <div className='row mb-6'>
                                            <div className='col-lg-3 col-form-label fw-bold fs-6'>Import To</div>
                                            <div className='col-lg-9 fv-row'>
                                                <select className="form-select" data-val="true" data-val-number="" id="" name="" onChange={(e) => handleChange(e)}>
                                                    {/* <option value="">Select</option> */}
                                                    <option value="assets" >Assets</option>
                                                    <option value="categories">Categories</option>
                                                    <option value="customers">Customers</option>
                                                    <option value="departments">Departments</option>
                                                    <option value="locations">Locations</option>
                                                    <option value="maintenances">Maintenances</option>
                                                    <option value="person">Person/Employee</option>
                                                    <option value="sites">Sites</option>
                                                    {/* <option value="warranties">Warranties</option>
                                                <option value="assets-linking">Assets Linking (Parent-Child)</option> */}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className='row mb-6'>
                                            <div className='col-6 fv-row'>
                                                <button type='button' onClick={() => handleTemplateSubmit()} className='btn btn-light-primary d-flex ms-3 w-100 justify-content-center'>
                                                    <i className='la la-cloud-download fs-2 me-1'></i>
                                                    Download Template
                                                </button>
                                            </div>
                                            {/* <div className='col-6 fv-row'>
                                            <button type='button' className='btn btn-light-primary d-flex ms-3 w-100 justify-content-center'>
                                                <i className='la la-cloud-download fs-2 me-1'></i>
                                                Download Field Limit
                                            </button>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className='row mb-6'>
                                            <div className='col-lg-3 col-form-label required fw-bold fs-6'>Select File</div>
                                            <div className='col-lg-9 fv-row'>
                                                <div className="">
                                                    <input accept=".xls,.csv,.xlsx" onChange={(e) => handleFileChange(e)} className="form-control" data-val="true" data-val-required="File is required." id="File" name="File" placeholder="" type="file" />
                                                    {/* <span className="field-validation-valid" data-valmsg-for="File" data-valmsg-replace="true">{fileError}</span> */}
                                                    {fileError && <div style={{ color: 'red' }}>{fileError}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button type='button' onClick={() => handleUpload()} className='btn btn-primary d-flex justify-content-center'>
                                    <i className='la la-cloud-upload fs-2 me-1'></i>
                                    Upload File
                                </button>
                            </div>
                            </>}
                        </div>
                        
                            </div>

                </div>
            </div>
            {
                setp2Form ?
                    <FormStep
                        title={title}
                        option={option}
                        titleIndexMap={titleIndexMap}
                        preview={previewFunction}
                        modalState={modalState}
                        setModalState={setModalState}
                        setPreviewModal={setPreviewModal}
                    /> :
                    null
            }
            {

                previewModal ?
                    <ImportForm
                        type={returnSelectedType()}
                        title={title}
                        option={execlData}
                        importFunction={handleImports}
                        cancel={cancelImports}
                        formState={formState}
                        setFormState={setFormState}
                    />
                    : null
            }
            {
                show ?
                    <Modal
                        setShow={setShow}
                        show={show}
                        click={(e) => {
                            setShow(false)
                            cancelImports(e)

                        }
                        }
                    />
                    :

                    ''}

        </div>

    )
}

export default Import