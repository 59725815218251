import { useFormik } from 'formik'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { initialPersons, PersonResponse } from '../core/_models'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getAllCustomers } from '../core/_requests'
import { useAuth } from '../../../../auth'
import { countriesList } from './countries'
import { useAddCustomerMutation, useUpdateCustomerMutation } from '../../../../services/persons'
import { useGetAllSitesQuery } from 'app/modules/services/sites'
import { useGetAllDepartmentsQuery } from 'app/modules/services/departments'
import { useGetAllLocationsBySiteQuery } from 'app/modules/services/locations'
import DepartmentsModel from '../../departments/components/DepartmentsModel'
import LocationModal from '../../locations/components/LocationModal'
import SitesModal from '../../../../apps/company-setup/sites/components/SitesModal'
import { KTSVG } from "../../../../../../_metronic/helpers"

const CustomersSechma = Yup.object().shape({
  fullName: Yup.string().trim()
    .required('Person Name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Person Name should contain only letters'),
  // postalCode: Yup.number().typeError('Postal Code is Number'),
})

const CustomersModal = (props: any) => {

  const { currentUser } = useAuth()
  // const [loading, setLoading] = useState<boolean>()
  const [siteId, setSiteId] = useState<boolean>(false)
  const [skip, setSkip] = useState<boolean>(false)
  const { data: siteData } = useGetAllSitesQuery(currentUser?.id);
  const [showSiteModal, setShowSiteModal] = useState<boolean>(false)
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false)
  const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false)
  const [customers, setCustomers] = useState<PersonResponse[] | undefined>()
  const [addCustomer, { isLoading: isAdding }] = useAddCustomerMutation();
  const [updateCustomer, { isLoading: isUpdating }] = useUpdateCustomerMutation();
  const { data: locationData, error: locationError } = useGetAllLocationsBySiteQuery({ userId: currentUser?.id, id: siteId }, { skip })
  const { data: departmentData } = useGetAllDepartmentsQuery(currentUser?.id)

  // useEffect(() => {
  //   if (currentUser) {
  //     formik.resetForm()
  //     getAllSites(currentUser?.id).then((response) => {
  //       setSites(response)
  //     })
  //   }
  // }, [])

  useEffect(() => {
    formik.resetForm()
    if (props?.CustomerData?.id) {
      formik.setValues({
        fullName: props?.CustomerData?.fullName,
        employeeId: props?.CustomerData?.employeeId,
        title: props?.CustomerData?.title,
        phone: props?.CustomerData?.phone,
        email: props?.CustomerData?.email,
        siteId: props?.CustomerData?.siteId,
        locationID: props?.CustomerData?.locationID,
        departmentId: props?.CustomerData?.departmentId,
        notes: props?.CustomerData?.notes,
        createdBy: props?.CustomerData?.createdBy,
        userId: props?.CustomerData?.userId,
      })
    }
  }, [props])

  useEffect(() => {
    setSkip(false) //set skip false becasue skip param in query hook does not load on mount
  }, [])

  const handleSiteClick = (e: any) => {
    let id = e.target.value
    setSiteId(id)
    setSkip(false)
  }
  const handleShowSiteModal = () => {
    setShowSiteModal(true)
  }

  const handleCloseSiteModal = () => {
    setShowSiteModal(false)
  }

  const handleShowLocationModal = () => {
    setShowLocationModal(true)
  }

  const handleCloseLocationModal = () => {
    setShowLocationModal(false)
  }

  const handleShowDepartmentModal = () => {
    setShowDepartmentModal(true)
  }

  const handleCloseDepartmentModal = () => {
    setShowDepartmentModal(false)
  }

  const formik = useFormik({
    initialValues: { ...initialPersons },
    validationSchema: CustomersSechma,
    onSubmit: async (values) => {
      // setLoading(true)
      if (currentUser) {
        values.fullName = values.fullName.trim()
        console.log(props?.CustomerData?.id, "--------idddd")
         
        try {
          const data = props?.CustomerData?.id
            ? await updateCustomer({ id: props?.CustomerData?.id, values }).unwrap()
            : await addCustomer({ ...values, userId: currentUser.id }).unwrap()
          if (data) {
            toast.dismiss()
            if (data?.success) {
              toast.success(data?.message)
              formik.resetForm()
              props.handleClose()
            } else {
              toast.error(data?.message)
            }
            // setLoading(false)

          }
        }
        catch (error: any) {
          if(error?.data?.message && error?.data?.status==409){
            
            toast.error(error?.data?.message)
          }
          else{
            toast.error('Something went wrong')
          }
          formik.setSubmitting(false)
          // setLoading(false)
        }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    props.handleClose()
  }

  return (
    <>
      <Modal className='custom-model-sm' show={props.show} onHide={props.handleClose} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{props?.CustomerData?.id ? 'Update Person' : 'Add Person'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='inner-site-modal'>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>Person Name</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('fullName')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                  {formik.touched.fullName && formik.errors.fullName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.fullName}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Employee ID</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('company')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                  {formik.touched.employeeId && formik.errors.employeeId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.employeeId}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Title</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('address1')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label  fw-bold fs-6 mb-2'>Phone</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('address2')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Email</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('email')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />

                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span className=''>Site</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('siteId')}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleSiteClick(e);
                    }}
                  >
                    <option value=''>Select Site</option>
                    {siteData?.sites.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  {/* <button
                    type='button'
                    className='btn btn-light-primary d-flex ms-3'
                    onClick={() => handleShowSiteModal()}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    New
                  </button> */}
                  <SitesModal show={showSiteModal} handleClose={handleCloseSiteModal} SiteData={[]} />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span className=''>Location</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('locationId')}

                  >
                    <option value=''>Select Location</option>
                    {locationData?.locations?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.id}>
                          {item.location}
                        </option>
                      )
                    })}
                  </select>
                  {/* <button
                    type='button'
                    className='btn btn-light-primary d-flex ms-3'
                    onClick={() => handleShowLocationModal()}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    New
                  </button> */}
                  <LocationModal show={showLocationModal} handleClose={handleCloseLocationModal} locationData={[]} />
                </div>

              </div>

              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span className=''>Department</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('departmentId')}

                  >
                    <option value=''>Select Department</option>
                    {departmentData?.department?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.id}>
                          {item.department}
                        </option>
                      )
                    })}
                  </select>
                  {/* <button
                    type='button'
                    className='btn btn-light-primary d-flex ms-3'
                    onClick={() => handleShowDepartmentModal()}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    New
                  </button> */}
                  <DepartmentsModel show={showDepartmentModal} handleClose={handleCloseDepartmentModal} data={[]} />
                </div>

              </div>

              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Notes</label>
                <div className='col-lg-9 fv-row'>
                <textarea
                  placeholder=''
                  {...formik.getFieldProps('notes')}
                  // type='text'
                  rows={3}
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
                </div>
              </div>
            </div>

            <div className='text-end custom_location'>
              <Button variant='secondary' onClick={handleClose}>
                Close{' '}
              </Button>

              <button
                type='submit'
                className='add_location_btn'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {(!isAdding && !isUpdating) && (
                  <span className='indicator-label'>
                    {props?.CustomerData?.id ? 'Update Person' : 'Add Person'}
                  </span>
                )}
                {(isAdding || isUpdating) && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomersModal
