import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from 'react-bootstrap';
interface ModalProps {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    click: (e: any) => void;
}
const ModalData = ({ click, setShow, show }: ModalProps) => {
    return (
        <Modal className='modal' show={show}>
            <div className="modal-dialog  modal-dialog-scrollable mw-md-600px" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel"> Do you want to abandon recently uploaded file?</h4>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShow(false)}>Cancel</button>
                        <button className="btn btn-primary" onClick={(e) => click(e)}> Abandon</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalData