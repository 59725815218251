import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'

// _____ Component/Helpers/Modals _____ //
import {useAuth} from '../../../../auth'
import LocationModal from './LocationModal'
import {LocationHeader} from './LocationHeader'
import {LocationResponse} from '../core/_models'
// import {deleteLocation, getAllLocation} from '../core/_requests'
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import ActionIcon from '../../../../../../_metronic/assets/icons/ActionIcon'
import { useDeleteLocationMutation, useGetAllLocationsBySiteQuery } from '../../../../services/locations'
import { useNavigate } from 'react-router-dom';

const LocationListing = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const [check, setCheck] = useState<number[]>([])
  const [data, setData] = useState({})
  const [show, setShow] = useState<boolean>(false)
  const [skip, setSkip] = useState<boolean>(false)
  const [siteId, setSiteId] = useState<boolean>(false)
  const [selected, setSelected] = useState<number[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const { data : userLocationList, error } = useGetAllLocationsBySiteQuery({userId:currentUser?.id,id: siteId},{skip})
  const [deleteLocations] = useDeleteLocationMutation();
  const [isSitesExist, setIsSitesExist] = useState<any>(false)
  const [deleteCascade, setDeleteCascade] = useState(false);
  const handleClose = () => {
    // getLocation()
    setShow(false)
  }

  useEffect(() => {
    setSkip(false) //set skip false becasue skip param in query hook does not load on mount
  }, [])
  const handleShow = (item: LocationResponse) => {
    setData(item)
    setShow(true)
  }

  useEffect(() => {
    checkVistingPage(userLocationList?.locations)
    console.log(userLocationList, "======userLocationList")
  }, [userLocationList])


  //__ fetch the list of all the locations __ //
  // const getLocation = () => {
  //   if (currentUser) {
  //     getAllLocation(currentUser.id)
  //       .then((response: any) => {
  //         setUserLocationList(response)
  //       })
  //       .catch((error) => {
  //         console.error('Error:', error)
  //       })
  //   }
  // }
  const checkVistingPage=(dataList:any)=>{
    if(isSitesExist===false)
    {if(dataList && dataList?.length>0){
      setIsSitesExist(dataList?.length)
    }
    else{
      setIsSitesExist(0)
    }
  }
  }
  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
    setdeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  // select all the locations
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = userLocationList?.locations?.map((item:any) => item.id)
      setSelectAll(true)
      setSelected(newSelecteds)
      setdeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setdeleteDataArr([])
    }
  }

  // delete the selected multiple locations and clear all the states
  const deleteSelectedLocation = async (ids: number[]) => {
    const res = await deleteLocations({userId:currentUser?.id, cascade:deleteCascade ? 1 : 0, id:ids}).unwrap()
    // const res = await deleteLocation(currentUser?.id, ids)
    // getLocation()
    if(res.status === 409){
      // setDeleteCascade(true)
      toast.info(res.message)
    }else{
      toast.success(res.message)
    }
      setCheck([])
      setdeleteDataArr([])
      setSelectAll(false)
      setDeleteModal(false)
      setDeleteCascade(false)
  }

  //cancle the delete modal
  const cancle = () => {
    setDeleteModal(false)
    setDeleteCascade(false)
  }

  //confirm the delete modal
  const confirm = () => deleteSelectedLocation(check)

  return (
    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        <div id='kt_content_container' className='container'>
          <div className='card'>          
            <ConfirmationPopup deleteModal={deleteModal} cancle={cancle} confirm={confirm} deleteCascade={deleteCascade}/>
            <LocationHeader deleteDataArr={deleteDataArr} setSiteId={setSiteId} setSkip={setSkip} setDeleteModal={setDeleteModal} setCheck={setCheck} handleShow={handleShow}/>
            <LocationModal show={show} handleClose={handleClose} locationData={data}/>
            <div className='card-body py-4'>
            <p className={'text-dark'}>You may also add <strong>Locations</strong>. <strong>Locations</strong> are a subset of <strong>Sites</strong>. For example, the <strong>Site</strong> may be a building or address. The <strong>Location</strong> may be a specific room, office or floor within the <strong>Site</strong>. Select a Site and add your list of <strong>Locations</strong> here.</p>
              <div className='table-responsive'>
                <table id='kt_table_users' className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' role='table' >
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      <th colSpan={1} role='columnheader' className='w-10px pe-2'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input type='checkbox' className='form-check-input' checked={selectAll} onChange={handleSelectAll}/>
                        </div>
                      </th>
                      <th colSpan={1} role='columnheader' className='min-w-125px'> Locations </th>
                      <th colSpan={1} role='columnheader' className='text-end min-w-100px'> Actions </th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                  
                    {userLocationList?.locations?.length > 0 ? (
                      userLocationList?.locations?.map((item: LocationResponse, idx: number) => {
                        return (
                          <tr key={idx} role='row'>
                            <td role='cell' className=''>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  type='checkbox' className='form-check-input' checked={selected.includes(item.id)}
                                  onChange={() => handleCheckboxChange(item.id)}
                                />
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className='d-flex align-items-center'>
                                <div className=' overflow-hidden me-3'>
                                  <a href='#'></a>
                                </div>
                                <div className='word-br'>{item.location}</div>
                              </div>
                            </td>
                            <td role='cell' className='text-end min-w-100px'>
                              <div className='dropdown'>
                                <button className='btn btn-secondary dropdown-toggle custom_action' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false' >
                                  Action
                                  <ActionIcon />
                                </button>
                                <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                  <li onClick={() => handleShow(item)}>
                                    <span className='dropdown-item'>Edit</span>
                                  </li>
                                  <li
                                    onClick={() => {
                                      setDeleteModal(true)
                                      setCheck([item.id])
                                    }}
                                  >
                                    <a className='dropdown-item' href='#'>
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {isSitesExist===0?
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button onClick={()=>{navigate(`/apps/setup/categories`)}} className='btn btn-primary'>
              {/* {!loading && isCompanyAdded? 'Submit':!loading && !isCompanyAdded?'Continue':''} */}
              Continue
              {/* {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )} */}
            </button>
          </div>
          :''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationListing
