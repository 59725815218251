import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {AccountTable} from './table/AccountTable'
import {KTCard} from '../../../../_metronic/helpers'

const AccountList = () => {
  
  return (
    <>
      <KTCard>
        <AccountTable />
      </KTCard>
      
    </>
  )
}

const AccountListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AccountList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AccountListWrapper}
