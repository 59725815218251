import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SiteResponse } from '../apps/company-setup/sites/core/_models';

const API_URL = process.env.REACT_APP_API_URL

export const eventsApi = createApi({
    reducerPath: 'eventsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: ['Events'],
  endpoints: (build) => ({
    getAllEvents: build.query<any, any>({
      query: (token) => ({
        url: `/events`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
      }),
      providesTags: ['Events'],
    }),
    getCustomEvents: build.query<any, any>({
      query: (token) => ({
        url: `/customevents`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
      }),
      providesTags: ['Events'],
    }),
    getCustomEvent: build.query<any, any>({
      query: ({token,id}) => ({
        url: `/customevents/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
      }),
      providesTags: ['Events'],
    }),
    addEvent: build.mutation<any, any>({
      query: ({data,token}) => ({
        url: `/events`,
        method: 'POST',
        body:data,
        headers: {
          Authorization: `Bearer ${token}`
        },
      }),
      invalidatesTags: () => [{ type: 'Events'}],
    }),
    addCustomEvent: build.mutation<any, any>({
      query: ({data,token}) => ({
        url: `/customevents`,
        method: 'POST',
        body:data,
        headers: {
          Authorization: `Bearer ${token}`
        },
      }),
      invalidatesTags: () => [{ type: 'Events'}],
    }),
    updateEvent: build.mutation<any, { eventId: any; values: any }>({
      query: ({ eventId, values }) => ({
        url: `events/${eventId}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error, { eventId }) => [{ type: 'Events', eventId }],
    }),
    updateCustomEvent: build.mutation<any,any>({
      query: ({ id, values,token }) => ({
        url: `customevents/${id}`,
        method: 'PUT',
        body: values,
        headers: {
          Authorization: `Bearer ${token}`
        },
      }),
      invalidatesTags: (result, error) => [{ type: 'Events'}],
    }),
    deleteEvents: build.mutation<any, any>({
      query({token, id}) {
        return {
          url: `customevents/${id}`,
          headers: {
            Authorization: `Bearer ${token}`
          },
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Events', userId }],
    }),
  }),
})

export const {
  useGetAllEventsQuery,
  useGetCustomEventsQuery,
  useGetCustomEventQuery,
  useAddEventMutation,
  useAddCustomEventMutation,
  useUpdateEventMutation,
  useUpdateCustomEventMutation,
  useDeleteEventsMutation,
} = eventsApi
