import React, { useEffect, useState } from 'react'
import { Options } from '../core/_models'
import { getAuth } from '../../../../auth'
import { addOptions, getOptions } from '../core/_requests'
import { toast } from 'react-toastify'
import { useGetOptionQuery, useAddOptionMutation } from '../../../../../modules/services/option'

const AssetTagging = () => {
  const auth = getAuth()
  const [options, setOptions] = useState<Options>({
    hasAutoAssetTagId: true,
    Starting: "",
    Digits: "",
    hasPadding: true,
    NextAssetTagId: "",
    Prefix: "",
    Suffix: "",
    hascheckInReminderEmail: true,
    checkInReminderEmail: [{ lead: "1", frequency: "Days", eventTime: "Before Event" }],
    hasLleaseReturnReminderEmail: true,
    leaseReturnReminderEmail: [{ lead: "1", frequency: "Days", eventTime: "Before Event" }],
  })
  const [flag, setFlag] = useState<Boolean>(false)
  const [error, setError] = useState<{ startingNumber: string }>({ startingNumber: "" })
  const [loading, setLoading] = useState(false)
  const { data, error: optionError, isLoading } = useGetOptionQuery(auth?.token)
  const [updateOption, { isLoading: isUpdating }] = useAddOptionMutation()


  useEffect(() => {
    setOptions(data?.options)
    console.log(options, "........ooooppp")
  }, [flag, data])

  const startingLength = options?.Starting?.length;
  const leadingZerosCount = parseInt(options?.Digits) - startingLength;

  let startingWithLeadingZeros = options?.Starting;
  for (let i = 0; i < leadingZerosCount; i++) {
    startingWithLeadingZeros = "0" + startingWithLeadingZeros;
  }

  const submitOptions = () => {
    if (options?.Starting && !isNaN(Number(options?.Starting))) {
      try {
        setError({ startingNumber: "" })
        updateOption({ data: options, token: auth?.token }).unwrap()
        if (data?.status == 200) {
          setFlag(!flag)
          toast.success("Options updated successfully.");
        }
      }
      catch (error) {
        console.error(error)
        toast.error('Something went wrong')
      }

    }
    else {
      setError({ startingNumber: "The Starting field is required and must be a number." })
    }
  }

  return (
    <>
      <div className='card'>
        <div className='main_asset_tagging'>
          {/* <div className='asset_tagging mb-5'>AssetTagging</div> */}
          <h3 className='fw-bolder mb-5 card-title'>AssetTagging</h3>
          <div className="row">
            <div className="col-sm-11 offset-sm-1">
              <div className="checkbox-list">
                <label className="checkbox " htmlFor="hasAutoAssetTagId">
                  <input type="checkbox" className="form-check-input custom_check" checked={options?.hasAutoAssetTagId} onChange={() => setOptions({ ...options, hasAutoAssetTagId: !options?.hasAutoAssetTagId })} id="hasAutoAssetTagId" name="hasAutoAssetTagId" value="True" /> <span></span>Automatic asset tags</label>
              </div>
              <div className="text_indent">
                <p className="help-block">Automatically assign asset tags when you create a new asset or duplicate an existing asset.</p>
                {options?.hasAutoAssetTagId &&
                  <div id="next-asset-id" className="d-block">
                    <div className="form-group mb-3">
                      <label className="mb-2 tag_start_no">Next Asset Tag Starting Number</label>
                      <input type="text" maxLength={10} data-val-number="The field Starting must be a number." value={options?.Starting} onChange={(e) => setOptions({ ...options, Starting: e.target.value })} data-val-range="Starting must be within 0 to 999999." data-val-range-max="999999" data-val-range-min="0" id="Starting" name="Starting" className="form-control valid next_asset_tag" data-val="true" data-val-length="Maximum length is 10." data-val-required="Starting number is required." data-val-length-max="10" /> <span className="text-danger mt-1" data-valmsg-for="Starting" data-valmsg-replace="true">{error?.startingNumber}</span> </div>
                    <div className="form-group mb-3">
                      <label className="mb-2" aria-label="Padding">Padding</label>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <div className="radio-list">
                            <label className="radio has_input_inline mb-3 d-flex">
                              <input type="radio" checked={options?.hasPadding} onChange={() => setOptions({ ...options, hasPadding: true })} id="padding" name="radHasPadding" className="radHasPadding" /> <span></span>Zeros &nbsp;&nbsp; No. of Digits:
                              <input type="text" id="Digits" name="Digits" maxLength={1} value={options?.Digits} onChange={(e) => setOptions({ ...options, Digits: e.target.value })} className="form-control-sm ms-2 custom_digits form-control" data-val="true" data-val-length="Maximum length is 1." data-val-length-max="1" data-val-number="Digits must be a number." data-val-range="Starting must be within 1 to 9." data-val-range-max="9" data-val-range-min="1" /> </label>
                            <label className="radio">
                              <input type="radio" checked={!options?.hasPadding} onChange={() => setOptions({ ...options, hasPadding: false })} id="paddingnone" name="radHasPadding" className="radHasPadding" /> <span></span>None</label>
                          </div> <span className="field-validation-valid" data-valmsg-for="Digits" data-valmsg-replace="true"></span> </div>
                        <div className="col-md-6">
                          <div id="divNextAssetTagId" className="assettag_id">{options?.Prefix}{options?.hasPadding ? startingWithLeadingZeros : options?.Starting}{options?.Suffix}</div>
                          <input data-val="true" className="custom_option_feild" data-val-remote="'NextAssetTagId' is invalid." data-val-remote-additionalfields="*.NextAssetTagId" data-val-remote-url="/assettiger/options/CheckDuplicateAssetTagId" id="NextAssetTagId" name="NextAssetTagId" type="text" value="1" /> </div>
                      </div> <span className="field-validation-valid" data-valmsg-for="NextAssetTagId" data-valmsg-replace="true"></span>
                      <div className="d-flex">
                        <div className="form-group mb-3 prefix_note" >
                          <label htmlFor="Prefix" className="text-end d-block">Prefix</label>
                          <input type="text" maxLength={10} value={options?.Prefix} onChange={(e) => setOptions({ ...options, Prefix: e.target.value })} data-val="true" data-val-length="Maximum length is 10." data-val-length-max="10" id="Prefix" name="Prefix" className="form-control text-end" /> </div>
                        <div className="form-group mb-3 ms-3 me-3">
                          <label>&nbsp;</label> <span className="consecutive_number" data-val="true" data-val-number="The field Starting must be a number." data-val-range="Starting must be within 0 to 999999." data-val-range-max="999999" data-val-range-min="0" data-val-required="Starting Number is required."><span className="m-auto">Consecutive<br />Number</span></span>
                        </div>
                        <div className="form-group mb-3 prefix_note" >
                          <label htmlFor="Suffix" className="text-start d-block">Suffix</label>
                          <input type="text" maxLength={10} value={options?.Suffix} onChange={(e) => setOptions({ ...options, Suffix: e.target.value })} data-val="true" data-val-length="Maximum length is 10." data-val-length-max="10" id="Suffix" name="Suffix" className="form-control" /> </div>
                      </div>
                      <div className="help-block mt-0">Note: Prefix and Suffix text does not change. Only the Consecutive number will change for each tag.</div>
                      <p className="help-block m-0">The Asset Tag values created will be incremented by a value of one, beginning with the largest Asset Tag value that existed in the database when the option was first enabled. Beginning with an empty database, the first automatically assigned Asset Tag will be a value of "1". Note: You must have the 'Automatic Asset Tags' feature enabled to use this feature.</p>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>

        <div className='main_asset_tagging remainder_email'>
          {/* <div className='asset_tagging mb-5 mt-4'>ReminderEmails</div> */}
          <h3 className='fw-bolder mb-5 card-title'>ReminderEmails</h3>
          <div className='row'>
            <div className='col-sm-11 offset-sm-1'>
              <div className='checkbox-list mb-4'>
                <label className='EmailInactive checkbox'>
                  <input
                    data-val='true'
                    className='form-check-input custom_check'
                    data-val-required='Check-in Reminder Email is required.'
                    id='IsCheckInReminder'
                    name='IsCheckInReminder'
                    type='checkbox'
                    checked={options?.hascheckInReminderEmail}
                    onChange={() => setOptions({ ...options, hascheckInReminderEmail: !options?.hascheckInReminderEmail })}
                  />
                  <input name='IsCheckInReminder' type='hidden' value='false' /> Check-in Reminder
                  Email <span></span>
                </label>
              </div>
              {options?.hascheckInReminderEmail && <div className='CheckInReminderEmail text_indent mb-5'>
                {options?.checkInReminderEmail?.map((item, idx) => (<div
                  className='EmailInput_group form-inline align-items-center mb-2 mb-sm-3'
                  id='CheckInReminder_0'
                >
                  <label className='px-2 px-sm-3 w-100 w-sm-auto'>
                    Email #<span className='EmailCount'>{idx + 1}</span>
                  </label>
                  <div className='px-2 px-sm-3 mb-2 mb-sm-0'>
                    <input
                      className='form-control form-control-sm fs-base w-60px w-sm-100px'
                      data-val='true'
                      data-val-number='The field Lead must be a number.'
                      data-val-range='Lead must be within 1 to 99.'
                      data-val-range-max='99'
                      data-val-range-min='1'
                      data-val-required='Lead is required.'
                      id='CheckInEventReminderModelList_0__Lead'
                      min='1'
                      name='CheckInEventReminderModelList[0].Lead'
                      type='number'
                      value={item?.lead}
                      onChange={(e) => {
                        const updatedReminderEmail = [...options?.checkInReminderEmail];
                        updatedReminderEmail[idx] = {
                          ...updatedReminderEmail[idx],
                          lead: e.target.value
                        };
                        setOptions({
                          ...options,
                          checkInReminderEmail: updatedReminderEmail
                        });
                      }}

                    />
                  </div>
                  <div className='px-2 px-sm-3 mb-2 mb-sm-0'>
                    <select
                      className='form-select form-select-sm fs-base'
                      data-val='true'
                      data-val-number='The field Frequency must be a number.'
                      data-val-required='Frequency is required.'
                      id='CheckInEventReminderModelList_0__FrequencyId'
                      name='CheckInEventReminderModelList[0].FrequencyId'
                      value={item?.frequency}
                      onChange={(e) => {
                        const updatedReminderEmail = [...options?.checkInReminderEmail];
                        updatedReminderEmail[idx] = {
                          ...updatedReminderEmail[idx],
                          frequency: e.target.value
                        };
                        setOptions({
                          ...options,
                          checkInReminderEmail: updatedReminderEmail
                        });
                      }}
                    >
                      <option value='Days'>Days</option>
                    </select>
                    <span
                      className='field-validation-valid'
                      data-valmsg-for='CheckInEventReminderModelList[0].FrequencyId'
                      data-valmsg-replace='true'
                    ></span>
                  </div>
                  <div className='px-2 px-sm-3 mb-2 mb-sm-0'>
                    <select
                      className='form-select form-select-sm fs-base'
                      data-val='true'
                      data-val-required='Before/After is required.'
                      id='CheckInEventReminderModelList_0__IsBeforeEvent'
                      name='CheckInEventReminderModelList[0].IsBeforeEvent'
                      value={item?.eventTime}
                      onChange={(e) => {
                        const updatedReminderEmail = [...options?.checkInReminderEmail];
                        updatedReminderEmail[idx] = {
                          ...updatedReminderEmail[idx],
                          eventTime: e.target.value
                        };
                        setOptions({
                          ...options,
                          checkInReminderEmail: updatedReminderEmail
                        });
                      }}
                    >
                      <option value='Before Event'>Before Event</option>
                      <option value='After Event'>After Event</option>
                    </select>
                    <span
                      className='field-validation-valid'
                      data-valmsg-for='CheckInEventReminderModelList[0].IsBeforeEvent'
                      data-valmsg-replace='true'
                    ></span>
                  </div>
                  {idx !== 0 && <div className='px-2 px-sm-3 mb-2 mb-sm-0'
                    onClick={() => {
                      const updatedReminderEmail = [...options?.checkInReminderEmail];
                      updatedReminderEmail.splice(idx, 1);
                      setOptions({
                        ...options,
                        checkInReminderEmail: updatedReminderEmail
                      })
                    }}
                  >
                    <a
                      className='btn btn-outline btn-outline-danger btn-active-danger btn-sm removeEmailFields Check-in px-3 py-2'
                      href='javascript:;'
                    >
                      <i className='la la-trash pe-0 custom_awe_img fs-4' aria-hidden='true'></i>
                    </a>
                  </div>}
                </div>))}
                <div className='form-inline align-items-center ps-md-20 mt-n2 mb-2 mb-sm-3'>
                  <span
                    className='field-validation-valid'
                    data-valmsg-for='CheckInEventReminderModelList[0].Lead'
                    data-valmsg-replace='true'
                  ></span>
                </div>
                <div className='form-inline align-items-center ps-md-20 mt-n2 mb-2 mb-sm-3'>
                  <span
                    className='field-validation-valid'
                    data-valmsg-for='CheckInEventReminderModelList[1].Lead'
                    data-valmsg-replace='true'
                  ></span>
                </div>
                <div className='clearfix'>
                  {' '}
                  <span
                    // href='#'
                    className='btn btn-outline btn-outline-primary btn-active-primary btn-sm AddAnotherEmail Check-in'

                    onClick={() => setOptions({
                      ...options,
                      checkInReminderEmail: [
                        ...(options?.checkInReminderEmail || []),
                        { lead: "1", frequency: "Days", eventTime: "Before Event" }
                      ]
                    })}

                  >
                    <i className='fa fa-plus' aria-hidden='true'></i> Add another email
                  </span>
                </div>
              </div>}
            </div>
            <div className='col-sm-11 offset-sm-1'>
              <div className='checkbox-list mb-4'>
                <label className='EmailInactive checkbox'>
                  <input
                    data-val='true'
                    className='form-check-input custom_check'
                    data-val-required='Lease Return Reminder Email is required.'
                    id='IsLeaseReturnReminder'
                    name='IsLeaseReturnReminder'
                    type='checkbox'
                    checked={options?.hasLleaseReturnReminderEmail}
                    onChange={() => setOptions({ ...options, hasLleaseReturnReminderEmail: !options?.hasLleaseReturnReminderEmail })}
                  />
                  <input name='IsLeaseReturnReminder' type='hidden' value='false' /> Lease Return
                  Reminder Email <span></span>
                </label>
              </div>
              {options?.hasLleaseReturnReminderEmail && <div className='LeaseReturnReminderEmail text_indent mb-5'>
                {options?.leaseReturnReminderEmail?.map((item, idx) => (<div
                  className='EmailInput_group form-inline align-items-center mb-2 mb-sm-3'
                  id='LeaseReturnReminder_0'
                >
                  <label className='px-2 px-sm-3 w-100 w-sm-auto'>
                    Email #<span className='EmailCount'>{idx + 1}</span>
                  </label>
                  <div className='px-2 px-sm-3 mb-2 mb-sm-0'>
                    <input
                      className='form-control form-control-sm fs-base w-60px w-sm-100px'
                      data-val='true'
                      data-val-number='The field Lead must be a number.'
                      data-val-range='Lead must be within 1 to 99.'
                      data-val-range-max='99'
                      data-val-range-min='1'
                      data-val-required='Lead is required.'
                      id='LeaseReturnEventReminderModelList_0__Lead'
                      min='1'
                      name='LeaseReturnEventReminderModelList[0].Lead'
                      type='number'
                      value={item?.lead}
                      onChange={(e) => {
                        const updatedReminderEmail = [...options?.leaseReturnReminderEmail];
                        updatedReminderEmail[idx] = {
                          ...updatedReminderEmail[idx],
                          lead: e.target.value
                        };
                        setOptions({
                          ...options,
                          leaseReturnReminderEmail: updatedReminderEmail
                        });
                      }} />
                  </div>
                  <div className='px-2 px-sm-3 mb-2 mb-sm-0'>
                    <select
                      className='form-select form-select-sm fs-base'
                      data-val='true'
                      data-val-number='The field Frequency must be a number.'
                      data-val-required='Frequency is required.'
                      id='LeaseReturnEventReminderModelList_0__FrequencyId'
                      name='LeaseReturnEventReminderModelList[0].FrequencyId'
                      value={item?.frequency}
                      onChange={(e) => {
                        const updatedReminderEmail = [...options?.leaseReturnReminderEmail];
                        updatedReminderEmail[idx] = {
                          ...updatedReminderEmail[idx],
                          frequency: e.target.value
                        };
                        setOptions({
                          ...options,
                          leaseReturnReminderEmail: updatedReminderEmail
                        });
                      }}
                    >
                      <option value='Days'>Days</option>
                    </select>
                    <span
                      className='field-validation-valid'
                      data-valmsg-for='LeaseReturnEventReminderModelList[0].FrequencyId'
                      data-valmsg-replace='true'
                    ></span>
                  </div>
                  <div className='px-2 px-sm-3 mb-2 mb-sm-0'>
                    <select
                      className='form-select form-select-sm fs-base'
                      data-val='true'
                      data-val-required='Before/After is required.'
                      id='LeaseReturnEventReminderModelList_0__IsBeforeEvent'
                      name='LeaseReturnEventReminderModelList[0].IsBeforeEvent'
                      value={item?.eventTime}
                      onChange={(e) => {
                        const updatedReminderEmail = [...options?.leaseReturnReminderEmail];
                        updatedReminderEmail[idx] = {
                          ...updatedReminderEmail[idx],
                          eventTime: e.target.value
                        };
                        setOptions({
                          ...options,
                          leaseReturnReminderEmail: updatedReminderEmail
                        });
                      }}
                    >
                      <option value='Before Event'>Before Event</option>
                      <option value='After Event'>After Event</option>
                    </select>
                    <span
                      className='field-validation-valid'
                      data-valmsg-for='LeaseReturnEventReminderModelList[0].IsBeforeEvent'
                      data-valmsg-replace='true'
                    ></span>
                  </div>
                  {idx !== 0 && <div className='px-2 px-sm-3 mb-2 mb-sm-0'
                    onClick={() => {
                      const updatedReminderEmail = [...options?.leaseReturnReminderEmail];
                      updatedReminderEmail.splice(idx, 1);
                      setOptions({
                        ...options,
                        leaseReturnReminderEmail: updatedReminderEmail
                      })
                    }}
                  >
                    <a
                      className='btn btn-outline btn-outline-danger btn-active-danger btn-sm removeEmailFields Check-in  px-3 py-2'
                      href='javascript:;'
                    >
                      <i className='la la-trash pe-0 custom_awe_img fs-4' aria-hidden='true'></i>
                    </a>
                  </div>}
                </div>))}
                <div className='form-inline align-items-center ps-md-20 mt-n2 mb-2 mb-sm-3'>
                  <span
                    className='field-validation-valid'
                    data-valmsg-for='LeaseReturnEventReminderModelList[0].Lead'
                    data-valmsg-replace='true'
                  ></span>
                </div>

                <div className='clearfix'>
                  {' '}
                  <a
                    href='javascript:void(0);'
                    className='btn btn-outline btn-outline-primary btn-active-primary btn-sm AddAnotherEmail Check-in'
                    onClick={() => setOptions({ ...options, leaseReturnReminderEmail: [...(options?.leaseReturnReminderEmail || []), { lead: "1", frequency: "Days", eventTime: "Before Event" }] })}
                  >
                    <i className='fa fa-plus' aria-hidden='true'></i> Add another email
                  </a>
                </div>
              </div>}
            </div>
          </div>
          <div className='card-footer notForWizard'>
            <div className='row'>
              <div className='offset-sm-1 col-sm-11 text-end mb-4'>
                <button type='submit' className='btn btn-primary' onClick={submitOptions}>
                  {isUpdating ? (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : "Save"}
                </button>
                <a href='/assettiger/options' className='btn btn-secondary cancel_btn'>
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssetTagging
