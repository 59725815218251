import React from 'react'
import CustomersList from './component/CustomersList'

const CustomersTable = () => {
  return (
    <CustomersList/>
  )
}

export default CustomersTable
