import React from 'react';
// import ExcelExportButton from './ExcelExportButton'; 
import {Link} from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
interface HeaderListingProps {
  assetsData: any;
  fileName: any;
  userAssets: any;
  deleteDataArr: number[];
  setDeleteModal: (value: boolean) => void;
  setCheck: (ids: number[]) => void;
  setShowSearch: (value: boolean) => void;
  setShowSetupColumns: (value: boolean) => void;
  handleExport: () => void;
  // handleExport:any;
}
const AssetsHeader = (props: HeaderListingProps) => {
  const navigate = useNavigate();

  return (
    <div className='d-sm-flex justify-content-between mb-3' data-kt-user-table-toolbar='base'>
      <div className='d-flex align-items-center mb-xl-0 mb-4'>
        <h3 className="card-title me-4 mb-0">
          <span className='me-2 align-middle'><i className="la la-list fs-1" aria-hidden="true"></i></span>
          <span className="card-label fw-bold fs-3 mb-1">List of Assets</span>
        </h3>
      </div>
      <div className='d-flex'>
        <div className='d-flex  justify-content-end'>
          <button
            type='button'
            className='btn btn-light-primary me-3 d-flex align-items-center main-btn-style'
            onClick={() => props.setShowSearch(true)}
          >
            <i className='fa fa-search fs-xl-5 fs-7 me-1'></i>
            Search Criteria
          </button>
        </div>
        <div className='d-flex  justify-content-end'> 
        <button  className='btn btn-light-primary me-3 d-flex align-items-center main-btn-style' onClick={()=>{props.handleExport()}}>
        <i className='far fa-file-excel fs-4 me-1'></i>
          
          Export to Excel</button>
        <button  className='btn btn-light-primary me-3 d-flex align-items-center main-btn-style' onClick={()=>{navigate('/asset/add')}}>
        <i className='far fa-plus fs-4 me-1'></i>
          
          Add an Asset</button>
        {/* <button
            type='button'
            className='btn btn-light-primary me-2 d-flex align-items-center  main-btn-style'
            onClick={() => props.setShowSetupColumns(true)}
          >
            <i className='la la-cog fs-xl-3 fs-4 me-1'></i>
            Setup Columns
          </button> */}

          {props.deleteDataArr.length > 0 && (
            <button
              type='button'
              className='btn btn-danger  main-btn-style' 
              onClick={() => {
                props.setDeleteModal(true)
                props.setCheck(props.deleteDataArr)
              }}
            >
              Delete Selected ( {props.deleteDataArr.length} )
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export { AssetsHeader }
