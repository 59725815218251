import FormField from './formField'
import "../import.css"
import { Dispatch, SetStateAction } from "react"
interface ImportFromProps {
    type: Number,
    title: any[],
    option: String[],
    importFunction: (e) => void,
    cancel: (e) => void,
    setFormState: Dispatch<SetStateAction<any>>,
    formState: any,
}

function ImportForm({
    type, title, option,
    importFunction, cancel, setFormState,
    formState
}: ImportFromProps) {
    return (
        <div className='card'>
            <form className="form-horizontal">
                <input name="__RequestVerificationToken" type="hidden" />
                <div className="card-body form-body">
                    <div className="step3">
                        <div className="separator separator-solid mb-4"></div>
                        <div className="form-title">
                            <h5>Step 3. Verify and Import</h5>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-sm vertical_middle" aria-label="Verify and Import">
                                <thead className="">
                                    <tr>
                                        {
                                            title && title.length > 0 && title.map((val, ind) => {
                                                return (
                                                    <th className="warning" key={ind}>{val}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        option && option.length > 0 && option.slice(0, 9).map((innerArray: any ,index:number) => {
                                            return (
                                                <tr>
                                                    {
                                                        Object.keys(innerArray).length > 0 &&
                                                        Object.keys(innerArray).slice(0, title.length)
                                                            .map((item: string, innerIndex: number) => (
                                                                <td key={innerIndex}>{innerArray[title[innerIndex]]} </td>
                                                            ))
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <FormField
                        type={type}
                        setFormState={setFormState}
                        formState={formState}
                    />
                    <div className="row" style={{ display: "none;" }}>
                        <div className="col-sm-10 offset-sm-2">
                            <button type="submit" className="btn btn-primary me-2" id="importdatawrapper" onClick={importFunction}>Import Data</button>
                            <button className="btn btn-secondary" onClick={cancel}>Cancel</button>
                        </div>
                    </div>
                </div>

            </form>
        </div>

    )
}

export default ImportForm