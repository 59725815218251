import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import Location from './locations/Location'
import CompanyInfo from './compnayInfo/CompanyInfo'
import Sites from './sites/Sites'
import Departments from './departments/Departments'
import Assets from './Database/assets/Assets'
import Options from './options/Options'
import CustomEmails from './customEmails/CustomEmails'
import Events from './event/components/Events'
import Categories from './categories/Categories'
import PersonEmployee from './Database/person-employee/Person-employee'
import CustomersTable from './Database/customers table/CustomersTable'
import MaintenanceTable from './Database/Maintenance Table/MaintenanceTable'
import WarrantyTable from './Database/Warranties Table/Warranty'
import ContractTable from './Database/Contract Table/ContractTable'
import { TableOption } from './tableOptions/TableOption'
import AddCustomEvents from './event/components/AddCustomEvents'
import UserAssets from './asset/Assets'
// import AssetDetail from './asset/components/AssetDetail'
import AddAssets from './addAssets/AddAssetsInfo'
import EditAssets from './editAssets/EditAssetsInfo'



const setupBreadCrumbs: Array<PageLink> = [
  {
    title: 'Setup',
    path: '/apps/setup/company',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SetupPage = () => {
  
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='company'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Company Information</PageTitle>
              <CompanyInfo />
            </>
          }
        />
        <Route
          path='location'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Location</PageTitle>
              <Location />
            </>
          }
        />

        <Route
          path='sites'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Sites</PageTitle>
              <Sites />
            </>
          }
        />



        {/* <Route
          path='assets'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Assets</PageTitle>
              <UserAssets />
            </>
          }
        /> */}

        <Route
          path='assets/add'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Add Assets</PageTitle>
              <AddAssets />
            </>
          }
        />
        {/* <Route
          path='assets/:id'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Asset Detail</PageTitle>
              <AssetDetail />
            </>
          }
        /> */}

        <Route
          path='assets/edit'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Edit Assets</PageTitle>
              <EditAssets />
            </>
          }
        />

        <Route
          path='categories'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Categories</PageTitle>
              <Categories />
            </>
          }
        />
        <Route
          path='department'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Departments</PageTitle>
              <Departments />
            </>
          }
        />

        <Route
          path='database/asset'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Assets Table</PageTitle>
              <Assets />

            </>
          }
        />
        <Route
          path='database/person'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Persons/Employees</PageTitle>

              <PersonEmployee />
            </>
          }
        />
        <Route
          path='database/customer'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Customers Table</PageTitle>
              <CustomersTable />
            </>
          }
        />
        <Route
          path='database/maintenance'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Maintenance Table</PageTitle>

              {/* <Location /> */}
              <MaintenanceTable />
            </>
          }
        />
        <Route
          path='database/warranty'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Warranties Table</PageTitle>
              <WarrantyTable />
            </>
          }
        />
        <Route
          path='database/contract'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Contract Table</PageTitle>
              <ContractTable />

            </>
          }
        />
        <Route
          path='database/warranty'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Warranties Table</PageTitle>
              {/* <Location /> */}
            </>
          }
        />
        <Route
          path='database/contract'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Contract Table</PageTitle>
              {/* <Location /> */}
            </>
          }
        />
        <Route
          path='table'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Table Option</PageTitle>
              <TableOption />
            </>
          }
        />
        <Route
          path='Options'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Options</PageTitle>
              <Options />
            </>
          }
        />
        <Route
          path='CustomEmails'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>CustomEmails</PageTitle>
              <CustomEmails />
            </>
          }
        />
        <Route
          path='events'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Events</PageTitle>
              <Events />
            </>
          }
        />
        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Add Custom Event</PageTitle>
              <AddCustomEvents />
            </>
          }
        />
        <Route
          path='events/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Edit Custom Event</PageTitle>
              <AddCustomEvents />
            </>
          }
        />

        <Route index element={<Navigate to='/apps/setup/company' />} />
      </Route>
    </Routes>
  )
}

export default SetupPage
