import React, {useEffect, useState} from 'react'
import {toAbsoluteUrlImage} from '../../../../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { GetProfileDetail, UpdateProfile } from '../SettingRequest'
import { useAuth } from '../../../../auth'
import {toast} from 'react-toastify'
import Avatar from "../../../../../../_metronic/assets/images/avatar.jpg"
import timezones from 'timezones-list';

const imageMimeType = /image\/(png|jpg|jpeg)/i;
const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string().min(3, 'First name must be at least 3 characters').required('First name is required').nullable(),

  lastName: Yup.string().min(3, 'Last name must be at least 3 characters').required('Last name is required').nullable(),
  title: Yup.string().min(5, 'Title must be at least 5 characters').required('Title is required').nullable(),
  phone: Yup.string().matches(/^[0-9]+$/, 'Phone must be a number').min(9, 'Contact phone must be at least 9 digits').required('Contact phone is required').nullable(),
  timeZone: Yup.string().required('Time zone is required').nullable(),
  dateFormat: Yup.string().required('Date Format is required').nullable(),
  timeFormat: Yup.string().required('Time Format is required').nullable(),
});

const ProfileDetails: React.FC = () => {

  const {currentUser,setCurrentUser} = useAuth()

  const [loading, setLoading] = useState(false)
  const [userUpdate, setUserUpdate] = useState(false)
  const [image, setImage] = useState<string>('');
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const [prevImage, setPrevImage] = useState<string>('');
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);

  const changeHandler = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      toast.error('This file format is not allowed!')
      return;
    }
    else{
      setFile(file);
    }}
  useEffect(() => {
    let fileReader, isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }

  }, [file]);

  useEffect(() => {
    
    if(currentUser){
      GetProfileDetail(currentUser.id).then((res) => {
        setCurrentUser(currentUser)
        setData(res?.user)
        setImage(res?.user?.photo)
        formik.setValues({
          image: res?.user?.photo,
          firstName: res?.user?.firstName,
          lastName: res?.user?.lastName ,
          title: res?.user?.title, 
          phone: res?.user?.phone,
          email: res?.user?.email,
          timeZone: res?.user?.timeZone,
          dateFormat: res?.user?.dateFormat,
          timeFormat: res?.user?.timeFormat,
          emailOTP: res?.user?.emailOTP,
          phoneOTP: res?.user?.phoneOTP,
        });
      })
    }
  },[userUpdate])

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await UpdateProfile(values);
        if (response?.data?.status === 200) {
          setUserUpdate(true)
          toast.success(response?.data?.message);
        }
      } catch (error) {
        setUserUpdate(false)
        toast.error((error as any).response?.data?.message);
       } finally {
        setLoading(false);
      }
    }
  });
  
  

 

  //__ Function To Prev Image __ //
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setPrevImage(URL.createObjectURL(files[0]));
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div className='image-input image-input-outline' data-kt-image-input='true'> 
                <label htmlFor='photo'>
                  <img className='image-input-wrapper w-125px h-125px' src={ fileDataURL  ?  fileDataURL  :  image ? toAbsoluteUrlImage(image) : Avatar } alt="profile"/>
                  <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      className='w-6 h-6 custom_camera'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
                      />
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
                      />
                    </svg>
                    </label>
                <input
                  className="form-control d-none" type="file" name="image" id="photo"
                  accept='.png, .jpg, .jpeg'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeHandler(e)
                    formik.setFieldValue('image',e?.target?.files && e?.target?.files[0]?.type?.match(imageMimeType)?e.target.files![0]:image)
                    // formik.setFieldValue("image", e.target.files![0]);
                  }}
                />
                  


                </div> 
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('firstName')}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstName}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      {...formik.getFieldProps('lastName')}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastName}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Title'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>

            
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Email</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email Address'
                  {...formik.getFieldProps('email')}
                  disabled
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Time Zone</label>

              <div className='col-lg-8 fv-row'>
                <select className='form-select form-select-solid form-select-lg' {...formik.getFieldProps('timeZone')}>
                  <option value='' selected hidden disabled>Select a Timezone..</option>
                  {timezones?.map((time) => (
                  <option value={time?.label}>{time?.label}</option>
                      ))}
                </select>
                {formik.touched.timeZone && formik.errors.timeZone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.timeZone}</div>
                  </div>
                )}
              </div>
            </div>


            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Date Format</label>

              <div className='col-lg-8 fv-row'>
                <select className='form-select form-select-solid form-select-lg'{...formik.getFieldProps('dateFormat')}>
                  <option value='' selected hidden disabled>Select a Date Format..</option>
                  <option value="08/31/2014" >MM/dd/yyyy</option>
                  <option value="08-31-2014">MM-dd-yyyy</option>
                  <option value="2014-08-31">yyyy-MM-dd</option>
                  <option value="31 Aug, 2014">Short - d MMM, yyyy</option>
                  <option value="31 August, 2014">Medium - d MMMM, yyyy</option>
                  <option value="Sunday, 31 August, 2014">Full - dddd, dd MMMM, yyyy</option>
                  <option value="31/08/2014">dd/MM/yyyy</option>
                </select>
                {formik.touched.dateFormat && formik.errors.dateFormat && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.dateFormat}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Time Format</label>
              <div className='col-lg-8 fv-row'>
                <select className='form-select form-select-solid form-select-lg' {...formik.getFieldProps('timeFormat')}>
                  <option value=''selected  hidden disabled>Select a Time Format</option>
                  <option value="09:58 PM" >12-hour short - 09:58 PM</option>
                  <option value="09:58:30 PM">12-hour long - 09:58:30 PM</option>
                  <option value="21:58">24-hour short - 21:58</option>
                  <option value="21:58:30">24-hour long - 21:58:30</option>
                </select>
                {formik.touched.timeFormat && formik.errors.timeFormat && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.timeFormat}</div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>MultiFactor Authentication</label>

              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label htmlFor="emailOTP" className='form-check form-check-inline form-check-solid me-5'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='emailOTP'
                    checked={formik.values.emailOTP} 
                    {...formik.getFieldProps('emailOTP')}
                  />
                    <span className='fw-bold ps-2 fs-6'>Email OTP</span>
                  </label>

                  <label htmlFor="phoneOTP" className='form-check form-check-inline form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='phoneOTP'
                    checked={formik.values.phoneOTP} 
                    {...formik.getFieldProps('phoneOTP')}
                  />
                    <span className='fw-bold ps-2 fs-6'>Phone OTP</span>
                  </label>
                </div>
              </div>
            </div> */}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
