import React from 'react'
import PersonsListing from './components/PersonsListing';

function Customers () {
  return (
    <div>
        <PersonsListing/>
    </div>
  )
}

export default Customers