import React, {useState, useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useAuth} from '../../../../auth'
import {useFormik} from 'formik'
// import {addCategory, updateCategory} from '../core/_requests'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useAddCategoryMutation, useUpdateCategoryMutation } from '../../../../services/categories'

const categorySchema = Yup.object().shape({
  category: Yup.string().trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Category is required'),
})

const initialValues = {
  category: '',
}

const CategoryModel = (props: any) => {
  const {currentUser} = useAuth()
  const [addCategory, {isLoading: isAdding}] = useAddCategoryMutation();
  const [updateCategory, {isLoading: isUpdating}] = useUpdateCategoryMutation();

  useEffect(() => {
    if (props?.data?.id) {
      formik.setValues({
        category: props?.data?.category,
      })
    }
  }, [props?.data?.id,props.data])


  const formik = useFormik({
    initialValues,
    validationSchema: categorySchema,
    onSubmit: async (values:any) => {
      try{
      if (currentUser) {
        values.category = values.category.trim();
        const data = props.data.id
          ?await updateCategory({...values, userId: currentUser.id, id: props.data.id}).unwrap()
          :await addCategory({...values, userId: currentUser?.id}).unwrap()
          
          if(data){
            toast.dismiss()
            if(data?.success){
              toast.success(data?.message)
            handleClose()
            }else{
              toast.error(data?.message)
            }
          }
        }
      }
        catch(error:any) {
          if(error?.data?.message && error?.data?.status==409){
            
            toast.error(error?.data?.message)
          }
          else{
            toast.error('Something went wrong')
          }
          }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    props.handleClose()
  }

  return (
    <Modal show={props.show} onHide={handleClose} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{props?.data?.id ? 'Edit Category' : 'Add a Category'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <p className='fs-6 mb-4'>If you want to add a new category of assets, you’re in the right spot. Add a category for computer equipment, wireless keyboards, or any assets you’re working with.</p>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Category</label>
            <input
              placeholder='Category'
              {...formik.getFieldProps('category')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.category && formik.errors.category},
                {
                  'is-valid': formik.touched.category && !formik.errors.category,
                }
              )}
              type='text'
              name='category'
              autoComplete='off'
            />
            {formik.touched.category && formik.errors.category && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.category}</span>
                </div>
              </div>
            )}
          </div>
          <div className=' d-flex align-items-center justify-content-end'>
            <Button variant='secondary' className='me-3' onClick={handleClose}>
              Close
            </Button>
            {/* <button type='submit' className='add_location_btn'>
        {' '}
        Add Location{' '}
      </button> */}
            <button
              type='submit'
              className='btn btn-light-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {/* {(!isAdding || !isUpdating) && (
              )} */}
              {(isAdding || isUpdating) ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ):(
                <span className='indicator-label'>{props.data.id ? 'Update Category' : 'Add Category'}</span>
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CategoryModel
