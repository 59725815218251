import {useFormik} from 'formik'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {initialSites, SiteResponse} from '../core/_models'
import * as Yup from 'yup'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import { getAllSites} from '../core/_requests'
import {useAuth} from '../../../../auth'
import {countriesList} from './countries'
import { useAddSiteMutation, useUpdateSiteMutation } from '../../../../services/sites'

const SitesSechma = Yup.object().shape({
  name: Yup.string().trim()
  .required('Site name is required')
  .matches(/^[a-zA-Z\s]+$/, 'Site name should contain only letters'),
  postalCode: Yup.number().typeError('Postal Code is Number'),
})

const SitesModal = (props: any) => {

  const {currentUser} = useAuth()
  // const [loading, setLoading] = useState<boolean>()
  const [sites, setSites] = useState<SiteResponse[] | undefined>()
  const [addSite, {isLoading: isAdding}] = useAddSiteMutation();
  const [updateSite, {isLoading: isUpdating}] = useUpdateSiteMutation();

  // useEffect(() => {
  //   if (currentUser) {
  //     formik.resetForm()
  //     getAllSites(currentUser?.id).then((response) => {
  //       setSites(response)
  //     })
  //   }
  // }, [])

  useEffect(() => {
    formik.resetForm()
    if (props?.SiteData?.id) {
      formik.setValues({
        name: props?.SiteData?.name,
        description: props?.SiteData?.description,
        address: props?.SiteData?.address,
        aptSuite: props?.SiteData?.aptSuite,
        city: props?.SiteData?.city,
        state: props?.SiteData?.state,
        postalCode: props?.SiteData?.postalCode,
        country: props?.SiteData?.country,
        userId: props?.SiteData?.userId,
      })
    }
  }, [props])

  const formik = useFormik({
    initialValues: {...initialSites},
    validationSchema: SitesSechma,
    onSubmit: async (values) => {
      // setLoading(true)
      if (currentUser) {
        values.name = values.name.trim()
        try{
          const data = props?.SiteData?.id
          ?await updateSite({siteId: props.SiteData.id, values}).unwrap()
          :await addSite({...values, userId: currentUser.id}).unwrap()
            if (data) {
              toast.dismiss()
              if(data?.success){
              toast.success(data?.message)
              formik.resetForm()
              props.handleClose()
              }else{
              toast.error(data?.message)
              }
              // setLoading(false)
            }
        }
          catch(error:any)  {
            console.log('doest not update', error)
            toast.error(error?.data?.message)
            formik.setSubmitting(false)
            // setLoading(false)
          }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    props.handleClose()
  }

  return (
    <>
      <Modal className='custom-model-sm' show={props.show} onHide={props.handleClose} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{props?.SiteData?.id ? 'Update the Site' : 'Add a Site'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='mb-4 fs-6'>Enter the data about your new site in the fields below and we will add it to your list.</p>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='inner-site-modal'>
              <div className='mb-4'>
                <label className='required fw-bold fs-6 mb-2'>Site Name</label>
                <input
                  placeholder='Site location'
                  {...formik.getFieldProps('name')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
              <div className='mb-4'>
                <label className='fw-bold fs-6 mb-2'>Description</label>
                <input
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>
              <div className='mb-4'>
                <label className=' fw-bold fs-6 mb-2'>Address</label>
                <input
                  placeholder='Address'
                  {...formik.getFieldProps('address')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
              </div>
              <div className='mb-4'>
                <label className=' fw-bold fs-6 mb-2'>Apt./Suite</label>
                <input
                  placeholder='Apt./Suite'
                  {...formik.getFieldProps('aptSuite')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
              </div>
              <div className='mb-4'>
                <label className=' fw-bold fs-6 mb-2'>Country</label>

                <div className='fv-row w-100'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('country')}
                  >
                    <option selected value=''>
                      {' '}
                      Select country
                    </option>
                    {countriesList?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.code}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className='mb-4'>
                <label className=' fw-bold fs-6 mb-2'>City</label>
                <input
                  placeholder='City'
                  {...formik.getFieldProps('city')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
              </div>
              <div className='mb-4'>
                <label className=' fw-bold fs-6 mb-2'>State</label>
                <input
                  placeholder='State'
                  {...formik.getFieldProps('state')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />
              </div>
              <div className='mb-4'>
                <label className=' fw-bold fs-6 mb-2'>Postal Code</label>
                <input
                  placeholder='Postal Code'
                  {...formik.getFieldProps('postalCode')}
                  type='text'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  autoComplete='off'
                />

                {formik.touched.postalCode && formik.errors.postalCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.postalCode}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='text-end custom_location'>
              <Button variant='secondary' onClick={handleClose}>
                Close{' '}
              </Button>

              <button
                type='submit'
                className='add_location_btn'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {(!isAdding && !isUpdating) && (
                  <span className='indicator-label'>
                    {props?.SiteData?.id ? 'Update the Site' : 'Add a Site'}
                  </span>
                )}
                {(isAdding || isUpdating) && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SitesModal
