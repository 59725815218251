export interface CheckoutInterface {
    returnDate:string, 
    checkedTo:string,
    email:string,
    userId:number,
    notes:string,
    locationId:number|undefined, 
    siteId?:number|undefined,
    departmentId:number|undefined,
    assetId:number
}
export const defaultCHeckoutDetail: CheckoutInterface = {
    returnDate:new Date().toISOString().split('T')[0], 
    checkedTo:"site",
    userId:0,
    notes:"",
    email:"",
    locationId:undefined, 
    siteId:undefined,
    departmentId:undefined,
    assetId:0
}
