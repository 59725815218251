import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
// import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import UserAssets from './asset/Assets'
import { PageLink, PageTitle } from '_metronic/layout/core'



const setupBreadCrumbs: Array<PageLink> = [
  {
    title: 'Asset',
    path: '/apps/asset/assets',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AssetPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>

        <Route
          path='assets'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Assets</PageTitle>
              <UserAssets />
            </>
          }
        />

       
      </Route>
    </Routes>
  )
}

export default AssetPage
