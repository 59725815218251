import {KTSVG} from '../../../../../../_metronic/helpers'

const CustomersHeader = (props: any) => {
  return (
    
    <div className='card-body d-flex border-bottom border-bottom-gray-200 justify-content-between pb-2 mb-3 align-items-center' data-kt-user-table-toolbar='base'>
      <h3 className="card-title align-items-start flex-column mb-0">
        <span className='me-2 align-middle'><i className="la la-list fs-1" aria-hidden="true"></i></span>
        <span className="card-label fw-bold fs-3 mb-1">List of Persons/Employees</span>
      </h3>
      
      <div className='d-flex justify-content-between'>
        <button
          type='button'
          className='btn btn-light-primary me-3'
          onClick={() => props.handleShow()}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Add New Persons / Employees
        </button>
        {/* <button
          type='button'
          className='btn btn-light-primary me-3'
          // onClick={() => props.handleShow()}
        >
          <i className='las la-cog fs-2'></i>
          Setup Columns
        </button> */}

      <div className='d-flex justify-content-end align-items-center'>
        {props.deleteDataArr.length > 0 && (
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => {
              props.setDeleteModal(true)
              props.setCheck(props.deleteDataArr)
            }}
          >
            Delete Selected ( {props.deleteDataArr.length} )
          </button>
        )}
      </div>
      </div>
      
    </div>
  )
}

export {CustomersHeader}
