import React from 'react'
import CustomersListing from './components/CustomersListing';

function Customers () {
  return (
    <div>
        <CustomersListing/>
    </div>
  )
}

export default Customers