import React, { useState, useEffect } from 'react'
import { PageTitle } from "../../../_metronic/layout/core"
import { useIntl } from 'react-intl'
import { KTSVG } from "../../../_metronic/helpers"
import ReserveModal from "./Modal/ReserveModal"
import DuplicateReserveModal from "./Modal/DuplicateReserveModal"
import { useAuth } from '../../modules/auth'
import axios from 'axios'
import { Field, useFormik } from 'formik'
import * as Yup from 'yup'
import { useGetAllSitesQuery } from '../../modules/services/sites'
import { useGetAllLocationsBySiteQuery } from '../../modules/services/locations'
import { useGetAllCustomersQuery as useGetAllPersonsQuery } from 'app/modules/services/persons'
import { useGetAllCustomersQuery } from 'app/modules/services/customers'
import { getEmployee } from '../../modules/apps/company-setup/Database/core/_requests'
import { ReserveInterface, defaultReserveDetail as initialValues } from './core/SetupModel'
import { toast } from 'react-toastify'
import { useAddReserveMutation } from 'app/modules/services/reserve'
import { useGetAllAssetsQuery } from 'app/modules/services/assets'
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker';
import CustomersModal from 'app/modules/apps/company-setup/customers/components/CustomersModal'
import PersonsModal from 'app/modules/apps/company-setup/persons/components/PersonsModal'
import moment from 'moment';
import Select from 'react-select';


const accountDetailsSchema = Yup.object().shape({
  startDate: Yup.mixed().test('is-date-or-null', 'Start date is required', function (value) {
    return value === null || Yup.date().isValidSync(value);
  }).required('Start date is required'),

  endDate: Yup.mixed().test('is-date-or-null', 'End date is required', function (value) {
    return value === null || Yup.date().isValidSync(value);
  }).required('End date is required'),



  // dueDate: Yup.date().required('Due Date is required'),
  // locationId:Yup.number().moreThan(0,"Please select Location").required("Please select Location"),
  // siteId: Yup.number().moreThan(0, "Please select site").required("Please select site"),
  // departmentId: Yup.number().moreThan(0,"Please select department").required("Please select department"),
  // assignTo:values?.assignTo,
  personId: Yup.string().when('reserveFor', {
    is: 'person',
    then: Yup.string().required('Person is required'),
  }),
  siteId: Yup.string().when('reserveFor', {
    is: 'site',
    then: Yup.string().required('Site is required'),
  }),
  customerId: Yup.string().when('reserveFor', {
    is: 'customer',
    then: Yup.string().required('Customer is required'),
  }),
})

const Reserve = () => {
  const intl = useIntl()
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [skip, setSkip] = useState<boolean>(false)
  const [siteId, setSiteId] = useState<boolean>(false)
  const [showPersonModal, setShowPersonModal] = useState<boolean>(false)
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
  const [assets, setAssets] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any[]>([null, null]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  // useEffect(() => {
  //   const currentDate = new Date();
  //   setStartDate(currentDate);
  //   setEndDate(currentDate);
  // }, []);
  const [showReserveModal, setShowReserveModal] = useState(false)
  const [duplicateReserveModal, setDuplicateReserveModal] = useState(false)
  const [pendingAssets, setPendingAssets] = useState<any>([]);
  const [selectedAssets, setSelectedAssets] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false)
  const [reserveFor, setReserveFor] = useState('person')
  const [emailEnabled, setEmailEnabled] = useState(false)
  const [firstEmail, setFirstEmail] = useState(false)
  const [secondEmail, setSecondEmail] = useState(false)
  const [selected, setSelected] = useState<any>([])
  const [reservedData, setReservedData] = useState<any>([])
  const [employeeData, setEmployeeData] = useState<any>([])
  const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [selectedCustomerValue, setSelectedCustomerValue] = React.useState(null);
  const [personOptinos, setPersonOptinos] = React.useState([]);
  const [customerOptinos, setCustomerOptinos] = React.useState([]);
  const [customErrors, setCustomErrors] = useState({ emailField: '' });
  const { data: siteData } = useGetAllSitesQuery(currentUser?.id);
  const { data: personData } = useGetAllPersonsQuery({ id: currentUser?.id, values: "" });
  const { data: customerData } = useGetAllCustomersQuery({ id: currentUser?.id, values: "" });
  const { data: locationData, error: locationError } = useGetAllLocationsBySiteQuery({ userId: currentUser?.id, id: siteId }, { skip })
  const [addReserve, { isLoading: isAdding }] = useAddReserveMutation();
  const { data: assetsData, isLoading, error, isSuccess } = useGetAllAssetsQuery({ userId: currentUser?.id, body: new URLSearchParams({}).toString(), page: 1, limit: 10 })
  const [isNewPersonAdded, setIsNewPersonAdded] = useState(false);
  const [isNewCustomerAdded, setIsNewCustomerAdded] = useState(false);




  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: accountDetailsSchema,
    onSubmit: async (values) => {
      //  
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

      try {
        //  
        if (emailEnabled && (!values.sendEmail || !emailRegex.test(values?.sendEmail))) {

          if (!values.sendEmail) {
            setCustomErrors({ emailField: 'Email is required' });
            return undefined;
          } else if (!emailRegex.test(values?.sendEmail)) {
            setCustomErrors({ emailField: 'Invalid email address' });
            return undefined;
          }

        } else {
          setCustomErrors({ emailField: '' })
          handleReserveSubmit(values)
        }
      } catch (error) {
        console.error(error)
        toast.error('Something went wrong')
      }
    },
  })

  // useEffect(() => {
  //   getEmployee(currentUser?.token).then((res:any) => {
  //     setEmployeeData(res?.data?.persons);
  //   });
  // }, []);
  useEffect(() => {
    if (personData) {
      let options = personData?.persons?.map((item) => ({
        value: item.id,
        label: item.fullName,
      }));
      setPersonOptinos(options);
    }
    if (customerData) {
      let options = customerData?.customers?.map((item) => ({
        value: item.id,
        label: item.fullName,
      }));
      setCustomerOptinos(options);
    }
  }, [personData, customerData])
  useEffect(() => {
    if (assetsData) {
      getAssetListing();
    }
  }, [assetsData])
  useEffect(() => {
    if (assets && pendingAssets && showReserveModal == false) {
      handlePendingList()
    }
  }, [assets, showReserveModal])

  useEffect(() => {
    if (isNewPersonAdded) {
      let data = { value: personData?.persons ? personData?.persons[personData?.persons?.length - 1]?.id : '', label: personData?.persons ? personData?.persons[personData?.persons?.length - 1]?.fullName : '' }
      handleSelectChange(data)
    }
    if (isNewCustomerAdded) {
      let data = { value: customerData?.customers ? customerData?.customers[customerData?.customers?.length - 1]?.id : '', label: customerData?.customers ? customerData?.customers[customerData?.customers?.length - 1]?.fullName : '' }
      handleSelectChangeCustomer(data)
    }
  }, [personData, customerData])

  const getAssetListing = async () => {
    setAssets(assetsData?.userAssets)
  }
  const handlePendingList = () => {
    const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id));
    console.log(data)
    setSelectedAssets(data);
    setdeleteDataArr([])

  }
  const handleReserveSubmit = async (values: ReserveInterface) => {

    let data = {
      startDate: values?.startDate,
      endDate: values?.endDate,
      reserveFor: values?.reserveFor,
      personId: values?.personId,
      siteId: values?.siteId,
      locationId: values?.locationId,
      customerId: values?.customerId,
      sendEmail: values?.sendEmail,
      reminderEmail1: values?.reminderEmail1,
      reminderEmail2: values?.reminderEmail2,
      assetId: pendingAssets,
      userId: currentUser?.id
    }
    const result = await addReserve(data).unwrap()
    if (result) {
      if (result.status === 409) {
        // setDeleteCascade(true)
        setReservedData(result?.reservedAssetIds)
        handleDuplicateReserveModal()
        // toast.info(result?.message ? result?.message : result?.data?.message)
      }
      else {
        toast.dismiss()
        toast.success(result?.message ? result?.message : result?.data?.message)
        // navigate('/assets')
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }

  }

  const handleShowReserveModal = () => {
    setShowReserveModal(true)
  }
  const handleDuplicateReserveModal = () => {
    setDuplicateReserveModal(true)
  }
  const handleCloseDuplicateReserveModal = () => {
    setDuplicateReserveModal(false)
  }
  const handleCloseReserveModal = () => {
    setShowReserveModal(false)
  }
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setdeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setdeleteDataArr([])
    }
  }
  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected: any) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId: any) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
    setdeleteDataArr((prevSelected: any) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId: any) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }
  const deleteSelectedAssests = async () => {
    const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id));
    console.log(data)
    setSelectedAssets(data);
    setdeleteDataArr([])
    setSelectAll(false)
    setSelected([])

  }
  useEffect(() => {
    setSkip(false) //set skip false becasue skip param in query hook does not load on mount
  }, [])

  const handleSiteClick = (e: any) => {
    let id = e.target.value
    setSiteId(id)
    setSkip(false)
  }
  const handleClosePersonModal = () => {
    setShowPersonModal(false)
  }

  const handleShowPersonModal = () => {
    setShowPersonModal(true)
    setIsNewPersonAdded(true);
  }
  const handleCloseCustomerModal = () => {
    setShowCustomerModal(false)
  }

  const handleShowCustomerModal = () => {
    setShowCustomerModal(true)
    setIsNewCustomerAdded(true);
  }
  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    formik.setFieldValue('personId', selectedOption?.value)
  };
  const handleSelectChangeCustomer = (selectedOption) => {
    setSelectedCustomerValue(selectedOption);
    formik.setFieldValue('customerId', selectedOption?.value)
  };
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Reserve' })}</PageTitle>

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0 '>
            <h3 className='fw-bolder m-0'><span className="me-2"><i className="la la-calendar fs-1"></i></span>Reserve</h3>
          </div>

        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-9'>
              <div className='pb-5'>
                {' '}
                <p className='fw-bolder m-0 mb-3'>Keep track of your assets within your organization and create an even more detailed history of them.</p>
                <div className='card-footer d-flex justify-content-start border-0 p-0'>
                  <button
                    type="button"
                    className='btn btn-light-primary me-3'
                    onClick={() => handleShowReserveModal()}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />

                    Select Assets
                  </button>
                  <ReserveModal show={showReserveModal} setPendingAssets={setPendingAssets} selectedAssets={selectedAssets} handleClose={handleCloseReserveModal} />
                  <DuplicateReserveModal show={duplicateReserveModal} reservedData={reservedData} handleClose={handleCloseDuplicateReserveModal} />
                </div>
                {selectedAssets && selectedAssets?.length > 0 ?
                  <>
                    <div className="separator border-2 my-10"></div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className="mt-3">Assets Pending Reserve</h4>
                      {deleteDataArr.length > 0 && (
                        <button
                          type='button'
                          className='btn btn-danger  mb-2'
                          onClick={() => {
                            deleteSelectedAssests()
                          }}
                        >
                          Delete Selected
                        </button>
                      )}
                    </div>
                    <div className="checkout-listing-table mb-3">
                      <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        role='table'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th colSpan={1} role='columnheader' className='w-10px pe-2'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </div>
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Asset Tag ID{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Description{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Status{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Assigned to{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Site{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Location{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Lease to{' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                          {selectedAssets?.length > 0 ? (
                            selectedAssets?.map((item: any) => {
                              return (
                                <tr role='row'>
                                  <td role='cell' className=''>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                      <input
                                        type='checkbox'
                                        className='form-check-input'
                                        checked={selected.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                      />
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.assetTagId}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.description}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      {/* <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.status}</a>
                                    </div> */}
                                      <div className='d-flex flex-column'>{item.statusType === 'check_out' ? 'Checked Out' : item.statusType === "lease" ? "Leased" : item.statusType === "dispose" ? "Disposed" : item.statusType === "available" ? "Available" : item.statusType}</div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.assetstatus && item?.assetstatus?.statusType !== 'lease' ? item?.location ? item?.site?.name + "/" + item?.location?.location : item?.site?.name : item?.assetstatus?.statusType == 'lease' ? "" : ''}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.site?.name}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      {/* <div className=' overflow-hidden me-3'>
                                        <a href=''></a>
                                    </div> */}
                                      <div className='d-flex flex-column'>{item?.location?.location}</div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>{item?.statusType == 'lease' && item?.assetslease ?
                                        item?.assetslease?.customer?.fullName : ''}</div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                  No records found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>Schedule</label>
                            <div className='col-lg-9 fv-row'>
                              <div className="form-group mb-3 custom-date-picker">
                                {/* <label className="col-form-label pt-0 pb-1" >Custom Date Range</label> */}
                                <ReactDatePicker
                                  selectsRange={true}
                                  startDate={startDate}
                                  endDate={endDate}

                                  onChange={(update) => {

                                    setStartDate(update[0]);
                                    setEndDate(update[1]);
                                    // Your other code here
                                    formik.setFieldValue('startDate', moment(update[0], "DD/MM/YYYY").toDate())
                                    formik.setFieldValue('endDate', moment(update[1], "DD/MM/YYYY").toDate())
                                  }}
                                  isClearable={true}
                                  className='form-control'
                                  placeholderText={'MM/dd/yyyy - MM/dd/yyyy'}
                                />
                              </div>
                              {formik.touched.startDate && formik.errors.startDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.startDate}</div>
                                </div>
                              )}
                              {formik.touched.endDate && formik.errors.endDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.endDate}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-3 col-form-label fw-bold fs-6">Reserve for</label>
                            <div className='col-lg-9 fv-row'>
                              <div className="d-flex">
                                <div className="m-2 form-check form-check-custom form-check-solid form-check-sm">
                                  <input className="" {...formik.getFieldProps('reserveFor')} onClick={() => { setReserveFor('person'); formik.setFieldValue('locationId', undefined); formik.setFieldValue('siteId', undefined); formik.setFieldValue('customerId', undefined) }} type="radio" value={'person'} checked={reserveFor == "person" ? true : false} name={'reserveFor'} id="person" />
                                  <label className="form-check-label fs-6 fw-bold" htmlFor="person" >
                                    Person
                                  </label>
                                </div>
                                <div className="m-2 form-check form-check-custom form-check-solid form-check-sm">
                                  <input className="" type="radio" {...formik.getFieldProps('reserveFor')} onClick={() => { setReserveFor('site'); formik.setFieldValue('customerId', undefined); formik.setFieldValue('personId', undefined) }} value={'site'} checked={reserveFor == "site" ? true : false} name={'reserveFor'} id="location" />
                                  <label className="form-check-label fs-6 fw-bold" htmlFor="location">
                                    Site / Location
                                  </label>
                                </div>
                                <div className="m-2 form-check form-check-custom form-check-solid form-check-sm">
                                  <input className="" type="radio" {...formik.getFieldProps('reserveFor')} onClick={() => { setReserveFor('customer'); formik.setFieldValue('locationId', undefined); formik.setFieldValue('siteId', undefined); formik.setFieldValue('personId', undefined) }} value={'customer'} checked={reserveFor == "customer" ? true : false} name={'reserveFor'} id="customer" />
                                  <label className="form-check-label fs-6 fw-bold" htmlFor="customer">
                                    Customer
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {reserveFor == "site" &&
                            <div>
                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6 required'>
                                  <span className=''>Site</span>
                                </label>
                                <div className='col-lg-9 fv-row'>
                                  <select
                                    className='form-select form-select-solid form-select-lg'
                                    {...formik.getFieldProps('siteId')}
                                    onChange={(e) => {
                                      formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                      handleSiteClick(e);
                                    }}
                                  >
                                    <option value=''>Select Site</option>
                                    {siteData?.sites.map((item: any, idx: number) => {
                                      return (
                                        <option key={idx} value={item.id}>
                                          {item.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                  {formik.touched.siteId && formik.errors.siteId && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>{formik.errors.siteId}</div>
                                    </div>
                                  )}
                                </div>

                              </div>
                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                  <span className=''>Location</span>
                                </label>
                                <div className='col-lg-9 fv-row d-flex'>
                                  <select
                                    className='form-select form-select-solid form-select-lg'
                                    {...formik.getFieldProps('locationId')}

                                  >
                                    <option value=''>Select Location</option>
                                    {locationData?.locations?.map((item: any, idx: number) => {
                                      return (
                                        <option key={idx} value={item.id}>
                                          {item.location}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>

                              </div>
                            </div>
                          }
                          {reserveFor == "person" &&
                            <div className='row mb-6'>
                              <label className='col-lg-3 col-form-label fw-bold fs-6 required'>
                                <span className=''>Person</span>
                              </label>
                              <div className='col-lg-9 fv-row'>
                                {/* <select
                                  className='form-select form-select-solid form-select-lg'
                                  {...formik.getFieldProps('personId')}
                                >
                                  <option value=''>Select person</option>
                                  {personData?.persons?.map((item: any, idx: number) => {
                                    return (
                                      <option key={idx} value={item.id}>
                                        {item.fullName}
                                      </option>
                                    )
                                  })}
                                </select> */}
                                <div className='d-flex'>
                                  <Select
                                    value={selectedValue}
                                    onChange={handleSelectChange}
                                    options={personOptinos}
                                    isSearchable={true} // Enable the search functionality
                                    placeholder="Select a Person"
                                    classNamePrefix="custom-select"
                                    // menuIsOpen={true}
                                    className="custom-react-select w-100"
                                  />
                                  <button
                                    type='button'
                                    className='btn btn-light-primary d-flex ms-3'
                                    onClick={() => handleShowPersonModal()}
                                  >
                                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                    New
                                  </button>
                                  <PersonsModal show={showPersonModal} handleClose={handleClosePersonModal} personData={[]} />
                                </div>
                                {formik.touched.personId && formik.errors.personId && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.personId}</div>
                                  </div>
                                )}
                              </div>

                            </div>
                          }
                          {reserveFor == "customer" &&
                            <div className='row mb-6'>
                              <label className='col-lg-3 col-form-label fw-bold fs-6 required'>
                                <span className=''>Customer</span>
                              </label>
                              <div className='col-lg-9 fv-row'>
                                {/* <select
                                  className='form-select form-select-solid form-select-lg'
                                  {...formik.getFieldProps('customerId')}
                                >
                                  <option value=''>Select customer</option>
                                  {customerData?.customers?.map((item: any, idx: number) => {
                                    return (
                                      <option key={idx} value={item.id}>
                                        {item.fullName}
                                      </option>
                                    )
                                  })}
                                </select>
                                <button
                                  type='button'
                                  className='btn btn-light-primary d-flex ms-3'
                                  onClick={() => handleShowCustomerModal()}
                                >
                                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                  New
                                </button> */}
                                <div className='d-flex'>
                                  <Select
                                    value={selectedCustomerValue}
                                    onChange={handleSelectChangeCustomer}
                                    options={customerOptinos}
                                    isSearchable={true} // Enable the search functionality
                                    placeholder="Select a Person"
                                    classNamePrefix="custom-select"
                                    // menuIsOpen={true}
                                    className="custom-react-select w-100"
                                  />
                                  <button
                                    type='button'
                                    className='btn btn-light-primary d-flex ms-3'
                                    onClick={() => handleShowCustomerModal()}
                                  >
                                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                    New
                                  </button>
                                  <CustomersModal show={showCustomerModal} handleClose={handleCloseCustomerModal} CustomerData={[]} />
                                </div>
                                {formik.touched.customerId && formik.errors.customerId && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.customerId}</div>
                                  </div>
                                )}
                              </div>

                            </div>
                          }

                        </div>
                        <div className="col-lg-6">
                          <div className={"mb-10 d-flex"}>

                            {/* <div className='pt-2'> */}
                            <div className="form-check form-check-custom form-check-solid form-check-sm d-flex align-self-start pt-2 me-3">
                              <input
                                className="form-check-input me-2"
                                type="checkbox" value=""
                                id="flexRadioLg"
                                checked={emailEnabled}
                                onChange={() => { setEmailEnabled(!emailEnabled); if (!emailEnabled) { formik.setFieldValue('sendEmail', undefined); } }}
                              />
                              <label className="form-label mb-0 fw-bold fs-6">Send Confirmation Email</label>
                            </div>
                            {/* </div> */}
                            <div>
                              <input type="email" disabled={emailEnabled == true ? false : true} {...formik.getFieldProps('sendEmail')} className="form-control form-control-solid flex-1" placeholder="Email" />
                              {emailEnabled && customErrors?.emailField !== "" && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{customErrors?.emailField}</div>
                                </div>

                              )}
                              </div>
                          </div>
                          <div className={emailEnabled ? "mb-10" : 'd-none'}>
                            <div className='d-flex align-items-center custom-emails justify-content-end'>
                              <div className="form-check form-check-custom form-check-solid form-check-sm d-flex align-center">
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox" value=""
                                  id="flexRadioLg"
                                  checked={firstEmail}
                                  onChange={() => { setFirstEmail(!firstEmail); if (!firstEmail) { formik.setFieldValue('reminderEmail1', undefined); } }}
                                />
                                <label className="form-label mb-0 fw-bold fs-6">Reminder Email #1</label>
                              </div>
                              <input type="number" disabled={firstEmail == true ? false : true} {...formik.getFieldProps('reminderEmail1')} className="form-control form-control-solid mx-3" placeholder="1" />
                              <label className="form-label mb-0 fw-bold fs-6">Days before Event</label>
                            </div>
                          </div>
                          <div className={emailEnabled ? "mb-10" : 'd-none'}>
                            <div className='d-flex align-items-center custom-emails justify-content-end'>
                              <div className="form-check form-check-custom form-check-solid form-check-sm d-flex align-center">
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox" value=""
                                  id="flexRadioLg"
                                  checked={secondEmail}
                                  onChange={() => { setSecondEmail(!secondEmail); if (!secondEmail) { formik.setFieldValue('reminderEmail2', undefined); } }}
                                />
                                <label className="form-label mb-0 fw-bold fs-6">Reminder Email #2</label>
                              </div>
                              <input type="number" disabled={secondEmail == true ? false : true} {...formik.getFieldProps('reminderEmail2')} className="form-control form-control-solid mx-3" placeholder="2" />
                              <label className="form-label mb-0 fw-bold fs-6">Days before Event</label>
                            </div>
                          </div>
                        </div>


                        <div className=' d-flex align-items-center justify-content-start'>
                          <button
                            type='submit'
                            className='btn btn-primary me-3'
                          >
                            Reserve
                          </button>
                          <a href="/reserve" className='btn btn-secondary maib-btn-style'>
                            Cancel
                          </a>
                        </div>
                      </div>
                    </form>
                  </>
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reserve