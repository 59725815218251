import React from 'react'
import AssetTagging from './components/AssetTagging'
import { Option } from './components/Option'
// import ReminderEmails from './components/ReminderEmails'
const Options = () => {
  return (
    <>
      <AssetTagging />
      {/* <ReminderEmails /> */}
      {/* <Option/> */}
      
    </>
  )
}

export default Options
