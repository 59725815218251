import React, {useState, useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useAuth} from '../../../../auth'
import {useFormik} from 'formik'
// import {addDepartment, updateDepartment} from '../core/_requests'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useAddDepartmentMutation, useUpdateDepartmentMutation } from '../../../../services/departments'

const departmentsSchema = Yup.object().shape({
  department: Yup.string().trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Department name is required'),
})

const initialValues = {
  department: '',
}

const DepartmentsModel = (props: any) => {
  const {currentUser} = useAuth()
  const [addDepartment, {isLoading: isAdding}] = useAddDepartmentMutation();
  const [updateDepartment, {isLoading: isUpdating}] = useUpdateDepartmentMutation();
  useEffect(() => {
    if (props?.data?.id) {
      formik.setValues({
        department: props?.data?.department,
      })
    }
  }, [props?.data?.id,props?.data])
  


  const formik = useFormik({
    initialValues,
    validationSchema: departmentsSchema,
    onSubmit: async (values:any) => {
      try{
      if (currentUser) {
        values.department = values.department.trim();
        const data = props.data.id
          ?await updateDepartment({...values, userId: currentUser?.id, id: props.data.id}).unwrap()
          : await addDepartment({...values, userId: currentUser?.id}).unwrap()
          if(data){  
            toast.dismiss()
            if(data?.success){
              toast.success(data?.message)
            handleClose()
            }else{
              toast.error(data?.message)
            }
          }
        }
      }
          catch(error:any) {
            if(error?.data?.message && error?.data?.status==409){
            
              toast.error(error?.data?.message)
            }
            else{
              toast.error('Something went wrong')
            }
          }
        
    },
  })

  const handleClose = () => {
    formik.resetForm()
    props.handleClose()
  }

  return (
    <Modal show={props.show} onHide={handleClose} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{props?.data?.id ? 'Edit Department' : 'Add a Department'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <p className='mb-4 fs-6'>Add <b>departments</b> that own or house the particular assets. Make them as broad or as specific as you want. <b>Departments</b> can be <i>'Accounting'</i>, <i>'Marketing'</i>, or <i>'Executive'</i>. Customize to your particular need.</p>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Department</label>
            <input
              placeholder='Department'
              {...formik.getFieldProps('department')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.department && formik.errors.department},
                {
                  'is-valid': formik.touched.department && !formik.errors.department,
                }
              )}
              type='text'
              name='department'
              autoComplete='off'
            />
            {formik.touched.department && formik.errors.department && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.department}</span>
                </div>
              </div>
            )}
          </div>
          <div className=' d-flex align-items-center justify-content-end'>
            <Button variant='secondary' className='me-3' onClick={handleClose}>
              Close
            </Button>
            {/* <button type='submit' className='add_location_btn'>
        {' '}
        Add Location{' '}
      </button> */}
            <button
              type='submit'
              className='btn btn-light-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {/* {(!isAdding || !isUpdating) && (
              )} */}
              {(isAdding || isUpdating) ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ):(
                <span className='indicator-label'>{props.data.id ? 'Update Department' : 'Add Department'}</span>
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default DepartmentsModel
