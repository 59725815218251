import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const API_URL = process.env.REACT_APP_API_URL

export const assetsApi = createApi({
  reducerPath: 'assetsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/user-assets/` }),
  tagTypes: ['Assets', 'Asset'],
  endpoints: (build) => ({
    getAllAssets: build.query<any, { userId: any, body: any, page: any, limit: any }>({
      query: ({ userId, body, page, limit }) => ({
        url: `list/${userId}?${body}&page=${page}&limit=${limit}`,
        method: 'GET',
      }),
      providesTags: ['Assets'],
    }),
    getSingleAsset: build.query<any, { assetId: any }>({
      query: ({ assetId }) => ({
        url: `/${assetId}`,
        method: 'GET',
      }),
      providesTags: ['Assets'],
    }),
    addAsset: build.mutation<any, FormData>({
      query: (formData) => ({
        url: `create`,
        method: 'POST',
        body:formData,
      }),
      invalidatesTags: () => [{ type: 'Assets' }],
    }),
    updateAsset: build.mutation<any, { assetId: any; formData: FormData }>({
      query: ({ assetId, formData }) => ({
        url: `update/${assetId}`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: (result, error, { assetId }) => [{ type: 'Assets' }],
    }),
    deleteAssets: build.mutation<any, { userId: number | undefined, id: number[] }>({
      query({ userId, id }) {
        return {
          url: `delete-all/${userId}`,
          method: 'DELETE',
          body: id
        }
      }, 
      invalidatesTags: (userId,id) => [{ type: 'Assets'}],
    }),
    // searchAssets: build.mutation<any, any>({
    //     query: (body) => ({
    //       url: ``,
    //       method: 'POST',
    //       body,
    //     }),
    //     invalidatesTags: () => [{ type: 'Assets'}],
    //   })
  }),
})

export const {
  useGetAllAssetsQuery,
  useGetSingleAssetQuery,
  useAddAssetMutation,
  useUpdateAssetMutation,
  useDeleteAssetsMutation,
} = assetsApi
