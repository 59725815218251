export interface CAccount {
  userId: any
  companyLogo: any
  companyName: string
  address: string
  city: string
  country: string
  state: string
  postalCode: string
  aptSuite: string
  timeZone: string
  currencySymbol: string
  financialYearStart: string
  dateFormat: string
}

export const defaultCompanyDetail: CAccount = {
  userId: '',
  companyLogo: '',
  companyName: '',
  address: '',
  city: '',
  country: '',
  state: '',
  aptSuite: '',
  postalCode: '',
  timeZone: '',
  currencySymbol: '',
  financialYearStart: '',
  dateFormat: '',
}
