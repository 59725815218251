import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { CustomerResponse } from '../apps/company-setup/customers/core/_models';
import { assetsApi } from './assets';

const API_URL = process.env.REACT_APP_API_URL

export const personsApi = createApi({
    reducerPath: 'personsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/person/` }),
  tagTypes: ['Persons'],
  // endpoints: (build) => ({
  //   getAllCustomers: build.query<any, any>({
  //     query: (userId) => `get`,
  //     providesTags: ['Customers'],
  //   }),
    
  endpoints: (build) => ({
    getAllCustomers: build.query<any, any>({
      query: ({ id, values }) => ({
        url: `get/${id}?search=${values}`,
        method: 'GET',
      }),
      providesTags: ['Persons'],
    }),
   
    addCustomer: build.mutation<any, any>({
      query: (body) => ({
        url: `add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Persons'}],
    }),
    updateCustomer: build.mutation<any, { id: any; values: any }>({
      query: ({ id, values }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Persons' }],
    }),
    deleteCustomers: build.mutation<any, any>({
      query({id,userId}) {
        return {
          url: `delete/${userId}`,
          method: 'DELETE',
          body: id
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            assetsApi.util.invalidateTags(["Assets"])
          );
        });
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Persons', userId }],
    }),
  }),
})

export const {
  useGetAllCustomersQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomersMutation,
} = personsApi
