import { KTSVG } from '../../../../../../_metronic/helpers'
import { useGetAllSitesQuery } from 'app/modules/services/sites';
import { useAuth } from 'app/modules/auth';
import { useEffect } from 'react';

const LocationHeader = (props: any) => {
  const { currentUser } = useAuth()
  const { data: siteData, isSuccess } = useGetAllSitesQuery(currentUser?.id);
  const handleSiteClick = (e: any) => {
    let id = e.target.value
    props.setSiteId(id)
    props.setSkip(false)
  }
  useEffect(() => {
    if (siteData?.sites && siteData?.sites?.length > 0) {
      props.setSiteId(siteData?.sites[0]?.id)
    }
  }, [siteData])


  return (
    <div className='d-flex align-items-center justify-content-between'>
      <h3 className="card-title align-items-start ps-9 flex-column">
        <span className='me-2 align-middle'><i className="la la-list fs-1" aria-hidden="true"></i></span>
        <span className="card-label fw-bold fs-3 mb-1">List of Locations</span>
      </h3>
      <div className='d-flex justify-content-end mt-6' data-kt-user-table-toolbar='base'>
        <div className='d-flex me-3'>
          <label className='col-form-label fw-bold fs-6 me-2'>
            <span className=''>Site</span>
          </label>
          <div className='fv-row d-flex'>
            <select
              onChange={(e) => { handleSiteClick(e) }}

              className='form-select form-select-solid form-select-lg site-select'

            >
              {siteData?.sites.map((item: any, idx: number) => {
                return (
                  <option key={idx} value={item.id}>{item.name}</option>
                )
              })}
            </select>
          </div>

        </div>
        <button type='button' className='btn btn-light-primary me-3' onClick={() => props.handleShow()}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Add New Location
        </button>

        {/* <button type='button' className='btn btn-primary '>
          <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
          Import Locations
        </button> */}

        <div className='d-flex justify-content-end align-items-center'>
          {props.deleteDataArr.length > 0 &&
            <button type='button' className='btn btn-danger' onClick={() => { props.setDeleteModal(true); props.setCheck(props.deleteDataArr) }} >
              Delete Selected ( {props.deleteDataArr.length} )
            </button>}
        </div>
      </div>
    </div>
  )
}

export { LocationHeader }
