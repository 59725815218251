export type Customer = {
  fullName: string
  employeeId?: string,
  title?: string
  phone?:string
  email?: string
  siteId?: number | null
  locationID?: number | null
  departmentId?: number | null
  notes?: string
  createdBy?: string
  userId? : number | null
}

export const initialPersons: Customer = {
  fullName: "",
  employeeId: "",
  title: "",
  phone: "",
  email: "",
  siteId: undefined,
  locationID: undefined,
  departmentId:undefined,
  notes: "",
  createdBy: "",
  userId: undefined,
}

export type PersonResponse = {
  id: number;
  fullName: string;
  employeeId?: string;
  title?: string;
  phone?: string;
  email?: string;
  siteId?: string;
  locationID?: string;
  departmentId?: string;
  notes?: string;
  createdBy?: string;
  // userId : number
};
