import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './assets';

const API_URL = process.env.REACT_APP_API_URL

export const categoriesApi = createApi({
    reducerPath: 'categoriesApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/category/` }),
  tagTypes: ['Categories'],
  endpoints: (build) => ({
    getAllCategories: build.query<any, any>({
      query: ({userId,page}) => `list/?page=${page}&userId=${userId}`,
      providesTags: ['Categories'],
    }),
    addCategory: build.mutation<any, any>({
      query: (values) => ({
        url: `create`,
        method: 'POST',
        body:values,
      }),
      invalidatesTags: () => [{ type: 'Categories'}],
    }),
    updateCategory: build.mutation<any, { id: any; values: any }>({
      query: (values) => ({
        url: `update`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error) => [{ type: 'Categories' }],
    }),
    deleteCategory: build.mutation<any, {userId:number | undefined, cascade:number, id: number[]}>({
      query({userId, cascade, id}) {
        return {
          url: `delete/${userId}?cascade=${cascade}`,
          method: 'PUT',
          body: id
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            assetsApi.util.invalidateTags(["Assets"])
          );
        });
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Categories', userId }],
    }),
  }),
})

export const {
  useGetAllCategoriesQuery,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi
