import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const API_URL = process.env.REACT_APP_API_URL

export const tableOptionApi = createApi({
    reducerPath: 'tableOptionApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/` }),
  tagTypes: ['TableOption'],
  endpoints: (build) => ({
    getTableOption: build.query<any, any>({
      query: (token) => ({
        url: `table-option`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      providesTags: ['TableOption'],
    }),
    // addOption: build.mutation<Company, Partial<Company>>({
    //   query: (body) => ({
    //     url: `posts`,
    //     method: 'POST',
    //     body,
    //   }),
    //   invalidatesTags: [{ type: 'Company', id: 'LIST' }],
    // }),
    // getPost: build.query<Company, string>({
    //   query: (id) => `posts/${id}`,
    //   providesTags: (result, error, id) => [{ type: 'Company', id }],
    // }),
    addTableOption: build.mutation<any,{data:any,token:any}>({
      query: ({data, token}) => ({
        url: `table-option`,
        method: 'POST',
        body: {
          data
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
       invalidatesTags: () => [{ type: 'TableOption'}],
    }),
    // deleteCompany: build.mutation<{ success: boolean; }, number>({
    //   query(userId) {
    //     return {
    //       url: `delete/${userId}`,
    //       method: 'DELETE',
    //     }
    //   },
    //   invalidatesOptions: (result, error, userId) => [{ type: 'Company', userId }],
    // }),
  }),
})

export const {
  // useGetPostQuery,
  useGetTableOptionQuery,
  // useUpdateCompanyMutation,
  useAddTableOptionMutation,
  // useDeleteCompanyMutation,
} = tableOptionApi
