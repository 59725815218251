import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './assets';

const API_URL = process.env.REACT_APP_API_URL

export const departmentsApi = createApi({
    reducerPath: 'departmentsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/department/` }),
  tagTypes: ['Departments'],
  endpoints: (build) => ({
    getAllDepartments: build.query<any, any>({
      query: (userId) => `list?userId=${userId}`,
      providesTags: ['Departments'],
    }),
    addDepartment: build.mutation<any, any>({
      query: (values) => ({
        url: `create`,
        method: 'POST',
        body:values,
      }),
      invalidatesTags: () => [{ type: 'Departments'}],
    }),
    updateDepartment: build.mutation<any, { id: any; values: any }>({
      query: (values) => ({
        url: `update`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error) => [{ type: 'Departments' }],
    }),
    deleteDepartment: build.mutation<any, {userId:number | undefined, cascade:number, id: number[]}>({
      query({userId, cascade, id}){
        return {
          url: `delete/?userId=${userId}&cascade=${cascade}`,
          method: 'DELETE',
          body: id
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            assetsApi.util.invalidateTags(["Assets"])
          );
        });
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Departments', userId }],
    }),
  }),
})

export const {
  useGetAllDepartmentsQuery,
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = departmentsApi
