import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './assets';

const API_URL = process.env.REACT_APP_API_URL

export const exportsApi = createApi({
    reducerPath: 'exportsApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/export/` }),
    endpoints: (build) => ({
        getExportCategories: build.query<any, any>({
            query: ({ userId }) => `categories/${userId}`,
        }),
        getExportDepartments: build.query<any, any>({
            query: ({ userId }) => `departments/${userId}`,
        }),
        getExportSites: build.query<any, any>({
            query: ({ userId }) => `sites/${userId}`,
        }),
        getExportCustomers: build.query<any, any>({
            query: ({ userId }) => `customers/${userId}`,
        }),
        getExportLocations: build.query<any, any>({
            query: ({ userId }) => `locations/${userId}`,
        }),
        getExportAssets: build.query<any, any>({
            query: ({ userId }) => `assets/${userId}`,
        }),
        getExportMaintenances: build.query<any, any>({
            query: ({ userId }) => `maintenances/${userId}`,
        }),
        getExportPersons: build.query<any, any>({
            query: ({ userId }) => `persons/${userId}`,
        }),
    }),
});

export const {
    useGetExportCategoriesQuery,
    useGetExportDepartmentsQuery,
    useGetExportSitesQuery,
    useGetExportCustomersQuery,
    useGetExportLocationsQuery,
    useGetExportAssetsQuery,
    useGetExportMaintenancesQuery,
    useGetExportPersonsQuery
} = exportsApi
