
export type Asset = {
  assetTagId?: string,
  brand?: string,
  categoryId?: number,
  createdAt?: string,
  departmentId?: number,
  description?: string,
  locationId?: number,
  model?: string,
  purchaseDate?: string,
  purchasedfrom?: string,
  serialNo?: string,
  siteId?: number,
  userId?:  number,
  cost?: number|null,
  statusType?: string,
  photo?:any
  }
  
  export const initialAssets  : Asset =  {
  assetTagId: "",
  brand: "",
  categoryId: undefined,
  createdAt:  "",
  departmentId: undefined,
  description: "",
  locationId: undefined,
  model: "",
  purchaseDate: undefined,
  purchasedfrom: "",
  serialNo: "",
  siteId: undefined,
  userId : 0,
  cost: 0.00,
  statusType: "available",
  photo:''
  }
  
  export type AssetResponse = {
  id: number,
  assetTagId?: string,
  brand?: string,
  categoryId?: number,
  createdAt?: string,
  departmentId?: number,
  description?: string,
  locationId?: number,
  model?: string,
  purchaseDate?: string,
  purchasedfrom?: string,
  serialNo?: string,
  siteId?: number,
  userId?:  number,
  cost?: number|null,
  statusType?: string,
  assetstatus?:any
  site?:any
  location?:any
  photo?:any
  }
  
  