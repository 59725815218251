export interface LeaseInterface {
    leaseBegins:string|Date, 
    customerId:string,
    leaseExpires:string,
    leaseNotes:string, 
    leaseEmail:string,
    userId:number, 
    assetId:number,
    overrideAssets:string|false,

}
export const defaultLeaseDetail: LeaseInterface = {
    leaseBegins:new Date().toISOString().split('T')[0], 
    customerId:"",
    leaseExpires:'',
    leaseNotes:"", 
    leaseEmail:"",
    userId:0, 
    assetId:0,
    overrideAssets: false,

}
