import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './assets';

const API_URL = process.env.REACT_APP_API_URL

export const locationsApi = createApi({
    reducerPath: 'locationsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/location/` }),
  tagTypes: ['Locations'],
  endpoints: (build) => ({
    getAllLocations: build.query<any, any>({
      query: (userId) => `list/${userId}`,
      providesTags: ['Locations'],
    }),
    getAllLocationsBySite: build.query<any, any>({
      query: ({userId,id}) => `search/${userId}?id=${id}`,
      providesTags: ['Locations'],
    }),
    addLocation: build.mutation<any, any>({
      query: ({data,userId}) => ({
        url: `create/${userId}`,
        method: 'POST',
        body:data,
      }),
      invalidatesTags: () => [{ type: 'Locations'}],
    }),
    updateLocation: build.mutation<any, { id: any; values: any }>({
      query: ({ id, values }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error) => [{ type: 'Locations' }],
    }),
    deleteLocation: build.mutation<any, {userId:number | undefined, cascade:number, id: number[]}>({
      query({userId, cascade, id}) {
        return {
          url: `delete-all/${userId}?cascade=${cascade}`,
          method: 'PUT',
          body: id
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            assetsApi.util.invalidateTags(["Assets"])
          );
        });
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Locations', userId }],
    }),
  }),
})

export const {
  useGetAllLocationsQuery,
  useGetAllLocationsBySiteQuery,
  useAddLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
} = locationsApi
