import { useFormik } from 'formik'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { initialCustomers, CustomerResponse } from '../core/_models'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getAllCustomers } from '../core/_requests'
import { useAuth } from '../../../../auth'
import { countriesList } from './countries'
import { useAddCustomerMutation, useUpdateCustomerMutation } from '../../../../services/customers'

const CustomersSechma = Yup.object().shape({
  fullName: Yup.string().trim()
    .required('Full Name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Full Name should contain only letters'),
  // postalCode: Yup.number().typeError('Postal Code is Number'),
})

const CustomersModal = (props: any) => {

  const { currentUser } = useAuth()
  // const [loading, setLoading] = useState<boolean>()
  const [customers, setCustomers] = useState<CustomerResponse[] | undefined>()
  const [addCustomer, { isLoading: isAdding }] = useAddCustomerMutation();
  const [updateCustomer, { isLoading: isUpdating }] = useUpdateCustomerMutation();

  // useEffect(() => {
  //   if (currentUser) {
  //     formik.resetForm()
  //     getAllSites(currentUser?.id).then((response) => {
  //       setSites(response)
  //     })
  //   }
  // }, [])

  useEffect(() => {
    formik.resetForm()
    if (props?.CustomerData?.id) {
      formik.setValues({
        fullName: props?.CustomerData?.fullName,
        company: props?.CustomerData?.company,
        address1: props?.CustomerData?.address1,
        address2: props?.CustomerData?.address2,
        city: props?.CustomerData?.city,
        state: props?.CustomerData?.state,
        zip: props?.CustomerData?.zip,
        country: props?.CustomerData?.country,
        phone: props?.CustomerData?.phone,
        cell: props?.CustomerData?.cell,
        email: props?.CustomerData?.email,
        notes: props?.CustomerData?.notes,
        userId: props?.CustomerData?.userId,
      })
    }
  }, [props])

  const formik = useFormik({
    initialValues: { ...initialCustomers },
    validationSchema: CustomersSechma,
    onSubmit: async (values) => {
      // setLoading(true)
      if (currentUser) {
        values.fullName = values.fullName.trim()
        try {
          const data = props?.CustomerData?.id
            ? await updateCustomer({ id: props.CustomerData?.id, values }).unwrap()
            : await addCustomer({ ...values, userId: currentUser.id }).unwrap()
          if (data) {
            toast.dismiss()
            if (data?.success) {
              toast.success(data?.message)
              formik.resetForm()
              props.handleClose()
            } else {
              toast.error(data?.message)
            }
            // setLoading(false)

          }
        }
        catch (error: any) {
          if(error?.data?.message && error?.data?.status==409){
            
            toast.error(error?.data?.message)
          }
          else{
            toast.error('Something went wrong')
          }
          formik.setSubmitting(false)
          // setLoading(false)
        }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    props.handleClose()
  }

  return (
    <>
      <Modal className='custom-model-sm' show={props.show} onHide={props.handleClose} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{props?.CustomerData?.id ? 'Update Customer' : 'Add Customer'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='inner-site-modal'>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>Full Name</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('fullName')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                  {formik.touched.fullName && formik.errors.fullName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.fullName}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Company</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('company')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                  {formik.touched.company && formik.errors.company && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.company}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Address1</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('address1')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Address2</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('address2')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>City</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('city')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>State</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('state')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Zip</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('zip')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Country</label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('country')}
                  >
                    <option selected value=''>
                      {' '}
                      Select country
                    </option>
                    {countriesList?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.code}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Phone</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('phone')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Cell</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('cell')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Email</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('email')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />

                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Notes</label>
                <div className='col-lg-9 fv-row'>
                  <textarea
                    placeholder=''
                    {...formik.getFieldProps('notes')}
                    // type='text'
                    rows={3}
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
            </div>
            <div className='text-end custom_location'>
              <Button variant='secondary' onClick={handleClose}>
                Close{' '}
              </Button>
              <button
                type='submit'
                className='add_location_btn'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {(!isAdding && !isUpdating) && (
                  <span className='indicator-label'>
                    {props?.CustomerData?.id ? 'Update Customer' : 'Add Customer'}
                  </span>
                )}
                {(isAdding || isUpdating) && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomersModal
