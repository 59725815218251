import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmationPopup from '../../../../../../../_metronic/helpers/components/ConfirmationPopup'
import {postMaintenanceData,getMaintenance  } from '../../core/_requests'
import { MaintenanceData,CustomField,Field } from '../../core/_models'
import {getAuth } from '../../../../../auth'

const MaintenanceList = () => {
  const auth = getAuth()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [deleteSelected, setDeleteSelected] = useState<number>(0)
  const [maintenanceData, setMaintenanceData] = useState<MaintenanceData>({ 
  hasDetails: false, 
  isDetailsRequired: false,
  hasDueDate: false,
  hasMaintenanceBy: false,
  isMaintenanceByRequired: false,
  hasMaintenanceStatus:false, 
  isMaintenanceStatusRequired: false,
  isDueDateRequired:false,
  hasDateCompleted: false,
  isDateCompletedRequired: false,
  hasCostOfRepairs: false,
  isCostOfRepairsRequired: false,
  hasRepeating: false,
  isRepeatingRequired: false,
  categoryList:"",
  customFields: [] || true });
const [customFieldlabel, setCustomFieldlabel] = useState<string>("");
const [customFieldlabelError, setCustomFieldlabelError] = useState<string>(""); //validation state
const [option, setOption] = useState<string>("");
const [optionError, setOptionError] = useState<string>(""); //validation state
const [isRequired, setIsRequired] = useState<boolean>(true);
const [listOptions, setListOptions] = useState<string>("");
const [listOptionError, setListOptionError] = useState<string>(""); //validation state
const [fields, setFields] = useState<{ id: number, value: string }[]>([]);
const [fieldValues, setFieldValues] = useState<string[]>([]);
const [editMaintenanceIndex, setEditMaintenanceIndex] = useState<number>();
const [formSubmitted, setFormSubmitted] = useState<boolean>(false); //state used for validation
const [loading, setLoading] = useState(false)

   //Custom Field Label
const handleCustomFieldLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  if (inputValue === "" || inputValue.match(/^ *$/)) {
    setCustomFieldlabel("");
  } else {
    setCustomFieldlabel(inputValue);
  }
};

//Data Type DropDown
const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  setOption(event.target.value);
};

//Data Required Yes OR Optional
const handleIsRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setIsRequired(event.target.value === "yes");
};

//List Options::::choose dropdown list and click checkout list the show input labe below
const handleListOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  if (inputValue === "" || inputValue.match(/^ *$/)) {
    setListOptions("");
  } else {
    setListOptions(inputValue);
  }
};

 // Save button click handler
const handleAddCustomField = () => {
  // Make sure that customFieldlabel is not empty
  if (!customFieldlabel || !option  ) {
    setCustomFieldlabelError("Label is required.");
    setOptionError("Data Type is required.");
    setListOptionError(" Option is required.")
    setFormSubmitted(true); // move setFormSubmitted inside if block
   return;
  }

// Validate listOptions only if the option is "Checkbox list"
if ((option === "Checkbox list" || option === "Dropdown list" || option === "Radio button list") && !listOptions) {
  console.error("Option is required.");
  setListOptionError("Option is required.");
  console.log("Fileds should not be empty")
  // setlistOptionErrorValidation(true);
  setFormSubmitted(true);
  return;
}

//All state together in one object
  const newCustomField: CustomField = {
    customFieldlabel,
    option,
    isRequired,
    listOptions,
    fields: [...fields],
  };
 if (maintenanceData && maintenanceData.customFields) {
    console.log("setCustomFieldlabel:::::", customFieldlabel);
    if (editMaintenanceIndex !== undefined) {
      console.log("yes", editMaintenanceIndex);
      maintenanceData.customFields[editMaintenanceIndex] = newCustomField;
      setMaintenanceData({ ...maintenanceData });
      // submit();
      setEditMaintenanceIndex(undefined); // Reset editMaintenanceIndex to undefined
      toast.success("Custom field updated successfully.");
    } else {
      if (!Array.isArray(maintenanceData.customFields)) {
        maintenanceData.customFields = [];
      }
      maintenanceData.customFields.push(newCustomField);
      setMaintenanceData({ ...maintenanceData });
      console.log("Custom field added successfully");
      toast.success("Custom field added successfully.");
    }
    //used submit() function or function
    // submit();
    console.log("Before API call", maintenanceData.customFields);
    postMaintenanceData(auth?.token, maintenanceData)
      .then((res) => {
      setMaintenanceData(res?.data?.updatedModel);
        })
      .catch((error) => {
      console.error(error);
      });
    // Reset the form fields
    setCustomFieldlabelError(""); //reset customField label validation
    setOptionError(""); //option customField label  validation
    setOption("");
    setListOptions("");
    setCustomFieldlabel("");
    setListOptions("");
    setOption("");
    setFields([]);
   setFormSubmitted(false); // set formSubmitted back to false
  } else {
    console.error("maintenanceData is not defined or has no customFields");
  }
};

useEffect(()=>{
  console.log("maintenanceData post response:::::",maintenanceData)
},[maintenanceData])

//when click Add More field button then create new input labe on below.
const addNewField = () => {
  const newField: Field = { id: Date.now(), value: "" };
  setFields([...fields, newField]);
};

// __ __ if create new input labe on below. if we want to remove it, we can remove it
const removeField = (index: number) => {
  const newFields = [...fields];
  newFields.splice(index, 1);
  setFields(newFields);
};

const handleChange = (index: number, value: string) => {
  const newFields = [...fields];
  newFields[index] = { ...newFields[index], value };
  setFields(newFields);
  const newFieldValues = [...fieldValues];
  newFieldValues[index] = value;
  setFieldValues(newFieldValues);
};

//checked Field all checkbox
function selectAllCheckboxes(event: React.ChangeEvent<HTMLInputElement>): void {
  const isChecked = event.target.checked;
  setMaintenanceData({
    ...maintenanceData,
    hasDetails: isChecked,
    hasMaintenanceBy: isChecked,
    hasDateCompleted: isChecked,
    hasCostOfRepairs: isChecked,
  });
}

  // checked checkboxes one by one
const handleCheckboxChange = (checkboxName: string, isChecked: boolean) => {
 if (maintenanceData) {
    // hasDetails
    if (checkboxName === "hasDetails") {
      if (isChecked) {
        setMaintenanceData({
          ...maintenanceData,
          hasDetails: isChecked,
          isDetailsRequired: true,
        });
      } else {
        setMaintenanceData({
          ...maintenanceData,
          hasDetails: isChecked,
          isDetailsRequired: false,
        });
      }
    } else if (checkboxName === "isDetailsRequired") {
      if (!isChecked) {
        setMaintenanceData({
          ...maintenanceData,
          isDetailsRequired: false,
        });
      } else {
        setMaintenanceData({
          ...maintenanceData,
          isDetailsRequired: true,
        });
      }
    }

    //hasMaintenanceBy
    else if (checkboxName === "hasMaintenanceBy") {
      if (isChecked) {
        setMaintenanceData({
          ...maintenanceData,
          hasMaintenanceBy: isChecked,
          isMaintenanceByRequired: true,
        });
      } else {
        setMaintenanceData({
          ...maintenanceData,
          hasMaintenanceBy: isChecked,
          isMaintenanceByRequired: false,
        });
      }
    } 
    
    else if (checkboxName === "isMaintenanceByRequired") {
      if (!isChecked) {
        setMaintenanceData({
          ...maintenanceData,
          isMaintenanceByRequired: false,
        });
      } else {
        setMaintenanceData({
          ...maintenanceData,
          isMaintenanceByRequired: true,
        });
      }
    }

    // hasDateCompleted
    else if (checkboxName === "hasDateCompleted") {
      if (isChecked) {
        setMaintenanceData({
          ...maintenanceData,
          hasDateCompleted: isChecked,
          isDateCompletedRequired: true,
        });
      } else {
        setMaintenanceData({
          ...maintenanceData,
          hasDateCompleted: isChecked,
          isDateCompletedRequired: false,
        });
      }
    } else if (checkboxName === "isDateCompletedRequired") {
      if (!isChecked) {
        setMaintenanceData({
          ...maintenanceData,
          isDateCompletedRequired: false,
        });
      } else {
        setMaintenanceData({
          ...maintenanceData,
          isDateCompletedRequired: true,
        });
      }
    }

    // hasCostOfRepairs
    else if (checkboxName === "hasCostOfRepairs") {
      if (isChecked) {
        setMaintenanceData({
          ...maintenanceData,
          hasCostOfRepairs: isChecked,
          isCostOfRepairsRequired: true,
        });
      } else {
        setMaintenanceData({
          ...maintenanceData,
          hasCostOfRepairs: isChecked,
          isCostOfRepairsRequired: false,
        });
      }
    } else if (checkboxName === "isCostOfRepairsRequired") {
      if (!isChecked) {
        setMaintenanceData({
          ...maintenanceData,
          isCostOfRepairsRequired: false,
        });
      } else {
        setMaintenanceData({
          ...maintenanceData,
          isCostOfRepairsRequired: true,
        });
      }
    }
    //isDueDateRequired
    else if (checkboxName === "isDueDateRequired") {
      if (isChecked) {
        setMaintenanceData({
          ...maintenanceData,
          isDueDateRequired: true,
        });
      } else {
        setMaintenanceData({
          ...maintenanceData,
          isDueDateRequired: false,
        });
      }
    }
    //isMaintenanceStatusRequired
    else if (checkboxName === "isMaintenanceStatusRequired") {
      if (isChecked) {
        setMaintenanceData({
          ...maintenanceData,
          isMaintenanceStatusRequired: true,
        });
      } else {
        setMaintenanceData({
          ...maintenanceData,
          isMaintenanceStatusRequired: false,
        });
      }
    } else {
      console.warn("Unexpected checkbox name:", checkboxName); // Error handling
    }
  }
};

 // //__ __ get method used because data fetching on backend and set on maintenanceData __
  useEffect(() => {
    getMaintenance(auth?.token).then((res) => {
      setMaintenanceData(res?.data?.maintenance);
      console.log("response maintenance get data::::::", res.data.maintenance);
    });
  }, []);

  useEffect(() => {
    console.log("get maintenanceData fetching", maintenanceData);
  }, [maintenanceData]);

  //__ __ Submit button handler __ __//
  const submit = (event?: React.FormEvent<HTMLFormElement>): void => {
    // make event optional
    if (event) {
      event.preventDefault(); // prevent the default form submission behavior
    }
    setLoading(true)
    postMaintenanceData(auth?.token, maintenanceData)
      .then((res) => {
        setMaintenanceData(res?.data?.updatedModel);
        toast.success("Database updated successfully");
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
      });
  };

// __ __ Handling Modals confirm delete and cancel __ __ //
  const cancle = () => {
    setDeleteModal(false);
  };
  const confirm = async () => {
    maintenanceData.customFields.splice(deleteSelected, 1);
    setMaintenanceData({ ...maintenanceData });
    postMaintenanceData(auth?.token, maintenanceData)
      .then((res) => {
        setMaintenanceData(res.data.updatedModel);
        toast.success("Custom Field deleted successfully ");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Custom Field does not deleted");
      });
    setDeleteModal(false);
  };

//__ __Edit button  Handler __ __//
  const EditHandler = async (index: number) => {
    const customField = maintenanceData.customFields[index];
    setCustomFieldlabel(customField.customFieldlabel); // Update the state variables with the custom field data
    setOption(customField.option || "");
    setIsRequired(customField.isRequired === true ? true : false);
    setListOptions(customField.listOptions || "");
    setFields(customField.fields || []);
    setEditMaintenanceIndex(index); // Set the edit employee index
   
  };

//__ __ when clicked closed or (X) means cross in  modal  then all fields are empty
  const resetFormFields = () => {
    setCustomFieldlabel("");
    setOption("");
   setListOptions("");
    setFields([]);
  };

  const handleClose = () => {
      resetFormFields();
      setEditMaintenanceIndex(undefined);
      // other code to close the modal
    };

 useEffect(()=>{
   console.log("maintenanceData:::",maintenanceData)
  },[maintenanceData])

  return (
<div>
    <div className="form-title d-flex">
    <span className='mt-1 Database'>Database  </span><span className='Personal/Employee'>Maintenance</span>
       </div>
    <div className="card">
    <ConfirmationPopup
      deleteModal={deleteModal}
      cancle={cancle}
      confirm={confirm}
    />
    <div id="database_ajax_wrapper">
      <form onSubmit={submit}>
        <input
          name="__RequestVerificationToken"
          type="hidden"
          value="bi5mAQGArgtf83vcFjyPEGK8rKsZ-9D3G-eRhsWQ-rTaCYjXK6gswp10gSiUZu3dXYXV2Zm9_cHp6yxRZbnLhFj5ZuJQOiJIAWnGosU6DIm2Zzc3Qcl40A_U_bv_VIr8fFJjrA2"
        />{" "}
        <div className="card-body">
          <div className="form_section">
          <div className="form-title d-flex">
            <span className='me-2'>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 32 32"
                  fill="#c30" // Set the fill color to dark red
                >
                  <path fill="#9b9b9b" d="M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z"></path>
                  <path fill="#9b9b9b" d="M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z"></path>
                  <path fill="#9b9b9b" d="M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z"></path>
                  <path fill="#9b9b9b" d="M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z"></path>
                </svg>
              </span>
              <h4 className='mt-1'>Maintenance Standard Fields</h4>
            </div>
            <div className="text_indent">
              <p>
                Select the fields you would like to use for the maintenance table.
              </p>
              <div className="table-responsive">
                <table
                  className="table table-bordered mb-0"
                  aria-label="Persons/Employees Standard Fields"
                >
                  <thead className="thead-light  light-yellow-table">
                    <tr>
                      <th style={{ width: "2%" }}>
                        <label
                          className="checkbox has_nolabel m-auto"
                          htmlFor="field_all"
                        >
                          {maintenanceData && (
                            <input
                              checked={
                                maintenanceData.hasDetails &&
                                maintenanceData.hasMaintenanceBy &&
                                maintenanceData.hasDateCompleted &&
                                maintenanceData.hasCostOfRepairs
                              }
                              onChange={selectAllCheckboxes}
                              type="checkbox"
                              id="field_all"
                              value=""
                            />
                          )}
                          <span></span>
                          <span className="sr-only">Field All</span>
                        </label>
                      </th>
                      <th style={{ width: "20%",color:"rgb(170 127 58)"  }}>Field name </th>
                      <th style={{ width: "20%",color:"rgb(170 127 58)"  }}>Data Required </th>
                      <th style={{color:"rgb(170 127 58)"  }}>Description </th>
                      <th style={{ width: "20%",color:"rgb(170 127 58)"  }}>Example </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label className="checkbox has_nolabel m-auto">
                          <input
                            checked={true}
                            className="fieldDisabledCheck"
                            data-val="true"
                            data-val-required="Title is required."
                            disabled={true}
                            type="checkbox"
                            value="true"
                          />
                          <span></span>
                          <span className="sr-only">Field</span>
                        </label>
                      </td>
                      <td className="fw-bolder text-nowrap">
                        Title<span className="required"></span>
                      </td>
                      <td>
                        <div className="radio-inline">
                          <label className="radio mb-0">
                            <input type="radio" className="m_5" checked={true} />{" "}
                            <span></span>Yes
                          </label>
                        </div>
                      </td>
                      <td>Title of the maintenance.</td>
                      <td>Monthly Calibration</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="checkbox has_nolabel m-auto">
                          <input
                            checked={maintenanceData?.hasDetails}
                            onChange={(e) =>
                              handleCheckboxChange("hasDetails", e.target.checked)
                            }
                            className="fieldCheck"
                            data-val="true"
                            data-val-required="Details is required."
                            id="hasDetail"
                            name="hasDetail"
                            type="checkbox"
                            value="true"
                          />
                          <input name="hasDetail" type="hidden" value="false" />
                          <span></span>
                          <span className="sr-only">Field</span>
                        </label>
                      </td>
                      <td className="fw-bolder text-nowrap">Details</td>
                      <td>
                        {maintenanceData?.hasDetails && (
                          <div
                            className="radio-inline standard_radio_can_hide hasDetail_radio"
                            style={{ display: "flex" }}
                          >
                            <label className="radio mb-0 p_16">
                              <input
                                id="isDetailRequired_yes"
                                name="isDetailsRequired"
                                type="radio"
                                value="yes"
                                className="m_5"
                                checked={maintenanceData.isDetailsRequired}
                                onChange={() =>
                                  handleCheckboxChange("isDetailsRequired", true)
                                }
                              />{" "}
                              <span></span>Yes
                            </label>
                            <label className="radio mb-0">
                              <input
                                id="isDetailRequired_Optional"
                                name="isDetailsRequired"
                                type="radio"
                                value="Optional"
                                className="m_5"
                                checked={!maintenanceData.isDetailsRequired}
                                onChange={() =>
                                  handleCheckboxChange("isDetailsRequired", false)
                                }
                              />
                              <span></span>Optional
                            </label>
                          </div>
                        )}
                      </td>
                      <td>Details of the maintenance</td>
                      <td>Calibrate to 120 units</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="checkbox has_nolabel m-auto">
                          <input
                            checked={true}
                            className="fieldDisabledCheck"
                            data-val="true"
                            data-val-required="Due Date is required."
                            disabled={true}
                            type="checkbox"
                            value="true"
                          />
                          <span></span>
                          <span className="sr-only">Field</span>
                        </label>
                      </td>
                      <td className="fw-bolder text-nowrap">Due Date</td>
                      <td>
                        <div className="radio-inline hasDueDate_radio">
                          <label className="radio mb-0 p_16">
                            <input
                              id="isDueDateRequired_True"
                              name="isDueDateRequired"
                              type="radio"
                              value="True"
                              className="m_5"
                              checked={maintenanceData?.isDueDateRequired}
                              onChange={() =>
                                handleCheckboxChange("isDueDateRequired", true)
                              }
                            />
                            <span></span>Yes
                          </label>
                          <label className="radio mb-0">
                            <input
                              id="isDueDateRequired_False"
                              name="isDueDateRequired"
                              type="radio"
                              value="False"
                              className="m_5"
                              checked={!maintenanceData?.isDueDateRequired}
                              onChange={() =>
                                handleCheckboxChange("isDueDateRequired", false)
                              }
                            />
                            <span></span>Optional
                          </label>
                        </div>
                      </td>
                      <td>Date when maintenance is due</td>
                      <td>3/5/2020</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="checkbox has_nolabel m-auto">
                          <input
                            checked={maintenanceData?.hasMaintenanceBy ?? false}
                            onChange={(e) =>
                              handleCheckboxChange(
                                "hasMaintenanceBy",
                                e.target.checked
                              )
                            }
                            className="fieldCheck"
                            data-val="true"
                            data-val-required="Maintenance By is required."
                            id="hasAssignTo"
                            name="hasAssignTo"
                            type="checkbox"
                            value="true"
                          />
                          <input name="hasAssignTo" type="hidden" value="false" />
                          <span></span>
                          <span className="sr-only">Field</span>
                        </label>
                      </td>
                      <td className="fw-bolder text-nowrap">Maintenance By</td>
                      <td>
                        {maintenanceData?.hasMaintenanceBy && (
                          <div
                            className="radio-inline standard_radio_can_hide hasAssignTo_radio"
                            style={{ display: "flex" }}
                          >
                            <label className="radio mb-0 p_16">
                              <input
                                id="isAssignToRequired_True"
                                name="isMaintenanceByRequired"
                                type="radio"
                                value="True"
                                className="m_5"
                                checked={maintenanceData?.isMaintenanceByRequired}
                                onChange={() =>
                                  handleCheckboxChange(
                                    "isMaintenanceByRequired",
                                    true
                                  )
                                }
                              />{" "}
                              <span></span>Yes
                            </label>
                            <label className="radio mb-0">
                              <input
                                id="isAssignToRequired_False"
                                name="isMaintenanceByRequired"
                                type="radio"
                                value="False"
                                className="m_5"
                                checked={
                                  !maintenanceData?.isMaintenanceByRequired
                                }
                                onChange={() =>
                                  handleCheckboxChange(
                                    "isMaintenanceByRequired",
                                    false
                                  )
                                }
                              />
                              <span></span>Optional
                            </label>
                          </div>
                        )}
                      </td>
                      <td>Person doing maintenance</td>
                      <td>John Doe</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="checkbox has_nolabel m-auto">
                          <input
                            checked={true}
                            className="fieldDisabledCheck"
                            data-val="true"
                            data-val-required="Status is required."
                            disabled={true}
                            type="checkbox"
                            value="true"
                          />
                          <span></span>
                          <span className="sr-only">Field</span>
                        </label>
                      </td>
                      <td className="fw-bolder text-nowrap">
                        Maintenance Status
                      </td>
                      <td>
                        <div className="radio-inline hasStatus_radio">
                          <label className="radio mb-0 p_16">
                            <input
                              id="isStatusRequired_True"
                              name="isMaintenanceStatusRequired"
                              type="radio"
                              value="True"
                              className="m_5"
                              checked={
                                maintenanceData?.isMaintenanceStatusRequired
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "isMaintenanceStatusRequired",
                                  true
                                )
                              }
                            />{" "}
                            <span></span>Yes
                          </label>
                          <label className="radio mb-0">
                            <input
                              id="isStatusRequired_False"
                              name="isMaintenanceStatusRequired"
                              type="radio"
                              value="False"
                              className="m_5"
                              checked={
                                !maintenanceData?.isMaintenanceStatusRequired
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "isMaintenanceStatusRequired",
                                  false
                                )
                              }
                            />
                            <span></span>Optional
                          </label>
                        </div>
                      </td>
                      <td>
                        System field to show current status of the maintenance.
                        The possible values are Scheduled, In progress, On Hold,
                        Cancelled, Completed.
                      </td>
                      <td>Scheduled</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="checkbox has_nolabel m-auto">
                          <input
                            checked={maintenanceData?.hasDateCompleted ?? false}
                            onChange={(e) =>
                              handleCheckboxChange(
                                "hasDateCompleted",
                                e.target.checked
                              )
                            }
                            className="fieldCheck"
                            data-val="true"
                            data-val-required="Date Completed is required."
                            id="hasDateComplete"
                            name="hasDateComplete"
                            type="checkbox"
                            value="true"
                          />
                          <input
                            name="hasDateComplete"
                            type="hidden"
                            value="false"
                          />
                          <span></span>
                          <span className="sr-only">Field</span>
                        </label>
                      </td>
                      <td className="fw-bolder text-nowrap">Date Completed</td>
                      <td>
                        {maintenanceData?.hasDateCompleted && (
                          <div
                            className="radio-inline standard_radio_can_hide hasDateComplete_radio"
                            style={{ display: "flex" }}
                          >
                            <label className="radio mb-0 p_16">
                              <input
                                id="isDateCompleteRequired_True"
                                name="isDateCompletedRequired"
                                type="radio"
                                value="True"
                                className="m_5"
                                checked={maintenanceData?.isDateCompletedRequired}
                                onChange={() =>
                                  handleCheckboxChange(
                                    "isDateCompletedRequired",
                                    true
                                  )
                                }
                              />{" "}
                              <span></span>Yes
                            </label>
                            <label className="radio mb-0">
                              <input
                                id="isDateCompleteRequired_False"
                                name="isDateCompletedRequired"
                                type="radio"
                                value="False"
                                className="m_5"
                                checked={!maintenanceData.isDateCompletedRequired}
                                onChange={() =>
                                  handleCheckboxChange(
                                    "isDateCompletedRequired",
                                    false
                                  )
                                }
                              />{" "}
                              <span></span>Optional
                            </label>
                          </div>
                        )}
                      </td>
                      <td>Date when maintenance is completed</td>
                      <td>3/5/2020</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="checkbox has_nolabel m-auto">
                          <input
                            checked={maintenanceData?.hasCostOfRepairs ?? false}
                            onChange={(e) =>
                              handleCheckboxChange(
                                "hasCostOfRepairs",
                                e.target.checked
                              )
                            }
                            className="fieldCheck"
                            data-val="true"
                            data-val-required="Cost of Repairs is required."
                            id="hasRepairCost"
                            name="hasRepairCost"
                            type="checkbox"
                            value="true"
                          />
                          <input
                            name="hasRepairCost"
                            type="hidden"
                            value="false"
                          />
                          <span></span>
                          <span className="sr-only">Field</span>
                        </label>
                      </td>
                      <td className="fw-bolder text-nowrap">Cost of Repairs</td>
                      <td>
                        {maintenanceData?.hasCostOfRepairs && (
                          <div
                            className="radio-inline standard_radio_can_hide hasRepairCost_radio"
                            style={{ display: "flex" }}
                          >
                            <label className="radio mb-0 p_16">
                              <input
                                id="isRepairCostRequired_True"
                                name="isRepairCostRequired"
                                type="radio"
                                value="True"
                                className="m_5"
                                checked={maintenanceData?.isCostOfRepairsRequired}
                                onChange={() =>
                                  handleCheckboxChange(
                                    "isCostOfRepairsRequired",
                                    true
                                  )
                                }
                              />{" "}
                              <span></span>Yes
                            </label>
                            <label className="radio mb-0">
                              <input
                                id="isRepairCostRequired_False"
                                name="isRepairCostRequired"
                                type="radio"
                                value="False"
                                className="m_5"
                                checked={
                                  !maintenanceData?.isCostOfRepairsRequired
                                }
                                onChange={() =>
                                  handleCheckboxChange(
                                    "isCostOfRepairsRequired",
                                    false
                                  )
                                }
                              />
                              <span></span>Optional
                            </label>
                          </div>
                        )}
                      </td>
                      <td>Total cost spent on this maintenance</td>
                      <td>$97.50</td>
                    </tr>
                    <tr>
                      <td>
                        <label className="checkbox has_nolabel m-auto">
                          <input
                            checked={true}
                            className="fieldDisabledCheck"
                            data-val="true"
                            data-val-required="Repeating is required."
                            disabled={true}
                            type="checkbox"
                            value="true"
                          />
                          <span></span>
                          <span className="sr-only">Field</span>
                        </label>
                      </td>
                      <td className="fw-bolder text-nowrap">Repeating</td>
                      <td>
                        <div className="radio-inline">
                          <label className="radio mb-0">
                            <input type="radio" className="m_5" checked={true} />{" "}
                            <span></span>Yes
                          </label>
                        </div>
                      </td>
                      <td>System fields to define repeating maintenances</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="form_section" id="custom_ajax_wrapper">
            <div className="separator separator-solid mb-6"></div>
            <div className="form-title d-flex">
              <span className='me-2'>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 32 32"
                  fill="#c30" // Set the fill color to dark red
                >
                  <path fill="#9b9b9b" d="M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z"></path>
                  <path fill="#9b9b9b" d="M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z"></path>
                  <path fill="#9b9b9b" d="M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z"></path>
                  <path fill="#9b9b9b" d="M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z"></path>
                </svg>
              </span>
            <h4 className='mt-1'>Maintenance Custom Fields</h4>
            </div>
            <div className="text_indent">
              <p>
                Add custom fields to join the standard fields that we provided.
              </p>
              <div className="form_section">
                <div className="mb-4">
                  {" "}
                  <a
                    data-bs-toggle="modal"
                    href="#addEditFieldBox"
                    className="btn btn-success"
                    onClick={handleClose}
                  >
                    {" "}
                    <i className="fa fa-plus" aria-hidden="true"></i> Add Custom
                    Field{" "}
                  </a>{" "}
                </div>
                <table
                  className="table table-bordered table-sm vertical_middle mb-0"
                  id="custom_fields_table"
                  aria-label="Asset Custom Fields"
                >

                 
                  <thead className="thead-light light-yellow-table">
                  {
                    maintenanceData?.customFields?.length>0? <tr>
                    <th style={{color:"rgb(170 127 58)" }}> Field Name </th>
                    <th style={{color:"rgb(170 127 58)" }}> Data Type </th>
                    <th style={{color:"rgb(170 127 58)" }}>Required </th>
                      <th className="text-center" style={{ width: "80px",color:"rgb(170 127 58)" }}>
                        {" "}
                        Edit{" "}
                      </th>
                      <th className="text-center" style={{ width: "95px",color:"rgb(170 127 58)" }}>
                        {" "}
                        Delete{" "}
                      </th>
                    </tr>:""
                  }
                   
                  </thead>
                  {/* used map on maintenanceData .customFields */}
                  {maintenanceData?.customFields?.map?.(
                    (items: any, index: number) => {
                      return (
                        <tbody >
                          <tr>
                            <td className="fw-bolder">
                              {items?.customFieldlabel}
                            </td>
                            <td> {items?.option} </td>
                            <td>
                              {items && items.isRequired === true
                                ? "yes"
                                : "optional"}
                            </td>
                            <td className="text-nowrap">
                              {/* Edit button */}
                              <a
                                onClick={() => {
                                  EditHandler(index);
                                  setEditMaintenanceIndex(index);
                                }}
                                data-bs-toggle="modal"
                                href="#addEditFieldBox"
                                className="btn btn-outline btn-outline-success btn-active-success btn-xs"
                              >
                                <i
                                  className="fa fa-pencil-alt fs-7"
                                  aria-hidden="true"
                                ></i>
                                Edit
                              </a>
                            </td>
                            <td className="text-nowrap">
                              {/* delete button  */}
                              <span
                                onClick={() => {
                                  setDeleteModal(true);
                                  setDeleteSelected(index);
                                }}
                                className="confirmDeleteCustomField btn btn-outline btn-outline-danger btn-active-danger btn-xs"
                              >
                                <i
                                  className="la la-trash fs-4"
                                  aria-hidden="true"
                                ></i>
                                Delete
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      );
                    }
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer right notForWizard">
          <div className="row">
            <div className="col-md-12 text-end">
              <button type="submit" className="btn btn-primary" disabled={loading}>
              {!loading && 'Submit'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              </button>
              <a href="/dashboard" className="btn btn-secondary">
                Cancel
              </a>
            </div>
          </div>
        </div>
        <input
          id="hasAssetDescription"
          name="hasAssetDescription"
          type="hidden"
          value="True"
        />
      </form>
      <form
        action="/assettiger/database/AddField?Length=8"
        className="form-horizontal"
        data-ajax="true"
        data-ajax-begin="javascript: funCustomFormSubmitBegin();"
        data-ajax-method="Post"
        data-ajax-mode="replace"
        data-ajax-success="javascript: funCustomFormSubmited();"
        data-ajax-update="#custom_ajax_wrapper"
        id="formCustomField"
        method="post"
        role="form"
      >
        <input
          name="__RequestVerificationToken"
          type="hidden"
          value="P6Drb77htOawFFsvekNgHfWBWhq60oDpCoubbGMBsqUDjl2iAaD8QBR9--EiX9PwaCFSBKs_1KJeo_cU8xCFJxl-CxmIsOWHGs6UMfZP8TvVLl9DSBSzMsLIvq24QW2RmsX0tQ2"
        />
        <div
          className="modal fade"
          id="addEditFieldBox"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable mw-md-600px">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Custom field</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                  aria-hidden="true"
                  aria-label="close"
                ></button>
              </div>
              <div className="modal-body form">
                <div className="custom-field-details">
                  <div className="form-group row">
                    <label
                      className="col-sm-4 col-form-label text-sm-end required"
                      htmlFor="AddEditCustomFieldModel_Label"
                    >
                      Custom Field Label
                    </label>
                    <div className="col-sm-7">
                      <input
                         className={`form-control input-medium ${
                          customFieldlabel === "" && formSubmitted ? "border border-danger" : customFieldlabel !== "" ? "border border-success" : "border border-black"
                        }`}
                      data-val="true"
                        data-val-length="Maximum length is 100."
                        data-val-length-max="100"
                        data-val-required="Label is required."
                        id="AddEditCustomFieldModel_Label"
                        maxLength={100}
                        name="AddEditCustomFieldModel.Label"
                        type="text"
                        value={customFieldlabel}
                        onChange={handleCustomFieldLabel}
                      />
                       {customFieldlabel === "" &&  (
                    <div className="text-danger">
                      {customFieldlabelError}
                    </div>
                  )}
                      <span
                        className="field-validation-valid"
                        data-valmsg-for="AddEditCustomFieldModel.Label"
                        data-valmsg-replace="true"
                      ></span>
                    </div>
                  </div>
                  <div className="form-group row mt-5">
                    <label
                      className="col-sm-4 col-form-label text-sm-end"
                      htmlFor="AddEditCustomFieldModel_FieldTypeId"
                    >
                      Data Type<span className="required"></span>
                    </label>
                    <div className="col-sm-7">
                      <select
                         className={`form-select input-medium valid ${
                          option === "" && formSubmitted ? "border border-danger" : option !== "" ? "border border-success" : "border border-black"
                        }`}
                        data-val="true"
                        data-val-number="The field Data Type must be a number."
                        data-val-required="Data Type is required."
                        id="AddEditCustomFieldModel_FieldTypeId"
                        name="AddEditCustomFieldModel.FieldTypeId"
                        value={option}
                        onChange={handleOptionChange}
                      >
                        <option value="">Select Data Type</option>
                        <option value="Checkbox list">Checkbox list</option>
                        <option value="Currency">Currency</option>
                        <option value="Date">Date</option>
                        <option value="Date and Time">Date and Time</option>
                        <option value="Dropdown list">Dropdown list</option>
                        <option value="Email">Email</option>
                        <option value="GPS Coordinates">GPS Coordinates</option>
                        <option value="Memo">Memo</option>
                        <option value="Numeric">Numeric</option>
                        <option value="Numeric Auto Increment">
                          Numeric Auto Increment
                        </option>
                        <option value="Radio button list">
                          Radio button list
                        </option>
                        <option value="Scanner">Scanner</option>
                        <option value="Text">Text</option>
                        <option value="URL">URL</option>
                      </select>
                      {option === "" && (
                        <div className="text-danger">{optionError}</div>
                      )}
                      <span
                        className="field-validation-valid"
                        data-valmsg-for="AddEditCustomFieldModel.FieldTypeId"
                        data-valmsg-replace="true"
                      ></span>
                    </div>
                  </div>
                  <div
                    className="form-group row align-items-center mt-5"
                    id="ValidateType"
                  >
                    <label className="col-sm-4 col-form-label text-sm-end">
                      Data Required
                    </label>
                    <div className="col-sm-7">
                      <div className="radio-inline">
                        <label className="radio mb-0 p_16">
                          <input
                            id="isValidate_True"
                            type="radio"
                            className="m_5"
                            value="yes"
                            checked={isRequired === true}
                            onChange={handleIsRequiredChange}
                          />
                          <span></span> Yes
                        </label>
                        <label className="radio mb-0">
                          <input
                            id="isValidate_False"
                            type="radio"
                            className="m_5"
                            value="optional"
                            checked={isRequired === false}
                            onChange={handleIsRequiredChange}
                          />
                          <span></span> Optional
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    className="form-group row DataInput"
                    id="StartingValue"
                    style={{ display: "none" }}
                  >
                    <label
                      className="col-sm-4 col-form-label text-sm-end"
                      htmlFor="AddEditCustomFieldModel_Counter"
                    >
                      Starting Value
                    </label>
                    <div className="col-sm-7">
                      <input
                        className="form-control i"
                        data-val="true"
                        data-val-number="The field Starting value must be a number."
                        id="AddEditCustomFieldModel_Counter"
                        maxLength={10}
                        min="0"
                        name="AddEditCustomFieldModel.Counter"
                        type="text"
                        value=""
                      />
                    </div>
                  </div>
                  {(option === "Checkbox list" ||
                    option === "Dropdown list" ||
                    option === "Radio button list") && (
                    <div className="form-group row DataInput customFieldOption ">
                      <label
                        className="col-sm-4 col-form-label text-sm-end"
                        htmlFor="AddEditCustomFieldModel_Option"
                      >
                        List Options
                      </label>
                      <div className="col-sm-8 input_fields_wrap">
                        <div className="form-inline flex-nowrap">
                          <div className="col">
                            <input
                             className={`form-control input-medium ${
                              listOptions === "" && formSubmitted ? "border border-danger" : listOptions !== "" ? "border border-success" : "border border-black"
                            }`}
                           data-val="true"
                              data-val-length="Maximum length is 1000."
                              data-val-length-max="1000"
                              data-val-required="Option is required."
                              id="AddEditCustomFieldModel_Option"
                              maxLength={1000}
                              name="AddEditCustomFieldModel.Option"
                              type="text"
                              value={listOptions}
                              onChange={handleListOptions}
                            />
                    {listOptions === ""&&  (
                    <div className="text-danger">
                      {listOptionError}
                    </div>
                  )}
                   </div>
                    <div className="col text-nowrap ps-3">
                            <a
                              onClick={addNewField}
                              href="javascript:void(0)"
                              className="btn btn-secondary btn-sm add_field_button"
                            >
                              <i
                                className="fa fa-plus fs-6"
                                aria-hidden="true"
                              ></i>
                              Add More Fields
                            </a>
                           
                          </div>
                        </div>
                        {fields.map((field, index) => (
                              <div key={field.id} className='form-inline flex-nowrap mt-3 align-items-center'>
                                <div className='col'>
                                <input
                                className='form-control input-medium border border-black'
                                  type="text"
                                  value={field.value}
                                  onChange={(e) =>
                                    handleChange(index, e.target.value)
                                  }
                                />
                                </div>
                                <div className="col text-nowrap ps-3">
                                <a className='btn btn-outline btn-outline-danger btn-active-danger btn-xs remove_field ' style={{padding:"5px 10px"}}  onClick={() => removeField(index)}>
                                <i className="fa fa-times fs-5"></i>    Remove
                                </a>
                                </div>
                              </div>
                            ))}
                        <span
                          className="field-validation-valid"
                          data-valmsg-for="AddEditCustomFieldModel.Options"
                          data-valmsg-replace="true"
                        ></span>
                        <span
                          className="field-validation-valid"
                          data-valmsg-for="AddEditCustomFieldModel.Option"
                          data-valmsg-replace="true"
                        ></span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={handleAddCustomField}
                  className="btn btn-primary"
                  // {...(customFieldlabel && option && (!listOptions || option !== "Checkbox list")
                  {...(customFieldlabel && option && (listOptions || option !== "Checkbox list") && (listOptions || option !== "Dropdown list")&& (listOptions || option !== "Radio button list")
                  ? { "data-bs-dismiss": "modal" }
                  : {})}
                   
                  value="Save"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <input
          data-val="true"
          data-val-length="Maximum length is 4000."
          data-val-length-max="4000"
          id="AddEditCustomFieldModel_Options"
          name="AddEditCustomFieldModel.Options"
          type="hidden"
          value=""
        />
        <input
          data-val="true"
          data-val-required="Is this field visible to assets of selective 'Categories'?"
          id="AddEditCustomFieldModel_hasCategory"
          name="AddEditCustomFieldModel.hasCategory"
          type="hidden"
          value="False"
        />
        <input
          data-val="true"
          data-val-required="Is this field need to be required?"
          id="AddEditCustomFieldModel_isValidate"
          name="AddEditCustomFieldModel.isValidate"
          type="hidden"
          value="True"
        />
        <input
          data-val="true"
          data-val-number="The field CustomFieldId must be a number."
          data-val-required="The CustomFieldId field is required."
          id="AddEditCustomFieldModel_CustomFieldId"
          name="AddEditCustomFieldModel.CustomFieldId"
          type="hidden"
          value="0"
        />
      </form>{" "}
      <div
        className="modal fade"
        id="confirmDeleteCustomField"
        tabIndex={-1}
        role="basic"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Delete Custom field</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-hidden="true"
                aria-label="close"
              ></button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this Custom field? </p>
              <p
                className="alert alert-danger"
                id="deleteCustomFieldObjWrapper"
              ></p>
            </div>
            <div className="modal-footer">
              <a
                className="btn btn-danger"
                id="confirmDeleteCustomFieldBtn"
                data-ref=""
              >
                Confirm Delete
              </a>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default MaintenanceList