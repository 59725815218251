import React, { useState, useEffect } from 'react'
import { PageTitle } from "../../../_metronic/layout/core"
import { useIntl } from 'react-intl'
import { KTSVG } from "../../../_metronic/helpers"
import LeaseReturnModal from "./Modal/LeaseReturnModal"
import { useAuth } from '../auth'
import axios from 'axios'
import { Field, useFormik } from 'formik'
import * as Yup from 'yup'
import { useGetAllSitesQuery } from '../services/sites'
import { useGetAllLocationsQuery } from '../services/locations'
import { useGetAllDepartmentsQuery } from '../services/departments'
import { getEmployee } from '../apps/company-setup/Database/core/_requests'
import { LeaseReturnInterface, defaultLeaseReturnDetail as initialValues } from './core/SetupModel'
import { toast } from 'react-toastify'
import { useAddLeaseReturnMutation } from '../services/leaseReturn'
import { useGetAllAssetsQuery } from 'app/modules/services/assets'
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap'
import CustomerModal from "./Modal/CustomerModal"



const accountDetailsSchema = Yup.object().shape({
  // leasingCustomer: Yup.string().required('Leasing Customer is required'),
  returnDate: Yup.date().required('Return Date is required'),
  // locationId:Yup.number().moreThan(0,"Please select Location").required("Please select Location"),
  // siteId: Yup.number().moreThan(0, "Please select site").required("Please select site"),
  // departmentId: Yup.number().moreThan(0,"Please select department").required("Please select department"),
  // assignTo:values?.assignTo,
})

const LeaseReturn = () => {
  const intl = useIntl()
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [assets, setAssets] = useState<any>([]);
  const [showLeaseReturnModal, setShowLeaseReturnModal] = useState(false)
  const [pendingAssets, setPendingAssets] = useState<any>([]);
  const [selectedAssets, setSelectedAssets] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false)
  const [personLeaseReturn, setPersonLeaseReturn] = useState(false)
  const [emailEnabled, setEmailEnabled] = useState(false)
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
  const [selected, setSelected] = useState<any>([])
  const [employeeData, setEmployeeData] = useState<any>([])
  const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
  const [customErrors, setCustomErrors] = useState({emailField:''});
  const { data: userSitesList } = useGetAllSitesQuery(currentUser?.id)
  const { data: userLocationList } = useGetAllLocationsQuery(currentUser?.id)
  const { data: userDepartmentList } = useGetAllDepartmentsQuery(currentUser?.id)
  const [addLeaseReturn, { isLoading: isAdding }] = useAddLeaseReturnMutation();
  // const { data: assetsData, isLoading, error, isSuccess } = useGetAllAssetsQuery({ userId: currentUser?.id, body: new URLSearchParams({}).toString(), page: 1, limit: 10 })
  const { data: assetsData, isLoading, error, isSuccess } = useGetAllAssetsQuery({ userId: currentUser?.id, body: new URLSearchParams({ status: "Lease" }).toString(), page: 1, limit: 10 })




  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: accountDetailsSchema,
    onSubmit: async (values) => {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      try {
        if (emailEnabled && (!values.sendEmail || !emailRegex.test(values?.sendEmail))) {
          
          if (!values.sendEmail) {
            setCustomErrors({ emailField: 'Email is required' });
            return undefined;
          } else if (!emailRegex.test(values?.sendEmail)) {
            setCustomErrors({ emailField: 'Invalid email address' });
            return undefined;
          }
          
        }else{
          setCustomErrors({emailField:''})
        handleLeaseReturnSubmit(values)
        }
      } catch (error) {
        console.error(error)
        toast.error('Something went wrong')
      }
    },
  })

  // useEffect(() => {
  //   getEmployee(currentUser?.token).then((res:any) => {
  //     setEmployeeData(res?.data?.persons);
  //   });
  // }, []);
  useEffect(() => {
    if (assetsData) {
      getAssetListing();
    }
  }, [assetsData])
  useEffect(() => {
    if (assets && pendingAssets && showLeaseReturnModal == false) {
      handlePendingList()
    }
  }, [assets, showLeaseReturnModal])
  const getAssetListing = async () => {
    setAssets(assetsData?.userAssets)
  }
  const handlePendingList = () => {
    const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id));
    console.log(data)
    setSelectedAssets(data);
    setdeleteDataArr([])

  }
  const handleLeaseReturnSubmit = async (values: LeaseReturnInterface) => {
    let data = {
      userId: currentUser?.id,
      returnDate: values?.returnDate,
      leaseReturnNotes: values?.leaseReturnNotes,
      sendEmail: values?.sendEmail,
      assetId: pendingAssets
    }
    const result = await addLeaseReturn(data).unwrap()
    if (result) {
      toast.dismiss()
      toast.success(result?.message ? result?.message : result?.data?.message)
      // navigate('/assets')
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

  }

  const handleShowLeaseReturnModal = () => {
    setShowLeaseReturnModal(true)
  }
  const handleCloseLeaseReturnModal = () => {
    setShowLeaseReturnModal(false)
  }
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setdeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setdeleteDataArr([])
    }
  }
  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected: any) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId: any) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
    setdeleteDataArr((prevSelected: any) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId: any) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }
  const deleteSelectedAssests = async () => {
    const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id));
    console.log(data)
    setSelectedAssets(data);
    setdeleteDataArr([])
    setSelectAll(false)
    setSelected([])

  }

  const handleShowCustomerModal = () => {
    setShowCustomerModal(true)
  }

  const handleCloseCustomerModal = () => {
    setShowCustomerModal(false)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Lease Return' })}</PageTitle>

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0 '>
            <h3 className='fw-bolder m-0'><span className='me-2'><i className='las la-paper-plane fs-1'></i></span> Lease Return</h3>
          </div>

        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-9'>
              <div className='pb-5'>
                {' '}
                <p className='fw-bolder m-0 mb-3'>Track the journey of each asset as it moves through your organization.</p>
                <div className='card-footer d-flex justify-content-start border-0 p-0'>
                  <button
                    type="button"
                    className='btn btn-light-primary me-3'
                    onClick={() => handleShowLeaseReturnModal()}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />

                    Select Assets
                  </button>
                  <LeaseReturnModal show={showLeaseReturnModal} setPendingAssets={setPendingAssets} selectedAssets={selectedAssets} handleClose={handleCloseLeaseReturnModal} />
                </div>
                {selectedAssets && selectedAssets?.length > 0 ?
                  <>
                    <div className="separator border-2 my-10"></div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className="mt-3">Assets Pending Lease Return</h4>
                      {deleteDataArr.length > 0 && (
                        <button
                          type='button'
                          className='btn btn-danger  mb-2'
                          onClick={() => {
                            deleteSelectedAssests()
                          }}
                        >
                          Delete Selected
                        </button>
                      )}
                    </div>
                    <div className="checkout-listing-table mb-3">
                      <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        role='table'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th colSpan={1} role='columnheader' className='w-10px pe-2'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </div>
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Asset Tag ID{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Description{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Status{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Assigned to{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Site{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Location{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                                            {' '}
                                            Lease to{' '}
                                        </th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                          {selectedAssets?.length > 0 ? (
                            selectedAssets?.map((item: any) => {
                              return (
                                <tr role='row'>
                                  <td role='cell' className=''>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                      <input
                                        type='checkbox'
                                        className='form-check-input'
                                        checked={selected.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                      />
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.assetTagId}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.description}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      {/* <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.status}</a>
                                    </div> */}
                                      <div className='d-flex flex-column'>{item.statusType === 'check_out' ? 'Checked Out' : item.statusType === "lease" ? "Leased" : item.statusType === "dispose" ? "Disposed" : item.statusType === "available" ? "Available" : item.statusType}</div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.assetstatus && item?.assetstatus?.statusType !== 'lease' ? item?.location ? item?.site?.name + "/" + item?.location?.location : item?.site?.name : item?.assetstatus?.statusType == 'lease' ? "" : ''}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.site?.name}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      {/* <div className=' overflow-hidden me-3'>
                                        <a href=''></a>
                                    </div> */}
                                      <div className='d-flex flex-column'>{item?.location?.location}</div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>{item?.statusType=='lease' && item?.assetslease? 
                                                                item?.assetslease?.customer?.fullName:''}</div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                  No records found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>Return Date</label>
                            <div className='col-lg-9 fv-row'>
                              <div className="input-group">
                                <input className="form-control form-control-solid" {...formik.getFieldProps('returnDate')} type="date" placeholder="Pick date rage" id="kt_daterangepicker_3" />
                                <span className="input-group-text border-0"><i className="fa fa-calendar"></i></span>
                              </div>
                              {formik.touched.returnDate && formik.errors.returnDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.returnDate}</div>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* <label className="required form-label">Site</label>
                          <select className="form-select mb-5" {...formik.getFieldProps('siteId')} aria-label="Select example">
                            <option>Select Site</option>
                            {userSitesList?.sites?.map((item: any, idx: string) => {
                              return (
                                <option value={item?.id}>{item?.name}</option>
                              )
                            })}
                          </select>
                          {formik.touched.siteId && formik.errors.siteId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.siteId}</div>
                            </div>
                          )} */}


                        </div>

                        <div className="col-lg-6">
                          <div className="row mb-6">
                            <div className='col-lg-3 col-form-label fw-bold fs-6'>
                              <div className="form-check form-check-custom form-check-solid form-check-sm d-flex align-center">
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox" value=""
                                  id="flexRadioLg"
                                  checked={emailEnabled}
                                  onChange={() => setEmailEnabled(!emailEnabled)}
                                />
                                <label className="form-label mb-0 fw-bold fs-6">Send Email</label>
                              </div>
                            </div>
                            <div className='col-lg-9 fv-row'>
                              <input type="email" {...formik.getFieldProps('sendEmail')} disabled={emailEnabled == true ? false : true} className="form-control flex-1 form-control-solid" placeholder="Email" />
                              {emailEnabled && customErrors?.emailField!=="" && (
                                  <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{customErrors?.emailField}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'><span className='ps-8'>Lease Notes</span></label>
                            <div className='col-lg-9 fv-row'>
                              <textarea className="form-control" {...formik.getFieldProps('leaseReturnNotes')} cols={20} rows={4} ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className=' d-flex align-items-center justify-content-start'>
                          <button
                            type='submit'
                            className='btn btn-primary me-3'
                          >
                            Lease Return
                          </button>
                          <a href="/lease-return" className='btn btn-secondary maib-btn-style'>
                            Cancel
                          </a>
                        </div>
                        {/* <div className="card-footer text-end text-md-start Step2Block" >
                        <div className="row">
                          <div className="col-md-9 col-lg-10">
                            <button id="SubmitBtn" type="submit" className="btn btn-primary me-3">Check-Out</button>
                    {/* <button onClick={() => { navigate('/apps/setup/assets') }} className="btn btn-secondary">Cancel</button> */}
                        {/* <Link to={'/assets'} className="btn btn-secondary">Cancel</Link> */}

                      </div>
                    </form>
                    {/* <button onClick={() => { navigate('/apps/setup/assets') }} className="btn btn-secondary">Cancel</button> */}
                  </>
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeaseReturn