import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../auth'
import { AssetResponse } from '../core/_models'
import { AssetsHeader } from './AssetsHeader'
import AssetsModal from './AssetsModal'
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import AssetsSearch from './AssetsSearch'
import { useDeleteAssetsMutation, useGetAllAssetsQuery } from '../../../../services/assets'
import { Link } from 'react-router-dom'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import moment from 'moment'
import "./AssetsStyle.css"
import { useGetCompanyQuery } from "app/modules/services/company"
import getSymbolFromCurrency from 'currency-symbol-map'
import { toAbsoluteUrlImage } from '../../../../../../_metronic/helpers'
// import { Placeholder } from '../../../'
import { JsonToExcel } from "react-json-to-excel";
import { exportToExcel } from 'react-json-to-excel';
import AssetsPlaceholder from '../../../../../../Images/assets-placeholder.jpg'
import {
  Grid,
  TableHeaderRow,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
  DragDropProvider,
  TableColumnReordering,
  VirtualTable,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import AssetsTagList from './AssetsTagList'

function ToggleButton(props) {
  return (
    <button onClick={props.onToggle} ref={props.buttonRef} className="btn btn-light-primary me-2 d-flex align-items-center  main-btn-style">
      <i className="la la-cog fs-xl-3 fs-4 me-1"></i> Setup Column
    </button>
  );
}

const AssetsListing = () => {

  const { currentUser } = useAuth()
  const [deleteDataArr, setdeleteDataArr] = useState<any>([])
  const [selectAllData, setSelectAllData] = useState<boolean>(false)
  const [selectedData, setSelectedData] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [check, setCheck] = useState<number[]>([])
  const [data, setData] = useState({})
  const [show, setShow] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [skip, setSkip] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>([]);
  const [selectAll, setSelectAll] = useState<any>(false);
  const [pagination, setPagination] = useState<any>({})
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [currencyLogo, setCurrencyLogo] = useState<any>();
  const [criteria, setCriteria] = useState([]);

  const [showSetupColumns, setShowSetupColumns] = useState<boolean>(false)


  const [searchAssets, setSearchAssets] = useState<any>({
    keyword: "",
    searchField: "",
    siteId: "",
    locationId: "",
    categoryId: "",
    departmentId: "",
    person: "",
    customer: "",
    status: "",
    groupBy: "",
    recordCount: "",
    dateRangeBy: "purchaseDate",
    quickDateRange: "",
    startDate: "",
    endDate: "",
    exportToExcel:false
  });
  const { data: companyData, isSuccess: company } = useGetCompanyQuery(currentUser?.id)
  const { data: assetsData, isLoading, error, isSuccess } = useGetAllAssetsQuery({ userId: currentUser?.id, body: new URLSearchParams(searchAssets).toString(), page, limit }, { skip })
  const [deleteAssets] = useDeleteAssetsMutation();
  const initialColumns =  [
    {
      name: 'selections',
      title: <input
      type='checkbox'
      className='form-check-input custom-form-check-input'
      onChange={(e) => handleSelectAll(e)}
      name={'multicheckSelect'}
    />
    ,
      getCellValue: (row) => (
        <div>
          <input
              type='checkbox'
              className='form-check-input custom-form-check-input'
              // checked={selected.includes(row.id)}
              onChange={() => {handleCheckboxChange(row.id)}}
              name={'multicheckSelect'}
            />
        </div>
      ),
    },
    { name: 'assetTagId', title: 'Asset Tag ID' },
    { name: 'description', title: 'Description' },
    { name: 'brand', title: 'Brand' },
    {
      name: 'purchaseDate',
      title: 'Purchase Date',
      getCellValue: (row) => {
        // Check if the purchaseDate is available
        if (row.purchaseDate) {
          // Format the purchase date if it's available
          return moment(row.purchaseDate).format('DD-MM-YYYY'); // Change the format as needed
        } else {
          // Return an empty string if the purchase date is not available
          return '';
        }
      },
    },
    {
      name: 'leasedTo',
      title: 'Leased To',
      getCellValue: (row) => {
        if (row?.statusType=='lease' && row?.assetslease) {
          return row.assetslease?.customer?.fullName; // Change the format as needed
        } else {
          
          return '';
        }
      },
    },
    {
      name: 'assignTo',
      title: 'Assigned To',
      getCellValue: (row) => {
    if (row?.statusType!=='lease') {
          if(row?.site){
            if(row?.site && row?.location){
              return row?.site?.name + " / " + row?.location?.location
            }
            else{
              return row?.site?.name
            }
          }
        } else {
          return '';
        }
      },
    },
    { name: 'cost', title: 'Cost' },
    {
      name: 'actions',
      title: 'Actions',
      getCellValue: (row) => (
          <div className='d-inline align-items-center'>
            <Link to={`/asset/view/${row.id}`} className='menu-link px-5 btn btn-secondary p-2 view-btn-style'>
              View
            </Link>
          </div>
      ),
    },
    {
      name: 'statusType',
      title: 'Status',
      getCellValue: (row) => (
        <div>
          <div className='d-inline align-items-center'>
          {row?.statusType === 'check_out' ? 'Checked Out' : row?.statusType === "lease" ? "Leased" : row?.statusType === "dispose" ? "Disposed" : row?.statusType === "available" ? "Available" : row?.statusType}
          </div>
        </div>
      ),
    },
    {
      name: 'image',
      title: '',
      getCellValue: (row) => (
        <div>
          <div className='d-inline align-items-center asset-img-table'>
          {row.photo ? <img src={toAbsoluteUrlImage(row.photo)} alt="Image" className='img-fluid'/> : <img src={AssetsPlaceholder} alt="dummy" className='img-fluid'/>}
          </div>
        </div>
      ),
    },
    
  ];

  const [columns, setColumns] = useState<any>(initialColumns);
  // const [columns ,setColumns] = useState<any>(
   
  // );
  const [hiddenColumnNames, setHiddenColumnNames] = useState(['image']);
  const [columnOrder, setColumnOrder] = useState([
    'selections',
    'image',
    'selection',
    'assetTagId',
    'description',
    'brand',
    'purchaseDate',
    'cost',
    'assignTo',
    'leasedTo',
    'statusType',
    'actions',
  ]);
  const [selection, setSelection] = useState<any>([]);
  const [tableColumnExtensions] = useState([
    { columnName: 'gender', width: 100 },
  ]);
  const [defaultHiddenColumnNames] = useState([]);
  useEffect(() => {
    const savedColumnVisibility = localStorage.getItem('columnVisibility');
    const savedColumnOrder = localStorage.getItem('gridColumnOrder');
    if (savedColumnVisibility) {
      const visibilityMap = JSON.parse(savedColumnVisibility);
      // Apply the saved visibility state to your columns
      const updatedColumns = initialColumns.map((column) => ({
        ...column,
        hidden: !visibilityMap[column.name],
      }));
      setColumns(updatedColumns);
      const falseKeys = Object.keys(visibilityMap).filter(key => visibilityMap[key] === false);
      
      setHiddenColumnNames(falseKeys)
    }
    if (savedColumnOrder) {
      setColumnOrder(JSON.parse(savedColumnOrder));
    }
  }, []);

  // Handle column visibility changes
  const handleColumnVisibilityChange = (hiddenColumns) => {
    // Update the columns state
    setHiddenColumnNames(hiddenColumns);
    const updatedColumns = columns.map((column) => ({
      ...column,
      hidden: hiddenColumns.includes(column.name),
    }));
    setColumns(updatedColumns);

    // Save the column visibility state in localStorage
    const visibilityMap = {};
    updatedColumns.forEach((column) => {
      visibilityMap[column.name] = !column.hidden;
    });
    localStorage.setItem('columnVisibility', JSON.stringify(visibilityMap));
  };

  const handleColumnOrderChange = (newOrder) => {
    // Update state with new column order
    setColumnOrder(newOrder);
    // Save column order to local storage
    localStorage.setItem('gridColumnOrder', JSON.stringify(newOrder));
  };
  useEffect(() => {
    let res = getSymbolFromCurrency(companyData?.company?.currencySymbol)
    setCurrencyLogo(res)
  }, [company])
  const handleSearchAssets = (e) => {
    if (e.name === "searchField" || e.name === "status") {
      const valuesArray = e.value.map(obj => obj.value);
      setSearchAssets({ ...searchAssets, searchField: valuesArray.toString() });
      setCriteria((prevCriteria:any) => ({
        ...prevCriteria,
        searchField: valuesArray.toString(), // Update the specific criteria property
      }));
    } else if (e.name === "status") {
      const valuesArray = e.value.map(obj => obj.value);
      setSearchAssets({ ...searchAssets, status: valuesArray.toString() });
      setCriteria((prevCriteria:any) => ({
        ...prevCriteria,
        status: valuesArray.toString(), // Update the specific criteria property
      }));
    } else if (e.name === "customDate") {
      let startDate = e.update[0] ? moment(e.update[0]).format("YYYY-MM-DD") : ""
      let endDate = e.update[1] ? moment(e.update[1]).format("YYYY-MM-DD") : ""
      setSearchAssets({ ...searchAssets, startDate: startDate, endDate: endDate });

      setCriteria((prevCriteria:any) => ({
        ...prevCriteria,
        startDate: startDate, // Update the specific criteria property
      }));
      setCriteria((prevCriteria:any) => ({
        ...prevCriteria,
        endDate: endDate, // Update the specific criteria property
      }));
    } else if (e.target.name === "recordCount") {
      setLimit(e.target.value);
      setCriteria((prevCriteria:any) => ({
        ...prevCriteria,
        recordCount: e.target.value, // Update the specific criteria property
      }));
    } else {
       
      setSearchAssets({ ...searchAssets, [e.target.name]: e.target.value });
      setCriteria((prevCriteria:any) => ({
        ...prevCriteria,
        [e.target.name]: e?.target?.selectedOptions? e?.target?.selectedOptions[0]?.label:e.target.value, // Update the specific criteria property
      }));
    }
  }
  const clearSearch = (keyToRemove) => {
    // Create a copy of the criteria object with the specified key removed
    const updatedCriteria = { ...criteria };
    delete updatedCriteria[keyToRemove];
  
    // Update the criteria state with the modified object
    setCriteria(updatedCriteria);
  
    // Create a copy of the searchAssets object with the specified key removed
    const updatedSearchAssets = { ...searchAssets };
    delete updatedSearchAssets[keyToRemove];
  
    // Update the searchAssets state with the modified object
    setSearchAssets(updatedSearchAssets);
    handleSearchSubmit()
  };


  const handleSearchSubmit = () => {
    setPage(1)
    setSkip(false);
    setShowSearch(false)
  }

  useEffect(() => {
    if (isSuccess) {
      setSkip(true);
    }
  }, [showSearch])

  useEffect(() => {
    setSkip(false) //set skip false becasue skip param in query hook does not load on mount
  }, [])

  useEffect(() => {
    if (assetsData?.userAssets.length > 0) {
      setPagination(assetsData?.pagination)
      setSkip(true)
    }
  }, [assetsData])

  // useEffect(() => {
  //   if(currentUser && assetsData?.userAssets.length > 0){
  //     setSkip(true)
  //   }
  // }, [page])


  const handleShow = (item: AssetResponse) => {
    setData(item)
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleCheckboxChange = (id: any) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)

      } else {
        return [...prevSelected, id]
      }
    })
    setdeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        setSelectAll(false)
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }
  const handleSelectAll = (data) => {
    if(data.target.checked){
      setSelectAllData(true);
      setSelectAll(data.target.checked);
      setSelectedData(true);
    }
    else{
      setSelectAllData(false);
      setSelectAll(data.target.checked);
      setSelectedData(false);
      setSelected([]);
      setdeleteDataArr([]);
    }
  };

 
  


  useEffect(()=>{
      if(selectAllData){
        const newSelecteds = assetsData?.userAssets?.map((item: any) => item.id) || [];
        setSelected(newSelecteds);    
        setdeleteDataArr(newSelecteds);
      }
      setSelectAllData(false)
  },[selectedData])
  useEffect(()=>{
    if (deleteDataArr && deleteDataArr.length === 0) {
      // Find all checkboxes with the name attribute set to "multicheckSelect"
      const multicheckSelectCheckboxes = document.querySelectorAll('[name="multicheckSelect"]');
    
      // Iterate through the selected checkboxes and uncheck them
      multicheckSelectCheckboxes.forEach((checkbox) => {
        if (checkbox instanceof HTMLInputElement) {
          checkbox.checked = false;
        }
      });
    }
    else if(selectAll==true){
      const multicheckSelectCheckboxes = document.querySelectorAll('[name="multicheckSelect"]');
    
      // Iterate through the selected checkboxes and uncheck them
      multicheckSelectCheckboxes.forEach((checkbox) => {
        if (checkbox instanceof HTMLInputElement) {
          checkbox.checked = true;
        }
      });
    }
    else if(selectAll==false){
      const multicheckSelectCheckbox = document.querySelector('[name="multicheckSelect"]') as HTMLInputElement;

  if (multicheckSelectCheckbox) {
    // If the checkbox exists and selected is empty, uncheck the checkbox
    multicheckSelectCheckbox.checked = false;
  }
    }
    
  },[deleteDataArr])
  // useEffect(()=>{
  //    
  //   if (selected && selected.length === 0) {
  //     // Find all checkboxes with the name attribute set to "multicheckSelect"
  //     const multicheckSelectCheckboxes = document.querySelectorAll('[name="multicheckSelect"]');
    
  //     // Iterate through the selected checkboxes and uncheck them
  //     multicheckSelectCheckboxes.forEach((checkbox) => {
  //       if (checkbox instanceof HTMLInputElement) {
  //         checkbox.checked = false;
  //       }
  //     });
  //   }
  //   else if(selectAll==true){
  //     const multicheckSelectCheckboxes = document.querySelectorAll('[name="multicheckSelect"]');
    
  //     // Iterate through the selected checkboxes and uncheck them
  //     multicheckSelectCheckboxes.forEach((checkbox) => {
  //       if (checkbox instanceof HTMLInputElement) {
  //         checkbox.checked = true;
  //       }
  //     });
  //   }
    
  // },[selected,selectAll])
  



  const deleteSelectedSites = async (id: number[]) => {
    const res = await deleteAssets({ userId: currentUser?.id, id }).unwrap()
    if (res.status === 500) {
      toast.info(res.message)
    } else {
      toast.success(res.message)
      setSkip(false)

    }
    setCheck([])
    setdeleteDataArr([])
    setSelectAll(false)
    setDeleteModal(false)
    setSelected([])
  }

  //cancle the delete modal
  const cancle = () => setDeleteModal(false)

  //confirm the delete modal
  const confirm = () => deleteSelectedSites(check)

  const handlePageChange = (currentPage: any) => {
    setPage(currentPage)
    setSkip(false);
  }

  const removeFilter = (fieldName) => {
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      [fieldName]: "",
    }));
  };
  const handleExport=()=>{
    
    setSearchAssets(prevSearchAssets => ({
      ...prevSearchAssets,
      exportToExcel: true,
    }));
    handleSearchSubmit()
    
  }
  useEffect(()=>{
    if(assetsData?.fileName && searchAssets?.exportToExcel){
      downloadAndRefresh()
    }
  },[assetsData])
  const downloadAndRefresh = () => {
    // Create a hidden link element
    const link = document.createElement('a');
    link.href = process.env.REACT_APP_PUBLIC_URL + assetsData?.fileName;
    link.target = '_blank'; // Open the link in a new tab
    link.download = 'downloaded-file-name'; // Set the desired file name

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);
    setSearchAssets(prevSearchAssets => ({
      ...prevSearchAssets,
      exportToExcel: false,
    }));
    // Refresh the page
    window.location.reload();
  };

  const currentPage = pagination?.page || 0;
  const totalRecords = pagination?.total || 0;
  const pageSize = limit || 10;
  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(currentPage * pageSize, totalRecords);
  return (
    <>
      <AssetsSearch showSearch={showSearch} setShowSearch={setShowSearch} searchAssets={searchAssets} handleSearchAssets={handleSearchAssets} handleSearchSubmit={handleSearchSubmit} />
      {/* <SetupColumns showSetupColumns={showSetupColumns} setShowSetupColumns={setShowSetupColumns} /> */}
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>

          <div id='kt_content_container' className={`container ${(showSearch || showSetupColumns) && "d-none"}`}>
            <div className='card'>
              <div className='d-inline-block mx-2'>
              </div>
              <div className='card-body'>
                <ConfirmationPopup deleteModal={deleteModal} cancle={cancle} confirm={confirm} setSkip={setSkip} />
                <AssetsHeader assetsData={assetsData} // Pass the assetsData prop
                  userAssets={assetsData?.userAssets} // Pass the userAssets prop
                  fileName={assetsData?.fileName} // Pass the userAssets prop
                  deleteDataArr={deleteDataArr}
                  setDeleteModal={setDeleteModal}
                  setCheck={setCheck}
                  setShowSearch={setShowSearch}
                  setShowSetupColumns={setShowSetupColumns} 
                  handleExport={handleExport}
                  />
                  
                <div className='mb-5'>
                  <AssetsTagList clearSearch={clearSearch} criteria={criteria} />
                </div>
                <AssetsModal show={show} handleClose={handleClose} AssetData={data} setSkip={setSkip} />
                <div className="d-md-flex align-items-center justify-content-between">
                  <div className='d-flex limit-options align-items-center'>
                    <span className="text-muted">Show</span>
                    <select name="iTakeList" id="iTakeList" className="form-select form-select-sm fw-bold mx-3 border-1"
                      value={limit}
                      onChange={(e) => { setPage(1); setLimit(parseInt(e.target.value)); setSkip(false); }}>
                      <option value="10" >10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    <span className="text-muted">entries</span>
                  </div>
                </div>
                <div className="card react-grid-card">
                <Grid
        rows={assetsData?.userAssets || []}
        columns={columns}
      >
        <VirtualTable />
        <DragDropProvider />
        <TableHeaderRow />
        {/* <TableColumnVisibility
          defaultHiddenColumnNames={[]}
          onHiddenColumnNamesChange={handleColumnVisibilityChange}
        /> */}
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={handleColumnVisibilityChange}
        />
        <Toolbar />
        <ColumnChooser toggleButtonComponent={ToggleButton} />
        {/* <TableColumnReordering
          defaultOrder={[
            'selections',
            'image',
            'selection',
            'assetTagId',
            'description',
            'brand',
            'purchaseDate',
            'cost',
            'assignTo',
            'leasedTo',
            'statusType',
            'actions',
          ]}
        /> */}
        <TableColumnReordering
        order={columnOrder}
        onOrderChange={handleColumnOrderChange}
      />
      </Grid>
                </div>
                {assetsData?.userAssets?.length ?
                  <div className='d-sm-flex align-items-center justify-content-between mt-3'>
                    <div className='mt-3'>
                      Showing {startRecord} to {endRecord} of {totalRecords} records
                    </div>
                    {pagination && (
                      <Pagination
                        className='mt-3 custom-pagination'
                        defaultCurrent={1}
                        pageSize={limit}
                        current={pagination?.page} // current active page
                        total={pagination?.total} // total pages
                        onChange={handlePageChange}
                        locale={localeInfo}
                      />
                    )}
                  </div>
                  :
                  ""
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AssetsListing