
export type Site = {
  name: string
  description?: string,
  address?: string
  aptSuite?: string
  city?: string
  state?: string
  postalCode?: string
  country?:string
  userId : number
}

export const initialSites  : Site =  {
  name:"",
  description: "",
  address: "",
  aptSuite: "",
  city: "",
  state: "",
  postalCode: "",
  country:"",
  userId:  0,
}

export type SiteResponse = {
  id: number,
  name:string
  description?: string
  address?: string
  aptSuite?: string
  city?: string
  state?: string
  postalCode?: string
  country?:string
  createdAt?: string,
  updatedAT?: string
}

