import React, { useEffect, useState } from 'react'
import { PageTitle } from "../../../_metronic/layout/core"
import { useIntl } from 'react-intl'
import { useAuth } from '../auth'
import { useGetExportCategoriesQuery,useGetExportDepartmentsQuery,useGetExportSitesQuery,useGetExportCustomersQuery,useGetExportLocationsQuery,useGetExportAssetsQuery,useGetExportMaintenancesQuery,useGetExportPersonsQuery } from '../services/exports'

function Export() {
    const intl = useIntl()
    const { currentUser } = useAuth();
    const [exportbtn, setExportbtn] = useState<boolean>(false)
    const [selectedValue, setSelectedValue] = useState<string>()
    const [skipPersons, setSkipPersons] = useState<boolean>(true)
    const [skipCategories, setSkipCategories] = useState<boolean>(true)
    const [skipCustomers, setSkipCustomers] = useState<boolean>(true)
    const [skipDepartments, setSkipDepartments] = useState<boolean>(true)
    const [skipLocations, setSkipLocations] = useState<boolean>(true)
    const [skipMaintenances, setSkipMaintenances] = useState<boolean>(true)
    const [skipAssets, setSkipAssets] = useState<boolean>(true)
    const [skipSites, setSkipSites] = useState<boolean>(true)
    const { data :exportCategories,isSuccess:successCategories } = useGetExportCategoriesQuery({ userId: currentUser?.id }, { skip:skipCategories });
    const { data : exportDepartments,isSuccess:successDepartments } = useGetExportDepartmentsQuery({ userId: currentUser?.id }, { skip:skipDepartments });
    const { data:exportSites,isSuccess:successSites } = useGetExportSitesQuery({ userId: currentUser?.id }, { skip:skipSites });
    const { data:exportCustomers,isSuccess:successCustomers } = useGetExportCustomersQuery({ userId: currentUser?.id }, { skip:skipCustomers });
    const { data:exportLocations,isSuccess:successLocations } = useGetExportLocationsQuery({ userId: currentUser?.id }, { skip:skipLocations });
    const { data:exportAssets,isSuccess:successAssets } = useGetExportAssetsQuery({ userId: currentUser?.id }, { skip:skipAssets });
    const { data:exportMaintenances,isSuccess:successMaintenances } = useGetExportMaintenancesQuery({ userId: currentUser?.id }, {skip: skipMaintenances });
    const { data:exportPersons,isSuccess:successPersons } = useGetExportPersonsQuery({ userId: currentUser?.id }, {skip: skipPersons });


    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setExportbtn(e.target.value !== "");
        setSelectedValue(e.target.value)
    }
    
    useEffect(()=>{
    if(successMaintenances){
        
        const fileName = process.env.REACT_APP_PUBLIC_URL + exportMaintenances?.fileName;
        if (exportMaintenances?.fileName) {
            downloadExcel(fileName)
        }
        setSkipMaintenances(true);
    }    
    if(successAssets){
    
        const fileName = process.env.REACT_APP_PUBLIC_URL + exportAssets?.fileName;
        if (exportAssets?.fileName) {
            downloadExcel(fileName)
        }
        setSkipAssets(true);
    }    
    if(successLocations){
         
        const fileName = process.env.REACT_APP_PUBLIC_URL + exportLocations?.fileName;
        if (exportLocations?.fileName) {
            downloadExcel(fileName)
        }
        setSkipLocations(true);
    }    
    if(successCustomers){
         
        const fileName = process.env.REACT_APP_PUBLIC_URL + exportCustomers?.fileName;
        if (exportCustomers?.fileName) {
            downloadExcel(fileName)
        }
        setSkipCustomers(true);
    }    
    if(successSites){
         
        const fileName = process.env.REACT_APP_PUBLIC_URL + exportSites?.fileName;
        if (exportSites?.fileName) {
            downloadExcel(fileName)
        }
        setSkipSites(true);
    }    
    if(successDepartments){
         
        const fileName = process.env.REACT_APP_PUBLIC_URL + exportDepartments?.fileName;
        if (exportDepartments?.fileName) {
            downloadExcel(fileName)
        }
        setSkipDepartments(true);
    }    
    if(successCategories){
         
        const fileName = process.env.REACT_APP_PUBLIC_URL + exportCategories?.fileName;
        if (exportCategories?.fileName) {
            downloadExcel(fileName)
        }
        setSkipCategories(true);
    }    
    if(successPersons){
         
        const fileName = process.env.REACT_APP_PUBLIC_URL + exportPersons?.fileName;
        if (exportPersons?.fileName) {
            downloadExcel(fileName)
        }
        setSkipPersons(true);
    }    
    },[successMaintenances,successAssets,successLocations,successCustomers,successSites,successDepartments,successCategories,successPersons])
    const downloadExcel = (fileName: any) => {
        const link = document.createElement('a');
        link.href = fileName;
        link.download = fileName;
        // Simulate a click event on the link
        link.click();
    }

    const handleSubmit = () => {
         
        if (selectedValue === 'assets') {
            setSkipAssets(false)
        } else if (selectedValue === 'categories') {
            setSkipCategories(false)
        } else if (selectedValue === 'customers') {
            setSkipCustomers(false)
        } else if (selectedValue === 'departments') {
            setSkipDepartments(false)
        } else if (selectedValue === 'locations') {
            setSkipLocations(false)
        } else if (selectedValue === 'maintenances') {
            setSkipMaintenances(false)
        } else if (selectedValue === 'person') {
            setSkipPersons(false)
        } else if (selectedValue === 'sites') {
            setSkipSites(false)
        }
    }

    return (
        <div>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Export' })}</PageTitle>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0 '>
                        <h3 className='fw-bolder m-0'><span className='me-2 align-middle'><i className='la la-cloud-upload fs-1'></i></span>Export Tables</h3>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    <div className='form'>
                        <div className='card-body border-top p-9'>
                            <div className='pb-5 mb-5'>
                                <h5>Step 1: Upload File</h5>
                            </div>
                            <div className="row mb-5">
                                <div className="col-xl-6">
                                    <div className='row mb-6'>
                                        <div className='col-lg-3 col-form-label fw-bold fs-6'>Export Table</div>
                                        <div className='col-lg-9 fv-row'>
                                            <select className="form-select" data-val="true" data-val-number="" id="" name="" onChange={(e) => handleChange(e)}>
                                                <option value="">Select</option>
                                                <option value="assets" >Assets</option>
                                                <option value="categories">Categories</option>
                                                <option value="customers">Customers</option>
                                                <option value="departments">Departments</option>
                                                <option value="locations">Locations</option>
                                                <option value="maintenances">Maintenances</option>
                                                <option value="person">Person/Employee</option>
                                                <option value="sites">Sites</option>
                                                {/* <option value="warranties">Warranties</option> */}
                                                {/* <option value="assets-linking">Assets Linking (Parent-Child)</option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {exportbtn && <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='button' className='btn btn-primary d-flex justify-content-center' onClick={() => handleSubmit()}>
                                <i className='la la-cloud-upload fs-2 me-1'></i>
                                Export
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Export