import {useState} from 'react'
import {useLocation} from 'react-router-dom'
import {resendEmail} from '../core/_requests'
import {toast} from 'react-toastify'

const VerifyEmail = () => {
  const [disableBtn, setDisabledBtn] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')

  const resendVerificationEmail = async (email: string) => {
    try {
      const response = await resendEmail(email)
      toast.success(response.data.message)
      setDisabledBtn(true)
      setTimeout(() => {
        setDisabledBtn(false)
      }, 50000)
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  return (
    <div>
      <h1>Verify Email</h1>
      <p className='m-0 d-inline'>
        Please click the link in the email we sent to{' '}
        <span className='text-nowrap fw-bolder'>{email}</span> to verify your account.
        To re-send verification email
      </p>
      {email && (
        <button
          className='mx-1 link-primary fw-bolder btn p-0 fs-5'
          onClick={() => resendVerificationEmail(email)}
          disabled={disableBtn}
        >
          Click here
        </button>
      )}
    </div>
  )
}

export default VerifyEmail
