import React, { useState } from 'react'
import Editor from './editor'

export const CustomEmail = () => {
    const [templateName, setTemplateName] = useState('checkout-email')
    const handleClickTabs = async (title) => {
        setTemplateName(title)
    }
    return (
        <div className='customize-email'>
            <div className='card'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>
                            <span className='me-2 align-middle'>
                                <i className='la la-mail-bulk fs-2x' aria-hidden='true'></i>
                            </span>{' '}
                            Customize Emails
                        </h3>
                    </div>
                </div>
                <div className='card-body border-top p-9'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <ul className='nav-tabs list-unstyled mb-5 fs-6'>
                                <li className='mb-2'>
                                    <span className='fs-6 fw-bold d-inline-block '>
                                        Check Out/Check In Emails
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className={`d-block px-2 py-1 cursor-pointer ${templateName === 'checkout-email' ? 'active' : ''}`} onClick={() => handleClickTabs("checkout-email")}>
                                        {' '}
                                        Check Out Email
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    {' '}
                                    <span className={`d-block px-2 py-1 cursor-pointer ${templateName === 'checkin-email' ? 'active' : ''}`} onClick={() => handleClickTabs("checkin-email")}>
                                        Check In Email
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    {' '}
                                    <span className={`d-block px-2 py-1 cursor-pointer ${templateName === 'verify-email-before-success' ? 'active' : ''}`} onClick={() => handleClickTabs("verify-email-before-success")}>
                                        Verify Email Before Success
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    {' '}
                                    <span className={`d-block px-2 py-1 cursor-pointer ${templateName === 'verify-email-after-success' ? 'active' : ''}`} onClick={() => handleClickTabs("verify-email-after-success")}>
                                        Verify Email After Success
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    {' '}
                                    <span className={`d-block px-2 py-1 cursor-pointer ${templateName === 'password-reset-template' ? 'active' : ''}`} onClick={() => handleClickTabs("password-reset-template")}>
                                        Password Reset Template
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    {' '}
                                    <span className={`d-block px-2 py-1 cursor-pointer ${templateName === 'change-password-template' ? 'active' : ''}`} onClick={() => handleClickTabs("change-password-template")}>
                                        Change Password Template
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className='tab_title nav-item fs-6 fw-bold d-inline-block '>
                                        Lease/Lease Return Email
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    {' '}
                                    <span className={`d-block px-2 py-1 cursor-pointer ${templateName === 'lease-email' ? 'active' : ''}`} onClick={() => handleClickTabs("lease-email")}>
                                        Lease Email
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    {' '}
                                    <span className={`d-block px-2 py-1 cursor-pointer ${templateName === 'lease-return-email' ? 'active' : ''}`} onClick={() => handleClickTabs("lease-return-email")}>
                                        Lease Return Email
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    {' '}
                                    <span className={`d-block px-2 py-1 cursor-pointer ${templateName === 'reservation-email' ? 'active' : ''}`} onClick={() => handleClickTabs("reservation-email")}>
                                        Reservation Email
                                    </span>
                                </li>
                                <li className='mb-2'>
                                    <span className='tab_title nav-item fs-6 fw-bold d-inline-block '>Setup</span>
                                </li>
                                <li className='mb-2'>
                                    {' '}
                                    <span className='d-block px-2 py-1 cursor-pointer'  onClick={() => handleClickTabs("")}>
                                        Master Template
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className='col-lg-9'>
                                <div className=''>
                                    <Editor type={templateName} />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CustomEmail
