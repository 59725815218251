import React from 'react'
import PersonEmployeeList from './components/PersonList'

const PersonEmployee = () => {
  return (
    <PersonEmployeeList/>
  )
}

export default PersonEmployee
