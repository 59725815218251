import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyEmail } from '../core/_requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { saveAuth, auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const emailToken = params.get('token')
  const socialToken = params.get('social_token')
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application

  useEffect(() => {
    if (emailToken) {
      emailVerification(emailToken)
    }
    else if (socialToken) {
      saveAuth({ token: socialToken })
      requestUser(socialToken)
    }
    else {
      if (auth && auth.token) {
        requestUser(auth.token)
      } else {
        logout()
        setShowSplashScreen(false)
      }
    }

    // eslint-disable-next-line
  }, [])

  const requestUser = async (apiToken: string) => {
    try {
      if (!didRequest.current) {
        const { data: user } = await getUserByToken(apiToken)
        if (user) {
          setCurrentUser(user.user)
        }
      }
    } catch (error) {
      console.error(error)
      if (!didRequest.current) {
        logout()
      }
    } finally {
      setShowSplashScreen(false)
    }

    return () => (didRequest.current = true)
  }

  const emailVerification = async (token: string | undefined): Promise<void> => {
    try {
      const response = await verifyEmail(token)
      // saveAuth({ token: response.data.token })
      // requestUser(response.data.token)
      if (response?.data?.success) {
        window.location.replace('/auth')
      }
    } catch (error: any) {
      if (error.response.data.status === 400) {
        if (auth?.token) {
          window.location.replace('/auth')
        }
      }
      navigate('/auth')
    }
  }

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
