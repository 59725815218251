export interface DisposeInterface {
    dateDispose:Date|string, 
    disposeTo:string,
    notes:string,
    userId:number|null, 
    assetId:number|null
}
export const defaultDisposeDetail: DisposeInterface = {
    dateDispose:new Date().toISOString().split('T')[0], 
    disposeTo:'',
    notes:'',
    userId:null, 
    assetId:null
}
