import React from 'react'
import DataAssets from './components/AssetsList'

const Assets = () => {
  return (
    <DataAssets/>
  )
}


export default Assets
