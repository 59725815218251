import React, {useState} from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../core/_requests'
import {toast} from 'react-toastify'

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

const initialValues = {
  newPassword: '',
  passwordConfirmation: '',
}

const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'Password must include at least one uppercase letter, one lowercase letter, one number, and one symbol'
    )
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'Password must include at least one uppercase letter, one lowercase letter, one number, and one symbol'
    )
    .required('Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

const ResetPassword = () => {
  const {token} = useParams() 
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordValidationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      if (token) {
        resetPassword(values.newPassword, values.passwordConfirmation, token)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
            toast.success('Password change successfuly')
            navigate('/auth')
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
          })
      } else {
        setStatus('Something went wrong')
      }
    },
  })
  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Reset Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Reset your password here</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6 required'>New Password</label>
          <input
            type='password'
            placeholder='New Password'
            autoComplete='off'
            {...formik.getFieldProps('newPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
              {
                'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
              }
            )}
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.newPassword}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='Confirm Password'
            autoComplete='off'
            {...formik.getFieldProps('passwordConfirmation')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid':
                  formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
              },
              {
                'is-valid':
                  formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
              }
            )}
          />
          {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.passwordConfirmation}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}

export default ResetPassword
