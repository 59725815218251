import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_URL = process.env.REACT_APP_API_URL;

export const emailTemplateApi = createApi({
  reducerPath: 'emailTemplateApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/email-template/` }),
  tagTypes: ['Categories'],
  endpoints: (build) => ({
    getEmailTemplate: build.mutation({
      query: (templateName) => ({
        url: `get-template`,
        method: 'POST',
        body:{"templateName":templateName},
    }), 
    }),
    updateEmailTemplate: build.mutation({
        query: ({ templateId }) => ({
            url: `update-template/${templateId}`,
            method: 'PUT',
        }),
    }),
  }),
});

export const { useGetEmailTemplateMutation, useUpdateEmailTemplateMutation } = emailTemplateApi;
