/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { googleLogin, register } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from 'react-toastify'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  confirmpassword: '',
  acceptTerms: false,
  // captchaResponse: '',
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'Only letters')
    .min(3, 'Minimum 3 letters')
    .max(50, 'Maximum 50 letters')
    .required('First name is required'),
  email: Yup.string()
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      'Invalid email address'
    )
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  lastname: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'Only letters')
    .min(3, 'Minimum 3 letters')
    .max(50, 'Maximum 50 letters')
    .required('Last name is required'),

  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Maximum 50 characters')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    )
    .required('Password is required'),

  confirmpassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the Terms and Conditions'),
  // captchaResponse: Yup.string().required('Required'),
})

export function Registration() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleCaptchaChange = (response: string | null): void => {
    if (response) {
      formik.setFieldValue('captchaResponse', response || '')
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        await register(
          values.email,
          values.firstname,
          values.lastname,
          values.password,
          // values.captchaResponse
        )
        // toast.success('User created successfully. Verify your email account.')
        toast.success('User created successfully')
        setSubmitting(false)
        setLoading(false)
        navigate(`/auth/verify-email?email=${values.email}`)
      } catch (error: any) {
        console.log(error.response)
        toast.dismiss()
        error.response.data.status === 409
          ? toast.error('User already exist')
          : toast.error('Something went wrong')

        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-7 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-2'>Create an Account</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-5'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
            Sign In
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Sign in with Google
      </button> */}
      {/* end::Action */}

      {/* <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div> */}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark required fs-6'>First name</label>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <strong> <span role='alert'>{formik.errors.firstname}</span></strong>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>
      <div className='fv-row mb-4'>
        {/* begin::Form group Lastname */}

        <label className='form-label fw-bolder text-dark required fs-6'>Last name</label>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <strong>  <span role='alert'>{formik.errors.lastname}</span></strong>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <strong>  <span role='alert'>{formik.errors.email}</span></strong>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-4' data-kt-password-meter='true'>
        <div className='mb-1 p-0'>
          <label className='form-label fw-bolder text-dark required required fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <i
              onClick={() => setShowPassword((prevState) => !prevState)}
              className={
                showPassword ? 'bi bi-eye-slash' + ' login-eye' : 'bi bi-eye' + ' login-eye'
              }
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <strong> <span role='alert'>{formik.errors.password}</span></strong>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-danger rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-warning rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark required fs-6'>Confirm Password</label>
        <div className='position-relative mb-3'>
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('confirmpassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.confirmpassword && formik.errors.confirmpassword,
              },
              {
                'is-valid': formik.touched.confirmpassword && !formik.errors.confirmpassword,
              }
            )}
          />
          <i
            onClick={() => setShowConfirmPassword((prevState) => !prevState)}
            className={
              showConfirmPassword ? 'bi bi-eye-slash' + ' login-eye' : 'bi bi-eye' + ' login-eye'
            }
          />
          {formik.touched.confirmpassword && formik.errors.confirmpassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <strong> <span role='alert'>{formik.errors.confirmpassword}</span></strong>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree with{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              Terms and Conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group ReCAPTCHA */}
      <div className='fv-row mb-7'>
        <ReCAPTCHA
          sitekey='Ldvp1snAAAAAPD0FfEDTUbSeIbs-uKfziDfEtdb'
          onChange={handleCaptchaChange}
          onExpired={() => formik.setFieldValue('captchaResponse', '')}
          onErrored={() => formik.setFieldValue('captchaResponse', '')}
        />
      </div>
      {/* end::Form group ReCAPTCHA*/}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting ||
            // !formik.isValid || 
            !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-0'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
