import React from 'react'
import CategoryListing from './components/CategoryListing'
import CategoryHeader from './components/CategoryHeader'

const Categories = () => {
  return (
    <>
      {/* <CategoryHeader /> */}
      <CategoryListing />
    </>
  )
}

export default Categories
