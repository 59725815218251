import React, { useState, useEffect } from 'react'
import { PageTitle } from "../../../_metronic/layout/core"
import { useIntl } from 'react-intl'
import { KTSVG } from "../../../_metronic/helpers"
import { useAuth } from '../../modules/auth'
import axios from 'axios'
import { Field, useFormik } from 'formik'
import * as Yup from 'yup'
import { useGetAllSitesQuery } from '../../modules/services/sites'
import { useGetAllLocationsQuery } from '../../modules/services/locations'
import { useGetAllDepartmentsQuery } from '../../modules/services/departments'
import { getEmployee } from '../../modules/apps/company-setup/Database/core/_requests'
import { CheckoutInterface, defaultCHeckoutDetail as initialValues } from './core/SetupModel'
import { toast } from 'react-toastify'
import { useAddCheckinMutation } from '../../modules/services/checkout'
import CheckinModal from "./Modal/CheckinModal"
import { useGetAllAssetsQuery } from 'app/modules/services/assets'
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap'


const accountDetailsSchema = Yup.object().shape({
  returnDate: Yup.date().required('Return date is required'),
  // dueDate:Yup.date().required('Due Date is required'),
  // locationId:Yup.number().moreThan(0,"Please select Location").required("Please select Location"),
  // siteId: Yup.number().moreThan(0, "Please select site").required("Please select site"),
  // departmentId: Yup.number().moreThan(0,"Please select department").required("Please select department"),
  // assignTo:values?.assignTo,
})

const Checkin = () => {
  const intl = useIntl()
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [assets, setAssets] = useState<any>([]);
  const [showCheckInModal, setShowCheckInModal] = useState(false)
  const [pendingAssets, setPendingAssets] = useState<any>([]);
  const [selectedAssets, setSelectedAssets] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false)
  const [personCheckout, setPersonCheckout] = useState(false)
  const [emailEnabled, setEmailEnabled] = useState(false)
  const [selected, setSelected] = useState<any>([])
  const [employeeData, setEmployeeData] = useState<any>([])
  const [customErrors, setCustomErrors] = useState({siteField:'',emailField:''});
  const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
  const { data: userSitesList } = useGetAllSitesQuery(currentUser?.id)
  const { data: userLocationList } = useGetAllLocationsQuery(currentUser?.id)
  const { data: userDepartmentList } = useGetAllDepartmentsQuery(currentUser?.id)
  const [addCheckin, { isLoading: isAdding }] = useAddCheckinMutation();
  const { data: assetsData, isLoading, error, isSuccess } = useGetAllAssetsQuery({ userId: currentUser?.id, body: new URLSearchParams({ status: "check_out" }).toString(), page: 1, limit: 10 })


  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: accountDetailsSchema,
    onSubmit: async (values) => {
       
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      try {
        if(personCheckout == false && values?.siteId===undefined){
          setCustomErrors({ siteField: 'Site is required',emailField:''});
          return undefined;
        }
        else if (emailEnabled && (!values.email || !emailRegex.test(values?.email))) {
          
          if (!values.email) {
            setCustomErrors({ siteField:"",emailField: 'Email is required' });
            return undefined;
          } else if (!emailRegex.test(values?.email)) {
            setCustomErrors({ siteField:"",emailField: 'Invalid email address' });
            return undefined;
          }
          
        }
        else{
          setCustomErrors({siteField:'',emailField:''})
          handleCheckinSubmit(values)
        }
      } catch (error) {
        console.error(error)
        toast.error('Something went wrong')
      }
    },
  })

  // useEffect(() => {
  //   getEmployee(currentUser?.token).then((res:any) => {
  //     setEmployeeData(res?.data?.persons);
  //   });
  // }, []);
  useEffect(() => {
    if (assetsData) {
      getAssetListing();
    }
  }, [assetsData])
  useEffect(() => {
    if (assets && pendingAssets && showCheckInModal == false) {
      handlePendingList()
    }
  }, [assets, showCheckInModal])
  const getAssetListing = async () => {
    setAssets(assetsData?.userAssets)
  }
  const handlePendingList = () => {
    const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id));
    console.log(data)
    setSelectedAssets(data);
    setdeleteDataArr([])

  }
  const handleCheckinSubmit = async (values: CheckoutInterface) => {
    console.log(values)
    let data = {
      returnDate: values?.returnDate,
      checkedTo: values?.checkedTo,
      userId: currentUser?.id,
      notes: values?.notes,
      locationId: values?.locationId,
      siteId: values?.siteId,
      departmentId: values?.departmentId,
      assetId: pendingAssets,
      email: values?.email
    }

    const result = await addCheckin(data).unwrap()
    if (result) {
      toast.dismiss()
      toast.success(result?.message ? result?.message : result?.data?.message)
      // navigate('/assets')
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

  }

  const handleshowCheckInModal = () => {
    setShowCheckInModal(true)
  }
  const handleCloseCheckinModal = () => {
    setShowCheckInModal(false)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setdeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setdeleteDataArr([])
    }
  }
  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected: any) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId: any) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
    setdeleteDataArr((prevSelected: any) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId: any) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }
  const deleteSelectedAssests = async () => {
    const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id));
    console.log(data)
    setSelectedAssets(data);
    setdeleteDataArr([])
    setSelectAll(false)
    setSelected([])
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Checkin' })}</PageTitle>

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'><span className='me-2'><i className='la la-user-times fs-1'></i></span> Check-In</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-9'>
              <div className='pb-5'>
                {' '}
                <p className='fw-bolder m-0 mb-3'>Track the journey of each asset as it moves through your organization.</p>
                <div className='card-footer d-flex justify-content-start border-0 p-0'>
                  <button
                    type="button"
                    className='btn btn-light-primary me-3'
                    onClick={() => handleshowCheckInModal()}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />

                    Select Assets
                  </button>
                  <CheckinModal setPendingAssets={setPendingAssets} selectedAssets={selectedAssets} show={showCheckInModal} handleClose={handleCloseCheckinModal} />
                </div>
                {selectedAssets && selectedAssets?.length > 0 ?
                  <>
                    <div className="separator border-2 my-10"></div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className="mt-3">Assets Pending Check-In</h4>
                      {deleteDataArr.length > 0 && (
                        <button
                          type='button'
                          className='btn btn-danger mb-2'
                          onClick={() => {
                            deleteSelectedAssests()
                          }}
                        >
                          Delete Selected
                        </button>
                      )}
                    </div>
                    <div className="checkout-listing-table mb-3">
                      <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        role='table'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th colSpan={1} role='columnheader' className='w-10px pe-2'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </div>
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Asset Tag ID{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Description{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Status{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Assigned to{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Site{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Location{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Lease to{' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                          {selectedAssets?.length > 0 ? (
                            selectedAssets?.map((item: any) => {
                              return (
                                <tr role='row'>
                                  <td role='cell' className=''>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                      <input
                                        type='checkbox'
                                        className='form-check-input'
                                        checked={selected.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                      />
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.assetTagId}</a>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.description}</a>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>{item.statusType === 'check_out' ? 'Checked Out' : item.statusType === "lease" ? "Leased" : item.statusType === "dispose" ? "Disposed" : item.statusType === "available" ? "Available" : item.statusType}</div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.assetstatus && item?.assetstatus?.statusType !== 'lease' ? item?.location ? item?.site?.name + "/" + item?.location?.location : item?.site?.name : item?.assetstatus?.statusType == 'lease' ? "" : ''}</a>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.site?.name}</a>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>{item?.location?.location}</div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>{item?.statusType=='lease' && item?.assetslease? 
                                                                item?.assetslease?.customer?.fullName:''}</div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                  No records found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-6">
                            <label className=" form-label fw-bold fs-6">Check-in from</label>
                            <div className="d-flex">
                              <div className="m-2 form-check form-check-custom form-check-solid form-check-sm">
                                <input className="" {...formik.getFieldProps('checkedTo')} onClick={() => { setPersonCheckout(true); setEmailEnabled(false) }} type="radio" value={'person'} checked={personCheckout == false ? false : true} name={'checkedTo'} id="person" />
                                <label className="form-check-label fw-bold fs-6" htmlFor="person" >
                                  Person
                                </label>
                              </div>
                              <div className="m-2 form-check form-check-custom form-check-solid form-check-sm">
                                <input className="" type="radio" {...formik.getFieldProps('checkedTo')} onClick={() => { setCustomErrors({siteField:'',emailField:''}); setPersonCheckout(false); setEmailEnabled(false); formik.setFieldValue('assignTo', undefined) }} value={'site'} checked={personCheckout == false ? true : false} name={'checkedTo'} id="location" />
                                <label className="form-check-label fw-bold fs-6" htmlFor="location">
                                  Site / Location
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className={personCheckout == true ? "col-lg-3 col-form-label required fw-bold fs-6" : "col-lg-3 col-form-label fw-bold fs-6"} >Return Date</label>
                            <div className='col-lg-9 fv-row'>
                              <input className="form-control form-control-solid" {...formik.getFieldProps('returnDate')} type="date" placeholder="Pick date rage" id="kt_daterangepicker_3" />
                              {personCheckout == true && formik.touched.returnDate && formik.errors.returnDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.returnDate}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className={personCheckout == true ? "col-lg-3 col-form-label fw-bold fs-6" : "col-lg-3 col-form-label fw-bold fs-6 required"}>Site</label>
                            <div className='col-lg-9 fv-row'>
                              <select className="form-select form-select-solid mb-3" {...formik.getFieldProps('siteId')} aria-label="Select example">
                                <option>Select Site</option>
                                {userSitesList?.sites?.map((item: any, idx: string) => {
                                  return (
                                    <option value={item?.id}>{item?.name}</option>
                                  )
                                })}
                              </select>
                              {personCheckout == false && formik.touched.siteId && formik.errors.siteId && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.siteId}</div>
                                </div>
                              )}
                              {customErrors?.siteField!=="" && personCheckout == false && (
                                     <div className='fv-plugins-message-container'>
                                     <div className='fv-help-block'>{customErrors?.siteField}</div>
                                   </div>

                                  )}
                            </div>
                          </div>


                          <div className="row mb-6">
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>Location</label>
                            <div className='col-lg-9 fv-row'>
                              <select className="form-select form-select-solid mb-3" {...formik.getFieldProps('locationId')} aria-label="Select example">
                                <option>Select Location</option>
                                {userLocationList?.locations?.map((item: any, idx: number) => {
                                  return (
                                    <option value={item?.id}>{item?.location}</option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>


                        </div>





                        <div className="col-lg-6">



                          <div className="row mb-6">
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>Department</label>
                            <div className='col-lg-9 fv-row'>
                              <select className="form-select form-select-solid mb-3" {...formik.getFieldProps('departmentId')} aria-label="Select example">
                                <option>Select Department</option>
                                {userDepartmentList?.department?.map((item: any, idx: number) => {
                                  return (
                                    <option value={item?.id}>{item?.department}</option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>

                          <div className='row mb-10'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>Check-in Notes</label>
                            <div className='col-lg-9 fv-row'>
                              <textarea className="form-control" {...formik.getFieldProps('notes')} cols={20} rows={4} ></textarea>
                            </div>
                          </div>
                          <div className={personCheckout == true ? "mb-10 row align-items-center" : "d-none"}>

                            <div className='col-lg-3 col-form-label fw-bold fs-6'>
                              <div className="form-check form-check-custom form-check-solid form-check-sm d-flex align-center mb-lg-0 mb-4">
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox" value=""
                                  id="flexRadioLg"
                                  checked={emailEnabled}
                                  onChange={() => setEmailEnabled(!emailEnabled)}
                                />
                                <label className="form-label mb-0 fw-bold fs-6">Send Email</label>
                              </div>
                            </div>
                            <div className='col-lg-9 fv-row'>
                              <input type="email" disabled={emailEnabled == true ? false : true} {...formik.getFieldProps('email')} className="form-control form-control-solid" placeholder="Email" />
                              {/* <input type="email" disabled={emailEnabled == true && !(selectedAssets?.some(item => item.assetstatus.checkedTo === 'site')) ? false : true} {...formik.getFieldProps('email')} className="form-control form-control-solid" placeholder="Email" /> */}
                              {emailEnabled && customErrors?.emailField!=="" && (
                                     <div className='fv-plugins-message-container'>
                                     <div className='fv-help-block'>{customErrors?.emailField}</div>
                                   </div>

                                  )}
                            </div>
                          </div>
                        </div>

                        <div className=' d-flex align-items-center justify-content-start'>
                          <button
                            type='submit'
                            className='btn btn-primary me-3 main-btn-style'
                          >
                            Check-In
                          </button>
                          <a href="/checkin" className='btn btn-secondary maib-btn-style'>
                            Cancel
                          </a>
                        </div>
                      </div>
                    </form>
                  </>
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Checkin