import React from 'react'
import WarrantiesList from './component/WarrantyList'

const WarrantyTable = () => {
  return (
    <WarrantiesList/>
  )
}

export default WarrantyTable
