import React from 'react'
import ContractList from './component/ContractList'

const ContractTable = () => {
  return (
    <ContractList/>
  )
}

export default ContractTable
