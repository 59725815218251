import {useFormik} from 'formik'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {initialLocation, Site} from '../core/_models'
import * as Yup from 'yup'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import { getListofSites } from '../core/_requests'
import {useAuth} from '../../../../auth'
import { useAddLocationMutation, useUpdateLocationMutation } from '../../../../services/locations'
import { useGetAllSitesQuery } from '../../../../services/sites'


const LocationSechma = Yup.object().shape({
  location: Yup.string().trim().required('Location is required').min(5),
  siteId: Yup.number().typeError('Site Name is required').required('Site Name is required'),
})


const LocationModal = (props: any) => {
  const {currentUser} = useAuth()
   const [sites, setSites] = useState<Site[] | undefined>();
   const [siteId, setSiteId] = useState<any>({});
   const [addLocation, {isLoading: isAdding}] = useAddLocationMutation();
   const [updateLocation, {isLoading: isUpdating}] = useUpdateLocationMutation();
  const { data : userSitesList, error } = useGetAllSitesQuery(currentUser?.id)

  useEffect(() => {
    if (currentUser) {
      formik.resetForm()
        if(userSitesList && userSitesList?.sites){
          setSites(userSitesList?.sites)
        }
    }
  }, [userSitesList])

  useEffect(() => {
    // formik.resetForm()
    if (props?.locationData?.id) {
      formik.setValues({
        location: props?.locationData?.location,
        siteId: props?.locationData?.siteId,
      })
    }
  }, [props.locationData])

  const formik = useFormik({
    initialValues: {
      ...initialLocation,
    },
    validationSchema: LocationSechma,
    onSubmit: async (values) => {
      if(currentUser){
      try{
        values.location = values?.location?.trim()
        const data = props?.locationData?.id
        ?await updateLocation({id:props.locationData.id, values}).unwrap()
        :await addLocation({data:values, userId:currentUser.id}).unwrap()
          if (data?.success) {
            toast.dismiss()
            toast.success(data?.message)
            formik.resetForm()
            props.handleClose()
          }else{
            toast.dismiss()
            toast.error(data?.message)
          }
        }
        catch(error:any){
          console.log('error', error?.message);
          toast.error('something went wrong')
        }
    }},
  })

  const handleClose = () => {
    formik.resetForm()
    props.handleClose()
  }
  const handleChangeSiteId = (e) => {
    debugger
    formik?.setFieldValue("siteId",e?.target?.value)
    // setSiteId({value:e?.target?.value,label:e?.target?.label})
    if (props?.setSiteId && props?.setSiteId !== undefined){
      props.setSiteId(e?.target?.value)
    }
  }

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} backdrop='static' keyboard={false}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>
            {props?.locationData?.siteId ? 'Update Location' : 'Add a Location'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <p className='mb-4 fs-6'>Enter the data about your new location in the fields below and we will add it to your list.</p>
            <div className='row mb-6 add-location-modal'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Site Name</label>
              <div className='col-lg-8 fv-row w-100'>
                <select className='form-select form-select-solid form-select-lg' onChange={(e) => handleChangeSiteId(e)}>
                  <option>Select any Site</option>
                  {sites?.map((item : Site, idx : number) => {
                    return (
                      <option key={idx} value={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>

                {formik.touched.siteId && formik.errors.siteId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.siteId}</div>
                  </div>
                )}
              </div>
            </div>

            <label className='required fw-bold fs-6 mb-2'>Add Location</label>
            <input placeholder='Site location' {...formik.getFieldProps('location')} type='text' name='location' className='form-control form-control-solid mb-3 mb-lg-0' autoComplete='off'/>
            {formik.touched.location && formik.errors.location && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.location}</span>
                </div>
              </div>
            )}
            <div className='text-end custom_location'>
              <Button variant='secondary' onClick={handleClose}>Close </Button>

              <button type='submit' className='add_location_btn' disabled={formik.isSubmitting}>
                {/* {(!isAdding || !isUpdating) && (
                  
                )} */}
                {(isAdding || isUpdating)? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ):(
                <span className='indicator-label'>
                  {props?.locationData?.siteId ? 'Update Location' : 'Add Location'}
                </span>
              )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LocationModal
