import React, {useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table'
import {toast} from 'react-toastify'
import {ID} from '../../../../../../_metronic/helpers'
import {useAuth} from '../../../../auth'
import {SiteResponse} from '../core/_models'
import {deleteSites, getAllSites} from '../core/_requests'
import {SitesHeader} from './SitesHeader'
import SitesModal from './SitesModal'
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import ActionIcon from '../../../../../../_metronic/assets/icons/ActionIcon'
import { useDeleteSitesMutation, useGetAllSitesQuery } from '../../../../services/sites'
import { useNavigate } from 'react-router-dom';

const SitesListing = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const userId = currentUser?.id
  const [check, setCheck] = useState<number[]>([])

  const [show, setShow] = useState<boolean>(false)
  
  const [data, setData] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const [selected, setSelected] = useState<number[]>([])
  // const [userSitesList, setUserSitesList] = useState<SiteResponse[]>([])
  const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const { data : userSitesList, error } = useGetAllSitesQuery(currentUser?.id)
  const [deleteSites] = useDeleteSitesMutation();
  const [isSitesExist, setIsSitesExist] = useState<any>(false)

  const [deleteCascade, setDeleteCascade] = useState(false);
  const handleClose = () => {
    // getSites()
    setShow(false)
  }

  const handleShow = (item: SiteResponse) => {
    setData(item)
    setShow(true)
  }

  useEffect(() => {
  checkVistingPage(userSitesList?.sites)    
  }, [userSitesList])

  // const getSites = () => {
  //   if (currentUser) {
  //     getAllSites(currentUser?.id)
  //       .then((response: any) => {
  //         setUserSitesList(response)
  //         console.log(response, 'ressssssss')
  //       })
  //       .catch((error) => {
  //         console.error('Error:', error)
  //       })
  //   }
  // }

  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
    setdeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }
  const checkVistingPage=(dataList:any)=>{
    if(isSitesExist===false)
    {if(dataList && dataList?.length>0){
      setIsSitesExist(dataList?.length)

    }
    else{
      setIsSitesExist(0)
    }
  }
  }
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = userSitesList?.sites?.map((item:any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setdeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setdeleteDataArr([])
    }
  }

  const deleteSelectedSites = async (id: number[]) => {
    const res = await deleteSites({userId, cascade:deleteCascade ? 1 : 0, id}).unwrap()
    if(res.status === 409){
      // setDeleteCascade(true)
      toast.info(res.message)
    }else{
      toast.success(res.message)
      // getSites()
    }
      setCheck([])
      setdeleteDataArr([])
      setSelectAll(false)
      setDeleteModal(false)
      setDeleteCascade(false)
  }

    //cancle the delete modal
    const cancle = () => {
      setDeleteModal(false)
      setDeleteCascade(false)
    }

    //confirm the delete modal
    const confirm = () => deleteSelectedSites(check)

  return (
    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        <div id='kt_content_container' className='container'>
          <div className='card'>
          <ConfirmationPopup deleteModal={deleteModal} cancle={cancle} confirm={confirm} deleteCascade={deleteCascade} />
            <SitesHeader deleteDataArr={deleteDataArr} setDeleteModal={setDeleteModal} setCheck={setCheck} handleShow={handleShow}/>
            <SitesModal show={show} handleClose={handleClose} SiteData={data}/>
          
            <div className='card-body py-4'>
            <p className={'text-dark'}><strong>AssetTiger</strong> allows you to enter multiple <strong>Sites</strong>. For example, the <strong>Site</strong> may be a building or address. This means that you can better track each asset that is assigned to a given <strong>Site</strong>. A detailed <strong>Site</strong> makes it easy to find and count each asset.</p>
              <div className='table-responsive'>
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed table-row-gray-300 fs-6 gy-5 dataTable no-footer'
                  role='table'
                >
                  <thead>
                    <tr className='text-start text-gray-800 border-bottom border-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                      <th colSpan={1} role='columnheader' className='w-10px pe-2'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            type='checkbox'
                            className='form-check-input'
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </div>
                      </th>
                      <th colSpan={1} role='columnheader' className='w-10%'>
                        {' '}
                        Site{' '}
                      </th>
                      <th colSpan={1} role='columnheader' className=''>
                        {' '}
                        Description{' '}
                      </th>
                      <th colSpan={1} role='columnheader' className=''>
                        {' '}
                        Address{' '}
                      </th>
                      <th colSpan={1} role='columnheader' className=''>
                        {' '}
                        Apt/Suite{' '}
                      </th>
                      <th colSpan={1} role='columnheader' className=''>
                        {' '}
                        City{' '}
                      </th>
                      <th colSpan={1} role='columnheader' className=''>
                        {' '}
                        State{' '}
                      </th>
                      <th colSpan={1} role='columnheader' className=''>
                        {' '}
                        Zip{' '}
                      </th>
                      <th colSpan={1} role='columnheader' className=''>
                        {' '}
                        country{' '}
                      </th>
                      <th colSpan={1} role='columnheader' className=''>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                    {userSitesList?.sites?.length ? (
                      userSitesList?.sites?.map((item: SiteResponse, idx: number) => {
                        return (
                          <tr key={idx} role='row'>
                            <td role='cell' className=''>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  checked={selected.includes(item.id)}
                                  onChange={() => handleCheckboxChange(item.id)}
                                />{' '}
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className=''>
                                <div className='word-br'>{item.name}</div>
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className=''>
                                <div className='d-flex flex-column'>{item.description}</div>
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className=''>
                                <div className='d-flex flex-column'>{item.address}</div>
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className=''>
                                <div className='d-flex flex-column'>{item.aptSuite}</div>
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className=''>
                                <div className='d-flex flex-column'>{item.city}</div>
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className=''>
                                <div className='d-flex flex-column'>{item.state}</div>
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className=''>
                                <div className='d-flex flex-column'>{item.postalCode}</div>
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className=''>
                                <div className='d-flex flex-column'>{item.country}</div>
                              </div>
                            </td>
                            <td role='cell' className=''>
                              <div className='dropdown'>
                                <button
                                  className='btn btn-secondary dropdown-toggle custom_action'
                                  type='button'
                                  id='dropdownMenuButton1'
                                  data-bs-toggle='dropdown'
                                  aria-expanded='false'
                                >
                                  Action
                                  <ActionIcon/>
                                </button>
                                <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                  <li onClick={() => handleShow(item)}>
                                    <span className='dropdown-item'>Edit</span>
                                  </li>
                                  <li
                                    onClick={() => {
                                      setDeleteModal(true)
                                      setCheck([item.id])
                                    }}
                                  >
                                    <a className='dropdown-item' href='#'>
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                
              </div>
            </div>
            {isSitesExist===0 ?
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button onClick={()=>{navigate(`/apps/setup/location`)}} className='btn btn-primary'>
              
              Continue
            </button>
          </div>:''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SitesListing
