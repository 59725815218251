import { useState, useEffect } from "react"
import { KTSVG } from "../../../../../../_metronic/helpers"
import SitesModal from '../../sites/components/SitesModal'
import LocationModal from "../../locations/components/LocationModal"
import CategoryModel from "../../categories/components/CategoryModel"
import DepartmentsModel from "../../departments/components/DepartmentsModel"
import { useAddAssetMutation, useUpdateAssetMutation, useGetSingleAssetQuery } from '../../../../services/assets'
import { useGetAllSitesQuery } from '../../../../services/sites'
import { useGetAllCategoriesQuery } from '../../../../services/categories'
import { useGetAllLocationsQuery, useGetAllLocationsBySiteQuery } from '../../../../services/locations'
import { useGetAllDepartmentsQuery } from '../../../../services/departments'
import { useAuth } from '../../../../auth'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { initialAssets, Asset as AssetLayout } from '../core/_models'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup'
import { PageTitle } from "_metronic/layout/core"
import { useIntl } from "react-intl"
import { toAbsoluteUrlImage } from "../../../../../../_metronic/helpers"
import Avatar from '../../../../../../_metronic/assets/images/avatar.jpg'
import { useGetCompanyQuery } from "app/modules/services/company"
import getSymbolFromCurrency from 'currency-symbol-map'
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const AssetsSechma = Yup.object().shape({
    description: Yup.string()
    .required('Description is required')
    .test('no-empty-spaces', 'Description should not be empty spaces', (value) => {
      return typeof value === 'string' && value.trim() !== ''; // Check if the value is a string and not empty spaces
    }),
  assetTagId: Yup.string()
    .required('Asset tag id is required')
    .test('no-empty-spaces', 'Asset tag id should not be empty spaces', (value) => {
      return typeof value === 'string' && value.trim() !== ''; // Check if the value is a string and not empty spaces
    }),
    cost: Yup.number()
        .nullable()
        .min(0, "Cost must be in positive value")
        .typeError("Please enter numbers only"),
      
    // purchaseDate: Yup.date().required("Purchase date is required"),
    // siteId: Yup.number().moreThan(0, "Please select site").required("Please select site"),
    // categoryId: Yup.number().moreThan(0, "Please select category").required("Please select category"),
    // departmentId: Yup.number().moreThan(0, "Please select department").required("Please select department"),
    // locationId: Yup.number().moreThan(0, "Please select location").required("Please select location"),



})




const AddAssets = () => {
    const { currentUser } = useAuth()
    const intl = useIntl()
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [skip, setSkip] = useState<boolean>(false)
    const [siteId, setSiteId] = useState<boolean>(false)
    // const [siteUpdated, setSiteUpdated] = useState<boolean>(false)
    const [isNewSiteAdded, setIsNewSiteAdded] = useState(false);
    const [isNewLocationAdded, setIsNewLocationAdded] = useState(false);
    const [isNewDepartmentAdded, setIsNewDepartmentAdded] = useState(false);
    const [isNewCategoryAdded, setIsNewCategoryAdded] = useState(false);
    const [showSiteModal, setShowSiteModal] = useState<boolean>(false)
    const [showLocationModal, setShowLocationModal] = useState<boolean>(false)
    const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false)
    const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false)
    const [fileDataURL, setFileDataURL] = useState(null);
    const [photo, setPhoto] = useState<string>('')

    const [file, setFile] = useState(null);
    const [currencyLogo, setCurrencyLogo] = useState<any>();
    const [addAsset, { isLoading: isAdding }] = useAddAssetMutation();
    
    const [updateAsset, { isLoading: isUpdating }] = useUpdateAssetMutation();
    const { data: siteData } = useGetAllSitesQuery(currentUser?.id);
    const { data: categoryData } = useGetAllCategoriesQuery({ userId: currentUser?.id, page: 1 })
    const { data: locationData, error: locationError } = useGetAllLocationsBySiteQuery({ userId: currentUser?.id, id: siteId }, { skip })
    const { data: departmentData } = useGetAllDepartmentsQuery(currentUser?.id)
    const { data: AssetData, isLoading, error } = useGetSingleAssetQuery({ assetId: id })
    const { data: companyData,isSuccess:company } = useGetCompanyQuery(currentUser?.id)
    useEffect(()=>{
        let res=getSymbolFromCurrency(companyData?.company?.currencySymbol)
        setCurrencyLogo(res)
    },[company])
    useEffect(()=>{
        if(isNewSiteAdded){
            formik.setFieldValue("siteId",siteData?.sites? siteData?.sites[0]?.id:'')
        }
        if(isNewDepartmentAdded){
            formik.setFieldValue("departmentId",departmentData?.department? departmentData?.department[0]?.id:'')
        }
        if(isNewCategoryAdded){
            formik.setFieldValue("categoryId",categoryData?.category? categoryData?.category[0]?.id:'')
        }
        if(isNewLocationAdded){
            formik.setFieldValue("locationId",locationData?.locations? locationData?.locations[locationData?.locations?.length-1]?.id:'')
            formik.setFieldValue("siteId",siteId)
        }
    },[siteData, departmentData, categoryData, locationData])
    const formik = useFormik({
        initialValues: { ...initialAssets },
        validationSchema: AssetsSechma,
        onSubmit: async (values: AssetLayout) => {
             
            // setLoading(true)
            if (currentUser) {
                const formData = new FormData()
                values.userId = currentUser?.id;
                Object.keys(values).map((key:any) => {
                    if (values[key] !== undefined && values[key] !== null) {
                      // Check if the value is a date and if it's a valid date
                      if (key === 'purchaseDate' && isNaN(Date.parse(values[key]))) {
                        // Handle the case where "purchaseDate" is empty (not a valid date)
                        // You can choose to skip it or handle it differently
                        // formData.append(key, "");
                      } else {
                        // For other non-empty values, append them to formData
                        formData.append(key, values[key]);
                      }
                    }
                    
                  });
                  


                const data = id
                    ? updateAsset({ assetId: id, formData }).unwrap()
                    : addAsset(formData).unwrap()
                await data
                    .then((res) => {
                        if (res?.status === 200) {
                            toast.dismiss()
                            toast.success(res?.message)
                            navigate('/assets')
                            console.log("update")
                            formik.resetForm()
                            //   setSkip(false)
                        }
                    })
                    .catch((error) => {
                        console.log('doest not update', error)
                        if (error.status === 409) {
                            toast.error(error.data.message)
                        } else {
                            toast.error("something went wrong")
                        }
                        formik.setSubmitting(false)
                        // setLoading(false)
                    })
            }
        },
    })
    const changeHandler = (e) => {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
            toast.error('This file format is not allowed!')
            return;
        }
        else {
            setFile(file);
        }
    }
    useEffect(() => {

        let fileReader, isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(file);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }

    }, [file]);
    useEffect(() => {
        setSkip(false) //set skip false becasue skip param in query hook does not load on mount
    }, [])
    //   useEffect(()=>{
    //     if(AssetData?.asset?.siteId && AssetData?.asset?.locationId && id){
    //         formik.setValues({
    //             locationId:AssetData?.asset?.locationId
    //         })
    //     }
    //   },[siteId])
    const handleSiteClick = (e: any) => {
        let id = e.target.value
        setSiteId(id)
        setSkip(false)
    }
    useEffect(() => {
        formik.resetForm()
        if (AssetData && AssetData?.asset?.length > 0) {
            setPhoto(AssetData?.asset?.photo)
        }
        if (id) {
            console.log("AssetData?.asset?.purchaseDate", AssetData?.asset?.purchaseDate)
            const purchaseDateFromDatabase = AssetData?.asset?.purchaseDate;
            const purchaseDate = new Date(purchaseDateFromDatabase);
            const localPurchaseDate = purchaseDate.toLocaleDateString('en-CA');
            if (AssetData?.asset?.photo && AssetData?.asset?.photo !== "") {
                setPhoto(AssetData?.asset?.photo)
            }
            if (AssetData?.asset?.siteId) {
                setSiteId(AssetData?.asset?.siteId)
            }

            formik.setValues({
                assetTagId: AssetData?.asset?.assetTagId,
                brand: AssetData?.asset?.brand,
                categoryId: AssetData?.asset?.categoryId,
                departmentId: AssetData?.asset?.departmentId,
                description: AssetData?.asset?.description,
                model: AssetData?.asset?.model,
                purchaseDate: AssetData?.asset?.purchaseDate==null?'':localPurchaseDate,
                purchasedfrom: AssetData?.asset?.purchasedfrom,
                serialNo: AssetData?.asset?.serialNo,
                siteId: AssetData?.asset?.siteId,
                locationId: AssetData?.asset?.locationId,
                userId: AssetData?.asset?.userId,
                cost: AssetData?.asset?.cost,
                statusType: AssetData?.asset?.statusType,
                photo: AssetData?.asset?.photo
            })
        }
    }, [AssetData])

    const handleCloseSiteModal = () => {
        setShowSiteModal(false)
    }

    const handleShowSiteModal = () => {
        setShowSiteModal(true)
        setIsNewSiteAdded(true);
    }

    const handleCloseLocationModal = () => {
        setShowLocationModal(false)
    }

    const handleShowLocationModal = () => {
        setShowLocationModal(true)
        setIsNewLocationAdded(true);
    }

    const handleCloseCategoryModal = () => {
        setShowCategoryModal(false)
    }

    const handleShowCategoryModal = () => {
        setShowCategoryModal(true)
        setIsNewCategoryAdded(true)
    }

    const handleCloseDepartmentModal = () => {
        setShowDepartmentModal(false)
    }

    const handleShowDepartmentModal = () => {
        setShowDepartmentModal(true)
        setIsNewDepartmentAdded(true)
    }

    return (
        <>  <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Add an Asset' })}</PageTitle>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 ' //cursor-pointer class is removed 
                    role='' // here role button is removed
                    // data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <span className="me-2 align-middle"><i className="la la-plus-circle fs-1"></i></span>
                        <h3 className='fw-bolder m-0'>Add an Asset</h3>
                    </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                    <form noValidate className='form' onSubmit={formik.handleSubmit}>
                        <div className='card-body border-top p-9'>
                            <div className='pb-5'>
                                {' '}
                                <h3 className='fw-bolder m-0 mb-3'>Asset details</h3>
                            </div>

                            {/* <div className='row mb-6'>
                                <label className='col-lg-2 col-form-label required fw-bold fs-6'>Description</label>
                                <div className='col-lg-10 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder=''
                                    />
                                </div>
                            </div> */}

                            <div className="row mb-6">
                                <div className="col-xl-6">

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6'>Description</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder=''
                                                {...formik.getFieldProps('description')}

                                            />
                                            {formik.touched.description && formik.errors.description && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.description}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6'>Asset Tag ID</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder=''
                                                {...formik.getFieldProps('assetTagId')}

                                            />
                                            {formik.touched.assetTagId && formik.errors.assetTagId && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.assetTagId}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6'>Purchase Date</label>
                                        <div className='col-lg-9 fv-row'>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className="form-control form-control-lg form-control-solid"
                                                    aria-label="Amount (to the nearest dollar)"
                                                    {...formik.getFieldProps('purchaseDate')}
                                                />
                                                <span className="input-group-text border-0"><i className="fa fa-calendar"></i></span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6'>Cost</label>
                                        <div className='col-lg-9 fv-row'>
                                            <div className="input-group currency-field">
                                                <span className="input-group-text border-0">{currencyLogo}</span>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-lg form-control-solid"
                                                    aria-label="Amount (to the nearest dollar)"
                                                    {...formik.getFieldProps('cost')}
                                                />
                                            </div>
                                            {formik.touched.cost && formik.errors.cost && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.cost}</div>
                                                </div>
                                            )}
                                        </div>

                                    </div>

                                </div>
                                <div className="col-xl-6">

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6'>Purchased From</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder=''
                                                {...formik.getFieldProps('purchasedfrom')}

                                            />
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6'>Brand</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder=''
                                                {...formik.getFieldProps('brand')}
                                            />
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6'>Model</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder=''
                                                {...formik.getFieldProps('model')}
                                            />
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label  fw-bold fs-6'>Serial No</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder=''
                                                {...formik.getFieldProps('serialNo')}

                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div className='pb-5 border-top pt-9'>
                                {' '}
                                <h3 className='fw-bolder m-0 mb-3'>Site, Location, Category and Department</h3>
                            </div>

                            <div className="row mb-6">
                                <div className="col-xl-6">

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                            <span className=''>Site</span>
                                        </label>
                                        <div className='col-lg-9 fv-row d-flex'>
                                            <select
                                                className='form-select form-select-solid form-select-lg'
                                                {...formik.getFieldProps('siteId')}
                                                onChange={(e) => {
                                                    formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                                    handleSiteClick(e);
                                                }}


                                            >
                                                <option value=''>Select Site</option>
                                                {siteData?.sites.map((item: any, idx: number) => {
                                                    return (
                                                        <option key={idx} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                            <button
                                                type='button'
                                                className='btn btn-light-primary d-flex ms-3'
                                                onClick={() => handleShowSiteModal()}
                                            >
                                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                                New
                                            </button>
                                            <SitesModal show={showSiteModal} handleClose={handleCloseSiteModal} SiteData={[]} />

                                        </div>

                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                            <span className=''>Location</span>
                                        </label>
                                        <div className='col-lg-9 fv-row d-flex'>
                                            <select
                                                className='form-select form-select-solid form-select-lg'
                                                {...formik.getFieldProps('locationId')}

                                            >
                                                <option value=''>Select Location</option>
                                                {locationData?.locations?.map((item: any, idx: number) => {
                                                    return (
                                                        <option key={idx} value={item.id}>
                                                            {item.location}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                            <button
                                                type='button'
                                                className='btn btn-light-primary d-flex ms-3'
                                                onClick={() => handleShowLocationModal()}
                                            >
                                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                                New
                                            </button>
                                            <LocationModal show={showLocationModal} setSiteId={setSiteId} handleClose={handleCloseLocationModal} locationData={[]} />
                                        </div>

                                    </div>





                                </div>
                                <div className="col-xl-6">

                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                            <span className=''>Category</span>
                                        </label>
                                        <div className='col-lg-9 fv-row d-flex'>
                                            <select
                                                className='form-select form-select-solid form-select-lg'
                                                {...formik.getFieldProps('categoryId')}

                                            >
                                                <option value=''>Select Category</option>
                                                {categoryData?.category?.map((item: any, idx: number) => {
                                                    return (
                                                        <option key={idx} value={item.id}>
                                                            {item.category}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                            <button
                                                type='button'
                                                className='btn btn-light-primary d-flex ms-3'
                                                onClick={() => handleShowCategoryModal()}
                                            >
                                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                                New
                                            </button>
                                            <CategoryModel show={showCategoryModal} handleClose={handleCloseCategoryModal} data={[]} />
                                        </div>

                                    </div>


                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                            <span className=''>Department</span>
                                        </label>
                                        <div className='col-lg-9 fv-row d-flex'>
                                            <select
                                                className='form-select form-select-solid form-select-lg'
                                                {...formik.getFieldProps('departmentId')}

                                            >
                                                <option value=''>Select Department</option>
                                                {departmentData?.department?.map((item: any, idx: number) => {
                                                    return (
                                                        <option key={idx} value={item.id}>
                                                            {item.department}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                            <button
                                                type='button'
                                                className='btn btn-light-primary d-flex ms-3'
                                                onClick={() => handleShowDepartmentModal()}
                                            >
                                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                                New
                                            </button>
                                            <DepartmentsModel show={showDepartmentModal} handleClose={handleCloseDepartmentModal} data={[]} />
                                        </div>

                                    </div>


                                </div>
                            </div>



                            {/* <div className='pb-5 border-top pt-9'>
                                {' '}
                                <h3 className='fw-bolder m-0 mb-3'>Asset Photo</h3>
                            </div>

                            <div className="choose-file">
                                <input type="file" className="btn btn-primary mb-3" />
                                <p className='fw-bolder m-0'>Only (JPG, GIF, PNG) are allowed.</p>
                            </div> */}
                            <div className='row mb-6'>
                                <div className="col-xl-6">
                                    <div className="row">
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Asset Image</label>
                                    <div className='col-lg-9'>
                                        <div className='image-input image-input-outline mb-lg-4 mb-3' data-kt-image-input='true'>
                                            <label htmlFor='photo'>
                                                <img
                                                    className='image-input-wrapper w-125px h-125px'
                                                    src={fileDataURL ? fileDataURL : photo ? toAbsoluteUrlImage(photo) : Avatar}
                                                    alt='profile'
                                                />
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    fill='none'
                                                    viewBox='0 0 24 24'
                                                    stroke-width='1.5'
                                                    stroke='currentColor'
                                                    className='w-6 h-6 custom_camera'
                                                >
                                                    <path
                                                        stroke-linecap='round'
                                                        stroke-linejoin='round'
                                                        d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
                                                    />
                                                    <path
                                                        stroke-linecap='round'
                                                        stroke-linejoin='round'
                                                        d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
                                                    />
                                                </svg>
                                            </label>
                                            <input
                                                className='form-control d-none'
                                                type='file'
                                                name='photo'
                                                id='photo'
                                                accept='.png, .jpg, .jpeg'
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    // onFileChange(e)
                                                    changeHandler(e);
                                                    formik.setFieldValue('photo', e?.target?.files && e?.target?.files[0]?.type?.match(imageMimeType) ? e.target.files![0] : photo)
                                                }}
                                            />
                                        </div>
                                        <p>Only (<b>JPG, GIF, PNG</b>) are allowed</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary main-btn-style me-2' disabled={loading}>
                                {!loading && 'Submit'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                            <a href="/assets" className="btn btn-secondary main-btn-style">
                                Cancel
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddAssets