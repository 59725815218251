import React, { useState, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { CAccount, defaultCompanyDetail as initialValues } from './core/SetupModel'
// import {updateCompanyInfo, getCompany, deleteCompany} from './core/_requests'
import { toAbsoluteUrlImage } from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from '../../../auth'
import { toast } from 'react-toastify'
import Avatar from '../../../../../_metronic/assets/images/avatar.jpg'
import { useDeleteCompanyMutation, useGetCompanyQuery, useUpdateCompanyMutation } from '../../../services/company'
import { useNavigate } from 'react-router-dom';
import timezones from 'timezones-list';
import { countries } from 'country-list-json';

const accountDetailsSchema = Yup.object().shape({
  companyName: Yup.string()
    .required('Company name is required')
    .min(8, 'Company name must be at least 8 characters')
    .max(50, 'Password must not exceed 50 characters'),
  country: Yup.string().required('Country is required'),
  address: Yup.string()
    .required('Address is required')
    .min(5, 'Address must be at least 5 characters')
    .max(50, 'Address must not exceed 50 characters'),
  city: Yup.string()
    .required('City is required')
    .min(4, 'City must be at least 4 characters')
    .max(20, 'City must not exceed 20 characters'),
  state: Yup.string()
    .required('State is required')
    .min(4, 'State must be at least 4 characters')
    .max(20, 'State must not exceed 20 characters'),
  postalCode: Yup.string()
    .required('Postal Code is required')
    .min(4, 'Postal Code must be at least 4 characters')
    .max(10, 'Postal Code must not exceed 10 characters')
    .matches(/^(?!0+$)\d{4,10}$/, 'Postal Code cannot be all zeros'),
  timeZone: Yup.string().required('Time zone is required').trim()
    .min(1, 'Time zone is required'),
  currencySymbol: Yup.string().required('Currency is required'),
  financialYearStart: Yup.string().required('Financial is required'),
  dateFormat: Yup.string().required('Date Format is required'),

})
const imageMimeType = /image\/(png|jpg|jpeg)/i;
const CompanyInfo = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate();
  const [data, setData] = useState<CAccount>(initialValues)
  const [image, setIamge] = useState<string>('')
  const [companyLogo, setCompanyLogo] = useState<string>('')
  const [isCompanyAdded, setIsCompanyAdded] = useState<boolean>(false)
  const updateData = (fieldsToUpdate: Partial<CAccount>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }
  const { data: companyData, error, isLoading } = useGetCompanyQuery(currentUser?.id)
  const [updateCompany, { isLoading: isUpdating }] = useUpdateCompanyMutation()
  const [deleteCompany] = useDeleteCompanyMutation()
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);

  const changeHandler = (e) => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      toast.error('This file format is not allowed!')
      return;
    }
    else {
      setFile(file);
    }
  }
  useEffect(() => {
    let fileReader, isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result)
        }
      }
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }

  }, [file]);
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      // setIamge(URL.createObjectURL(files[0]))
      const imageFile = files[0]; // Assuming the first file in the 'files' array is an image file.
      const blob = new Blob([imageFile], { type: imageFile.type });
      const blobURL = URL.createObjectURL(blob);
      setIamge(blobURL)


    }
  }
  const handleDelete = () => {
    if (currentUser) {
      deleteCompany(currentUser?.id).then((res: any) => {
        toast.success("Company is deleted Successfully!")
      }).catch((err: any) => {
        console.log(err)
        toast.error("Error in responce!")
      })
    }
  }


  useEffect(() => {
    if (companyData?.company?.length === 0) {
      initialValues.userId = currentUser?.id;
      formik.setValues(initialValues);
      setIsCompanyAdded(false)
    } else if (currentUser && companyData?.company) {
      if (typeof companyData?.company === 'object' && companyData?.company !== null && !(Array.isArray(companyData?.company))) {
        setIsCompanyAdded(true)
        setCompanyLogo(companyData?.company?.companyLogo)
      }
      formik.setValues({
        userId: currentUser?.id,
        companyName: companyData.company.companyName,
        address: companyData.company?.address,
        city: companyData.company?.city,
        country: companyData.company?.country,
        state: companyData.company?.state,
        postalCode: companyData.company?.postalCode,
        aptSuite: companyData.company?.aptSuite,
        timeZone: companyData.company?.timeZone,
        currencySymbol: companyData.company?.currencySymbol,
        financialYearStart: companyData.company?.financialYearStart,
        dateFormat: companyData.company?.dateFormat,
        companyLogo: companyData.company?.companyLogo,
      })
    }
  }, [companyData])


  const formik = useFormik<CAccount>({
    initialValues,
    validationSchema: accountDetailsSchema,
    onSubmit: async (values: CAccount) => {
      // setLoading(true)
      try {
        const formData = new FormData()
        for (const key of Object.keys(values)) {
          if (Object.prototype.hasOwnProperty.call(values, key)) {
            if (values) {
              const value = values[key as keyof CAccount]
              formData.append(key, value)
            }
          }
        }
        const { userId } = values;

        const data = await updateCompany({ userId: currentUser?.id, formData }).unwrap()
        if (data?.status == 200) {
          toast.success(data?.message)
          if (!isCompanyAdded) {
            navigate(`/apps/setup/sites`)
          }
        }
      } catch (error) {
        console.error(error)
        toast.error('Something went wrong')
        // setLoading(false)
      }
    },
  })
  return (
    <>

      <div className='card mb-5 mb-xl-10'>
        {/* <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'><span className='me-2 align-middle'><i className="la la-briefcase fs-2x" aria-hidden="true"></i></span> Company Information</h3>
          </div>
        </div> */}

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='pb-5'>
                {' '}
                <h3 className='fw-bolder m-0 mb-3'><span className="me-2 align-middle"><i className="la la-briefcase fs-1" aria-hidden="true"></i></span> Company Details</h3>
                <span className='fw-bolder m-0'>Provide the name and site of the main office.</span>
              </div>

              <div className='row'>
                <div className="col-lg-8 offset-lg-2 col-12">
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>Company</label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Company name'
                        {...formik.getFieldProps('companyName')}
                      />
                      {formik.touched.companyName && formik.errors.companyName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.companyName}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                      <span className='required'>Country</span>
                    </label>

                    <div className='col-lg-9 fv-row'>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('country')}
                      >
                        <option value=''>Select a Country...</option>
                        {countries?.map((country) => (
                          <option value={country?.code}>{country?.name}</option>
                        ))}
                      </select>
                      {formik.touched.country && formik.errors.country && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.country}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>Address</label>

                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Address'
                        {...formik.getFieldProps('address')}
                      />
                      {formik.touched.address && formik.errors.address && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.address}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Apt./Suite</label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Apt./Suite'
                        {...formik.getFieldProps('aptSuite')}
                      />
                      {formik.touched.aptSuite && formik.errors.aptSuite && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.aptSuite}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>City</label>

                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='City'
                        {...formik.getFieldProps('city')}
                      />
                      {formik.touched.city && formik.errors.city && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.city}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>State</label>

                    <div className='col-lg-9 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='State'
                        {...formik.getFieldProps('state')}
                      />
                      {formik.touched.state && formik.errors.state && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.state}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                      <span className='required'>Postal Code</span>
                    </label>

                    <div className='col-lg-9 fv-row'>
                      <input
                        type='tel'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Postal Code'
                        {...formik.getFieldProps('postalCode')}
                      />
                      {formik.touched.postalCode && formik.errors.postalCode && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.postalCode}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='pb-5'>
                {' '}
                <h3 className='fw-bolder m-0 mb-3'>Timezone & Currency</h3>
                <span className='fw-bolder m-0'>
                  Adjust the settings to fit your company's local timezone, currency, and date format.
                </span>
              </div>
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-12">
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>Time Zone</label>

                    <div className='col-lg-9 fv-row'>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('timeZone')}
                      >
                        <option value=''>Select a Timezone..</option>
                        {timezones?.map((time) => (
                          <option value={time?.label}>{time?.label}</option>
                        ))}
                      </select>
                      {formik.touched.timeZone && formik.errors.timeZone && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.timeZone}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      Currency Symbol
                    </label>

                    <div className='col-lg-9 fv-row'>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('currencySymbol')}
                      >
                        <option value="AFN">AFN - Afghan Afghani - ؋</option>
                        <option value="ALL">ALL - Albanian Lek - Lek</option>
                        <option value="DZD">DZD - Algerian Dinar - دج</option>
                        <option value="AOA">AOA - Angolan Kwanza - Kz</option>
                        <option value="ARS">ARS - Argentine Peso - $</option>
                        <option value="AMD">AMD - Armenian Dram - ֏</option>
                        <option value="AWG">AWG - Aruban Florin - ƒ</option>
                        <option value="AUD">AUD - Australian Dollar - $</option>
                        <option value="AZN">AZN - Azerbaijani Manat - m</option>
                        <option value="BSD">BSD - Bahamian Dollar - B$</option>
                        <option value="BHD">BHD - Bahraini Dinar - .د.ب</option>
                        <option value="BDT">BDT - Bangladeshi Taka - ৳</option>
                        <option value="BBD">BBD - Barbadian Dollar - Bds$</option>
                        <option value="BYR">BYR - Belarusian Ruble - Br</option>
                        <option value="BEF">BEF - Belgian Franc - fr</option>
                        <option value="BZD">BZD - Belize Dollar - $</option>
                        <option value="BMD">BMD - Bermudan Dollar - $</option>
                        <option value="BTN">BTN - Bhutanese Ngultrum - Nu.</option>
                        <option value="BTC">BTC - Bitcoin - ฿</option>
                        <option value="BOB">BOB - Bolivian Boliviano - Bs.</option>
                        <option value="BAM">BAM - Bosnia-Herzegovina Convertible Mark - KM</option>
                        <option value="BWP">BWP - Botswanan Pula - P</option>
                        <option value="BRL">BRL - Brazilian Real - R$</option>
                        <option value="GBP">GBP - British Pound Sterling - £</option>
                        <option value="BND">BND - Brunei Dollar - B$</option>
                        <option value="BGN">BGN - Bulgarian Lev - Лв.</option>
                        <option value="BIF">BIF - Burundian Franc - FBu</option>
                        <option value="KHR">KHR - Cambodian Riel - KHR</option>
                        <option value="CAD">CAD - Canadian Dollar - $</option>
                        <option value="CVE">CVE - Cape Verdean Escudo - $</option>
                        <option value="KYD">KYD - Cayman Islands Dollar - $</option>
                        <option value="XOF">XOF - CFA Franc BCEAO - CFA</option>
                        <option value="XAF">XAF - CFA Franc BEAC - FCFA</option>
                        <option value="XPF">XPF - CFP Franc - ₣</option>
                        <option value="CLP">CLP - Chilean Peso - $</option>
                        <option value="CNY">CNY - Chinese Yuan - ¥</option>
                        <option value="COP">COP - Colombian Peso - $</option>
                        <option value="KMF">KMF - Comorian Franc - CF</option>
                        <option value="CDF">CDF - Congolese Franc - FC</option>
                        <option value="CRC">CRC - Costa Rican ColÃ³n - ₡</option>
                        <option value="HRK">HRK - Croatian Kuna - kn</option>
                        <option value="CUC">CUC - Cuban Convertible Peso - $, CUC</option>
                        <option value="CZK">CZK - Czech Republic Koruna - Kč</option>
                        <option value="DKK">DKK - Danish Krone - Kr.</option>
                        <option value="DJF">DJF - Djiboutian Franc - Fdj</option>
                        <option value="DOP">DOP - Dominican Peso - $</option>
                        <option value="XCD">XCD - East Caribbean Dollar - $</option>
                        <option value="EGP">EGP - Egyptian Pound - ج.م</option>
                        <option value="ERN">ERN - Eritrean Nakfa - Nfk</option>
                        <option value="EEK">EEK - Estonian Kroon - kr</option>
                        <option value="ETB">ETB - Ethiopian Birr - Nkf</option>
                        <option value="EUR">EUR - Euro - €</option>
                        <option value="FKP">FKP - Falkland Islands Pound - £</option>
                        <option value="FJD">FJD - Fijian Dollar - FJ$</option>
                        <option value="GMD">GMD - Gambian Dalasi - D</option>
                        <option value="GEL">GEL - Georgian Lari - ლ</option>
                        <option value="DEM">DEM - German Mark - DM</option>
                        <option value="GHS">GHS - Ghanaian Cedi - GH₵</option>
                        <option value="GIP">GIP - Gibraltar Pound - £</option>
                        <option value="GRD">GRD - Greek Drachma - ₯, Δρχ, Δρ</option>
                        <option value="GTQ">GTQ - Guatemalan Quetzal - Q</option>
                        <option value="GNF">GNF - Guinean Franc - FG</option>
                        <option value="GYD">GYD - Guyanaese Dollar - $</option>
                        <option value="HTG">HTG - Haitian Gourde - G</option>
                        <option value="HNL">HNL - Honduran Lempira - L</option>
                        <option value="HKD">HKD - Hong Kong Dollar - $</option>
                        <option value="HUF">HUF - Hungarian Forint - Ft</option>
                        <option value="ISK">ISK - Icelandic KrÃ³na - kr</option>
                        <option value="INR">INR - Indian Rupee - ₹</option>
                        <option value="IDR">IDR - Indonesian Rupiah - Rp</option>
                        <option value="IRR">IRR - Iranian Rial - ﷼</option>
                        <option value="IQD">IQD - Iraqi Dinar - د.ع</option>
                        <option value="ILS">ILS - Israeli New Sheqel - ₪</option>
                        <option value="ITL">ITL - Italian Lira - L,£</option>
                        <option value="JMD">JMD - Jamaican Dollar - J$</option>
                        <option value="JPY">JPY - Japanese Yen - ¥</option>
                        <option value="JOD">JOD - Jordanian Dinar - ا.د</option>
                        <option value="KZT">KZT - Kazakhstani Tenge - лв</option>
                        <option value="KES">KES - Kenyan Shilling - KSh</option>
                        <option value="KWD">KWD - Kuwaiti Dinar - ك.د</option>
                        <option value="KGS">KGS - Kyrgystani Som - лв</option>
                        <option value="LAK">LAK - Laotian Kip - ₭</option>
                        <option value="LVL">LVL - Latvian Lats - Ls</option>
                        <option value="LBP">LBP - Lebanese Pound - £</option>
                        <option value="LSL">LSL - Lesotho Loti - L</option>
                        <option value="LRD">LRD - Liberian Dollar - $</option>
                        <option value="LYD">LYD - Libyan Dinar - د.ل</option>
                        <option value="LTL">LTL - Lithuanian Litas - Lt</option>
                        <option value="MOP">MOP - Macanese Pataca - $</option>
                        <option value="MKD">MKD - Macedonian Denar - ден</option>
                        <option value="MGA">MGA - Malagasy Ariary - Ar</option>
                        <option value="MWK">MWK - Malawian Kwacha - MK</option>
                        <option value="MYR">MYR - Malaysian Ringgit - RM</option>
                        <option value="MVR">MVR - Maldivian Rufiyaa - Rf</option>
                        <option value="MRO">MRO - Mauritanian Ouguiya - MRU</option>
                        <option value="MUR">MUR - Mauritian Rupee - ₨</option>
                        <option value="MXN">MXN - Mexican Peso - $</option>
                        <option value="MDL">MDL - Moldovan Leu - L</option>
                        <option value="MNT">MNT - Mongolian Tugrik - ₮</option>
                        <option value="MAD">MAD - Moroccan Dirham - MAD</option>
                        <option value="MZM">MZM - Mozambican Metical - MT</option>
                        <option value="MMK">MMK - Myanmar Kyat - K</option>
                        <option value="NAD">NAD - Namibian Dollar - $</option>
                        <option value="NPR">NPR - Nepalese Rupee - ₨</option>
                        <option value="ANG">ANG - Netherlands Antillean Guilder - ƒ</option>
                        <option value="TWD">TWD - New Taiwan Dollar - $</option>
                        <option value="NZD">NZD - New Zealand Dollar - $</option>
                        <option value="NIO">NIO - Nicaraguan CÃ³rdoba - C$</option>
                        <option value="NGN">NGN - Nigerian Naira - ₦</option>
                        <option value="KPW">KPW - North Korean Won - ₩</option>
                        <option value="NOK">NOK - Norwegian Krone - kr</option>
                        <option value="OMR">OMR - Omani Rial - .ع.ر</option>
                        <option value="PKR">PKR - Pakistani Rupee - ₨</option>
                        <option value="PAB">PAB - Panamanian Balboa - B/.</option>
                        <option value="PGK">PGK - Papua New Guinean Kina - K</option>
                        <option value="PYG">PYG - Paraguayan Guarani - ₲</option>
                        <option value="PEN">PEN - Peruvian Nuevo Sol - S/.</option>
                        <option value="PHP">PHP - Philippine Peso - ₱</option>
                        <option value="PLN">PLN - Polish Zloty - zł</option>
                        <option value="QAR">QAR - Qatari Rial - ق.ر</option>
                        <option value="RON">RON - Romanian Leu - lei</option>
                        <option value="RUB">RUB - Russian Ruble - ₽</option>
                        <option value="RWF">RWF - Rwandan Franc - FRw</option>
                        <option value="SVC">SVC - Salvadoran ColÃ³n - ₡</option>
                        <option value="WST">WST - Samoan Tala - SAT</option>
                        <option value="SAR">SAR - Saudi Riyal - ﷼</option>
                        <option value="RSD">RSD - Serbian Dinar - din</option>
                        <option value="SCR">SCR - Seychellois Rupee - SRe</option>
                        <option value="SLL">SLL - Sierra Leonean Leone - Le</option>
                        <option value="SGD">SGD - Singapore Dollar - $</option>
                        <option value="SKK">SKK - Slovak Koruna - Sk</option>
                        <option value="SBD">SBD - Solomon Islands Dollar - Si$</option>
                        <option value="SOS">SOS - Somali Shilling - Sh.so.</option>
                        <option value="ZAR">ZAR - South African Rand - R</option>
                        <option value="KRW">KRW - South Korean Won - ₩</option>
                        <option value="XDR">XDR - Special Drawing Rights - SDR</option>
                        <option value="LKR">LKR - Sri Lankan Rupee - Rs</option>
                        <option value="SHP">SHP - St. Helena Pound - £</option>
                        <option value="SDG">SDG - Sudanese Pound - .س.ج</option>
                        <option value="SRD">SRD - Surinamese Dollar - $</option>
                        <option value="SZL">SZL - Swazi Lilangeni - E</option>
                        <option value="SEK">SEK - Swedish Krona - kr</option>
                        <option value="CHF">CHF - Swiss Franc - CHf</option>
                        <option value="SYP">SYP - Syrian Pound - LS</option>
                        <option value="STD">STD - São Tomé and Príncipe Dobra - Db</option>
                        <option value="TJS">TJS - Tajikistani Somoni - SM</option>
                        <option value="TZS">TZS - Tanzanian Shilling - TSh</option>
                        <option value="THB">THB - Thai Baht - ฿</option>
                        <option value="TOP">TOP - Tongan pa'anga - $</option>
                        <option value="TTD">TTD - Trinidad & Tobago Dollar - $</option>
                        <option value="TND">TND - Tunisian Dinar - ت.د</option>
                        <option value="TRY">TRY - Turkish Lira - ₺</option>
                        <option value="TMT">TMT - Turkmenistani Manat - T</option>
                        <option value="UGX">UGX - Ugandan Shilling - USh</option>
                        <option value="UAH">UAH - Ukrainian Hryvnia - ₴</option>
                        <option value="AED">AED - United Arab Emirates Dirham - إ.د</option>
                        <option value="UYU">UYU - Uruguayan Peso - $</option>
                        <option selected value="USD">USD - US Dollar - $</option>
                        <option value="UZS">UZS - Uzbekistan Som - лв</option>
                        <option value="VUV">VUV - Vanuatu Vatu - VT</option>
                        <option value="VEF">VEF - Venezuelan BolÃ­var - Bs</option>
                        <option value="VND">VND - Vietnamese Dong - ₫</option>
                        <option value="YER">YER - Yemeni Rial - ﷼</option>
                        <option value="ZMK">ZMK - Zambian Kwacha - ZK</option>
                      </select>
                      {formik.touched.currencySymbol && formik.errors.currencySymbol && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.currencySymbol}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>Date format</label>

                    <div className='col-lg-9 fv-row'>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('dateFormat')}
                      >
                        <option value=''>Select a date format..</option>
                        <option value='USD'>MM/dd/yyyy</option>
                        <option value='GBP'>yyyy-MM-dd</option>
                        <option value='AUD'>dd/MM/yyyy</option>
                      </select>
                      {formik.touched.dateFormat && formik.errors.dateFormat && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.dateFormat}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      Financial Year begins on
                    </label>

                    <div className='col-lg-9 fv-row'>
                      <input
                        className='form-control form-control-lg form-control-solid'
                        type='date'
                        {...formik.getFieldProps('financialYearStart')}
                      />
                      {formik.touched.financialYearStart && formik.errors.financialYearStart && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.financialYearStart}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='pb-5'>
                {' '}
                <h3 className='fw-bolder m-0 mb-3'> Company Logo</h3>
                <span className='fw-bolder m-0'>
                  Upload your organization’s logo to make this space your own.
                </span>
              </div>
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-12">
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Avatar</label>
                    <div className='col-lg-9'>
                      <div className='image-input image-input-outline' data-kt-image-input='true'>
                        <label htmlFor='photo'>
                          <img
                            className='image-input-wrapper w-125px h-125px'
                            src={fileDataURL ? fileDataURL : companyLogo ? toAbsoluteUrlImage(companyLogo) : Avatar}
                            alt='profile'
                          />
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke-width='1.5'
                            stroke='currentColor'
                            className='w-6 h-6 custom_camera'
                          >
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
                            />
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
                            />
                          </svg>
                        </label>
                        <input
                          className='form-control d-none'
                          type='file'
                          name='companyLogo'
                          id='photo'
                          accept='.png, .jpg, .jpeg'
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            // onFileChange(e)
                            changeHandler(e);
                            formik.setFieldValue('companyLogo', e?.target?.files && e?.target?.files[0]?.type?.match(imageMimeType) ? e.target.files![0] : companyLogo)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={isUpdating}>
                {!isUpdating && 'Submit'}
                {isUpdating && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title fw-bolder">Delete Company and Close Account</h3>
          <div className="card-toolbar">

          </div>
        </div>
        <div className="card-body">
          <p>This operation will delete your company account, ALL user accounts and ALL asset data from AssetTiger. <strong>This operation is irrevocable.</strong></p>
          <p>
            If you want to just delete asset data, go the 'Delete Asset Data' section below.
          </p>
          <button type="button" data-bs-toggle="modal" data-bs-target="#kt_modal_1" className="btn btn-danger">Delete Company, User Accounts and ALL Data</button>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Company and Close Account</h5>
              <div
                className="btn btn-icon btn-sm btn-light ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </div>
            </div>
            <div className="modal-body">
              <p className="mb-3">Are you sure you want to delete your company, ALL user accounts and ALL asset data from AssetTiger?</p>

              <div className="form-group d-flex flex-wrap align-items-center mb-3">
                {/* <span>To make sure you really want to go ahead, please answer this: 8 + 7 = </span> */}
                {/* <input id="txt_validate_delete" name="txt_validate_delete" type="number" data-val="true" data-val-required="Value is required." className="form-control form-control-sm ms-2" style={{width:"65px"}} min="0" max="99" data-val-equalto-other="*.result_1" data-val-equalto="Sum is not correct." aria-label="validatedel"/> */}
                <span className="field-validation-valid" data-valmsg-for="txt_validate_delete" data-valmsg-replace="true"></span>
              </div>
              <p className="text-danger">This operation is irrevocable.</p>
            </div>
            <div className="modal-footer">

              <button type="button" onClick={() => handleDelete()}  data-bs-dismiss="modal" className="btn btn-danger">
                Delete Company, User Accounts and ALL Data
              </button>
              <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>



    </>

  )
}

export default CompanyInfo
