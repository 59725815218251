import React, { useEffect, useState } from 'react'
import { getAuth } from '../../../../../auth'
import { CustomFieldData, Field, updatedStandardAndCustomData } from '../../core/_models'
import { addCustomersFields, getCustomersFields } from '../../core/_requests'
import ConfirmationPopup from '../../../../../../../_metronic/helpers/components/ConfirmationPopup'
import { toast } from 'react-toastify'

const CustomersList = () => {
  const auth = getAuth()
  const [standardFields, setStandardFields] = useState<updatedStandardAndCustomData>({
    hasFullName: true,
    isFullNameKeyField: true,
    hasEmail: true,
    isEmailKeyField: false,
    isEmailRequired: true,
    hasCompany: true,
    isCompanyRequired: true,
    hasAddress: true,
    isAddressRequired: true,
    hasPhone: true,
    isPhoneRequired: true,
    hasMobilePhone: true,
    isMobilePhoneRequired: true,
    hasNotes: true,
    isNotesRequired: true,
    customFields: '',
  })
  const [editStandardFields, setEditStandardFields] = useState<updatedStandardAndCustomData>({
    hasFullName: true,
    isFullNameKeyField: true,
    hasEmail: true,
    isEmailKeyField: false,
    isEmailRequired: true,
    hasCompany: true,
    isCompanyRequired: true,
    hasAddress: true,
    isAddressRequired: true,
    hasPhone: true,
    isPhoneRequired: true,
    hasMobilePhone: true,
    isMobilePhoneRequired: true,
    hasNotes: true,
    isNotesRequired: true,
    customFields: '',
  })
  const [customFieldData, setCustomFieldData] = useState<CustomFieldData>({
    customFieldName: '',
    customFieldDataType: '',
    isRequired: false,
    listOptions: [],
  })
  const [customFieldDataArray, setCustomFieldDataArray] = useState<CustomFieldData[] | null>([])
  const [loading, setLoading] = useState(false)
  const [editIdx, setEditIdx] = useState<Number | null>()
  const [deleteIdx, setDeleteIdx] = useState<Number | null>()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [flag, setFlag] = useState<Boolean>(false)
  const [fields, setFields] = useState<{ id: number; value: string }[]>([])
  const [fieldValues, setFieldValues] = useState<string[]>([])
  const [error, setError] = useState<{ fieldName: string; dataType: string; listOption: string }>({
    fieldName: '',
    dataType: '',
    listOption: ''
  })

  useEffect(() => {
    getCustomersFields(auth?.token).then((res) => {
      setStandardFields(res)
      setEditStandardFields(res)
      Array.isArray(res.customFields) && setCustomFieldDataArray(res?.customFields)
    })
  }, [flag])

  const selectAllCheckboxes = () => {
    (standardFields.hasCompany && standardFields.hasAddress && standardFields.hasPhone && standardFields.hasMobilePhone && standardFields.hasNotes) ?
      setStandardFields({ ...standardFields, hasCompany: false, hasAddress: false, hasPhone: false, hasMobilePhone: false, hasNotes: false })
      : setStandardFields({ ...standardFields, hasCompany: true, hasAddress: true, hasPhone: true, hasMobilePhone: true, hasNotes: true })
  }

  const handleCustomFieldData = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    if (name === 'optional') {
      customFieldData.isRequired = false
      setCustomFieldData({ ...customFieldData })
    } else if (name === 'yes') {
      customFieldData.isRequired = true
      setCustomFieldData({ ...customFieldData })
    } else {
      setCustomFieldData({ ...customFieldData, [name]: value })
    }
  }

  const submitCustomField = () => {
    if (customFieldData?.customFieldName && customFieldData?.customFieldDataType && ((customFieldData?.customFieldDataType === 'Checkbox list' || customFieldData?.customFieldDataType === 'Radio button list' || customFieldData?.customFieldDataType === 'Dropdown list') ? fields[0]?.value : true)) {
      if (customFieldDataArray && typeof editIdx === 'number') {
        customFieldDataArray[editIdx] = customFieldData
      } else {
        customFieldDataArray?.push(customFieldData)
      }
      setCustomFieldDataArray([...(customFieldDataArray ?? [])])
      setEditStandardFields({
        ...editStandardFields,
        customFields: JSON.stringify(customFieldDataArray),
      })
      const updatedFields = {
        ...editStandardFields,
        customFields: JSON.stringify(customFieldDataArray),
      }
      addCustomersFields(updatedFields, auth?.token).then(() => {
        setFlag(!flag)
        toast.success("Custom field updated successfully.");
      })
      setCustomFieldData({
        customFieldName: '',
        customFieldDataType: '',
        isRequired: false,
        listOptions: [],
      })
      setError({
        fieldName: '',
        dataType: '',
        listOption: ''
      })
    } else {
      setError({
        ...error,
        fieldName: !customFieldData?.customFieldName ? 'Label is required.' : '',
        dataType: !customFieldData?.customFieldDataType ? 'Data Type is required.' : '',
        listOption: !fields[0].value ? 'Option is required.' : '',
      })
    }
  }

  const confirm = () => {
    if (typeof deleteIdx === 'number') {
      customFieldDataArray?.splice(deleteIdx, 1)
    }
    setEditStandardFields({
      ...editStandardFields,
      customFields: JSON.stringify(customFieldDataArray),
    })
    const updatedFields = {
      ...editStandardFields,
      customFields: JSON.stringify(customFieldDataArray),
    }
    addCustomersFields(updatedFields, auth?.token).then(() => {
      setFlag(!flag)
      toast.success("Custom Field deleted successfully");
    })
    setDeleteModal(false);
  }

  const submitCustomersFields = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const updatedFields = {
      ...standardFields,
      customFields: JSON.stringify(standardFields.customFields),
    }
    setLoading(true)
    addCustomersFields(updatedFields, auth?.token).then(() => {
      setFlag(!flag)
      toast.success("Database updated successfully");
      setLoading(false)
    })
      .catch((error) => {
        setLoading(false)
      });
  }

  const addCustomField = () => {
    setCustomFieldData({
      customFieldName: '',
      customFieldDataType: '',
      isRequired: false,
      listOptions: [],
    })
    setError({
      fieldName: '',
      dataType: '',
      listOption: ''
    })
    setEditIdx(null)
    setFields([{ id: Date.now(), value: '' }])
  }

  const addNewField = () => {
    const newField: Field = { id: Date.now(), value: '' }
    setFields([...fields, newField])
  }

  const removeField = (index: number) => {
    const newFields = [...fields]
    newFields.splice(index, 1)
    setFields(newFields)
    setCustomFieldData({ ...customFieldData, listOptions: newFields })
  }

  const handleChange = (index: number, value: string) => {
    const newFields = [...fields]
    newFields[index] = { ...newFields[index], value }
    setFields(newFields)
    const newFieldValues = [...fieldValues]
    newFieldValues[index] = value
    setFieldValues(newFieldValues)
    setCustomFieldData({ ...customFieldData, listOptions: newFields })
  }

  const cancle = () => {
    setDeleteModal(false);
  };

  return (
    <div className='card'>
      <ConfirmationPopup
        deleteModal={deleteModal}
        cancle={cancle}
        confirm={confirm}
      />
      <div id='database_ajax_wrapper'>
        <form>
          <input
            name='__RequestVerificationToken'
            type='hidden'
            value='bi5mAQGArgtf83vcFjyPEGK8rKsZ-9D3G-eRhsWQ-rTaCYjXK6gswp10gSiUZu3dXYXV2Zm9_cHp6yxRZbnLhFj5ZuJQOiJIAWnGosU6DIm2Zzc3Qcl40A_U_bv_VIr8fFJjrA2'
          />{' '}
          <div className='card-body'>
            <div className='form_section form_custom_border'>
              <div className="form-title d-flex">
                <span className='me-2'>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="#c30" // Set the fill color to dark red
                  >
                    <path fill="#9b9b9b" d="M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z"></path>
                    <path fill="#9b9b9b" d="M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z"></path>
                    <path fill="#9b9b9b" d="M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z"></path>
                    <path fill="#9b9b9b" d="M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z"></path>
                  </svg>
                </span>
                <h4 className='mt-1'>Customers Standard Fields</h4>

              </div>
              <div className='text_indent'>
                <p>
                  Customers are the individuals or organizations to whom you{' '}
                  <i className='la la-send' aria-hidden='true'></i> <strong>lease out</strong> your
                  equipment. Select the fields you would like to use for your customers.
                </p>
                <div className='table-responsive'>
                  <table
                    className='table table-bordered mb-6'
                    aria-label='Persons/Employees Standard Fields'
                  >
                    <thead className='thead-light'>
                      <tr>
                        <th style={{ width: '2%' }}>
                          <label className='checkbox has_nolabel m-auto' htmlFor='field_all'>
                            <input
                              type='checkbox'
                              checked={
                                standardFields?.hasCompany &&
                                standardFields?.hasAddress &&
                                standardFields?.hasPhone &&
                                standardFields?.hasMobilePhone &&
                                standardFields?.hasNotes
                              }
                              onChange={selectAllCheckboxes}
                              id='field_all'
                              value=''
                            />
                            <span></span>
                            <span className='sr-only'>Field All</span>
                          </label>
                        </th>
                        <th style={{ width: '20%' }}>Field name </th>
                        <th style={{ width: '20%' }}>Data Required </th>
                        <th>Description </th>
                        <th style={{ width: '20%' }}>Example </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label className='checkbox has_nolabel m-auto'>
                            <input
                              checked={true}
                              className='fieldDisabledCheck'
                              data-val='true'
                              data-val-required='Full Name is required.'
                              disabled={true}
                              id='hasName'
                              name='hasName'
                              type='checkbox'
                              value='true'
                            />
                            <input name='hasName' type='hidden' value='false' />
                            <span></span>
                            <span className='sr-only'>Field</span>
                          </label>
                        </td>
                        <td className='fw-bolder text-nowrap'>
                          Full Name <span className='required'></span>
                          <i
                            className='la la-key fs-4'
                            aria-hidden='true'
                            id='isCustomerEmailUnique_True_icon'
                            title='Key Field'
                            style={{ verticalAlign: '-2px', display: 'inline-block' }}
                          ></i>
                        </td>
                        <td>
                          <div className='radio-inline'>
                            <label className='radio mb-0'>
                              <input type='radio' className='m_5' checked={true} /> <span></span>Yes
                            </label>
                          </div>
                        </td>
                        <td> Full name of the customer </td>
                        <td> Jane Doe </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='checkbox has_nolabel m-auto'>
                            <input
                              checked={true}
                              className='fieldDisabledCheck'
                              data-val='true'
                              data-val-required='Email is required.'
                              disabled={true}
                              id='hasEmail'
                              name='hasEmail'
                              type='checkbox'
                              value='true'
                            />
                            <input name='hasEmail' type='hidden' value='false' />
                            <span></span>
                            <span className='sr-only'>Field</span>
                          </label>
                        </td>
                        <td className='fw-bolder text-nowrap'>
                          Email
                          <i
                            className='la la-key text-danger fs-4'
                            aria-hidden='true'
                            id='isCustomerEmailUnique_False_icon'
                            title='Key Field'
                            style={{ verticalAlign: '-2px', display: 'none' }}
                          ></i>
                        </td>
                        <td>
                          <div className='radio-inline'>
                            <label className='radio mb-0 p_16'>
                              <input
                                id='isEmailRequired_True'
                                name='isEmailRequired'
                                type='radio'
                                value='True'
                                className='m_5'
                                checked={standardFields?.isEmailRequired ? true : false}
                                onChange={() =>
                                  setStandardFields({ ...standardFields, isEmailRequired: true })
                                }
                              />{' '}
                              <span></span>Yes
                            </label>
                            <label
                              className='radio mb-0'
                              id='isEmailRequired_False_block'
                              style={{ display: 'inline-block' }}
                            >
                              <input
                                id='isEmailRequired_False'
                                name='isEmailRequired'
                                type='radio'
                                value='False'
                                className='m_5'
                                checked={!standardFields?.isEmailRequired ? true : false}
                                onChange={() =>
                                  setStandardFields({ ...standardFields, isEmailRequired: false })
                                }
                              />{' '}
                              <span></span>Optional
                            </label>
                          </div>
                        </td>
                        <td> Email of the customer </td>
                        <td> janedoe@example.com </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='checkbox has_nolabel m-auto'>
                            <input
                              checked={standardFields?.hasCompany}
                              onChange={() =>
                                setStandardFields({
                                  ...standardFields,
                                  hasCompany: !standardFields.hasCompany,
                                })
                              }
                              className='fieldCheck'
                              data-val='true'
                              data-val-required='Company is required.'
                              id='hasCompany'
                              name='hasCompany'
                              type='checkbox'
                              value='true'
                            />
                            <input name='hasCompany' type='hidden' value='false' />
                            <span></span>
                            <span className='sr-only'>Field</span>
                          </label>
                        </td>
                        <td className='fw-bolder text-nowrap'>Company</td>
                        <td>
                          {standardFields?.hasCompany && (
                            <div
                              className='radio-inline standard_radio_can_hide hasCompany_radio'
                              style={{ display: 'block' }}
                            >
                              <label className='radio mb-0 p_16'>
                                <input
                                  id='isCompanyRequired_True'
                                  checked={standardFields?.isCompanyRequired ? true : false}
                                  onChange={() =>
                                    setStandardFields({ ...standardFields, isCompanyRequired: true })
                                  }
                                  type='radio'
                                  value='True'
                                  className='m_5'
                                />{' '}
                                <span></span>Yes
                              </label>
                              <label className='radio mb-0'>
                                <input
                                  id='isCompanyRequired_False'
                                  checked={!standardFields?.isCompanyRequired ? true : false}
                                  onChange={() =>
                                    setStandardFields({ ...standardFields, isCompanyRequired: false })
                                  }
                                  type='radio'
                                  value='False'
                                  className='m_5'
                                />{' '}
                                <span></span>Optional
                              </label>
                            </div>
                          )}
                        </td>
                        <td> Customer's company name </td>
                        <td> Jane Doe Company </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='checkbox has_nolabel m-auto'>
                            <input
                              checked={standardFields?.hasAddress}
                              onChange={() =>
                                setStandardFields({
                                  ...standardFields,
                                  hasAddress: !standardFields.hasAddress,
                                })
                              }
                              className='fieldCheck'
                              data-val='true'
                              data-val-required='Address is required.'
                              id='hasAddress'
                              name='hasAddress'
                              type='checkbox'
                              value='true'
                            />
                            <input name='hasAddress' type='hidden' value='false' />
                            <span></span>
                            <span className='sr-only'>Field</span>
                          </label>
                        </td>
                        <td className='fw-bolder text-nowrap'>Address</td>
                        <td>
                          {standardFields?.hasAddress && (
                            <div
                              className='radio-inline standard_radio_can_hide hasAddress_radio'
                              style={{ display: 'block' }}
                            >
                              <label className='radio mb-0 p_16'>
                                <input
                                  id='isAddressRequired_True'
                                  checked={standardFields?.isAddressRequired ? true : false}
                                  onChange={() =>
                                    setStandardFields({ ...standardFields, isAddressRequired: true })
                                  }
                                  type='radio'
                                  value='True'
                                  className='m_5'
                                />{' '}
                                <span></span>Yes
                              </label>
                              <label className='radio mb-0'>
                                <input
                                  id='isAddressRequired_False'
                                  checked={!standardFields?.isAddressRequired ? true : false}
                                  onChange={() =>
                                    setStandardFields({ ...standardFields, isAddressRequired: false })
                                  }
                                  type='radio'
                                  value='False'
                                  className='m_5'
                                />{' '}
                                <span></span>Optional
                              </label>
                            </div>
                          )}
                        </td>
                        <td> All address fields of the customer </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='checkbox has_nolabel m-auto'>
                            <input
                              checked={standardFields?.hasPhone}
                              onChange={() =>
                                setStandardFields({
                                  ...standardFields,
                                  hasPhone: !standardFields.hasPhone,
                                })
                              }
                              className='fieldCheck'
                              data-val='true'
                              data-val-required='Phone is required.'
                              id='hasPhone'
                              name='hasPhone'
                              type='checkbox'
                              value='true'
                            />
                            <input name='hasPhone' type='hidden' value='false' />
                            <span></span>
                            <span className='sr-only'>Field</span>
                          </label>
                        </td>
                        <td className='fw-bolder text-nowrap'>Phone</td>
                        <td>
                          {standardFields?.hasPhone && (
                            <div
                              className='radio-inline standard_radio_can_hide hasPhone_radio'
                              style={{ display: 'block' }}
                            >
                              <label className='radio mb-0 p_16'>
                                <input
                                  id='isPhoneRequired_True'
                                  checked={standardFields?.isPhoneRequired ? true : false}
                                  onChange={() =>
                                    setStandardFields({ ...standardFields, isPhoneRequired: true })
                                  }
                                  type='radio'
                                  value='True'
                                  className='m_5'
                                />{' '}
                                <span></span>Yes
                              </label>
                              <label className='radio mb-0'>
                                <input
                                  id='isPhoneRequired_False'
                                  checked={!standardFields?.isPhoneRequired ? true : false}
                                  onChange={() =>
                                    setStandardFields({ ...standardFields, isPhoneRequired: false })
                                  }
                                  type='radio'
                                  value='False'
                                  className='m_5'
                                />{' '}
                                <span></span>Optional
                              </label>
                            </div>
                          )}
                        </td>
                        <td> Phone number of the customer </td>
                        <td> (555) 123-4567 </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='checkbox has_nolabel m-auto'>
                            <input
                              checked={standardFields?.hasMobilePhone}
                              onChange={() =>
                                setStandardFields({
                                  ...standardFields,
                                  hasMobilePhone: !standardFields.hasMobilePhone,
                                })
                              }
                              className='fieldCheck'
                              data-val='true'
                              data-val-required='Mobile Phone is required.'
                              id='hasCell'
                              name='hasCell'
                              type='checkbox'
                              value='true'
                            />
                            <input name='hasCell' type='hidden' value='false' />
                            <span></span>
                            <span className='sr-only'>Field</span>
                          </label>
                        </td>
                        <td className='fw-bolder text-nowrap'>Mobile Phone</td>
                        <td>
                          {standardFields?.hasMobilePhone && (
                            <div
                              className='radio-inline standard_radio_can_hide hasCell_radio'
                              style={{ display: 'block' }}
                            >
                              <label className='radio mb-0 p_16'>
                                <input
                                  id='isCellRequired_True'
                                  checked={standardFields?.isMobilePhoneRequired ? true : false}
                                  onChange={() =>
                                    setStandardFields({
                                      ...standardFields,
                                      isMobilePhoneRequired: true,
                                    })
                                  }
                                  type='radio'
                                  value='True'
                                  className='m_5'
                                />{' '}
                                <span></span>Yes
                              </label>
                              <label className='radio mb-0'>
                                <input
                                  id='isCellRequired_False'
                                  checked={!standardFields?.isMobilePhoneRequired ? true : false}
                                  onChange={() =>
                                    setStandardFields({
                                      ...standardFields,
                                      isMobilePhoneRequired: false,
                                    })
                                  }
                                  type='radio'
                                  value='False'
                                  className='m_5'
                                />{' '}
                                <span></span>Optional
                              </label>
                            </div>
                          )}
                        </td>
                        <td> Mobile Cell of the customer </td>
                        <td> (123) 456-7890 </td>
                      </tr>
                      <tr>
                        <td>
                          <label className='checkbox has_nolabel m-auto'>
                            <input
                              checked={standardFields?.hasNotes}
                              onChange={() =>
                                setStandardFields({
                                  ...standardFields,
                                  hasNotes: !standardFields.hasNotes,
                                })
                              }
                              className='fieldCheck'
                              data-val='true'
                              data-val-required='Notes is required.'
                              id='hasNotes'
                              name='hasNotes'
                              type='checkbox'
                              value='true'
                            />
                            <input name='hasNotes' type='hidden' value='false' />
                            <span></span>
                            <span className='sr-only'>Field</span>
                          </label>
                        </td>
                        <td className='fw-bolder text-nowrap'>Notes</td>
                        <td>
                          {standardFields?.hasNotes && (
                            <div
                              className='radio-inline standard_radio_can_hide hasNotes_radio'
                              style={{ display: 'block' }}
                            >
                              <label className='radio mb-0 p_16'>
                                <input
                                  id='isNotesRequired_True'
                                  checked={standardFields?.isNotesRequired ? true : false}
                                  onChange={() =>
                                    setStandardFields({ ...standardFields, isNotesRequired: true })
                                  }
                                  type='radio'
                                  value='True'
                                  className='m_5'
                                />{' '}
                                <span></span>Yes
                              </label>
                              <label className='radio mb-0'>
                                <input
                                  id='isNotesRequired_False'
                                  checked={!standardFields?.isNotesRequired ? true : false}
                                  onChange={() =>
                                    setStandardFields({ ...standardFields, isNotesRequired: false })
                                  }
                                  type='radio'
                                  value='False'
                                  className='m_5'
                                />{' '}
                                <span></span>Optional
                              </label>
                            </div>
                          )}
                        </td>
                        <td> Text area for notes </td>
                        <td> Leases equipment for 12 months. </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='form_section'>
              <div className='separator separator-solid mb-6'></div>
              <h4 className='form-title'>
                {' '}
                <i className='la la-key fs-2 me-3' aria-hidden='true'></i>Key Field (Unique Identifier)
              </h4>
              <div className='text_indent'>
                <p>
                  Select the <strong>key field</strong> that you would like to use as a unique
                  identifier. The key field should have unique values in the system.
                </p>
                <p>
                  For example, if you select '<strong>Full Name</strong>' as a key field, you cannot
                  have two persons with the name 'John Doe'. To make them unique, you may have to
                  name them 'John&nbsp;Doe' and 'John&nbsp;Doe&nbsp;2'. As an alternative, use '
                  <strong>Email</strong>' as a key field since email is always unique for each
                  person.
                </p>
                <div className='row'>
                  <div className='col-md-11 offset-md-1'>
                    <div className='radio-list custom_flex_dir'>
                      <label className='radio mb-3'>
                        <input
                          type='radio'
                          id='isPersonEmailUnique_False'
                          checked={standardFields.isFullNameKeyField}
                          onChange={() => setStandardFields({ ...standardFields, isFullNameKeyField: true, isEmailKeyField: false })}
                          value='False'
                          className='m_5'
                        />{' '}
                        <span></span>
                        <strong className='pe-1'>Full Name</strong> as a unique identifier.
                      </label>
                      <label className='radio mb-3'>
                        <input
                          type='radio'
                          id='isPersonEmailUnique_True'
                          checked={standardFields.isEmailKeyField}
                          onChange={() => setStandardFields({ ...standardFields, isFullNameKeyField: false, isEmailKeyField: true })}
                          value='True'
                          className='m_5'
                        />{' '}
                        <span></span>
                        <strong className='pe-1'>Email</strong> as a unique identifier.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='form_section' id='custom_ajax_wrapper'>
              <div className='separator separator-solid mb-6'></div>
              <div className='form-title d-flex'>
                <span className='me-2'>
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="#c30" // Set the fill color to dark red
                  >
                    <path fill="#9b9b9b" d="M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z"></path>
                    <path fill="#9b9b9b" d="M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z"></path>
                    <path fill="#9b9b9b" d="M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z"></path>
                    <path fill="#9b9b9b" d="M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z"></path>
                  </svg>
                </span>
                <h4 className='mt-1'>Customers Custom Fields</h4>
              </div>
              <div className='text_indent'>
                <p>Add custom fields to join the standard fields that we provided.</p>
                <div className='form_section'>
                  <div className='mb-4' onClick={addCustomField}>
                    {' '}
                    <a data-bs-toggle='modal' href='#addEditFieldBox' className='btn btn-success'>
                      {' '}
                      <i className='fa fa-plus' aria-hidden='true'></i> Add Custom Field{' '}
                    </a>{' '}
                  </div>

                  <table
                    className='table table-bordered table-sm vertical_middle mb-0'
                    id='custom_fields_table'
                    aria-label='Asset Custom Fields'
                  >
                    <thead className='thead-light'>
                      <tr>
                        <th> Field Name </th>
                        <th> Data Type </th>
                        <th> Required </th>
                        <th className='text-center' style={{ width: '80px' }}>
                          {' '}
                          Edit{' '}
                        </th>
                        <th className='text-center' style={{ width: '95px' }}>
                          {' '}
                          Delete{' '}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(standardFields.customFields) &&
                        standardFields.customFields.map((item, idx) => (
                          <tr key={idx}>
                            <td className='fw-bolder'>{item?.customFieldName}</td>
                            <td>{item?.customFieldDataType}</td>
                            <td>{item?.isRequired == true ? 'Yes' : 'Optional'}</td>
                            <td className='text-nowrap'>
                              <a
                                data-bs-toggle='modal'
                                onClick={() => {
                                  setEditIdx(idx)
                                  setCustomFieldData(
                                    customFieldDataArray?.length && customFieldDataArray[idx]
                                      ? customFieldDataArray[idx]
                                      : {
                                        customFieldName: '',
                                        customFieldDataType: '',
                                        isRequired: false,
                                        listOptions: [],
                                      }
                                  )
                                  setFields(
                                    customFieldDataArray?.length && customFieldDataArray[idx]
                                      ? customFieldDataArray[idx].listOptions
                                      : []
                                  )
                                }}
                                href='#addEditFieldBox'
                                className='btn btn-outline btn-outline-success btn-active-success btn-xs'
                              >
                                <i className='fa fa-pencil-alt fs-7' aria-hidden='true'></i>Edit
                              </a>
                            </td>
                            <td className='text-nowrap'>
                              <span
                                onClick={() => {
                                  setDeleteModal(true);
                                  setDeleteIdx(idx);
                                }}
                                className="confirmDeleteCustomField btn btn-outline btn-outline-danger btn-active-danger btn-xs"
                              >
                                <i className='la la-trash fs-4' aria-hidden='true'></i>Delete
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer right notForWizard'>
            <div className='row'>
              <div className='col-md-12 text-end'>
                <button
                  type='submit'
                  onClick={(e) => submitCustomersFields(e)}
                  className='btn btn-primary me-2'
                >
                  {loading ? (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : "Submit"}
                </button>
                <a href='/dashboard' className='btn btn-secondary'>
                  Cancel
                </a>
              </div>
            </div>
          </div>
          <input id='hasAssetDescription' name='hasAssetDescription' type='hidden' value='True' />
        </form>
        <form
          action='/assettiger/database/AddField?Length=8'
          className='form-horizontal'
          data-ajax='true'
          data-ajax-begin='javascript: funCustomFormSubmitBegin();'
          data-ajax-method='Post'
          data-ajax-mode='replace'
          data-ajax-success='javascript: funCustomFormSubmited();'
          data-ajax-update='#custom_ajax_wrapper'
          id='formCustomField'
          method='post'
          role='form'
        >
          <input
            name='__RequestVerificationToken'
            type='hidden'
            value='P6Drb77htOawFFsvekNgHfWBWhq60oDpCoubbGMBsqUDjl2iAaD8QBR9--EiX9PwaCFSBKs_1KJeo_cU8xCFJxl-CxmIsOWHGs6UMfZP8TvVLl9DSBSzMsLIvq24QW2RmsX0tQ2'
          />
          <div
            className='modal fade'
            id='addEditFieldBox'
            tabIndex={-1}
            role='dialog'
            aria-hidden='true'
          >
            <div className='modal-dialog modal-dialog-scrollable mw-md-600px'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Add Custom field</h4>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-hidden='true'
                    aria-label='close'
                  ></button>
                </div>
                <div className='modal-body form'>
                  <div className='custom-field-details'>
                    <div className='form-group row'>
                      <label
                        className='col-sm-4 col-form-label text-sm-end required'
                        htmlFor='AddEditCustomFieldModel_Label'
                      >
                        Custom Field Label
                      </label>
                      <div className='col-sm-7'>
                        <input
                          className='form-control input-medium'
                          onChange={handleCustomFieldData}
                          data-val='true'
                          data-val-length='Maximum length is 100.'
                          data-val-length-max='100'
                          data-val-required='Label is required.'
                          id='AddEditCustomFieldModel_Label'
                          maxLength={100}
                          name='customFieldName'
                          type='text'
                          value={customFieldData?.customFieldName}
                        />
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='AddEditCustomFieldModel.Label'
                          data-valmsg-replace='true'
                        ></span>
                        <div className='text-danger mt-1'>{error.fieldName}</div>
                      </div>
                    </div>
                    <div className='form-group row mt-5'>
                      <label
                        className='col-sm-4 col-form-label text-sm-end'
                        htmlFor='AddEditCustomFieldModel_FieldTypeId'
                      >
                        Data Type<span className='required'></span>
                      </label>
                      <div className='col-sm-7'>
                        <select
                          className='form-select input-medium valid'
                          value={customFieldData.customFieldDataType}
                          onChange={handleCustomFieldData}
                          data-val='true'
                          data-val-number='The field Data Type must be a number.'
                          data-val-required='Data Type is required.'
                          id='AddEditCustomFieldModel_FieldTypeId'
                          name='customFieldDataType'
                        >
                          <option value=''>Select Data Type</option>
                          <option value='Checkbox list'>Checkbox list</option>
                          <option value='Currency'>Currency</option>
                          <option value='Date'>Date</option>
                          <option value='Date and Time'>Date and Time</option>
                          <option value='Dropdown list'>Dropdown list</option>
                          <option value='Email'>Email</option>
                          <option value='GPS Coordinates'>GPS Coordinates</option>
                          <option value='Memo'>Memo</option>
                          <option value='Numeric'>Numeric</option>
                          <option value='Numeric Auto Increment'>Numeric Auto Increment</option>
                          <option value='Radio button list'>Radio button list</option>
                          <option value='Scanner'>Scanner</option>
                          <option value='Text'>Text</option>
                          <option value='URL'>URL</option>
                        </select>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='AddEditCustomFieldModel.FieldTypeId'
                          data-valmsg-replace='true'
                        ></span>
                        <div className='text-danger mt-1'>{error.dataType}</div>
                      </div>
                    </div>
                    {customFieldData?.customFieldDataType !== 'Radio button list' && (
                      <div className='form-group row align-items-center mt-5' id='ValidateType'>
                        <label className='col-sm-4 col-form-label text-sm-end'>Data Required</label>
                        <div className='col-sm-7'>
                          <div className='radio-inline'>
                            <label className='radio mb-0 p_16'>
                              <input
                                id='isValidate_True'
                                checked={customFieldData?.isRequired}
                                onChange={handleCustomFieldData}
                                type='radio'
                                name='yes'
                                className='m_5'
                              />
                              <span></span> Yes
                            </label>
                            <label className='radio mb-0'>
                              <input
                                id='isValidate_False'
                                checked={!customFieldData?.isRequired}
                                onChange={handleCustomFieldData}
                                type='radio'
                                name='optional'
                                className='m_5'
                              />
                              <span></span> Optional
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className='form-group row DataInput'
                      id='StartingValue'
                      style={{ display: 'none' }}
                    >
                      <label
                        className='col-sm-4 col-form-label text-sm-end'
                        htmlFor='AddEditCustomFieldModel_Counter'
                      >
                        Starting Value
                      </label>
                      <div className='col-sm-7'>
                        <input
                          className='form-control i'
                          data-val='true'
                          data-val-number='The field Starting value must be a number.'
                          id='AddEditCustomFieldModel_Counter'
                          maxLength={10}
                          min='0'
                          name='AddEditCustomFieldModel.Counter'
                          type='text'
                          value=''
                        />
                      </div>
                    </div>
                    {(customFieldData?.customFieldDataType === 'Checkbox list' ||
                      customFieldData?.customFieldDataType === 'Radio button list' ||
                      customFieldData?.customFieldDataType === 'Dropdown list') && (
                        <div className='form-group row DataInput customFieldOption'>
                          <label
                            className='col-sm-4 col-form-label text-sm-end'
                            htmlFor='AddEditCustomFieldModel_Option'
                          >
                            List Options
                          </label>
                          <div className='col-sm-8 input_fields_wrap'>
                            <div className='form-inline flex-nowrap'>
                              <div className='col'>
                                {fields.map((field, index) => (
                                  <div key={field.id} className='form-inline flex-nowrap mt-3 align-items-center'>
                                    <div className='col'>
                                      <input
                                        className='form-control input-medium border border-black'
                                        type='text'
                                        value={field.value}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                      />
                                      {index == 0 && <div className='text-danger mt-1'>{error.listOption}</div>}
                                    </div>
                                    {index !== 0 && <div className="col text-nowrap ps-3">
                                      <a className='btn btn-outline btn-outline-danger btn-active-danger btn-xs remove_field ' style={{ padding: "5px 10px" }} onClick={() => removeField(index)}>
                                        <i className="fa fa-times fs-5"></i>  Remove
                                      </a>
                                    </div>}
                                    {index == 0 && <div className='col text-nowrap ps-3'>
                                      <a
                                        onClick={addNewField}
                                        href='javascript:void(0)'
                                        className='btn btn-secondary btn-sm add_field_button'
                                      >
                                        <i className='fa fa-plus fs-6' aria-hidden='true'></i>Add More
                                        Fields
                                      </a>
                                    </div>}
                                  </div>
                                ))}
                              </div>
                            </div>
                            <span
                              className='field-validation-valid'
                              data-valmsg-for='AddEditCustomFieldModel.Options'
                              data-valmsg-replace='true'
                            ></span>
                            <span
                              className='field-validation-valid'
                              data-valmsg-for='AddEditCustomFieldModel.Option'
                              data-valmsg-replace='true'
                            ></span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    {...(customFieldData?.customFieldName && customFieldData?.customFieldDataType && ((customFieldData?.customFieldDataType === 'Checkbox list' || customFieldData?.customFieldDataType === 'Radio button list' || customFieldData?.customFieldDataType === 'Dropdown list') ? fields[0]?.value : true)
                      ? { 'data-bs-dismiss': 'modal' }
                      : {})}
                    value='Save'
                    onClick={submitCustomField}
                  >
                    Save
                  </button>
                  <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <input
            data-val='true'
            data-val-length='Maximum length is 4000.'
            data-val-length-max='4000'
            id='AddEditCustomFieldModel_Options'
            name='AddEditCustomFieldModel.Options'
            type='hidden'
            value=''
          />
          <input
            data-val='true'
            data-val-required="Is this field visible to assets of selective 'Categories'?"
            id='AddEditCustomFieldModel_hasCategory'
            name='AddEditCustomFieldModel.hasCategory'
            type='hidden'
            value='False'
          />
          <input
            data-val='true'
            data-val-required='Is this field need to be required?'
            id='AddEditCustomFieldModel_isValidate'
            name='AddEditCustomFieldModel.isValidate'
            type='hidden'
            value='True'
          />
          <input
            data-val='true'
            data-val-number='The field CustomFieldId must be a number.'
            data-val-required='The CustomFieldId field is required.'
            id='AddEditCustomFieldModel_CustomFieldId'
            name='AddEditCustomFieldModel.CustomFieldId'
            type='hidden'
            value='0'
          />
        </form>{' '}
        <div
          className='modal fade'
          id='confirmDeleteCustomField'
          tabIndex={-1}
          role='basic'
          aria-hidden='true'
        >
        </div>
      </div>
    </div>
  )
}

export default CustomersList
