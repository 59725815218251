import React, { useEffect, useState } from 'react'
import { getAuth } from '../../../../auth';
import { TableOptions } from '../core/_models';
import { getUpdatedTableOptions, UpdateTableOptions } from '../core/_requests';
import { toast } from 'react-toastify';
import { useGetTableOptionQuery,useAddTableOptionMutation } from '../../../../services/tableOption';
import { useNavigate } from 'react-router-dom';


const Options = () => {
    const auth = getAuth()
    const navigate = useNavigate();
    const [isSitesExist, setIsSitesExist] = useState<any>(false)
    const [options, setOptions]= useState<TableOptions>({
        hasAssetDepreciation: true,
        AssetDepreciationMethod: "",
        hasCalculationFrequency: "",
        hasContracts: true,
        hasInsurance: true,
        hasFunding: true,
        hasMaintainence: true,
        hasWarranties: true,
        hasSubCategories: true,
        hasDepartments: true,
        hasAudit: true,
        hasAssetPhotos: true,
        hasDocuments: true,
        hasLinkingAssets: true,
        hasLinkingAssetsCheckout: true,
        hasLinkingAssetsReservations: true,
        hasLinkingAssetsLeaseAssets: true,
        hasLinkingAssetsLost: true,
        hasLinkingAssetsRepair: true,
        hasLinkingAssetsBroken: true,
        hasLinkingAssetsDispose: true,
        hasLinkingAssetsDonate: true,
        hasLinkingAssetsSellAssets: true,
        hasLinkingAssetsAuditAssets: true,
    })
    const { data , error:optionError, isLoading } = useGetTableOptionQuery(auth?.token);
    const [updateTableOption, {isLoading: isUpdating}] = useAddTableOptionMutation()


    useEffect(()=>{
        if(JSON.stringify(data?.result) !== JSON.stringify(options)){
            checkVistingPage(data?.result)
        }
        setOptions(data?.result)
    },[data])

    const checkVistingPage=(dataList:any)=>{
        if(isSitesExist===false)
        {if(dataList?.length>0){
          setIsSitesExist(dataList?.length)
    
        }
        else{
          setIsSitesExist(dataList?.length)
        }
      }
      }
    const submitTableOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        try{
        updateTableOption({data:options, token:auth?.token}).unwrap()
        if (data?.status == 200) {
            toast.success("Table Options updated successfully.");
            if(isSitesExist===0 || isSitesExist===false){
                navigate(`/apps/setup/options`)
            }
        }
        }
        catch(error)  {
            console.log(error)
            toast.error('Something went Wrong!')
          }
    }

    return (
        <div>
            <div className="card">
                <div id="table_ajax_wrapper">

                    {/* novalidate="novalidate" */}
                    <form action="/table?Length=5" className="form-horizontal" data-ajax="true" data-ajax-method="Post" data-ajax-mode="replace" data-ajax-success="javascript: funTableFormSubmited(); fun_setDeprDescription();" data-ajax-update="#table_ajax_wrapper" id="assetTableForm" method="post" role="form" ><input name="__RequestVerificationToken" type="hidden" value="M52kz7qyBDGUvKvG6EJt0mvPNU9YEWta011wx7BlLeXH0G59efuSq0gqce8HTu6ccTAjLbPqVb6MqcLvSfQ7e60TZCw4vj_q7KIlZHL4CnMmC_bx3TWX1JE0rG-0bDIyMQd_kw2" />
                        <div className="card-body">
                            <p className="intro"><strong>AssetTiger</strong> lets you decide how comprehensive you want your system. Use these options to fashion your ideal asset tracking and create more reports.</p>
                            <input data-val="true" data-val-required="Inventory is required." id="hasInventory" name="hasInventory" type="hidden" value="False" />
                            <div className="form_section">
                                <div className="form-title d-flex"> 
                                    <span className='me-3'>
                                        <i className="la la-calendar fs-1" aria-hidden="true"></i>
                                    </span>
                                    <h4>Depreciation</h4> 
                                </div>
                                <p className="intro">Depreciation is used to expense the cost of your assets over their useful life. If you would like to track the depreciation of assets, select <strong>Yes</strong> and define your default depreciation method and calculation frequency.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end" htmlFor="isDepreciation">Asset Depreciation:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            {/* onchange="javascript: $('#isDepreciation').val('True'); $('.formDepreciation').slideDown(300);"
                                            onchange="javascript: $('#isDepreciation').val('False'); $('.formDepreciation').slideUp(300);" */}

                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="radDepreciation" checked={options?.hasAssetDepreciation} 
                                            onChange={() =>
                                                setOptions({ 
                                                    ...options,
                                                    hasAssetDepreciation: true
                                                 })
                                            }
                                             /> 
                                            <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="radDepreciation" checked={!options?.hasAssetDepreciation} onChange={() => setOptions({...options, hasAssetDepreciation: false})} /> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                                {options?.hasAssetDepreciation && <div> <div className="form-group row formDepreciation" style={{ display: 'flex' }}>
                                    <label className="col-sm-4 col-md-3 col-form-label mb-0 text-sm-end" htmlFor="DepreciationMethodId">Default Depreciation Method</label>
                                    <div className="col-sm-6 col-md-7">
                                        <p>Select the default depreciation method to be used for most assets. You still have the option to override and choose another depreciation method when creating assets.</p>
                                        <div className="form-inline">
                                            {/* onchange="javascript: fun_setDeprDescription();" */}
                                            <select className="form-select" data-val="true" data-val-number="The field Depreciation must be a number." id="DepreciationMethodId" name="DepreciationMethodId" value={options?.AssetDepreciationMethod.toString()} onChange={(e) => setOptions({...options, AssetDepreciationMethod: e.target.value})}>
                                                <option value="Straight Line" data-description="Straight Line - Allocates the same amount to each period over time. This is the most popular and easiest to use.">Straight Line</option>
                                                <option value="Declining Balance" data-description="Declining Balance – Allocates 1x as much as straight line in the first half of the asset life, and full as much in the second half. Typically used for tax purposes.">Declining Balance</option>
                                                <option value="Double Declining Balance" data-description="Double Declining – Allocates twice as much as straight line in the first half of the asset life, and half as much in the second half. Typically used for tax purposes.">Double Declining Balance</option>
                                                <option value="150% Declining Balance" data-description="150% Declining – works the same as double declining but just at a 1.5x level of straight line. Again typically used for tax purposes.">150% Declining Balance</option>
                                                <option value="Sum of the Years' Digits" data-description="Sum of year’s digits – Allocates the deprecation based on the fractional calculation of the assets useful life. This accelerates deprecation faster than straight line but lesser than double declining. Typically not used but acceptable for US GAAP purposes.">Sum of the Years' Digits</option>
                                            </select>
                                            {options?.AssetDepreciationMethod && (
                                                <div>{document.querySelector(`option[value="${options?.AssetDepreciationMethod}"]`)?.getAttribute("data-description")}</div>
                                            )}
                                            <span className="hide control-label popovers" data-trigger="hover" data-original-title="Straight Line" data-placement="right" data-content="Allocates the same amount to each period over time.  This is the most popular and easiest to use."><i className="icon-question" style={{ fontSize: '20px', marginLeft: '10px' }} aria-hidden="true"></i></span>
                                        </div>
                                        <span className="help-block mb-0" id="DepreciationMethodId_help"></span>
                                    </div>
                                </div>
                                <div className="form-group row formDepreciation" style={{ display: 'flex' }}>
                                    <label className="col-sm-4 col-md-3 col-form-label mb-0 text-sm-end" htmlFor="DepreciationFrequencyId">Calculation Frequency</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="form-inline">
                                            <select className="form-select" data-val="true" data-val-number="The field Calculation Frequency must be a number." id="DepreciationFrequencyId" name="DepreciationFrequencyId" value={options?.hasCalculationFrequency.toString()} onChange={(e) => setOptions({...options, hasCalculationFrequency: e.target.value})} >
                                                <option value="Monthly">Monthly</option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Yearly">Yearly</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                }
                                <div className="formDepreciation" style={{ display: 'none' }}>
                                    <input data-val="true" data-val-number="The field Financial Year begins on must be a number." data-val-required="Financial month is required." id="FinancialMonth" name="FinancialMonth" type="hidden" value="1" />
                                    <input data-val="true" data-val-number="The field Financial Year begins on must be a number." data-val-required="Financial year is required." id="FinancialDate" name="FinancialDate" type="hidden" value="1" />
                                </div>
                            </div>
                            <input data-val="true" data-val-required="Asset Depreciation is required." id="isDepreciation" name="isDepreciation" type="hidden" value="False" />
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"> 
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="sliders" style={{ transform: 'rotate(90deg)', width: '18px', height: '18px', marginRight: '12px', verticalAlign: '-3px' }}><path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM9.05 3a2.5 2.5 0 014.9 0H16v1h-2.05a2.5 2.5 0 01-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM2.05 8a2.5 2.5 0 014.9 0H16v1H6.95a2.5 2.5 0 01-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-2.45 1a2.5 2.5 0 014.9 0H16v1h-2.05a2.5 2.5 0 01-4.9 0H0v-1h9.05z" style={{ fill: '#9b9b9b' }}></path></svg>
                                <h4>Contracts / Licenses</h4> </div>
                                <p className="intro">If your assets are under a contract or certain agreement you want to consider activating this option. Software licenses can also be managed under this option.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Enable Contracts:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasContracts" value="True" checked={options?.hasContracts} onChange={() => setOptions({...options, hasContracts: true})} /> <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasContracts"checked={!options?.hasContracts} onChange={() => setOptions({...options, hasContracts: false})} value="False" /> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"> 
                                    <span className='me-3'>
                                        <i className="la la-shield fs-1" aria-hidden="true"></i>
                                    </span>
                                    <h4>Insurance</h4> 
                                </div>
                                <p className="intro">Insurance can be a hassle to manage and remember. Enable this option to allow insurance variables to be allocated to your assets. A blanket insurance can be allocated to multiple assets.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Enable Insurance:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasInsurances" checked={options?.hasInsurance} onChange={() => setOptions({...options, hasInsurance: true})} value="True" /> <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasInsurances" value="False" checked={!options?.hasInsurance} onChange={() => setOptions({...options, hasInsurance: false})}/> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"> 
                                <span className='me-3'>
                                    <i className="la la-bank fs-1" aria-hidden="true"></i>
                                </span>
                                <h4>Funding</h4> 
                                </div>
                                <p className="intro">Activate this feature to add Funding to be used when purchasing assets. This can help if you are a not-for-profit organization and have certain funds for different needs. It can also be used in for-profit companies to allocate a budget for asset purchases.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Enable Funding:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasFunds" value="True" checked={options?.hasFunding} onChange={() => setOptions({...options, hasFunding: true})} /> <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasFunds" checked={!options?.hasFunding} onChange={() => setOptions({...options, hasFunding: false})} value="False" /> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"> 
                                    <span className='me-3'>
                                        <i className="la la-cog fs-1" aria-hidden="true"></i>
                                    </span>
                                    <h4>Maintenances</h4> 
                                </div>
                                <p className="intro">Enable to view when an asset has been through a maintenance event and the ability to schedule the routine frequency of having that asset checked for repairs.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Enable Asset's Maintenance:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasMaintenances" checked={options?.hasMaintainence} onChange={() => setOptions({...options, hasMaintainence: true})} value="True" /> <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasMaintenances" value="False" checked={!options?.hasMaintainence} onChange={() => setOptions({...options, hasMaintainence: false})} /> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"> 
                                    <span className='me-3'>
                                        <i className="las la-certificate fs-1" aria-hidden="true"></i>
                                    </span>
                                    <h4>Warranties</h4> 
                                </div>
                                <p className="intro">Enable the ability to add when an asset’s warranty will expire and be notified of the event.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Enable Asset's Warranty:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasWarranties" value="True" checked={options?.hasWarranties} onChange={() => setOptions({...options, hasWarranties: true})}/> <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasWarranties" checked={!options?.hasWarranties} onChange={() => setOptions({...options, hasWarranties: false})} value="False" /> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"> 
                                    <span className='me-3'>
                                        <i className="far fa-list-alt fs-4" aria-hidden="true"></i>
                                    </span>
                                    <h4>Sub Categories</h4> 
                                </div>
                                <p className="intro">Enable the ability to add sub-categories and assign an asset to those sub-categories.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Allocate assets to Sub Categories:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasSubCategories" value="True" checked={options?.hasSubCategories} onChange={() => setOptions({...options, hasSubCategories: true})} /> <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasSubCategories" checked={!options?.hasSubCategories} onChange={() => setOptions({...options, hasSubCategories: false})} value="False" /> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"> 
                                    <span className='me-3'><i className="la la-list fs-1" aria-hidden="true"></i></span>
                                    <h4>Departments</h4> 
                                </div>
                                <p className="intro">Enable the ability to add departments and assign asset to those departments.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Allocate assets to Departments:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasDepartments" checked={options?.hasDepartments} onChange={() => setOptions({...options, hasDepartments: true})} value="True" /> <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasDepartments" value="False" checked={!options?.hasDepartments} onChange={() => setOptions({...options, hasDepartments: false})} /> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"> 
                                    <span className='me-3'>
                                        <i className="la la-edit fs-1" aria-hidden="true"></i>
                                    </span>
                                    <h4>Audit</h4> 
                                </div>
                                <p className="intro">Enable the ability to perform audits of assets to ensure they are still in locations/departments that were previously reported and take actions if not.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Asset Audit:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            {/* onchange="javascript:  $('.AuditLinking_wrapper').slideDown(300);"
                                            onchange="javascript: $('.AuditLinking_wrapper').slideUp(300);" */}
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasAudit" checked={options?.hasAudit} onChange={() => setOptions({...options, hasAudit: true})} value="True" /> <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasAudit" value="False" checked={!options?.hasAudit} onChange={() => setOptions({...options, hasAudit: false})} /> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"> 
                                    <span className='me-3'>
                                        <i className="la la-camera fs-1" aria-hidden="true"></i>
                                    </span>
                                    <h4>Multiple Photos of an Asset</h4> 
                                </div>
                                <p className="intro">Enable to have multiple photos of each asset. Otherwise, only one photo of the asset will be allowed.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Asset Photos:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasPhotos"  value="True" checked={options?.hasAssetPhotos} onChange={() => setOptions({...options, hasAssetPhotos: true})} /> <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasPhotos" value="False" checked={!options?.hasAssetPhotos} onChange={() => setOptions({...options, hasAssetPhotos: false})} /> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"> 
                                    <span className='me-3'>    <i className="la la-inbox fs-1" aria-hidden="true"></i></span>
                                    <h4>Documents</h4> 
                                </div>
                                <p className="intro">Enable the ability to have various PDF, Word or Excel documents stored with the asset.  These can include asset directions, purchase orders or invoices that may be associated with the asset.</p>
                                <div className="col-md-12">
                                    <div className="form-group row align-items-center">
                                        <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Asset Documents:</label>
                                        <div className="col-sm-6 col-md-7">
                                            <div className="radio-inline">
                                                <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasDocuments" checked={options?.hasDocuments} onChange={() => setOptions({...options, hasDocuments: true})} value="True" /> <span></span>Yes</label>
                                                <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="hasDocuments" value="False" checked={!options?.hasDocuments} onChange={() => setOptions({...options, hasDocuments: false})} /> <span></span>No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_section">
                                <div className="separator separator-solid mb-6"></div>
                                <div className="form-title d-flex"><h4>Linking of Assets</h4></div>
                                <p className="intro">Enable the ability to link assets to have a parent-child relationship. To assign multiple assets that are related to each other and/or function as a whole, assign one asset as the parent, the rest of them as the children, and the group will stay linked. You can either have these assets function as one unit or have them associated but able to move independently.</p>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label-sm mb-0 text-sm-end">Enable Linking of Assets:</label>
                                    <div className="col-sm-6 col-md-7">
                                        <div className="radio-inline">
                                            {/* onchange="javascript: $('.AssetLinkingWrapper').slideDown(300);" 
                                            onchange="javascript: $('.AssetLinkingWrapper').slideUp(300);" */}
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="AssetLinking" checked={options?.hasLinkingAssets} onChange={() => setOptions({...options, hasLinkingAssets: true})} value="True" /> <span></span>Yes</label>
                                            <label className="radio mb-0"><input className='me-lg-3 me-2' type="radio" name="AssetLinking" value="False" checked={!options?.hasLinkingAssets} onChange={() => setOptions({...options, hasLinkingAssets: false})} /> <span></span>No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="AssetLinkingWrapper" style={{ display: 'block' }}>
                                    <div className="form-group row">
                                        <p className="intro">When you define linked assets, you can mark them <strong>Transact as a whole</strong>, which groups these assets and make them function as one unit. Select the events that are performed on a group of linked assets as one unit:</p>
                                        <div className="col-sm-8 col-md-9 offset-md-3 offset-sm-4">
                                            <div className="checkbox-list">
                                                <div className="CheckoutLinking_wrapper form-group mb-3" style={{ display: 'block' }}><label className="checkbox"><input className='me-4' checked={options?.hasLinkingAssetsCheckout} onChange={() => setOptions({...options, hasLinkingAssetsCheckout: !options?.hasLinkingAssetsCheckout})} data-val="true" data-val-required="Checkout Linking is required." id="CheckoutLinking" name="CheckoutLinking" type="checkbox" value="true" /><input name="CheckoutLinking" type="hidden" value="false" /><i className="la la-user-check fs-2 text-primary me-2" style={{ verticalAlign: '-1px' }} aria-hidden="true"></i>  <span></span>Check-out</label></div>
                                                <div className="ReserveLinking_wrapper form-group mb-3" style={{ display: 'block' }}><label className="checkbox"> <input className='me-4' checked={options?.hasLinkingAssetsReservations} onChange={() => setOptions({...options, hasLinkingAssetsReservations: !options?.hasLinkingAssetsReservations})} data-val="true" data-val-required="Reserve Linking is required." id="ReserveLinking" name="ReserveLinking" type="checkbox" value="true" /><input name="ReserveLinking" type="hidden" value="false" /><i className="la la-calendar fs-2 text-primary me-2" style={{ verticalAlign: '-1px' }} aria-hidden="true"></i> <span></span>Reservation</label></div>
                                                <div className="LeaseLinking_wrapper form-group mb-3" style={{ display: 'block' }}><label className="checkbox"><input className='me-4' checked={options?.hasLinkingAssetsLeaseAssets} onChange={() => setOptions({...options, hasLinkingAssetsLeaseAssets: !options?.hasLinkingAssetsLeaseAssets})} data-val="true" data-val-required="Lease Linking is required." id="LeaseLinking" name="LeaseLinking" type="checkbox" value="true" /><input name="LeaseLinking" type="hidden" value="false" /> <i className="la la-send-o fs-2 text-primary me-2" style={{ verticalAlign: '-1px' }} aria-hidden="true"></i> <span></span>Lease assets</label></div>
                                                <div className="LostLinking_wrapper form-group mb-3" style={{ display: 'block' }}><label className="checkbox"><input className='me-4' checked={options?.hasLinkingAssetsLost} onChange={() => setOptions({...options, hasLinkingAssetsLost: !options?.hasLinkingAssetsLost})} data-val="true" data-val-required="Lost Linking is required." id="LostLinking" name="LostLinking" type="checkbox" value="true" /><input name="LostLinking" type="hidden" value="false" /><i className="la la-thumbs-o-down fs-2 text-primary me-2" style={{ verticalAlign: '-1px' }} aria-hidden="true"></i>  <span></span>Lost/Found assets</label></div>
                                                <div className="RepairLinking_wrapper form-group mb-3" style={{ display: 'block' }}><label className="checkbox"> <input className='me-4' checked={options?.hasLinkingAssetsRepair} onChange={() => setOptions({...options, hasLinkingAssetsRepair: !options?.hasLinkingAssetsRepair})} data-val="true" data-val-required="Repair Linking is required." id="RepairLinking" name="RepairLinking" type="checkbox" value="true" /><input name="RepairLinking" type="hidden" value="false" /><i className="la la-wrench fs-2 text-primary me-2" style={{ verticalAlign: '-1px' }} aria-hidden="true"></i> <span></span>Repair assets</label></div>
                                                <div className="BrokenLinking_wrapper form-group mb-3" style={{ display: 'block' }}><label className="checkbox"><input className='me-4' checked={options?.hasLinkingAssetsBroken} onChange={() => setOptions({...options, hasLinkingAssetsBroken: !options?.hasLinkingAssetsBroken})} data-val="true" data-val-required="Broken Linking is required." id="BrokenLinking" name="BrokenLinking" type="checkbox" value="true" /><input name="BrokenLinking" type="hidden" value="false" /><i className="la la-unlink fs-2 text-primary me-2" style={{ verticalAlign: '-1px' }} aria-hidden="true"></i>  <span></span>Broken assets</label></div>
                                                <div className="DisposeLinking_wrapper form-group mb-3" style={{ display: 'block' }}><label className="checkbox"> <input className='me-4' checked={options?.hasLinkingAssetsDispose} onChange={() => setOptions({...options, hasLinkingAssetsDispose: !options?.hasLinkingAssetsDispose})} data-val="true" data-val-required="Dispose Linking is required." id="DisposeLinking" name="DisposeLinking" type="checkbox" value="true" /><input name="DisposeLinking" type="hidden" value="false" /><i className="la la-recycle fs-2 text-primary me-2" style={{ verticalAlign: '-1px' }} aria-hidden="true"></i> <span></span>Dispose assets</label></div>
                                                <div className="DonateLinking_wrapper form-group mb-3" style={{ display: 'block' }}><label className="checkbox"> <input className='me-4' checked={options?.hasLinkingAssetsDonate} onChange={() => setOptions({...options, hasLinkingAssetsDonate: !options?.hasLinkingAssetsDonate})} data-val="true" data-val-required="Donate Linking is required." id="DonateLinking" name="DonateLinking" type="checkbox" value="true" /><input name="DonateLinking" type="hidden" value="false" /><i className="la la-heart-o fs-2 text-primary me-2" style={{ verticalAlign: '-1px' }} aria-hidden="true"></i> <span></span>Donate assets</label></div>
                                                <div className="SellLinking_wrapper form-group mb-3" style={{ display: 'block' }}><label className="checkbox"> <input className='me-4' checked={options?.hasLinkingAssetsSellAssets} onChange={() => setOptions({...options, hasLinkingAssetsSellAssets: !options?.hasLinkingAssetsSellAssets})} data-val="true" data-val-required="Sell Linking is required." id="SellLinking" name="SellLinking" type="checkbox" value="true" /><input name="SellLinking" type="hidden" value="false" /><i className="la la-gavel fs-2 text-primary me-2" style={{ verticalAlign: '-1px' }} aria-hidden="true"></i> <span></span>Sell assets</label></div>
                                                <div className="AuditLinking_wrapper form-group mb-3" style={{ display: 'block' }}><label className="checkbox"><input className='me-4' checked={options?.hasLinkingAssetsAuditAssets} onChange={() => setOptions({...options, hasLinkingAssetsAuditAssets: !options?.hasLinkingAssetsAuditAssets})} data-val="true" data-val-required="Audit Linking is required." id="AuditLinking" name="AuditLinking" type="checkbox" value="true" /><input name="AuditLinking" type="hidden" value="false" /><i className="la la-edit fs-2 text-primary me-2" style={{ verticalAlign: '-1px' }} aria-hidden="true"></i>  <span></span>Audit assets</label></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer notForWizard">
                            <div className="row">
                                <div className="col-md-9 offset-md-3 text-end text-md-start">
                                    <button type="submit" onClick={(e) => submitTableOptions(e)} className="btn btn-primary me-2">
                                    {isUpdating ? (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                        ) : isSitesExist===0 || isSitesExist===false?"Continue":"Submit"}
                                    </button>
                                    <a href="/table" className="btn btn-secondary">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </form>    
                    
                    </div>
                   
            </div>
        </div>
    )
}

export default Options
