import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SiteResponse } from '../apps/company-setup/sites/core/_models';
import { assetsApi } from './assets';

const API_URL = process.env.REACT_APP_API_URL

export const sitesApi = createApi({
    reducerPath: 'sitesApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/site/` }),
  tagTypes: ['Sites'],
  endpoints: (build) => ({
    getAllSites: build.query<any, any>({
      query: (userId) => `list/${userId}`,
      providesTags: ['Sites'],
    }),
   
   
    addSite: build.mutation<any, any>({
      query: (body) => ({
        url: `create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Sites'}],
    }),
    updateSite: build.mutation<any, { siteId: any; values: any }>({
      query: ({ siteId, values }) => ({
        url: `update/${siteId}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error, { siteId }) => [{ type: 'Sites', siteId }],
    }),
    deleteSites: build.mutation<any, {userId:number | undefined, cascade:number, id: number[]}>({
      query({userId, cascade, id}) {
        return {
          url: `delete-all/${userId}?cascade=${cascade}`,
          method: 'DELETE',
          body: id
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            assetsApi.util.invalidateTags(["Assets"])
          );
        });
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Sites', userId }],
    }),
  }),
})

export const {
  useGetAllSitesQuery,
  useAddSiteMutation,
  useUpdateSiteMutation,
  useDeleteSitesMutation,
} = sitesApi
