import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useGetEmailTemplateMutation, useUpdateEmailTemplateMutation } from 'app/modules/services/emailTemplate';
import { toast } from 'react-toastify'

export default function App(props) {

  const [emailData, setEmailData] = useState({})
  const [getEmailTemplate] = useGetEmailTemplateMutation()
  const [updateEmailTemplate] = useUpdateEmailTemplateMutation() 

  const handleEmailSubjectChange = (event) => {
    setEmailData({ ...emailData, title: event.target.value });
  };
  const editorRef = useRef(null);

  const resetContent = () => {
    handleClickTabs()
  };
  useEffect(() => {
    if (props.type) {
      handleClickTabs()
    }
  }, [props.type])

  const handleClickTabs = async () => {
    try {
      const result = await getEmailTemplate(props.type).unwrap()
      setEmailData(result.template)
      toast.dismiss()
      toast.success(result?.message ? result?.message : result?.data?.message)
    } 
    catch (error) {
      toast.error('Something went wrong')
    }
  }

  const submitEmailTemplate = async () => {
    try{
      const requestData = {
        templateId: emailData.id,
      };
      const result = await updateEmailTemplate(requestData).unwrap()
      toast.dismiss()
      toast.success(result?.message ? result?.message : result?.data?.message)
    }
    catch(error){
      toast.error('Something went wrong')
    }
  }

  return (
    <>
      <div className='mb-7'>
        <p className='fs-6 fw-bold mb-1'>Email Subject</p>
        <p className='text-muted'>
          <span className='me-1 fs-5'><i className='la la-info-circle fw-bolder' /></span>
          Text that will appear in the email subject line.</p>
        <input className="form-control" data-val="true" data-val-length="Maximum length is 1000." data-val-length-max="1000" data-val-required="Email Subject is required." id="EmailSubject" name="EmailSubject" type="text" value={emailData?.title} onChange={handleEmailSubjectChange} />
      </div>
      <div className='mb-7'>
        <Editor
          onInit={(evt, editor) => editorRef.current = editor}
          value={emailData?.markUp}
          onEditorChange={(content) => setEmailData({ ...emailData, markUp: content })}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
              'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
              'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
              'alignleft aligncenter alignright alignjustify | ' +
              'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </div>
      <div className=' d-flex align-items-center justify-content-between'>
        <button className='btn btn-secondary main-btn-style' onClick={resetContent}>Reset</button>
        <div className='d-flex align-items-center'>
          <button type='submit' className='btn btn-primary me-2' onClick={submitEmailTemplate}> Save </button>
          <a href="/apps/setup/customEmails" className='btn btn-secondary main-btn-style'> Cancel </a>
        </div>
      </div>
    </>
  );
}