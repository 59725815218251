import React, { useState, useEffect } from 'react'
import { PageTitle } from "../../../_metronic/layout/core"
import { useIntl } from 'react-intl'
import { KTSVG } from "../../../_metronic/helpers"
import MaintenanceModal from "./Modal/MaintenanceModal"
import { useAuth } from '../../modules/auth'
import axios from 'axios'
import { Field, useFormik } from 'formik'
import * as Yup from 'yup'
import { useGetAllSitesQuery } from '../../modules/services/sites'
import { useGetAllLocationsBySiteQuery } from '../../modules/services/locations'
import { useGetAllDepartmentsQuery } from '../../modules/services/departments'
import { getEmployee } from '../../modules/apps/company-setup/Database/core/_requests'
import { MaintenanceInterface, defaultMaintenanceDetail as initialValues } from './core/SetupModel'
import { toast } from 'react-toastify'
import { useAddMaintenanceMutation } from '../../modules/services/maintenance'
import { useGetAllAssetsQuery } from 'app/modules/services/assets'
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap'
import SitesModal from '../apps/company-setup/sites/components/SitesModal'
import LocationModal from "../apps/company-setup/locations/components/LocationModal"
import DepartmentsModel from "../apps/company-setup/departments/components/DepartmentsModel"
import { useGetCompanyQuery } from '../services/company'
import getSymbolFromCurrency from 'currency-symbol-map'


const accountDetailsSchema = Yup.object().shape({
  maintenanceTitle: Yup.string()
  .required('Title is required')
  .test('no-empty-spaces', 'Title should not be empty', (value = '') => {
    return !/^\s*$/.test(value); // Check if the value contains only empty spaces
  }),


  //   leaseBegins: Yup.date().required('Lease Begins Date is required'),
  //   // locationId:Yup.number().moreThan(0,"Please select Location").required("Please select Location"),
  //   // siteId: Yup.number().moreThan(0, "Please select site").required("Please select site"),
  //   // departmentId: Yup.number().moreThan(0,"Please select department").required("Please select department"),
  //   // assignTo:values?.assignTo,
})

const Maintenance = () => {
  const intl = useIntl()
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [assets, setAssets] = useState<any>([]);
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false)
  const [pendingAssets, setPendingAssets] = useState<any>([]);
  const [selectedAssets, setSelectedAssets] = useState<any>([]);
  const [selectAll, setSelectAll] = useState(false)
  const [repeatingMaintenance, setRepeatingMaintenance] = useState(false)
  const [repeatingFrequency, setRepeatingFrequency] = useState('')
  const [emailEnabled, setEmailEnabled] = useState(false)
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
  const [selected, setSelected] = useState<any>([])
  const [skip, setSkip] = useState<boolean>(false)
  const [siteId, setSiteId] = useState<boolean>(false)
  const [showSiteModal, setShowSiteModal] = useState<boolean>(false)
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false)
  const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false)
  const [employeeData, setEmployeeData] = useState<any>([])
  const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
  const { data: siteData } = useGetAllSitesQuery(currentUser?.id);
  const { data: locationData, error: locationError } = useGetAllLocationsBySiteQuery({ userId: currentUser?.id, id: siteId }, { skip })
  const { data: departmentData } = useGetAllDepartmentsQuery(currentUser?.id)
  const [addMaintenance, { isLoading: isAdding }] = useAddMaintenanceMutation();
  const { data: assetsData, isLoading, error, isSuccess } = useGetAllAssetsQuery({ userId: currentUser?.id, body: new URLSearchParams({}).toString(), page: 1, limit: 10 })
  const [currencyLogo, setCurrencyLogo] = useState<any>();
  const { data: companyData,isSuccess:company } = useGetCompanyQuery(currentUser?.id)
  
  useEffect(()=>{
    let res=getSymbolFromCurrency(companyData?.company?.currencySymbol)
    setCurrencyLogo(res)
},[company])

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: accountDetailsSchema,
    onSubmit: async (values) => {
      try {
        handleMaintenanceSubmit(values)
      } catch (error) {
        console.error(error)
        toast.error('Something went wrong')
      }
    },
  })

  // useEffect(() => {
  //   getEmployee(currentUser?.token).then((res:any) => {
  //     setEmployeeData(res?.data?.persons);
  //   });
  // }, []);
  useEffect(() => {
    setSkip(false) //set skip false becasue skip param in query hook does not load on mount
  }, [])
  const handleSiteClick = (e: any) => {
    let id = e.target.value
    setSiteId(id)
    setSkip(false)
  }
  useEffect(() => {
    if (assetsData) {
      getAssetListing();
    }
  }, [assetsData])
  useEffect(() => {
    if (assets && pendingAssets && showMaintenanceModal == false) {
      handlePendingList()
    }
  }, [assets, showMaintenanceModal])
  const getAssetListing = async () => {
    setAssets(assetsData?.userAssets)
  }
  const handlePendingList = () => {
    const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id));
    console.log(data)
    setSelectedAssets(data);
    setdeleteDataArr([])

  }
  const handleMaintenanceSubmit = async (values: MaintenanceInterface) => {
    let data = {
      maintenanceTitle: values?.maintenanceTitle,
      maintenanceDetails: values?.maintenanceDetails,
      maintenanceDueDate: values?.dueDate,
      maintenanceBy: values?.maintenanceBy,
      maintenanceStatus: values?.maintenanceStatus,
      maintenanceDateCompleted: values?.leaseExpires,
      maintenanceCost: values?.cost,
      maintenanceReapting: values?.maintenanceReapting,
      maintenanceFrequency: values?.maintenanceFrequency,
      recureOnEvery: values?.recureOnEvery,
      recureOn: values?.recureOn,
      // recureOnNumber:values?.recureOnNumber, 
      // recureOnWeek:values?.recureOnWeek, 
      // recureOnMonth:values?.recureOnMonth, 
      // recureOnYear:values?.recureOnYear,
      assetId: pendingAssets,
      userId: currentUser?.id
    }
    const result = await addMaintenance(data).unwrap()
    if (result) {
      toast.dismiss()
      toast.success(result?.message ? result?.message : result?.data?.message)
      // navigate('/assets')
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

  }

  const handleShowMaintenanceModal = () => {
    setShowMaintenanceModal(true)
  }
  const handleCloseMaintenanceModal = () => {
    setShowMaintenanceModal(false)
  }
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setdeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setdeleteDataArr([])
    }
  }
  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected: any) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId: any) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
    setdeleteDataArr((prevSelected: any) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId: any) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }
  const deleteSelectedAssests = async () => {
    const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id));
    console.log(data)
    setSelectedAssets(data);
    setdeleteDataArr([])
    setSelectAll(false)
    setSelected([])

  }

  const handleCloseSiteModal = () => {
    setShowSiteModal(false)
  }

  const handleShowSiteModal = () => {
    setShowSiteModal(true)
  }

  const handleCloseLocationModal = () => {
    setShowLocationModal(false)
  }

  const handleShowLocationModal = () => {
    setShowLocationModal(true)
  }

  const handleCloseDepartmentModal = () => {
    setShowDepartmentModal(false)
  }

  const handleShowDepartmentModal = () => {
    setShowDepartmentModal(true)
  }


  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Maintenance' })}</PageTitle>


      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0 '>
            <h3 className='fw-bolder m-0'><span className='me-2 align-middle'><i className='la la-cogs fs-1'></i></span> Maintenance</h3>

          </div>

        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-9'>
              <div className='pb-5'>
                {' '}
                <p className='fw-bolder m-0 mb-3'>Bulk define maintenance of assets.</p>
                <div className='card-footer d-flex justify-content-start border-0 p-0'>
                  <button
                    type="button"
                    className='btn btn-light-primary me-3'
                    onClick={() => handleShowMaintenanceModal()}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />

                    Select Assets
                  </button>
                  <MaintenanceModal show={showMaintenanceModal} setPendingAssets={setPendingAssets} selectedAssets={selectedAssets} handleClose={handleCloseMaintenanceModal} />
                </div>
                {selectedAssets && selectedAssets?.length > 0 ?
                  <>
                    <div className="separator border-2 my-10"></div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='fw-bolder m-0'>Assets Pending Maintenance</p>
                      {deleteDataArr.length > 0 && (
                        <button
                          type='button'
                          className='btn btn-danger  mb-2'
                          onClick={() => {
                            deleteSelectedAssests()
                          }}
                        >
                          Delete Selected
                        </button>
                      )}
                    </div>
                    <div className="checkout-listing-table mb-3">
                      <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        role='table'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th colSpan={1} role='columnheader' className='w-10px pe-2'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                <input
                                  type='checkbox'
                                  className='form-check-input'
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </div>
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Asset Tag ID{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Description{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Status{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Assigned to{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Site{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                              {' '}
                              Location{' '}
                            </th>
                            <th colSpan={1} role='columnheader' className='min-w-125px'>
                                            {' '}
                                            Lease to{' '}
                                        </th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                          {selectedAssets?.length > 0 ? (
                            selectedAssets?.map((item: any) => {
                              return (
                                <tr role='row'>
                                  <td role='cell' className=''>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                      <input
                                        type='checkbox'
                                        className='form-check-input'
                                        checked={selected.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                      />
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.assetTagId}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.description}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      {/* <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.status}</a>
                                    </div> */}
                                      <div className='d-flex flex-column'>{item.statusType === 'check_out' ? 'Checked Out' : item.statusType === "lease" ? "Leased" : item.statusType === "dispose" ? "Disposed" : item.statusType === "available" ? "Available" : item.statusType}</div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.assetstatus && item?.assetstatus?.statusType !== 'lease' ? item?.location ? item?.site?.name + "/" + item?.location?.location : item?.site?.name : item?.assetstatus?.statusType == 'lease' ? "" : ''}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <a href='#'>{item?.site?.name}</a>
                                      </div>
                                      {/* <div className='d-flex flex-column'>----</div> */}
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      {/* <div className=' overflow-hidden me-3'>
                                        <a href=''></a>
                                    </div> */}
                                      <div className='d-flex flex-column'>{item?.location?.location}</div>
                                    </div>
                                  </td>
                                  <td role='cell' className=''>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>{item?.statusType=='lease' && item?.assetslease? 
                                                                item?.assetslease?.customer?.fullName:''}</div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                  No records found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="separator border-2 my-10"></div>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                      <div className="row">
                        <div className="border-top">
                          <div className="row mt-3">

                            <div className="col-lg-6">

                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Maintenance Title</label>
                                <div className='col-lg-9 fv-row'>
                                  <input
                                    placeholder=''
                                    type='text'
                                    className='form-control form-control-solid mb-3 mb-lg-0'
                                    autoComplete='off'
                                    {...formik.getFieldProps('maintenanceTitle')}
                                    onChange={(e) => {
                                      formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                    }}
                                  />
                                  {formik.touched.maintenanceTitle && formik.errors.maintenanceTitle && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>{formik.errors.maintenanceTitle}</div>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>Maintenance Details</label>
                                <div className='col-lg-9 fv-row'>
                                  <textarea className="form-control"
                                    {...formik.getFieldProps('maintenanceDetails')}
                                    onChange={(e) => {
                                      formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                    }}
                                    data-val-length="Maximum length is 1000."
                                    data-val-length-max="1000"
                                    cols={20} rows={4}
                                    data-val="true"
                                    id="Notes"
                                    name="maintenanceDetails">
                                  </textarea>
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label  fw-bold fs-6'>Maint. Due Date</label>
                                <div className='col-lg-9 fv-row'>
                                  <div className="input-group">
                                    <input
                                      type="date"
                                      className="form-control form-control-lg form-control-solid"
                                      aria-label="Amount (to the nearest dollar)"
                                      {...formik.getFieldProps('dueDate')}
                                      onChange={(e) => {
                                        formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                      }}
                                    />
                                    <span className="input-group-text border-0"><i className="fa fa-calendar"></i></span>
                                  </div>
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>Maintenance By</label>
                                <div className='col-lg-9 fv-row'>
                                  <textarea className="form-control"
                                    data-val-length="Maximum length is 1000."
                                    data-val-length-max="1000"
                                    cols={20} rows={4}
                                    data-val="true"
                                    id="Notes"
                                    {...formik.getFieldProps('maintenanceBy')}
                                    onChange={(e) => {
                                      formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                    }}></textarea>
                                </div>
                              </div>



                            </div>
                            <div className="col-lg-6">

                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                  <span className=''>Maintenance Status</span>
                                </label>
                                <div className='col-lg-9 fv-row'>
                                  <select
                                    {...formik.getFieldProps('maintenanceStatus')}
                                    onChange={(e) => {
                                      formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                    }}
                                    className='form-select form-select-solid form-select-lg'
                                  >
                                    <option value=''>Select ...</option>
                                    <option value='scheduled'>Scheduled</option>
                                    <option value='inProgress'>In progress</option>
                                    <option value='onHold'>On Hold</option>
                                    <option value='cancelled'>Cancelled</option>
                                    <option value='completed'>Completed</option>
                                  </select>
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>Date Completed</label>
                                <div className='col-lg-9 fv-row'>
                                  <div className="input-group">
                                    <input type="date"
                                      className="form-control form-control-lg form-control-solid"
                                      aria-label="Amount (to the nearest dollar)"
                                      {...formik.getFieldProps('leaseExpires')}
                                      onChange={(e) => {
                                        formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                      }}
                                    />
                                    <span className="input-group-text border-0"><i className="fa fa-calendar"></i></span>
                                  </div>
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label  fw-bold fs-6'>Maintenance Cost</label>
                                <div className='col-lg-9 fv-row'>
                                  <div className="input-group currency-field">
                                    <span className="input-group-text border-0">{currencyLogo}</span>
                                    <input type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      aria-label="Amount (to the nearest dollar)"
                                      {...formik.getFieldProps('cost')}
                                      onChange={(e) => {
                                        formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row mb-6">
                                <label className="col-lg-3 col-form-label  fw-bold fs-6">Repeating</label>
                                <div className='col-lg-9 fv-row'>
                                  <div className="d-flex">
                                    <div className="m-2 form-check form-check-custom form-check-solid form-check-sm fw-bold">
                                      <input className="" {...formik.getFieldProps('maintenanceReapting')} onClick={() => { setRepeatingMaintenance(true); }} type="radio" value={'yes'} checked={repeatingMaintenance == false ? false : true} name={'checkedTo'} id="person" />
                                      <label className="form-check-label" htmlFor="person" >
                                        Yes
                                      </label>
                                    </div>
                                    <div className="m-2 form-check form-check-custom form-check-solid form-check-sm fw-bold">
                                      <input className="" type="radio" {...formik.getFieldProps('maintenanceReapting')} onClick={() => { setRepeatingMaintenance(false); }} value={'no'} checked={repeatingMaintenance == false ? true : false} name={'checkedTo'} id="location" />
                                      <label className="form-check-label" htmlFor="location">
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={repeatingMaintenance ? "row mb-6" : "d-none"}>
                                <label className="col-lg-3 col-form-label  fw-bold fs-6">Frequency</label>
                                <div className='col-lg-9 fv-row'>
                                <div className="d-flex">
                                  <div className="m-2 form-check form-check-custom form-check-solid form-check-sm fw-bold">
                                    <input className="" {...formik.getFieldProps('maintenanceFrequency')} onClick={() => { setRepeatingFrequency('daily'); }} type="radio" value={'daily'} checked={repeatingFrequency == "daily" ? true : false} name={'frequency'} id="person" />
                                    <label className="form-check-label" htmlFor="person" >
                                      Daily
                                    </label>
                                  </div>
                                  <div className="m-2 form-check form-check-custom form-check-solid form-check-sm fw-bold">
                                    <input className="" {...formik.getFieldProps('maintenanceFrequency')} onClick={() => { setRepeatingFrequency('weekly'); }} type="radio" value={'weekly'} checked={repeatingFrequency == "weekly" ? true : false} name={'frequency'} id="person" />
                                    <label className="form-check-label" htmlFor="person" >
                                      Weekly
                                    </label>
                                  </div><div className="m-2 form-check form-check-custom form-check-solid form-check-sm fw-bold">
                                    <input className="" {...formik.getFieldProps('maintenanceFrequency')} onClick={() => { setRepeatingFrequency('monthly'); }} type="radio" value={'monthly'} checked={repeatingFrequency == "monthly" ? true : false} name={'frequency'} id="person" />
                                    <label className="form-check-label" htmlFor="person" >
                                      Monthly
                                    </label>
                                  </div><div className="m-2 form-check form-check-custom form-check-solid form-check-sm fw-bold">
                                    <input className="" {...formik.getFieldProps('maintenanceFrequency')} onClick={() => { setRepeatingFrequency('yearly'); }} type="radio" value={'yearly'} checked={repeatingFrequency == "yearly" ? true : false} name={'frequency'} id="person" />
                                    <label className="form-check-label" htmlFor="person" >
                                      Yearly
                                    </label>
                                  </div>
                                </div>
                                </div>
                              </div>
                              {repeatingFrequency == 'weekly' || repeatingFrequency == 'monthly' || repeatingFrequency == 'yearly' &&
                                <label className='col-lg-3 col-form-label  fw-bold fs-6'>Recur on:</label>
                              }

                              <div className={repeatingFrequency == 'weekly' || repeatingFrequency == 'monthly' ? 'row mb-6' : 'd-none'}>
                                <label className='col-lg-3 col-form-label  fw-bold fs-6'>Every</label>
                                <div className='col-lg-9 fv-row'>
                                  <div className="input-group">
                                    <input type="number"
                                      className="form-control form-control-lg form-control-solid"
                                      {...formik.getFieldProps('recureOnEvery')}
                                      onChange={(e) => {
                                        formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={repeatingFrequency == 'yearly' ? 'row mb-6' : 'd-none'}>
                                <label className='col-lg-3 col-form-label  fw-bold fs-6'>Every</label>
                                <div className='col-lg-9 fv-row'>
                                  <div className="input-group">
                                    <select
                                      {...formik.getFieldProps('recureOnEvery')}
                                      onChange={(e) => {
                                        formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                      }}
                                      className='form-select form-select-solid form-select-lg'
                                    >
                                      <option value="">Select Month</option>
                                      <option value="january">January</option>
                                      <option value="february">February</option>
                                      <option value="march">March</option>
                                      <option value="april">April</option>
                                      <option value="may">May</option>
                                      <option value="june">June</option>
                                      <option value="july">July</option>
                                      <option value="august">August</option>
                                      <option value="september">September</option>
                                      <option value="october">October</option>
                                      <option value="november">November</option>
                                      <option value="december">December</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className={repeatingFrequency == 'weekly' ? 'row mb-6' : 'd-none'}>
                                <label className='col-lg-3 col-form-label  fw-bold fs-6'>On</label>
                                <div className='col-lg-9 fv-row'>
                                  <div className="input-group">
                                    <select
                                      {...formik.getFieldProps('recureOn')}
                                      onChange={(e) => {
                                        formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                      }}
                                      className='form-select form-select-solid form-select-lg'
                                    >
                                      <option value="">Please Select Day</option>
                                      <option value="monday">Monday</option>
                                      <option value="tuesday">Tuesday</option>
                                      <option value="wednesday">Wednesday</option>
                                      <option value="thursday">Thursday</option>
                                      <option value="friday">Friday</option>
                                      <option value="saturday">Saturday</option>
                                      <option value="sunday">Sunday</option>

                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className={repeatingFrequency == 'yearly' || repeatingFrequency == 'monthly' ? 'row mb-6' : 'd-none'}>
                                <label className='col-lg-3 col-form-label  fw-bold fs-6'>On</label>
                                <div className='col-lg-9 fv-row'>
                                  <div className="input-group">
                                    <select
                                      {...formik.getFieldProps('recureOn')}
                                      onChange={(e) => {
                                        formik.handleChange(e); // Make sure to call formik.handleChange to update Formik state
                                      }}
                                      className='form-select form-select-solid form-select-lg'
                                    >
                                      <option value="">Please Select..</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                      <option value="11">11</option>
                                      <option value="12">12</option>
                                      <option value="13">13</option>
                                      <option value="14">14</option>
                                      <option value="15">15</option>
                                      <option value="16">16</option>
                                      <option value="17">17</option>
                                      <option value="18">18</option>
                                      <option value="19">19</option>
                                      <option value="20">20</option>
                                      <option value="21">21</option>
                                      <option value="22">22</option>
                                      <option value="23">23</option>
                                      <option value="24">24</option>
                                      <option value="25">25</option>
                                      <option value="26">26</option>
                                      <option value="27">27</option>
                                      <option value="28">28</option>
                                      <option value="29">29</option>
                                      <option value="30">30</option>
                                      <option value="31">31</option>

                                    </select>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>




                        <div className=' d-flex align-items-center justify-content-start'>
                          <button
                            type='submit'
                            className='btn btn-primary me-3'
                          >
                            Add
                          </button>
                          <a href="/maintenance" className='btn btn-secondary maib-btn-style'>
                            Cancel
                          </a>
                        </div>
                        {/* <div className="card-footer text-end text-md-start Step2Block" >
                        <div className="row">
                          <div className="col-md-9 col-lg-10">
                            <button id="SubmitBtn" type="submit" className="btn btn-primary me-3">Check-Out</button>
                    {/* <button onClick={() => { navigate('/apps/setup/assets') }} className="btn btn-secondary">Cancel</button> */}
                        {/* <Link to={'/assets'} className="btn btn-secondary">Cancel</Link> */}

                      </div>
                    </form>
                    {/* <button onClick={() => { navigate('/apps/setup/assets') }} className="btn btn-secondary">Cancel</button> */}
                  </>
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Maintenance