import {IProfileDetails, UserResponse} from './SettingsModel'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const USER_PROFILE = `${API_URL}/user`


export function UpdateProfile(values: IProfileDetails) {
    const formData = new FormData();
  
    for (const key of Object.keys(values)) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        if(values){ 
            const value = values[key as keyof IProfileDetails];    
            formData.append(key, value);
        }
      }
    }
  
    return axios.put(`${USER_PROFILE}/profile`, formData);
  }

export function GetProfileDetail(id : number) { 
    return axios.get(`${USER_PROFILE}/${id}`)
    .then(response => {
      return response.data; // return the data property of the response object
    })}


 