/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useGetCompanyQuery } from 'app/modules/services/company'
import { useAuth } from 'app/modules/auth'
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify'

export function AsideMenuMain() {
  const intl = useIntl()  
  const { currentUser } = useAuth();
  const { data: companyData } = useGetCompanyQuery(currentUser?.id)
  type CustomAsideMenuItemIcon = React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (currentUser) {
      if (currentUser) {
        if (Array.isArray(companyData?.company) && location.pathname !== '/apps/setup/company') {
          toast.dismiss();
          navigate('/apps/setup/company')
          setTimeout(() => {
            toast.info('Company information is required!')
          }, 700);
        }
      }
    }
  }, [currentUser, location.pathname])
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon={"las la-home fs-2 me-3"}
        title={"Dashboard"}
      />

      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
      /> */}

      <AsideMenuItemWithSub
        to='/apps/asset'
        title='Assets'
        icon={"las la-puzzle-piece fs-2 me-3"}
      >
        <AsideMenuItem to='/assets' title='List of Assets' icon={"las la-list fs-2 me-3"} />
        <AsideMenuItem
          to='/asset/add'
          icon={"la la-plus-circle fs-2 me-3"}
          title={'Add an Assets'}
        />
        <AsideMenuItem
          to='/checkout'
          icon={"las la-user-check fs-2 me-3"}
          title={'Check out'}
        />
        <AsideMenuItem
          to='/checkin'
          icon={"las la-user-times fs-2 me-3"}
          title={'Check in'}
        />                             
        <AsideMenuItem
          to='/lease'
          icon={"las la-paper-plane fs-2 me-3"}
          title={'Lease'}
        />
        <AsideMenuItem
          to='/lease-return'
          icon={"las la-paper-plane fs-2 me-3"}
          title={'Lease Return'}
        />
        <AsideMenuItem
          to='/dispose'
          icon={"las la-recycle fs-2 me-3"}
          title={'Dispose'}
        />
        <AsideMenuItem
          to='/maintenance'
          icon={"las la-cogs fs-2 me-3"}
          title={'Maintenance'}
        />
        <AsideMenuItem
          to='/move'
          icon={"las la-arrows-alt fs-2 me-3"}
          title={'Move'}
        />
        <AsideMenuItem
          to='/reserve'
          icon={"la la-calendar fs-2 me-3"}
          title={'Reserve'}
        />
        {/* <AsideMenuItem to='/crafted/pages/account-details/overview' title='Account-Details' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/apps/setup'
        title='Setup'
        icon={"las la-cog fs-2 me-3"}
      >
        {/* <AsideMenuItem to='/apps/setup/assets' title='Assets' hasBullet={true} /> */}
        {!companyData?.company ?
          <AsideMenuItem to='/apps/setup/company' title='Company Info.' icon={"las la-briefcase fs-2 me-3"} />
          :
          <>
            <AsideMenuItem to='/apps/setup/company' title='Company Info.' icon={"las la-briefcase fs-2 me-3"} />
            <AsideMenuItem to='/apps/setup/sites' title='Sites' icon={"las la-map-marker fs-2 me-3"} />
            <AsideMenuItem to='/apps/setup/location' title='Location' icon={"las la-map-signs fs-2 me-3"} />
            <AsideMenuItem to='/apps/setup/categories' title='Categories' icon={"far fa-list-alt fs-3 me-3"} />
            <AsideMenuItem to='/apps/setup/department' title='Departments' icon={"las la-border-all fs-2 me-3"} />
            <AsideMenuItemWithSub
              to='/apps/setup/database'
              title='Databases'
              icon={"las la-database fs-2 me-3"}
            >
              <AsideMenuItem to='/apps/setup/database/asset' title='Assets Table' hasBullet={true} />
              <AsideMenuItem to='/apps/setup/database/person' title='Persons/Employees' hasBullet={true} />
              <AsideMenuItem to='/apps/setup/database/customer' title='Customer Table' hasBullet={true} />
              <AsideMenuItem to='/apps/setup/database/maintenance' title='Maintenance Table' hasBullet={true} />
              <AsideMenuItem to='/apps/setup/database/warranty' title='Warranties Table' hasBullet={true} />
              <AsideMenuItem to='/apps/setup/database/contract' title='Contract Table' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItem to='/apps/setup/events' title='Events' icon={"las la-calendar-check fs-1 me-3"} />
            <AsideMenuItem to='/apps/setup/table' title='Table Options' icon={"las la-table fs-1 me-3"} />
            <AsideMenuItem to='/apps/setup/options' title='Options' icon={"lab la-elementor fs-1 me-3"} />
            <AsideMenuItem to='/apps/setup/customEmails' title='Customize Emails' icon={"lab la la-mail-bulk fs-1 me-3"} />

          </>
        }
        {/* <AsideMenuItem to='/dashboard/manage' title='Manage Dashboard' hasBullet={true} /> */}

      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/apps/tools'
        title='Tools'
        icon={"la la-wrench fs-2 me-3"}
      >
        <AsideMenuItem to='/import' title='Import' icon={"la la-cloud-upload fs-2 me-15"} />
        <AsideMenuItem to='/export' title='Export' icon={"la la-cloud-download fs-2 me-15"} />
      </AsideMenuItemWithSub>
      
      <AsideMenuItemWithSub
        to='/apps/advanced'
        title='Advanced'
        icon={"la la-toolbox fs-2 me-3"}
      >
        <AsideMenuItem to='/persons' title='Persons/Employees' icon={"la la-user-tie fs-2 me-15"} />
        <AsideMenuItem to='/customers' title='Customers' icon={"la la-users fs-2 me-15"} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>


      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        icon='/media/icons/duotune/ecommerce/ecm007.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} /> */}
      {/* <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} /> */}
      {/* <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          /> */}
      {/* </AsideMenuItemWithSub> */}

      {/* <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub> */}
      {/* </AsideMenuItemWithSub> */}



      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon={"las la-user fs-2 me-3"}
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        {/* <AsideMenuItem to='/crafted/pages/account-details/overview' title='Account-Details' hasBullet={true} /> */}
      </AsideMenuItemWithSub>


      {/* <AsideMenuItemWithSub
        to='/crafted/pages/account-details'
        title='Account Details'
        icon='/media/icons/duotune/communication/com006.svg'
      > */}
      {/* <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} /> */}
      {/* </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
      /> */}

      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
