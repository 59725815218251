import {useAuth} from '../../../../app/modules/auth'
import {KTSVG, toAbsoluteUrl, toAbsoluteUrlImage} from '../../../helpers'
import {HeaderUserMenu, Search} from '../../../partials'
import Avatar from "../../../../_metronic/assets/images/avatar.jpg"
/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const {currentUser} = useAuth()
  return (
    <div className='d-flex align-items-center'>
      <div className='w-100' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-start' data-kt-menu-overflow='false'>
        <div className='aside-user py-5'>
          <div className='d-flex align-items-sm-center justify-content-center cursor-pointer'>
            <div className='symbol symbol-50px'>
              <img className='image-input-wrapper ' src={currentUser?.photo ? toAbsoluteUrlImage(currentUser?.photo) : Avatar } alt="profile"/>
            </div>
            <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
              <div className='d-flex'>
                <div className='flex-grow-1 me-2'>
                  <h2 className='card-title fs-6 fw-bold'>
                    {currentUser?.firstName} {currentUser?.lastName}
                  </h2>
                  <span className='text-gray-600 fw-bold d-block fs-8'>{currentUser?.title}</span>
                </div>
                <div className='me-n2'>
                    <KTSVG
                      path='/media/icons/duotune/coding/cod001.svg'
                      className='svg-icon-muted svg-icon-12'
                    />
                  <HeaderUserMenu />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      {/* <div className='aside-search py-5'>
        <Search />
      </div> */}
    </div>
  )
}

export {AsideToolbar}
